import { useEffect } from "react"

/**
 * A shortcut hook for useEffect with empty [] dependency array
 * it helps to reproduce behaviour of "componentDidMount" and
 * avoids repeatedly disabling "react-hooks/exhaustive-deps"
 *
 * @param effectCallback Imperative function that can return a cleanup function
 */
const useEffectOnce = (effectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effectCallback, [])
}

export default useEffectOnce
