export const INTERVAL_HOURLY = "1h";
export const INTERVAL_DAILY = "24h";
export const DATETIME_FORMAT_YYYY_MM_DD_HH_MM = "yyyy-MM-dd, HH:mm";
export const DATETIME_FORMAT_YYYY_MM_DD = "yyyy-MM-dd";

export const HOUR_MILIS = 3600000;
export const DAY_MILIS = 86400000;

export const GRAPH_GRID = "graphGrid";
export const AVERAGE = "average";
export const MIN = "min";
export const MAX = "max";

export const INTERVAL_OPTIONS = new Map([
  [INTERVAL_HOURLY, "text_hourly"],
  [INTERVAL_DAILY, "text_daily"],
]);

export const SPEED_OPTIONS = [
  { label: "text_last_week", type: "week", value: 1, disableMonth: 0.25 }, // 1 week
  { label: "text_last_month", type: "month", value: 1, disableMonth: 1 }, // 1 month
  { label: "text_last_3_months", type: "months", value: 3, disableMonth: 3 }, // 3 months
  { label: "text_last_year", type: "year", value: 1, disableMonth: 12 }, // 1 year
];

export const DIAGRAM_SORT_OPTIONS = new Map([
  ["heat", "text_heat_energy"],
  ["vol", "text_flow"],
  ["st", "text_supply_temp."],
  ["rt", "text_return_temp."],
  ["dt", "text_delta_temp."],
]);

export const columns = [
  {
    key: "heat",
    name: "text_heat_energy",
    suffix: "kWh/h",
    showInLegend: true,
    color: ["graph", "orange"],
    lineColor: ["graph", "lightOrange"],
  },
  {
    key: "vol",
    name: "text_flow",
    suffix: "m³/h",
    showInLegend: true,
    color: ["graph", "green"],
    lineColor: ["graph", "lightGreen"],
  },
  {
    key: "st",
    name: "text_supply_temp",
    suffix: "°C",
    showInLegend: true,
    color: ["graph", "pink"],
    lineColor: ["graph", "lightPink"],
  },
  {
    key: "rt",
    name: "text_return_temp",
    suffix: "°C",
    showInLegend: true,
    color: ["graph", "blue"],
    lineColor: ["graph", "lightblue"],
  },
  {
    key: "dt",
    name: "text_delta_temp",
    suffix: "°C",
    showInLegend: true,
    color: ["graph", "teal"],
    lineColor: ["graph", "lightTeal"],
  },
];
