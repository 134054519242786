import { Provider, observer } from "mobx-react";
import { HistoryAdapter, RouterContext, RouterView } from "mobx-state-router";
import { IntlProvider } from "react-intl";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import ReactGA from "react-ga";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { ToastContainer } from "react-toastify";

import rootStore from "./stores/root_store";
import history from "./history";
import ThemeProvider from "./ThemeProvider";
import * as serviceWorker from "./service-worker";
import errors from "./conf/errors";
import { APP_VERSION, GA_TRACKID } from "./conf/conf";
import routeMappings from "./conf/route_mappings";
import { log } from "./core/logger";

import "./app.css";
import "react-toastify/dist/ReactToastify.css";

const env = process.env.NODE_ENV;
import(`./app.${env}.js`);

// Router History
const hAdaptor = new HistoryAdapter(rootStore.routerStore, history);
hAdaptor.observeRouterStateChanges();

// Google Analytics
ReactGA.initialize(GA_TRACKID);
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

function App() {
  return (
    <RouterContext.Provider value={rootStore.routerStore}>
      <Provider
        rootStore={rootStore}
        dash={rootStore.dash}
        session={rootStore.session}
        frcst={rootStore.frcst}
        production={rootStore.production}
        networks={rootStore.networks}
        sub={rootStore.sub}
        ui={rootStore.ui}
        sublist={rootStore.sublist}
        rta={rootStore.rta}
        distdash={rootStore.distdash}
        auth={rootStore.auth}
        pricing={rootStore.pricing}
        newapi={rootStore.newapi}
        faultDetectionApi={rootStore.faultDetectionApi}
        preport={rootStore.preport}
        sales={rootStore.sales}
        meter={rootStore.meter}
        opt_store={rootStore.opt_store}
        settingsStore={rootStore.settingsStore}
        preferences={rootStore.preferences}
        notifStore={rootStore.notifStore}
        language={rootStore.language}
      >
        <IntlProvider locale={rootStore.ui.locale} key={rootStore.ui.locale}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider themeName="new">
                <CssBaseline />
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  limit={2}
                  hideProgressBar
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="colored"
                />
                <RouterView viewMap={routeMappings} />
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </IntlProvider>
      </Provider>
    </RouterContext.Provider>
  );
}

function updateServiceWorker(waitingWorker) {
  if (waitingWorker) waitingWorker.postMessage({ type: "SKIP_WAITING" });
  window.location.reload();
}

function onServiceWorkerUpdate(registration) {
  log("new version is available", registration && registration.waiting);
  rootStore.app.gotError(errors.app_update, {
    msg: `newer version available`,
    btn: {
      title: "update",
      cb: () => {
        updateServiceWorker(registration.waiting);
      },
    },
  });
}
serviceWorker.register({ onUpdate: onServiceWorkerUpdate });

log(`PLATFORM APP:${env} [ VERSION: ${APP_VERSION} ]`);
log("Feature Flags: ", process.env.REACT_APP_FEATURES);

export default observer(App);
