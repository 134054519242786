/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import clsx from "clsx";

import { dateSelectStyles } from "./SelectMonth";

function SelectYear(props) {
  const {
    store,
    name,
    classes,
    classesOverride,
    disabled,
    onChange,
    options: userOptions,
    menuItemClasses,
    ...restProps
  } = props;
  const defaultClasses = dateSelectStyles();
  const nclasses = { ...defaultClasses, ...classes, ...classesOverride };

  const selectedYear = store[name] || 2019;
  const options = useMemo(() => {
    if (Array.isArray(userOptions) && userOptions.length === 0) {
      const years = [];
      for (let i = 0; i < 8; i++) {
        years.push(selectedYear - i + 3);
      }
      return years;
    }
    return userOptions;
  }, [userOptions, selectedYear]);

  const getSelectedIndex = useCallback(
    () => options.indexOf(selectedYear),
    [options, selectedYear]
  );
  const [selectedIndex, setSelectedIndex] = useState(getSelectedIndex);
  const [menuOpen, setOpen] = useState(false);
  const anchorRef = useRef();

  const toggleMenuOpen = useCallback(() => {
    setOpen((current) => !current);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  function handleSelectItem(_, index) {
    setSelectedIndex(index);
    const newOption = options[index];
    if (typeof onChange === "function") {
      onChange(newOption, name);
    }
    runInAction(() => {
      if (store[name]) {
        store[name] = newOption;
      }
    });
    setOpen(false);
  }

  useEffect(() => {
    setSelectedIndex(getSelectedIndex);
  }, [getSelectedIndex, options]);

  const selectedOption = options[selectedIndex]
    ? store[name] || options[selectedIndex]
    : userOptions[0];

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={toggleMenuOpen}
        color="inherit"
        {...restProps}
        className={clsx(defaultClasses.selectDate, classesOverride)}
        classes={nclasses}
        endIcon={menuOpen ? <ArrowDropUp /> : <ArrowDropDownIcon />}
        disabled={store.is_data_processed === false || disabled}
        data-testid="select-year-button"
      >
        {selectedOption}
      </Button>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  data-testid="select-year-list"
                  sx={{ maxHeight: "400px", overflowY: options?.length >= 13 && "scroll" }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleSelectItem(event, index)}
                      classes={{ root: nclasses.menuItem, selected: nclasses.menuSelected }}
                      data-testid={`year-option-${option}`}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

SelectYear.defaultProps = {
  name: "year",
  store: {},
  options: [],
  disabled: false,
  classes: {},
  classesOverride: {},
  menuItemClasses: { root: null, selected: null },
  onChange: null,
};

SelectYear.propTypes = {
  name: PropTypes.string,
  store: PropTypes.shape({ is_data_processed: PropTypes.bool }),
  classes: PropTypes.objectOf(PropTypes.string),
  classesOverride: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.number),
  menuItemClasses: PropTypes.shape({
    root: PropTypes.string || null,
    selected: PropTypes.string || null,
  }),
  onChange: PropTypes.func,
};

export default observer(SelectYear);
