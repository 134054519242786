import { createContext, useContext } from "react";
import { flow, makeAutoObservable } from "mobx";
import { DateTime } from "luxon";

import { SUBSTATION_BLOCK_TYPES as SBT, blkReader } from "../../../conf/blocks";
import { CLUSTER, SUBSTATION } from "../../Networks/Constants";
import { getUrlParam, insertUrlParam } from "../../../conf/routes";
import { isValue, tsavgderive, tsderive } from "../../../core/utils";

import * as CONSUMPTION from "./Constants";
import { sortedAgg } from "./utils";

const CHART_WIDTH_OFFSET = 195;

export class ConsumptionStore {
  isDataAvailable = false;

  areReadingsAveragedForDaily = false;
  flowLimiter = null;
  interval = CONSUMPTION.INTERVAL_HOURLY;
  chartRef = null;
  /**
   * Immediately updated boundiries/extremes by all components from @FilterBar
   * See @ChartNavigator Component
   */
  extremes = null;
  prevExtremes = null;
  /**
   * Stores the dates for currently fetched data range
   * It will be updated only with Laad More section
   * See @FetchRangeSelector Component
   */
  fetchedRange = null;
  /**
   * Debounced value from @extremes
   * Its used to filter data for second and third charts with @tsavgderive @tsderive utils
   * See @ScatterMetrics and @SortedMetrics Components
   */
  readerRange = null;

  constructor(parent) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.parent = parent;
    this.notify = parent.notifStore;

    const networkDate = this.parent.networks.CurrentHour;
    this.fetchedRange = {};
    // beginning of two years back
    this.fetchedRange.start = this.parent.networks.dtFromYM(networkDate.year - 2, 1, "start");
    // end of current hour
    this.fetchedRange.end = networkDate.set({ hours: DateTime.now().hour + 1 });

    // Populate initial fetch ranges based on query strings
    if (getUrlParam("investigate")) {
      this.fetchedRange.start = DateTime.fromFormat(
        getUrlParam("start_date"),
        CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD
      );
      this.fetchedRange.end = DateTime.fromFormat(
        getUrlParam("end_date"),
        CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD
      ).endOf("day");
    }
  }

  reset() {
    this.data = null;
    this.extremes = {
      start: this.fetchedRange.start,
      end: this.fetchedRange.end.minus({ hours: 1 }),
    };
    this.readerRange = { start: this.fetchedRange.start, end: this.fetchedRange.end };
  }

  onIntervalChange(value) {
    const prevInterval = this.interval;
    this.interval = value;
    const isDaily = value === CONSUMPTION.INTERVAL_DAILY;
    this.averaged = isDaily;
    this.areReadingsAveragedForDaily = isDaily;

    if (prevInterval !== this.interval) {
      if (isDaily) {
        this.prevExtremes = this.extremes;
        // Set hours to midnight on daily interval
        this.extremes = {
          start: this.extremes.start.set({ hour: 0 }),
          end: this.extremes.end.set({ hour: 0 }),
        };
        return;
      }

      // Sync extreme range end with the chart on switching back to the hourly interval
      this.extremes = this.prevExtremes;
    }
  }

  registerChart(ref) {
    this.chartRef = ref;
  }

  setExtremes(start, end) {
    this.extremes = { start, end };
  }

  setFetchedRange(start, end) {
    this.fetchedRange.start = start;
    this.fetchedRange.end = end;
    // Set the query parameters for the dates
    if (getUrlParam("investigate")) {
      insertUrlParam("start_date", start.toISODate());
      insertUrlParam("end_date", end.toISODate());
    }
  }

  // this replicates the same behavior as networks.dtFromYM
  // which will enable "tsderive" to include last point as the chart
  shiftReaderEndRange(date) {
    if (!date) return date;
    let endDate = date.plus({ seconds: 1 });
    if (this.averaged) endDate = date.plus({ day: 1 });
    return endDate;
  }

  setReaderRange(start, end) {
    this.readerRange = {
      start,
      end: this.shiftReaderEndRange(end),
    };
  }

  get reader() {
    if (!this.isDataAvailable || !this.data) return null;

    return blkReader(this.data, [
      ["name", ["meter", "ts"], "ts"],
      ["name", ["meter", "heat_energy"], "heat"],
      ["name", ["meter", "volume"], "vol"],
      ["name", ["meter", "supplytemp"], "st"],
      ["name", ["meter", "returntemp"], "rt"],
      ["derive", (r) => r.st - r.rt, "dt"],

      ["name", ["weather", "t"], "outdoor"],
    ]);
  }

  get seriesData() {
    if (!this.isDataAvailable) return {};

    const start = this.fetchedRange?.start.toMillis();
    const end = this.fetchedRange?.end.toMillis();
    const delta = CONSUMPTION.HOUR_MILIS;

    const dataFns = [];
    [...CONSUMPTION.columns, { key: "outdoor" }, { key: "ts" }].forEach((col) => {
      dataFns.push([
        col.key,
        (row, rarr) => {
          if (isValue(row[col.key])) {
            rarr.push(row[col.key]);
          } else {
            rarr.push(null);
          }
        },
      ]);
    });

    const original = this.areReadingsAveragedForDaily
      ? tsavgderive({
          reader: this.reader,
          start,
          end,
          delta,
          avgdelta: CONSUMPTION.DAY_MILIS,
          fns: dataFns,
          mapper: () => [],
          agg: sortedAgg,
        })
      : tsderive({
          reader: this.reader,
          start,
          end,
          delta,
          fns: dataFns,
        });

    // returning above directly causes mobx "cycle" error
    // TODO: find out how to avoid this
    return { original };
  }

  get seriesInRanges() {
    if (!this.seriesData.original) return [];

    return this.seriesData.original.filter(
      (row) => row.ts >= this.readerRange.start.ts && row.ts <= this.readerRange.end.ts
    );
  }

  get seriesInExtremes() {
    if (!this.seriesData.original) return [];

    const end = this.shiftReaderEndRange(this.extremes.end);
    return this.seriesData.original.filter(
      (row) => row.ts >= this.extremes.start.ts && row.ts <= end.ts
    );
  }

  get chartWidth() {
    const { ui } = this.parent;
    return ui.main_area + CHART_WIDTH_OFFSET;
  }

  getGraphsData = flow(function* getGraphsData() {
    this.isDataAvailable = false;
    this.flowLimiter = null;
    this.data = {};

    const { sub, networks, newapi, ui } = this.parent;
    const lastProcessedMonth = networks.lpMonth;

    const resourceType = ui.is_subsummary_open ? SUBSTATION : CLUSTER;
    const resourceId =
      resourceType === SUBSTATION ? sub.current_substation : sub.current_cluster.id;

    const substationOrClusterData = yield newapi.getInfoBlocksV4({
      network_id: networks.current_network.uid,
      resource_id: resourceId,
      resource_type: resourceType,
      block_names: [
        SBT.pricing.to_block_name(),
        SBT.core.to_block_name({
          year: lastProcessedMonth.year,
          month: lastProcessedMonth.month,
        }),
        SBT.location.to_block_name(),
      ],
    });
    const dataReader = blkReader(substationOrClusterData, [
      ["name", [SBT.pricing.to_block_name(), "flow_limit"], "flow_limit"],
      ["name", [SBT.location.to_block_name(), SBT.location.col.weather_coordinates], "coords"],
    ]);
    this.flowLimiter = dataReader(sub.current_substation).flow_limit;

    let [weather, meter] = [null, null];
    try {
      [weather, meter] = yield Promise.all([
        newapi.getWeatherDataV4({
          resource_id: resourceId,
          resource_type: resourceType,
          network_id: networks.current_network.uid,
          coordinates: dataReader(sub.current_substation).coords,
          date_min: this.fetchedRange.start,
          date_max: this.fetchedRange.end,
        }),
        newapi.getMeterDataV4({
          resource_uid: resourceId,
          resource_type: resourceType,
          network_id: networks.current_network.uid,
          ts_start: this.fetchedRange.start,
          ts_end: this.fetchedRange.end,
          stage: "clean",
          meter: "primary",
          components: ["heat_energy", "volume", "supplytemp", "returntemp"],
        }),
      ]);
    } catch (err) {
      this.notify.error("Network Error! Cannot fetch details.", err.error);

      this.data = null;
      this.isDataAvailable = false;
      return;
    }

    this.data = { weather, meter };
    this.isDataAvailable = !!weather && !!meter;
  });
}

export const StateContext = createContext();

export default function useConsumption() {
  return useContext(StateContext);
}
