import { runInAction } from "mobx";
import { useLocalObservable } from "mobx-react";

import { blkReader, collectorBlockPath } from "../../../conf/blocks";
import {
  COLUMN_PERIOD,
  generateBlockNames,
  generateColumnsBySBT,
  generateSpecs,
  getPeriodArgs,
} from "../../../conf/Columns";
import { formatNumberForLocale } from "../../../core/utils";
import useMobxSelector from "../../../hooks/useMobxSelector";
import useReaction from "../../../hooks/useReaction";
import useStores from "../../../hooks/useStores";
import { SUBSTATION } from "../constant";

const useSummary = ({ type }) => {
  const { networks, ui, sub, newapi } = useStores();
  const local = useLocalObservable(() => ({
    dataFetched: false,
    data: {},
    blockData: {},
    clusterSubstation: {},
    year: networks.lpYear.year,
    activePeriod: COLUMN_PERIOD.year,
    resourceId: type === SUBSTATION ? sub.current_substation : sub.current_cluster.id,

    get columns() {
      //* default side menu SBT blocks
      const SBTBlocks = [
        "core",
        "customer",
        "pricing",
        "ep_design_24h",
        "install_address",
        "epcore_normalized",
      ];

      const generatedColumns = generateColumnsBySBT(local.activePeriod, SBTBlocks);
      return [
        {
          id: "id",
          path: "id",
          blockName: () => (_, indexValue) => networks.current_substations.get(indexValue),
        },
        ...generatedColumns,
      ];
    },

    get periodArgs() {
      const { year } = getPeriodArgs(local.year, null, local.activePeriod, networks);
      return { year };
    },

    blockReader: ({ data }) =>
      blkReader(data, generateSpecs(local.columns, local.periodArgs), collectorBlockPath),

    fetchData: async () => {
      runInAction(() => {
        local.blockData = {};
        local.dataFetched = false;
      });
      const result = await newapi.getInfoBlocksV4({
        resource_id: local.resourceId,
        resource_type: type,
        network_id: networks.current_network.uid,
        block_names: generateBlockNames(local.columns, local.periodArgs),
      });

      if (!result) return;

      runInAction(() => {
        local.blockData = result || {};
        const dataReader = local.blockReader({ data: local.blockData });
        local.data = dataReader(local.resourceId);
        local.dataFetched = true;
      });
    },

    goToExplore: () => {
      if (type === SUBSTATION) {
        ui.goToExploreFromSummary();
      } else {
        ui.goToExploreFromClusterSummary();
      }
    },

    handleClickAway: () => {
      if (ui.is_subsummary_open) {
        ui.closeSubsummary();
      }
    },

    calculateDeltaTemp: (supplyTemp, returnTemp) => {
      const res = supplyTemp - returnTemp;
      return !res ? null : formatNumberForLocale(res);
    },

    calculateFullLoadEnergy: (energySum, heatEnergy) => {
      const res = energySum / heatEnergy;
      return !res ? null : formatNumberForLocale(res);
    },
  }));

  useReaction(
    () => [
      ui.is_cluster_summary_open,
      ui.is_subsummary_open,
      sub.current_substation,
      sub.current_cluster?.id,
    ],
    async ([isClusterSummaryOpen, isSubstationSummaryOpen, currentSubstation, currentCluster]) => {
      networks.refreshTime();
      if (currentSubstation || currentCluster) {
        runInAction(() => {
          local.resourceId = type === SUBSTATION ? sub.current_substation : sub.current_cluster?.id;
        });
      }
      if (isClusterSummaryOpen || isSubstationSummaryOpen) {
        await local.fetchData();
      }
    },
    { fireImmediately: true, delay: 100 }
  );

  return useMobxSelector(() => ({
    dataFetched: local.dataFetched,
    data: local.data,
    blockData: local.blockData,
    year: local.year,
    activePeriod: local.activePeriod,
    columns: local.columns,
    periodArgs: local.periodArgs,

    goToExplore: local.goToExplore,
    handleClickAway: local.handleClickAway,
    calculateDeltaTemp: local.calculateDeltaTemp,
    calculateFullLoadEnergy: local.calculateFullLoadEnergy,
  }));
};

export default useSummary;
