import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Button, Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import useStores from "../../../hooks/useStores";
import useReaction from "../../../hooks/useReaction";
import { HeadingBar } from "../../../shared_components/ui/layout";

import useConsumption from "./state";
import { DATETIME_FORMAT_YYYY_MM_DD } from "./Constants";
import ConsumptionDatePicker from "./ConsumptionDatePicker";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.palette.grey.white,
    padding: theme.spacing(0, 2),
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
  },
  actions: {
    display: "flex",
    height: "100%",
    "& > * ": {
      display: "flex",
      border: `solid ${theme.palette.grey.blue2}`,
      borderWidth: "0 1px 0 1px",
      alignItems: "center",
      padding: theme.spacing(2),
    },
    "& > *:last-child": {
      paddingRight: 0,
      border: 0,
    },
  },
  popover: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  space: {
    display: "flex",
    gap: theme.spacing(0, 1),
  },
  datePicker: {
    "&&": {
      border: `${theme.palette.primary.main} 1px solid`,
      width: "112px",
      height: "30px",
      paddingLeft: "4px",
      "& svg": {
        color: theme.palette.primary.main,
        fontSize: "25px",
        marginTop: "4px",
      },
    },
  },
  popoverButton: {
    width: "115px",
  },
  dateRange: {
    gap: "5px",
    ...theme.typography.h6,
  },
}));

const DATA_PICKER_VIEW = ["year", "month", "day"];

function FetchRangeSelector({ title }) {
  const classes = useStyles();
  const { t } = useTranslation(["extendView", "_action"]);
  const consumption = useConsumption();
  const { networks } = useStores();
  const [openPopover, setOpenPopover] = useState(null);
  const [dateRangeStart, setDateRangeStart] = useState(consumption.fetchedRange.start);
  const [dateRangeEnd, setDateRangeEnd] = useState(consumption.fetchedRange.end);
  const maxDate = useRef(consumption.fetchedRange.end);

  const handlePopoverClick = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setOpenPopover(null);
    setDateRangeStart(consumption.fetchedRange.start);
    setDateRangeEnd(consumption.fetchedRange.end);
  };

  const onApply = () => {
    let endDate = dateRangeEnd;
    if (consumption.areReadingsAveragedForDaily) {
      endDate = dateRangeEnd.endOf("day");
    }
    consumption.getGraphsData();
    consumption.setFetchedRange(dateRangeStart, endDate);
    setOpenPopover(null);
  };

  const handleStartDatePick = (dt) => {
    if (!dt || dt?.invalid) return;
    setDateRangeStart(dt);
  };

  const handleEndDatePick = (dt) => {
    if (!dt || dt?.invalid) return;
    setDateRangeEnd(dt);
  };

  /**
   * this will prevent the data from missing when we change from interval from "HOURLY" to "DAILY"
   * view more details in this ticket PLAT-2628
   */
  useReaction(
    () => [consumption.interval],
    () => {
      onApply();
    }
  );

  return (
    <>
      <HeadingBar
        title={t(title, { ns: "extendView" })}
        addons={[
          {
            action: (
              <div className={classes.actions}>
                <div className={classes.dateRange}>
                  {/* dont need to setTimeZone anymore since we are using ISO date in the sate file */}
                  {t("text_data_from", { ns: "extendView" })}:
                  <span data-testid="fetchedRange-start">
                    {consumption.fetchedRange.start.toFormat(DATETIME_FORMAT_YYYY_MM_DD)}
                  </span>
                  {t("text_to")}:
                  <span data-testid="fetchedRange-end">
                    {consumption.fetchedRange.end.toFormat(DATETIME_FORMAT_YYYY_MM_DD)}
                  </span>
                </div>
              </div>
            ),
          },
          {
            action: (
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handlePopoverClick}
                  disabled={!consumption.isDataAvailable}
                  data-testid="open-fetch-range-selector-btn"
                >
                  {t("action_load_more_data", { ns: "_action" })}
                </Button>
              </div>
            ),
          },
        ]}
        data-testid="fetchedRange-title"
      />
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popover}>
          <div className={classes.dateRange}>{t("text_available_data", { ns: "extendView" })}:</div>
          <div className={classes.space}>
            <ConsumptionDatePicker
              format={DATETIME_FORMAT_YYYY_MM_DD}
              value={dateRangeStart}
              onChange={handleStartDatePick}
              classname={classes.datePicker}
              views={DATA_PICKER_VIEW}
              // we have no data to know what is the oldest data we have
              // currently hardcoding to the most meaningful date (if not it goes back to 1900)
              // TODO: set this dynamically once there is backend for it
              minDate={DateTime.fromObject({ year: 1999, month: 1, day: 1 })}
              maxDate={dateRangeEnd}
              id="fetch-range-start"
            />
            <ConsumptionDatePicker
              format={DATETIME_FORMAT_YYYY_MM_DD}
              value={dateRangeEnd.setZone(networks.network_timezone)}
              onChange={handleEndDatePick}
              classname={classes.datePicker}
              views={DATA_PICKER_VIEW}
              minDate={dateRangeStart}
              maxDate={maxDate.current}
              id="fetch-range-end"
            />
          </div>
          <div className={classes.space}>
            <Button
              color="primary"
              variant="contained"
              className={classes.popoverButton}
              onClick={onApply}
              data-testid="apply-fetch-range-selector-btn"
            >
              {t("action_load", { ns: "_action" })}
            </Button>
            <Button
              color="primary"
              variant="contained"
              className={classes.popoverButton}
              onClick={handlePopoverClose}
            >
              {t("action_cancel", { ns: "_action" })}
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
}

FetchRangeSelector.defaultProps = {
  title: "",
};

FetchRangeSelector.propTypes = {
  title: PropTypes.string,
};

export default observer(FetchRangeSelector);
