import { createContext, useContext } from "react";
import { makeAutoObservable, flow, autorun } from "mobx";
import { DateTime } from "luxon";

import {
  blkReader,
  collectorBlockPath,
  getValueFromBlock,
  NETWORK_BLOCK_TYPES,
} from "../../../conf/blocks";
import { generateSpecs } from "../../../conf/Columns";
import { getUrlParam, insertUrlParam } from "../../../conf/routes";

import faultDetectionColumns from "./FaultDetection.columns";

function getFaultLevel(faults) {
  // faults is an array eg ["fg", "fy", "fy", "fr"]
  // fr is red, fy is yellow, fg is green
  // Red is the highest priority
  // Yellow is the second highest priority
  // Green is the lowest priority
  // Return the highest priority fault
  if (faults.includes("fr")) {
    return "fr";
  }
  if (faults.includes("fy")) {
    return "fy";
  }
  return "fg";
}

export class FaultDetectionStore {
  loading = true;
  ready = false;

  // These should be private when TypeScript is added
  dataFetched = false;
  blockData = null;
  meterData = null;
  faultData = null;
  latestDataUpload = {
    value: null,
    errorMessage: null,
  };

  faults = {
    substations: [],
    errorMessage: null,
  };

  tableData = {
    data: [],
    errorMessage: null,
  };

  // Default dates
  // Last 2 weeks
  fromDate = getUrlParam("start_date")
    ? DateTime.fromISO(getUrlParam("start_date"))
    : DateTime.local().minus({ months: 1 });

  toDate = getUrlParam("end_date") ? DateTime.fromISO(getUrlParam("end_date")) : DateTime.local();
  filterState = "Relevant";
  // Non Observables
  formattedColumns;

  constructor(parent) {
    this.parent = parent;

    makeAutoObservable(this, {}, { autoBind: true });
    this.ready = true;
    this.formattedColumns = faultDetectionColumns.map((c) => ({
      ...c,
      blockName:
        typeof c.blockName === "function"
          ? c.blockName(this.parent.networks.LastProcessedYear)
          : c.blockName,
    }));
    autorun(() => {
      this.processData();
    });
  }

  get currentNetworkId() {
    return this.parent.networks.current_network.uid;
  }

  setFilter(filter) {
    this.filterState = filter;
  }

  setFromDate(date) {
    this.fromDate = date;
    if (this.toDate < this.fromDate) {
      this.toDate = this.fromDate;
    }
    // We set both the start and end date here, because the substation explore
    // needs both
    insertUrlParam("start_date", this.fromDate.toISODate());
    insertUrlParam("end_date", this.toDate.toISODate());
  }

  setToDate(date) {
    this.toDate = date;
    if (this.toDate < this.fromDate) {
      this.fromDate = this.toDate;
    }
    // We set both the start and end date here, because the substation explore
    // needs both
    insertUrlParam("end_date", this.toDate.toISODate());
    insertUrlParam("start_date", this.fromDate.toISODate());
  }

  getBlockNames() {
    // Block names to be fetched
    const blockNames = this.formattedColumns.filter((c) => c.blockName).map((c) => c.blockName);
    // Remove duplicates
    return [...new Set(blockNames)];
  }

  getData = flow(function* () {
    this.loading = true;
    this.dataFetched = false;
    // Lets first get the meterdata for the "network", because we need the last processed month
    try {
      this.meterData = yield this.parent.newapi.getInfoBlocksV4({
        resource_type: "network",
        resource_id: this.currentNetworkId,
        block_names: [NETWORK_BLOCK_TYPES.metering_latest_upload.to_block_name()],
      });
    } catch (error) {
      this.parent.notifStore.error("Failed to fetch meter data");
      this.meterData = null;
    }

    // Then we get the fault data
    try {
      this.faultData = yield this.parent.faultDetectionApi.getFaultsForNetwork(
        this.currentNetworkId,
        this.fromDate,
        this.toDate
      );
    } catch (error) {
      this.parent.notifStore.error("Failed to get fault data");
      this.faultData = null;
    }

    // Then we get the additional blocks to generate the table for the "network_substations"
    try {
      this.blockData = yield this.parent.newapi.getInfoBlocksV4({
        resource_type: "network_substations",
        resource_id: this.currentNetworkId,
        block_names: this.getBlockNames(),
      });
    } catch (error) {
      console.warning("Failed to get block data", error);
      this.blockData = null;
    }

    // Finally we set the dataFetched flag to true
    this.dataFetched = true;
    this.loading = false;
  });

  get columns() {
    return [
      {
        id: "id",
        accessor: "id",
        Header: "text_substation_id",
        type: "link",
        info: "text_substation_id_desc",
        translateNs: "_common",
        isVisible: true,
        hideable: false,
        onClick: (subId) => {
          this.parent.sub.updateCurrentSubstation(subId);
          this.parent.ui.openSubsummary();
        },
        width: 180,
      },
      ...this.formattedColumns,
      {
        id: "fault_detection.actions",
        accessor: "sub_id",
        Header: "text_fd_action",
        sortType: "",
        type: "actions",
        info: "text_fd_action_desc",
        translateNs: "_common",
        hideable: false,
        isVisible: true,
        disableSortBy: true,
        align: "center",
        actions: [
          {
            id: "explore",
            label: "Investigate",
            onClick: (subId) => {
              this.parent.sub.updateCurrentSubstation(subId);
              // Open consumption tab
              insertUrlParam("explore_tab", "consumption");
              insertUrlParam("end_date", this.toDate.toISODate());
              insertUrlParam("start_date", this.fromDate.toISODate());
              insertUrlParam("investigate", true);
              this.parent.ui.openSubsummary();
              this.parent.ui.openSubDetail();
            },
          },
        ],
      },
    ];
  }

  processData() {
    // Output fields
    const latestDataUpload = {
      value: null,
      errorMessage: null,
    };
    const faults = {
      substations: [],
      errorMessage: null,
    };
    const tableData = {
      data: [],
      errorMessage: null,
    };

    // If we havent fetched data yet, return empty values
    if (this.dataFetched) {
      // Set fields dependant on meterdata
      if (this.meterData) {
        latestDataUpload.value = getValueFromBlock(
          this.meterData,
          "metering_latest_upload",
          this.currentNetworkId,
          "processed_time"
        );
        if (latestDataUpload.value) {
          // Set timezone to network timezone
          latestDataUpload.value = latestDataUpload.value.setZone(
            this.parent.networks.network_timezone
          );
          latestDataUpload.errorMessage = "";
        } else {
          latestDataUpload.errorMessage = "No data uploaded";
        }
      } else {
        latestDataUpload.errorMessage = "Failed to get meter data";
      }

      // Set fields dependant on faultdata
      if (this.faultData) {
        faults.substations = this.faultData.data.collection;
      } else {
        faults.errorMessage = "Failed to get fault data";
      }

      // Set tableData
      if (this.blockData) {
        // prepare data model from block_data by colSpecs
        const reader = blkReader(
          this.blockData,
          generateSpecs(this.formattedColumns, this.parent.networks.LastProcessedYear),
          collectorBlockPath
        );
        const rows = [];
        const activeSubKeys = Array.from(this.parent.networks.active_substations.keys());
        const activeFaultSubs = faults.substations.filter((sub) => activeSubKeys.includes(sub.uid));

        activeFaultSubs.forEach((sub) => {
          const subUid = sub.uid;
          const row = reader(subUid);
          row.sub_id = subUid;
          row.id = this.parent.networks.active_substations.get(subUid);
          // Assign fault info
          row.fault_detection = {
            fault_level: getFaultLevel(sub?.faults?.slice()),
            ignored: sub?.ignore_faults ? "Ignored" : "Relevant",
          };
          row.core.delta_temp =
            row.core.supplytemp_flowweighted_avg - row.core.returntemp_flowweighted_avg;
          if (this.filterState === "All" || this.filterState === row.fault_detection?.ignored) {
            rows.push(row);
          }
        });
        tableData.data = rows;
      } else {
        tableData.errorMessage = "Failed to get block data";
      }
    }

    this.latestDataUpload = latestDataUpload;
    this.faults = faults;
    this.tableData = tableData;
  }

  getSubstationFaultStatus = flow(function* (subId) {
    // Use the api to get the fault status for a substation
    return yield this.parent.faultDetectionApi.getSubstationFaultStatus(subId);
  });

  setSubstationFaultStatus(netId, subId, status) {
    // Use the api to set the fault status for a substation
    this.parent.faultDetectionApi.setSubstationFaultStatus(netId, subId, status);
  }
}

export const FaultDetectionContext = createContext();

export default function useFaultDetectionStore() {
  return useContext(FaultDetectionContext);
}
