import { useCallback, useState } from "react";
import { observer } from "mobx-react";
import { Skeleton, Box } from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { formatNumberForLocale, isValue } from "../../../core/utils";
import {
  ChartToolbox,
  HighchartsBase,
  GraphContainer,
} from "../../../shared_components/ui/analytics/charts";
import ButtonDropdown from "../../ui/ButtonDropdown";
import { addAnnotations } from "../../../shared_components/ui/analytics/charts/utils";
import { withErrorBoundary } from "../../ui/ErrorBoundary";

import useConsumption from "./state";
import {
  columns,
  GRAPH_GRID,
  DATETIME_FORMAT_YYYY_MM_DD_HH_MM,
  DIAGRAM_SORT_OPTIONS,
} from "./Constants";
import { isTemperature, legendItemClickHandler } from "./utils";

const useStyles = makeStyles((theme) => ({
  diagramSortDropDown: {
    border: `${theme.palette.primary.main} solid 1px`,
  },
}));

const HEIGHT = 460;

function SortedMetrics() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation(["extendView"]);
  const { areReadingsAveragedForDaily, isDataAvailable, flowLimiter, chartWidth, seriesInRanges } =
    useConsumption();
  const [sortColumn, setSortColumn] = useState("heat");
  const [durationDiagramToolbox, setDurationDiagramToolbox] = useState([]);
  const [visibleSeries, setVisibleSeries] = useState({
    heat: true,
    vol: false,
    st: false,
    rt: false,
    dt: false,
  });

  const chartId = "duration";
  const yAxis = [];
  const series = [];

  if (seriesInRanges) {
    const sortedSeries = [...seriesInRanges].sort((a, b) => {
      const x = a[sortColumn];
      const y = b[sortColumn];
      if (x === y) {
        return 0;
      }
      if (!isValue(x)) {
        return 1;
      }
      if (!isValue(y)) {
        return -1;
      }
      return x < y ? 1 : -1;
    });

    let yaxisSeries = null;

    // How many of RT, ST or DT series are visible
    const visibleTemperatureSeries = Object.keys(visibleSeries).reduce((prev, current) => {
      if (!isTemperature(current)) return prev;
      if (visibleSeries[current]) return prev + 1;
      return prev;
    }, 0);

    if (visibleTemperatureSeries) {
      yAxis.push({
        visible: true,
        lineWidth: 1,
        opposite: false,
        title: {
          useHTML: true,
          text: `<span class="${chartId}-title-temp">${t("text_temperature")} °C</span>`,
        },
        labels: {
          align: "right",
          x: -15,
        },
      });
    }

    columns.forEach(({ key, ...col }) => {
      const type = sortColumn === key ? "line" : "scatter";
      const color = theme.palette[col.color[0]][col.color[1]];
      const visible = visibleSeries[key];
      const name = col.suffix === "°C" ? `${t(col.name)}.` : t(col.name);
      let yAxisIndex = yAxis.length;

      if (isTemperature(key)) yAxisIndex = 0;

      series.push({
        id: key,
        type,
        name,
        data: visible && sortedSeries.map((s) => s[key]),
        timeStamp: sortedSeries.map((s) => s.ts),
        color,
        visible,
        tooltip: {
          valueSuffix: col.suffix,
        },
        yAxis: yAxisIndex,
        allowPointSelect: true,
        marker: {
          fillColor: color,
          radius: 2,
          symbol: "circle",
        },
        events: {
          legendItemClick: legendItemClickHandler(setVisibleSeries),
        },
        showInLegend: col.showInLegend,
      });

      yaxisSeries = {
        visible,
        labels: {
          align: "right",
          x: -15,
          color,
        },
        chartColor: theme.palette[col.lineColor[0]][col.lineColor[1]],
        lineWidth: 1,
        resize: {
          enabled: true,
        },
        title: {
          useHTML: true,
          text: `<span class="${chartId}-title-${key}">${name} ${col.suffix}</span>`,
        },
      };
      if (key === "vol" && flowLimiter) {
        yaxisSeries.plotLines = [
          {
            color: "red",
            value: flowLimiter,
            width: 1,
          },
        ];
      }

      if (!isTemperature(key)) yAxis.push(yaxisSeries);
    });
  }

  const handleDiagramChartSort = useCallback((value) => {
    setSortColumn(value);
  }, []);

  return (
    <GraphContainer
      title={t("text_duration_diagram")}
      subTitles={
        <>
          Sort by:
          <ButtonDropdown
            options={DIAGRAM_SORT_OPTIONS}
            onChange={handleDiagramChartSort}
            selectedValue={sortColumn}
            className={classes.diagramSortDropDown}
            data-testid="open-sort-by-btn"
            translationNs="extendView"
          />
          <Box
            display="flex"
            alignItems="center"
            paddingLeft={2}
            borderLeft={2}
            borderColor="grey.blue2"
            height="100%"
          >
            <ChartToolbox
              className={classes.diagramSortDropDown}
              values={durationDiagramToolbox}
              setValues={setDurationDiagramToolbox}
              disabled={!isDataAvailable}
              data-testid={`${chartId}-toolbox`}
            />
          </Box>
        </>
      }
      data-testid={`chart-${chartId}-container`}
    >
      {!isDataAvailable ? (
        <Skeleton variant="rectangular" height={HEIGHT} />
      ) : (
        <HighchartsBase
          series={series}
          yAxis={yAxis}
          xAxis={{
            gridLineWidth: durationDiagramToolbox.includes(GRAPH_GRID) ? 1 : 0,
          }}
          chart={{
            id: chartId,
            height: HEIGHT,
            marginTop: 60,
            width: chartWidth,
            zoomType: "xy",
          }}
          annotations={addAnnotations({
            series,
            options: durationDiagramToolbox,
          })}
          legend={{
            enabled: true,
            layout: "horizontal",
            useHTML: true,
            align: "left",
            verticalAlign: "top",
            labelFormatter() {
              const { color, name, userOptions } = this;
              return `<div data-testid="${chartId}__legend__${userOptions.id}" style="border-bottom: 3px solid ${color}">${name}</div>`;
            },
            itemStyle: {
              ...theme.typography.body2,
            },
          }}
          tooltip={{
            split: true,
            formatter() {
              const { points, point } = this;
              const { index, series: pointSeries } = point || points[0].point;

              let pointTimestamp = DateTime.fromMillis(pointSeries.options.timeStamp[index]);

              if (areReadingsAveragedForDaily) {
                pointTimestamp = pointTimestamp.set({ hour: 0, minute: 0 });
              }

              const formattedDate = pointTimestamp.toFormat(DATETIME_FORMAT_YYYY_MM_DD_HH_MM);

              if (points) {
                const valX = formatNumberForLocale(points[0].x);
                const k = [`<b>${valX}</b>`];
                points.forEach((p) => {
                  k.push(
                    `<b> ${p.series.name} ${formatNumberForLocale(p.y)} ${
                      p.series.tooltipOptions.valueSuffix
                    }<br />${formattedDate}</b>`
                  );
                });
                return k;
              }

              const valY = formatNumberForLocale(point.y);
              const unit = point.series.tooltipOptions.valueSuffix;
              return `<b>${point.series.name} ${valY} ${unit} <br /> ${formattedDate}</b>`;
            },
          }}
        />
      )}
    </GraphContainer>
  );
}

export default withErrorBoundary(observer(SortedMetrics));
