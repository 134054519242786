import { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box, Paper, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";

import UtfTab from "../../../../components/ui/UtfTab";
import useStores from "../../../../hooks/useStores";
import useEffectOnce from "../../../../hooks/useEffectOnce";
import { getUrlParam, insertUrlParam } from "../../../../conf/routes";

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: "calc(100% - 56px)",
    overflow: "auto",
  },
}));

function SectionSelector(props) {
  const { selectCallback, sections, saveToRoute, topOfPage, routeSelector, triggerSelectOnMount } =
    props;
  const { networks } = useStores();

  // Pick first section that has permission granted and not disabled
  const defaultSection = useMemo(() => {
    if (!sections) return {};
    let nonLockedSection = null;
    sections.forEach((section) => {
      if (nonLockedSection) return;
      const locked = !networks.haveAccess(section.permissions || []);
      if (!locked && !section.disabled) nonLockedSection = section;
    });
    return nonLockedSection;
  }, [networks, sections]);

  const [activeTab, setActiveTab] = useState(
    saveToRoute ? getUrlParam(routeSelector) || defaultSection.id : defaultSection.id
  );
  const ref = useRef();

  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [activeTab]);

  useEffectOnce(() => {
    // Make sure to update the url the first time the component is mounted.
    if (saveToRoute) {
      // We can do this, if we want to trigger the state change
      // but this takes a very long time for a simple tab change
      // const state = createRouterState(routerStore.routerState.routeName, {
      //   queryParams: {
      //     [routeSelector]: activeTab,
      //   },
      // });
      // routerStore.goToState(state);
      insertUrlParam(routeSelector, activeTab);
    }

    // Make sure to call the callback if the page loads without the default tab.
    if (activeTab !== defaultSection.id || triggerSelectOnMount) {
      if (selectCallback) {
        selectCallback(activeTab);
      }
    }
  });

  const onClickTabItem = (_component, newSection) => {
    setActiveTab(newSection);
    if (selectCallback) {
      selectCallback(newSection);
    }
    if (saveToRoute) {
      insertUrlParam(routeSelector, newSection);
    }
  };

  const classes = useStyles();

  if (!Array.isArray(sections) || sections.length === 0) {
    // eslint-disable-next-line no-console
    console.error(`[SectionSelector] "sections" prop must be provided!`);

    return null;
  }

  return (
    <>
      <Paper elevation={2} style={{ zIndex: 2, position: "relative" }}>
        <Tabs value={activeTab} onChange={onClickTabItem} variant="fullWidth">
          {sections.map((option) => {
            const locked = !networks.haveAccess(option.permissions || []);
            const disabled = locked || !!option?.disabled;
            return (
              <UtfTab
                key={option.id}
                label={option.title}
                value={option.id}
                disabled={disabled}
                isLocked={locked}
              />
            );
          })}
        </Tabs>
      </Paper>
      <Box
        ref={ref}
        px={topOfPage ? 4 : 1}
        pb={1}
        className={topOfPage ? classes.container : ""}
        data-testid={`section-content-${activeTab}`}
      >
        {sections.map((option) => {
          if (option.id === activeTab) {
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <option.component key={option.id} {...option.props} />;
          }
          return null;
        })}
      </Box>
    </>
  );
}

SectionSelector.defaultProps = {
  selectCallback: null,
  saveToRoute: false,
  topOfPage: false,
  routeSelector: "tab",
  triggerSelectOnMount: false,
};

SectionSelector.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string),
      component: PropTypes.elementType.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      props: PropTypes.object,
    })
  ).isRequired,
  selectCallback: PropTypes.func,
  saveToRoute: PropTypes.bool,
  topOfPage: PropTypes.bool,
  routeSelector: PropTypes.string,
  triggerSelectOnMount: PropTypes.bool,
};

export default observer(SectionSelector);
