import G6 from "@antv/g6";
import { PropTypes } from "prop-types";
import { useEffect, useRef } from "react";

let graph = null;

function NetworkGraph({ data }) {
  const container = useRef(null);

  useEffect(() => {
    if (!container.current) {
      return null;
    }

    const width = container.current.scrollWidth;
    const height = container.current.scrollHeight || 500;

    if (graph) {
      graph.destroy();
    }
    graph = new G6.Graph({
      container: container.current,
      width,
      height,
      layout: {
        type: "gForce",
        preventOverlap: true,
        gravity: 6,
      },
      defaultNode: {
        size: 45,
        labelCfg: {
          style: {
            fontSize: 6,
          },
        },
      },
      defaultEdge: {
        size: 1,
        color: "#e2e2e2",
      },
      fitView: true,
      fitViewPadding: 160,
      modes: {
        default: ["drag-canvas", "zoom-canvas", "drag-node"],
      },
    });

    graph.data(data);
    graph.render();

    return () => {
      graph.changeData(data);
    };
  }, [data]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
      ref={container}
    />
  );
}

NetworkGraph.propTypes = {
  data: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ).isRequired,
    edges: PropTypes.arrayOf(
      PropTypes.shape({
        source: PropTypes.string.isRequired,
        target: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default NetworkGraph;
