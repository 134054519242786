import { Component } from 'react';
import { inject, observer } from "mobx-react";
import SwipeableViews from 'react-swipeable-views';
import Skeleton from '@mui/material/Skeleton';
import FeaturedBlogCarouselItem from './FeaturedBlogCarouselItem';
import FeaturedCarouselStrip from './FeaturedBlogCarouselStrip';

class FeaturedBlogCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = { idx: 0 }
    }

    render() {
        const { dash } = this.props;
        if (dash.featuredBlogs.length === 0) {
            return (
                <Skeleton></Skeleton>
            );
        } else {
            return (
                <div style={{ height: '100%', display: "flex", flexDirection: "column", marginTop: 8, padding: 16, backgroundColor: "white" }}>
                    <div style={{
                        display: "flex",
                        flexGrow: "1",
                        height: 0,
                    }}>
                        <div style={{ display: "flex", minHeight: 0, flexGrow: 1, flexDirection: "column" }}>
                            <SwipeableViews
                                animateHeight={false}
                                enableMouseEvents
                                index={dash.current_blog_id}
                                slideStyle={{ height: '100%' }}
                                containerStyle={{ height: '100%', flexGrow: 1, display: "flex", }}
                                style={{ height: '100%', flexGrow: 1, display: "flex", }}>
                                {dash.featuredBlogs.map(blog => <FeaturedBlogCarouselItem
                                    key={blog.cms_id}
                                    blog={blog} />)}
                            </SwipeableViews>
                        </div>
                    </div>
                    <FeaturedCarouselStrip />
                </div>
            );

        }
    }
}

export default inject('dash')(observer(FeaturedBlogCarousel));