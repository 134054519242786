import useStores from "./useStores"

export default function useSubstationStores() {
  const { networks, sub, ui, newapi } = useStores();
  return {
    networks,
    sub,
    ui,
    newapi,
  };
}
