// Copyright (C) 2019 Utilifeed AB. All Rights Reserved.

import SvgIcon from '@mui/material/SvgIcon';

export default function Networks(props) {
    return (
        <SvgIcon {...props}>
            <path d="M23.7 9H12.6V6h3a1.2 1.2 0 0 0 1.2-1.2V1.2A1.2 1.2 0 0 0 15.6 0H8.4a1.2 1.2 0 0 0-1.2 1.2v3.6A1.2 1.2 0 0 0 8.4 6h3v3H.3a.3.3 0 0 0-.3.3v.6a.3.3 0 0 0 .3.3h4.5v3H2.4a1.2 1.2 0 0 0-1.2 1.2V18a1.2 1.2 0 0 0 1.2 1.2h6A1.2 1.2 0 0 0 9.6 18v-3.6a1.2 1.2 0 0 0-1.2-1.2H6v-3h12v3h-2.4a1.2 1.2 0 0 0-1.2 1.2V18a1.2 1.2 0 0 0 1.2 1.2h6a1.2 1.2 0 0 0 1.2-1.2v-3.6a1.2 1.2 0 0 0-1.2-1.2h-2.4v-3h4.5a.3.3 0 0 0 .3-.3v-.6a.3.3 0 0 0-.3-.3zM8.4 14.4V18h-6v-3.6zm0-9.6V1.2h7.2v3.6zm13.2 9.6V18h-6v-3.6z" />
        </SvgIcon>
    )
}