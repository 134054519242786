import { useAsyncMemo } from "./useAsync"
import useSubstationStores from "./useSubstationStores"

function useInfoBlock({ resource_type, network_id, resource_id, block_names, block_definitions }) {
  const { newapi } = useSubstationStores()

  const [, state] = useAsyncMemo(
    async () => {
      return newapi.getInfoBlocksV4({
        resource_type,
        network_id,
        resource_id,
        block_names,
        block_definitions,
      })
    },
    [newapi, resource_type, network_id, resource_id, block_names, block_definitions],
    {}
  )

  return state
}

export default useInfoBlock
