/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from "react";
import { ErrorBoundary as DefaultErrorBoundary } from "react-error-boundary";
import { Button, Alert, AlertTitle } from "@mui/material";
import PropTypes from "prop-types";

const isDev = process.env.NODE_ENV !== "production";

function DefaultErrorFallback({ error, resetErrorBoundary }) {
  let message =
    "The app was partially crashed. You can try to reload the page and contact support if the problem is consistent.";
  let title = "Unexpected error occured!";
  if (isDev) {
    title = "Component has crashed due to an unhandled error. See the error stack;";
    message = error.message;
  }

  return (
    <Alert
      severity="error"
      variant="filled"
      action={
        isDev ? (
          <Button color="inherit" size="small" onClick={resetErrorBoundary}>
            Reload
          </Button>
        ) : null
      }
      sx={{ m: 1 }}
      data-testid="default-error-fallback"
    >
      <AlertTitle>{title}</AlertTitle>

      <pre>{message}</pre>

      {/* Print Error Stack-Trace during development */}
      {isDev ? <pre>{error.stack}</pre> : null}
    </Alert>
  );
}

DefaultErrorFallback.propTypes = {
  error: PropTypes.instanceOf(Error).isRequired,
  resetErrorBoundary: PropTypes.func.isRequired,
};

function UtfErrorBoundary({
  children,
  onResetKeysChange,
  onReset,
  onError,
  resetKeys,
  fallback,
  FallbackComponent,
  fallbackRender,
}) {
  return (
    <DefaultErrorBoundary
      FallbackComponent={FallbackComponent || DefaultErrorFallback}
      {...{
        onResetKeysChange,
        onReset,
        onError,
        resetKeys,
        fallback,
        fallbackRender,
      }}
    >
      {children}
    </DefaultErrorBoundary>
  );
}

UtfErrorBoundary.defaultProps = {
  onResetKeysChange: undefined,
  onReset: undefined,
  onError: undefined,
  resetKeys: [],
  fallback: undefined,
  fallbackRender: undefined,
  FallbackComponent: undefined,
};

UtfErrorBoundary.propTypes = {
  onResetKeysChange: PropTypes.func,
  onReset: PropTypes.func,
  onError: PropTypes.func,
  resetKeys: PropTypes.arrayOf(PropTypes.string),
  fallback: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.elementType]),
  FallbackComponent: PropTypes.elementType,
  fallbackRender: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default UtfErrorBoundary;

export function withErrorBoundary(Component, errorBoundaryProps) {
  const Wrapped = forwardRef((props, ref) => (
    <UtfErrorBoundary {...errorBoundaryProps}>
      <Component {...props} ref={ref} />
    </UtfErrorBoundary>
  ));

  // Format for display in DevTools
  const name = Component.displayName || Component.name || "Unknown";
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}
