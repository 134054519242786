import { observable, action, flow, makeObservable } from "mobx";
import { Subject, interval } from "rxjs";
import { debounce, distinctUntilChanged } from "rxjs/operators";

import { SUBSTATION_BLOCK_TYPES as SBT, blkReader } from "../conf/blocks";

const NEXT_SUB = new Subject().pipe(
  debounce(() => interval(300)),
  distinctUntilChanged()
);

class Substation {
  is_fetching_blocks = false;
  is_fetching_readings = false;

  current_substation = null;
  current_cluster = null;
  nxt_substation = null;

  infoblock = null;
  raw_readings = null;
  raw_cluster_readings = null;

  reading_start_year = null;
  reading_start_month = null;
  reading_end_year = null;
  reading_end_month = null;
  are_readings_averaged_for_daily = false;
  flow_limiter = null;
  scaling_factor = null;

  constructor(parent) {
    makeObservable(this, {
      is_fetching_blocks: observable,
      is_fetching_readings: observable,
      current_substation: observable,
      current_cluster: observable,
      nxt_substation: observable,
      infoblock: observable,
      raw_readings: observable,
      raw_cluster_readings: observable,
      reading_start_year: observable,
      reading_start_month: observable,
      reading_end_year: observable,
      reading_end_month: observable,
      are_readings_averaged_for_daily: observable,
      flow_limiter: observable,
      scaling_factor: observable,
      onLoad: action.bound,
      resetData: action.bound,
      updateCurrentSubstation: action.bound,
      updateCurrentCluster: action.bound,
      updateNxtSub: action.bound,
      updateReadingOptions: action.bound,
    });

    this.parent = parent;
    this.current_substation = null;
    this.current_cluster = null;
    NEXT_SUB.subscribe(this.updateNxtSub);
  }

  onLoad() {
    this.reading_start_year = this.parent.networks.lpYear.year - 1;
    this.reading_start_month = null;
    this.reading_end_year = this.parent.networks.lpYear.year;
    this.reading_end_month = null;
  }

  resetData() {
    this.raw_readings = null;
    this.infoblock = null;
  }

  updateCurrentSubstation(substationId) {
    this.current_substation = substationId;
    this.nxt_substation = substationId;
    this.resetData();
  }

  updateCurrentCluster(clusterId) {
    this.current_cluster = clusterId;
  }

  updateNxtSub(substationId) {
    this.nxt_substation = substationId;
  }

  updateNxtSubstation(substationId) {
    NEXT_SUB.next(substationId);
  }

  updateReadingOptions(data) {
    this.reading_start_year = data.start_year;
    this.reading_start_month = data.start_month;
    this.reading_end_year = data.end_year;
    this.reading_end_month = data.end_month;
    this.are_readings_averaged_for_daily = data.averaged;
  }

  meterReadingOptions() {
    return [
      this.reading_start_year,
      this.reading_start_month,
      this.reading_end_year,
      this.reading_end_month,
    ];
  }

  substationChanged() {
    return [this.current_substation];
  }

  getReadings = flow(function* () {
    this.is_fetching_readings = true;
    try {
      this.flow_limiter = null;
      const lastProcessedMonth = this.parent.networks.lpMonth;
      const coreBlock = SBT.core.to_block_name({
        year: lastProcessedMonth.year,
        month: lastProcessedMonth.month,
      });
      const subData = yield this.parent.newapi.getInfoBlocksV4({
        resource_id: this.current_substation,
        network_id: this.parent.networks.current_network.uid,
        resource_type: "substation",
        block_names: [SBT.pricing.to_block_name(), coreBlock, SBT.location.to_block_name()],
      });
      const subReaderPricingMeta = blkReader(subData, [
        ["name", [SBT.pricing.to_block_name(), "flow_limit"], "flow_limit"],
        ["name", [SBT.location.to_block_name(), SBT.location.col.weather_coordinates], "coords"],
      ]);
      this.flow_limiter = subReaderPricingMeta(this.current_substation).flow_limit;
      this.raw_readings = null;

      const startDate = this.parent.networks.dtFromYM(
        this.reading_start_year,
        this.reading_start_month,
        "start"
      );
      const endDate = this.parent.networks.dtFromYM(
        this.reading_end_year,
        this.reading_end_month,
        "end"
      );

      const subWeatherData = yield this.parent.newapi.getWeatherDataV4({
        resource_id: this.current_substation,
        resource_type: "substation",
        coordinates: subReaderPricingMeta(this.current_substation).coords,
        date_min: startDate,
        date_max: endDate,
      });
      const subMeterData = yield this.parent.newapi.getMeterDataV4({
        resource_uid: this.current_substation,
        ts_start: startDate,
        network_id: this.parent.networks.current_network.uid,
        ts_end: endDate,
        stage: "clean",
        meter: "primary",
        components: ["heat_energy", "volume"],
      });
      this.data = { weather: subWeatherData.weather, meter_data: subMeterData.meter_data };
    } finally {
      this.is_fetching_readings = false;
    }
  });
}

export default Substation;
