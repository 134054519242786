import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { observer } from "mobx-react";
import { Typography, Tooltip } from "@mui/material";

const useStyles = makeStyles(() => ({
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    cursor: "default",
  },
  section__expand: {
    flexGrow: 1,
  },
  line: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  text: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > h1": {
      fontSize: "5.714rem",
      "@media (max-width: 1400px)": {
        fontSize: "4.714rem",
      },
    },
  },
  exp: {
    display: "inline-flex",
    marginLeft: 4,
  },
}));

function LargeNumber({ value, exp, unit, color, size, tooltip }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.section, classes.section__expand)} data-testid="large-number">
      <Tooltip title={tooltip} followCursor placement="top" TransitionProps={{ timeout: 600 }}>
        <div className={classes.line}>
          <div className={classes.text}>
            <Typography
              variant={size === "small" ? "h2" : "h1"}
              component="h5"
              color={color}
              data-maskdata
            >
              {value}
            </Typography>
            {exp !== 0 && (
              <div className={classes.exp}>
                <Typography component="span" variant="h2" color={color}>
                  x
                </Typography>
                <Typography component="span" variant="h2" color={color}>
                  10
                </Typography>
                <Typography component="span" variant="h3" color={color}>
                  {exp}
                </Typography>
              </div>
            )}
          </div>
          <Typography component="span" variant="subtitle1" color={color} style={{ marginLeft: 4 }}>
            {unit}
          </Typography>
        </div>
      </Tooltip>
    </div>
  );
}

LargeNumber.defaultProps = {
  color: "secondary",
  exp: 0,
  unit: "",
  size: "default",
  tooltip: null,
};

LargeNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  exp: PropTypes.number,
  unit: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(["default", "small"]),
  tooltip: PropTypes.string,
};

export default observer(LargeNumber);
