export function sortedAgg(arrayMap, fNames, res) {
  const r = {};
  fNames.forEach((fName) => {
    const array = arrayMap[fName];
    if (array !== null && array !== undefined) {
      const totSum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      if (totSum === 0) {
        r[fName] = null;
      } else {
        r[fName] = totSum / array.length;
      }
    } else {
      r[fName] = null;
    }
  });
  res.push(r);
}

export function legendItemClickHandler(setVisibleSeries) {
  return function legendItemClick(event) {
    const {
      userOptions: { id },
    } = event.target;
    const { visible: isVisible, chart } = this;

    // ensure at least one legend item is selected
    if (isVisible) {
      let visPoint = 0;
      chart.series.forEach((point) => {
        if (point.visible) visPoint += 1;
        return visPoint <= 1;
      });

      if (visPoint < 2) {
        event.preventDefault();
        return;
      }
    }

    setVisibleSeries((current) => ({ ...current, [id]: !current[id] }));

    // eslint-disable-next-line consistent-return
    return false; // prevent HC to update visibility.
  };
}

export function isTemperature(key) {
  return key === "st" || key === "rt" || key === "dt";
}
