import React from "react";
import { observer, inject, disposeOnUnmount, Observer } from "mobx-react";
import { action, makeObservable, flow, reaction, observable } from "mobx";
import { Typography, Box, Button, ButtonGroup, Checkbox } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { withTranslation } from "react-i18next";

import { BLOCK } from "../../conf/constants";
import { getValueFromBlock } from "../../conf/blocks";
import { FILTER_HEIGHT } from "../../conf/ui_constants";

class FilterOptionInner extends React.Component {
  categoryName = "Missing";
  categoryCount = 0;

  constructor(props) {
    super(props);
    makeObservable(this, {
      toggleOption: action.bound,
    });
  }

  toggleOption(event) {
    const {
      rootStore: { filters },
      spec,
      option,
      optionCount,
    } = this.props;
    if (event.target.checked) {
      filters.addSelectedOption(spec.get("param"), option[0], optionCount);
    } else {
      filters.removeSelectedOption(spec.get("param"), option[0]);
    }
  }

  render() {
    const { spec, option } = this.props;
    this.categoryName = option[0] || "Missing";
    this.categoryCount = option[1] || 0;
    const checked = spec.get("state").selected.has(option[0]);

    return (
      <div style={{ width: "100%" }}>
        <Box
          fontWeight="regular"
          fontFamily="Roboto"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div maxwidth="70%">
            <Checkbox checked={checked} size="small" color="primary" onChange={this.toggleOption} />
            {this.categoryName}
          </div>
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            bgcolor="#eee"
            height="25px"
            width="30%"
            fontWeight={500}
          >
            {this.categoryCount}
          </Box>
        </Box>
      </div>
    );
  }
}

const FilterOption = inject("rootStore")(observer(FilterOptionInner));

class CategoricalFilter extends React.Component {
  options = new Map();
  is_data_fetched = false;
  is_ready = false;
  maxNum = null;
  minNum = null;
  K = null;

  constructor(props) {
    super(props);

    makeObservable(this, {
      options: observable,
      is_data_fetched: observable,
      is_ready: observable,
      maxNum: observable,
      minNum: observable,
      K: observable,
      updateData: action.bound,
      selectAll: action.bound,
      selectNone: action.bound,
    });

    this.renderRow = this.renderRow.bind(this);
    this.blocks = {};
  }

  componentDidMount() {
    const {
      spec,
      rootStore: { networks, newapi },
    } = this.props;

    disposeOnUnmount(
      this,
      reaction(
        () => [networks.current_network, networks.active_substations.size],
        flow(function* () {
          const blkName = spec.get("block");
          const blockSpec = BLOCK[blkName];
          const block_name = blockSpec.to_block_name({
            year: this.year || networks.analysisYear,
            month: this.month || networks.analysisMonth,
          });
          const result = yield newapi.getInfoBlocksV4({
            resource_type: "network_substations",
            resource_id: networks.current_network.uid,
            block_names: [block_name],
          });
          if (result) {
            this.updateData(result);
          }
        }).bind(this),
        {
          fireImmediately: true,
        }
      )
    );
  }

  updateData(blockdata) {
    const {
      spec,
      rootStore: { filters, networks },
    } = this.props;
    const fstate = spec.get("state");
    const year = fstate.year || networks.analysisYear;
    const month = fstate.month || networks.analysisMonth;
    this.blocks = blockdata;
    const blockName = BLOCK[spec.get("block")].to_block_name({ year, month });
    const column = spec.get("param");
    let maxNum = null;
    let minNum = null;
    let options = new Map();
    let count = 0;
    const asubs = networks.active_substations;
    for (const sub of networks.current_substations.keys()) {
      const value = getValueFromBlock(blockdata, blockName, sub, column);
      const option_count = options.get(value) || 0;
      options.set(value, asubs.has(sub) ? option_count + 1 : option_count);
    }
    options = new Map(Array.from(options));
    for (const k of options.values()) {
      maxNum = k > maxNum || !maxNum ? k : maxNum;
      minNum = k < minNum || !minNum ? k : minNum;
      count += k;
    }
    this.maxNum = maxNum;
    this.minNum = minNum;
    this.K = 100.0 / count;
    this.options = options;
    if (spec.get("state").all) {
      filters.addSelectedOptions(spec.get("param"), options.keys());
    }
    this.is_ready = true;
  }

  selectAll() {
    const {
      rootStore: { filters },
      spec,
    } = this.props;
    filters.selectAllOptions(spec.get("param"), Array.from(this.options.keys()));
  }

  selectNone() {
    const {
      rootStore: { filters },
      spec,
    } = this.props;
    filters.selectNoneOfOption(spec.get("param"));
  }

  renderRow(p) {
    const { index, data, style } = p;
    const { spec } = this.props;
    return (
      <div key={index} style={style}>
        <FilterOption spec={spec} option={data[index]} optionCount={data.length} />
      </div>
    );
  }

  render() {
    const { t, spec } = this.props;
    const is_active = spec.get("is_active");
    const listHeight = Math.min(this.options.size * 38, FILTER_HEIGHT);

    return (
      <Box
        borderBottom="1px solid #ddd"
        display="flex"
        flexDirection="column"
        bgcolor="white"
        py={2}
      >
        <Box
          fontSize="h6.fontSize"
          display="flex"
          flexDirection="row"
          pl={2}
          pr={2}
          alignItems="center"
          justifyContent="start"
        >
          <Typography variant="button">{t(spec.get("label"), { ns: "filters" })}</Typography>
          <Box flexGrow={1} />
          {is_active && (
            <ButtonGroup color="primary" size="small">
              <Button
                variant={spec.get("state").all ? "contained" : "outlined"}
                onClick={this.selectAll}
              >
                {t("action_all", { ns: "_action" })}
              </Button>
              <Button
                variant={spec.get("state").selected.size === 0 ? "contained" : "outlined"}
                onClick={this.selectNone}
              >
                {t("action_none", { ns: "_action" })}
              </Button>
            </ButtonGroup>
          )}
        </Box>
        {is_active ? (
          <Box height={`${listHeight}px`} pl={1} pr={2}>
            <AutoSizer>
              {({ height, width }) => (
                <Observer>
                  {() =>
                    this.is_ready ? (
                      <FixedSizeList
                        height={height}
                        width={width}
                        itemSize={38}
                        itemData={Array.from(this.options.entries()).sort((a, b) => {
                          // Make sure to place null values at the end
                          if (a[0] === null) {
                            return 1;
                          }
                          if (b[0] === null) {
                            return -1;
                          }
                          return a[0] < b[0] ? -1 : 1;
                        })}
                        itemCount={this.options.size}
                      >
                        {this.renderRow}
                      </FixedSizeList>
                    ) : (
                      <Skeleton variant="rectangular" height={FILTER_HEIGHT - 38} />
                    )
                  }
                </Observer>
              )}
            </AutoSizer>
          </Box>
        ) : null}
      </Box>
    );
  }
}

export default withTranslation(["filters", "_action"])(
  inject("rootStore")(observer(CategoricalFilter))
);
