import { Component } from "react";
import { observer, inject } from "mobx-react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SettingsIcon from "@mui/icons-material/Settings";
import withStyles from "@mui/styles/withStyles";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import { Divider } from "@mui/material";
import { withTranslation } from "react-i18next";

import { DRAWER_WIDTH, BOTTOM_ACTION_BAR_HEIGHT } from "../../conf/ui_constants";
import { SubHeader } from "../../shared_components/ui/layout";

const ChooseFilterComp = observer(({ spec, components, ...props }) => {
  const Comp = components[spec.get("type")];
  return <Comp spec={spec} {...props} />;
});

const styles = (theme) => ({
  drawer_open: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawer: {
    width: 0,
    flexShrink: 1,
  },
  drawerPaper: {
    width: 0,
  },
  drawerPaper_open: {
    width: DRAWER_WIDTH,
  },

  toolbar: theme.mixins.toolbar,
});

class SubstationFilters extends Component {
  onUpload(event) {
    const {
      rootStore: { filters },
    } = this.props;
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const obj = JSON.parse(e.target.result);
      filters.fromJson(obj);
    };

    // Read in the image file as a data URL.
    if (file) {
      reader.readAsText(file);
    }
  }

  downloadFilters() {
    const {
      rootStore: { filters },
    } = this.props;
    const exportObject = filters.toJson();
    const filename = "filters.json";
    const contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(exportObject)))], {
        type: contentType,
      });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      a.download = filename;
      a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(exportObject))}`;
      a.target = "_blank";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  render() {
    const {
      t,
      rootStore: { ui, filters },
    } = this.props;
    return (
      <div data-testid="global-filters-panel">
        <SubHeader
          wikiUrl="https://wiki.energypredict.com/en/global_features/filter"
          endComponent={
            <Button
              color="primary"
              variant="contained"
              size="small"
              disableElevation
              startIcon={<ReplayOutlinedIcon />}
              onClick={filters.resetFilters}
            >
              {t("action_reset_filter")}
            </Button>
          }
        >
          <Button
            color="primary"
            variant="contained"
            size="small"
            disableElevation
            startIcon={<SettingsIcon />}
            onClick={ui.openEditfilters}
            data-testid="toggle-edit-filter-button"
          >
            {t("action_edit_filter")}
          </Button>
        </SubHeader>
        <Divider />
        <Box
          display="flex"
          flexDirection="column"
          pb={`${BOTTOM_ACTION_BAR_HEIGHT}px`}
          data-testid="global-filter"
        >
          <span data-maskdata>
            {ui.is_filteroptions_open &&
              Array.from(filters.filters.values())
                .filter((fs) => fs.get("is_active"))
                .map((fs) => (
                  <ChooseFilterComp
                    key={fs.get("param")}
                    spec={fs}
                    components={filters.COMPONENTS}
                    ui_open={ui.is_filteroptions_open}
                  />
                ))}
          </span>
        </Box>
        <Box
          height={BOTTOM_ACTION_BAR_HEIGHT}
          border="1px solid #ddd"
          justifyContent="flex-start"
          alignItems="center"
          bgcolor="#fff"
          display="flex"
          position="fixed"
          color="primary"
          bottom={0}
          left={0}
          zIndex={1}
          px={2}
          width={DRAWER_WIDTH}
        >
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={this.downloadFilters}
            startIcon={<CloudDownloadOutlinedIcon />}
          >
            {t("action_download_filter")}
          </Button>
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              style={{ marginLeft: 16 }}
              startIcon={<CloudUploadOutlinedIcon />}
              component="span"
            >
              {t("action_upload_filter")}
            </Button>
          </label>

          <input
            style={{ display: "none" }}
            id="contained-button-file"
            multiple
            type="file"
            onChange={this.onUpload}
          />
        </Box>
      </div>
    );
  }
}

export default withTranslation(["_action"])(
  inject("rootStore")(withStyles(styles)(observer(SubstationFilters)))
);
