import { formatNumberForUnitMainLabel, isValue, } from '../../core/utils';

function FUnitValueHtml(inum, iunit) {
    if (isValue(inum)) {
        const [num, exp, pre, unit] = formatNumberForUnitMainLabel(inum, iunit)
        const exp_part = exp ? `<span> x10</span><sup>${exp}</sup>` : "";

        return `<span>${num}${exp_part} ${pre}${unit}</span>`
    } else {
        return ""
    }
}

export default FUnitValueHtml;
