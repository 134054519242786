import SvgIcon from "@mui/material/SvgIcon";
import makeStyles from "@mui/styles/makeStyles";

const useIconStyles = makeStyles((theme) => ({
  root: {
    "&&": {
      fill: `${theme.palette.primary.main}`,
      "&:hover": {
        fill: `${theme.palette.secondary.main}`,
      },
    },
  },
}));

export default function QuestionIcon(props) {
  const iconClasses = useIconStyles();
  return (
    <SvgIcon className={iconClasses.root} viewBox="0 0 16 16" {...props}>
      <path d="M8.012 10.713a.9.9 0 100 1.8.9.9 0 000-1.8zm.247-.774h-.516a.387.387 0 01-.387-.387v-.016c0-2.269 2.5-2.052 2.5-3.465 0-.646-.573-1.3-1.853-1.3a2.132 2.132 0 00-1.91.926.386.386 0 01-.524.077l-.424-.3a.387.387 0 01-.085-.554 3.385 3.385 0 012.941-1.436c1.688 0 3.143.96 3.143 2.587 0 2.175-2.5 2.06-2.5 3.465v.012a.387.387 0 01-.387.387zm-.247-8.9c5.364 0 8.717 5.807 6.035 10.452-2.682 4.645-9.387 4.645-12.069 0A6.97 6.97 0 018.012 1.032m0-1.032C1.854 0-1.995 6.667 1.084 12c3.079 5.333 10.777 5.333 13.856 0A8 8 0 008.012 0z" />
    </SvgIcon>
  );
}
