import { Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

export default function Text({ color, mr, ml, mt, mb, ...restProps }) {
  const theme = useTheme(restProps);
  const newColor = color ? theme.palette[color[0]][color[1]] : "inherit";
  const style = { color: newColor };
  if (mr) {
    style.marginRight = theme.spacing(mr);
  }
  if (ml) {
    style.marginLeft = theme.spacing(ml);
  }
  if (mt) {
    style.marginTop = theme.spacing(mt);
  }
  if (mb) {
    style.marginBottom = theme.spacing(mb);
  }

  return <Typography {...restProps} style={style} />;
}
