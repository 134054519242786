import { SvgIcon } from "@mui/material";

export default function CalendarIcon({ iconColor, ...props }) {
  return (
    <SvgIcon {...props}>
      <g xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5 2H11V.375A.376.376 0 0 0 10.625 0h-.25A.376.376 0 0 0 10 .375V2H4V.375A.376.376 0 0 0 3.625 0h-.25A.376.376 0 0 0 3 .375V2H1.5A1.5 1.5 0 0 0 0 3.5v11A1.5 1.5 0 0 0 1.5 16h11a1.5 1.5 0 0 0 1.5-1.5v-11A1.5 1.5 0 0 0 12.5 2zm-11 1h11a.5.5 0 0 1 .5.5V5H1V3.5a.5.5 0 0 1 .5-.5zm11 12h-11a.5.5 0 0 1-.5-.5V6h12v8.5a.5.5 0 0 1-.5.5zm-7.875-5h-1.25A.376.376 0 0 1 3 9.625v-1.25A.376.376 0 0 1 3.375 8h1.25A.376.376 0 0 1 5 8.375v1.25a.376.376 0 0 1-.375.375zm3 0h-1.25A.376.376 0 0 1 6 9.625v-1.25A.376.376 0 0 1 6.375 8h1.25A.376.376 0 0 1 8 8.375v1.25a.376.376 0 0 1-.375.375zm3 0h-1.25A.376.376 0 0 1 9 9.625v-1.25A.376.376 0 0 1 9.375 8h1.25a.376.376 0 0 1 .375.375v1.25a.376.376 0 0 1-.375.375zm-3 3h-1.25A.376.376 0 0 1 6 12.625v-1.25A.376.376 0 0 1 6.375 11h1.25a.376.376 0 0 1 .375.375v1.25a.376.376 0 0 1-.375.375zm-3 0h-1.25A.376.376 0 0 1 3 12.625v-1.25A.376.376 0 0 1 3.375 11h1.25a.376.376 0 0 1 .375.375v1.25a.376.376 0 0 1-.375.375zm6 0h-1.25A.376.376 0 0 1 9 12.625v-1.25A.376.376 0 0 1 9.375 11h1.25a.376.376 0 0 1 .375.375v1.25a.376.376 0 0 1-.375.375z"
          transform="translate(1)"
          fill={iconColor}
        />
      </g>
    </SvgIcon>
  );
}
