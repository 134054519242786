/* eslint-disable no-restricted-syntax */
import { action, computed, makeObservable, observable, runInAction } from "mobx";

import { DRAWER_WIDTH, MENU_WIDTH, SECTION_X_SEP, SIDENAV_WIDTH } from "../conf/ui_constants";
import { debounce } from "../core/utils";

const MODELS = [
  "is_substation_model_open",
  "is_cluster_model_open",
  "is_subdetail_open",
  "is_cluster_detail_open",
  "is_filteroptions_open",
  "is_subsummary_open",
  "is_cluster_summary_open",
  "is_sidenav_open",
];

class UiStore {
  theme = "new";
  maskData = false;
  is_sidenav_open = true;
  is_substation_model_open = false;
  is_cluster_model_open = false;
  is_filteroptions_open = false;
  is_subsummary_open = false;
  is_cluster_summary_open = false;
  is_editfilters_open = false;
  is_subdetail_open = false;
  is_cluster_detail_open = false;
  locale = "en-us";
  timezone = "CEST";
  banners = [];

  windowWidth = null;
  screenWindow = null;

  constructor(parent) {
    makeObservable(this, {
      theme: observable,
      maskData: observable,
      is_sidenav_open: observable,
      is_substation_model_open: observable,
      is_cluster_model_open: observable,
      is_filteroptions_open: observable,
      is_subsummary_open: observable,
      is_cluster_summary_open: observable,
      is_editfilters_open: observable,
      is_subdetail_open: observable,
      is_cluster_detail_open: observable,
      locale: observable,
      timezone: observable,
      banners: observable,
      windowWidth: observable,
      screenWindow: observable,
      is_sidepagemodels_open: computed,
      main_sidebar_width: computed,
      max_drawer_width: computed,
      max_section_width: computed,
      main_area: computed,
      negative_section_area_width: computed,
      half_graph_width: computed,
      one_third_width: computed,
      two_third_width: computed,
      setWindow: action.bound,
      setWindowWidth: action.bound,
      modelToggle: action.bound,
      toggleSubstations: action.bound,
      goToExploreFromSummary: action.bound,
      goToExploreFromClusterSummary: action.bound,
      toggleFilters: action.bound,
      toggleSubsummary: action.bound,
      toggleClusterSummary: action.bound,
      toggleCluster: action.bound,
      openSubsummary: action.bound,
      openSubCluster: action.bound,
      closeSubsummary: action.bound,
      closeClusterSummary: action.bound,
      addBanner: action.bound,
      removeBanner: action.bound,
      openEditfilters: action.bound,
      closeEditFilters: action.bound,
      toggleSubDetail: action.bound,
      toggleClusterDetail: action.bound,
      openSubDetail: action.bound,
      openClusterDetail: action.bound,
      closeDetailPanel: action.bound,
      openSideNav: action.bound,
      sideNavToggle: action.bound,
      closeSideNav: action.bound,
      setMaskData: action.bound,
    });

    this.parent = parent;

    this.setWindow();
    try {
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (err) {
      console.log("unable to determine user's timezone, using fallback");
    }
  }

  /* views */

  get is_sidepagemodels_open() {
    return (
      this.is_subsummary_open ||
      this.is_cluster_summary_open ||
      this.is_subdetail_open ||
      this.is_cluster_detail_open ||
      this.is_substation_model_open ||
      this.is_cluster_model_open ||
      this.is_filteroptions_open ||
      this.is_sidenav_open
    );
  }

  // eslint-disable-next-line class-methods-use-this
  get main_sidebar_width() {
    return MENU_WIDTH;
  }

  get max_drawer_width() {
    return this.windowWidth - (this.is_sidenav_open ? SIDENAV_WIDTH : MENU_WIDTH) - SECTION_X_SEP;
  }

  get max_section_width() {
    return (
      this.windowWidth - (this.is_sidenav_open ? SIDENAV_WIDTH : MENU_WIDTH) - 2 * SECTION_X_SEP
    );
  }

  get main_area() {
    const drawer = this.is_sidepagemodels_open ? DRAWER_WIDTH : 0;
    return this.windowWidth - drawer - 2 * SECTION_X_SEP;
  }

  get negative_section_area_width() {
    return this.is_sidepagemodels_open ? DRAWER_WIDTH : 0;
  }

  get half_graph_width() {
    return this.main_area / 2 - 4;
  }

  get one_third_width() {
    return this.main_area * 0.333 - 4;
  }

  get two_third_width() {
    return this.main_area * 0.666 - 4;
  }

  /* actions  */

  setWindow() {
    if (typeof window === "object") {
      this.screenWindow = window;
      this.handleWindowWidthChange();
      this.screenWindow.addEventListener("resize", this.handleWindowWidthChange);
    }
  }

  handleWindowWidthChange = debounce(() => {
    runInAction(() => {
      const width = this.screenWindow.innerWidth;
      this.setWindowWidth(width);
    });
  }, 100);

  setWindowWidth(width) {
    this.windowWidth = width;
    return this.windowWidth;
  }

  modelToggle(current) {
    this[current] = !this[current];
    if (this[current]) {
      for (const model of MODELS) {
        if (model !== current) {
          this[model] = false;
        }
      }
    }
  }

  toggleSubstations() {
    const newState = !this.is_substation_model_open;
    if (newState) {
      for (const model of MODELS) {
        if (model !== "is_substation_model_open") {
          this[model] = false;
        }
      }
    }
    this.is_substation_model_open = newState;
  }

  toggleCluster() {
    const newState = !this.is_cluster_model_open;
    if (newState) {
      for (const model of MODELS) {
        if (model !== "is_cluster_model_open") {
          this[model] = false;
        }
      }
    }
    this.is_cluster_model_open = newState;
  }

  goToExploreFromSummary() {
    this.is_subsummary_open = false;
    this.is_substation_model_open = true;
  }

  goToExploreFromClusterSummary() {
    this.is_cluster_summary_open = false;
    this.is_cluster_model_open = true;
  }

  goToExploreFromCluster() {
    this.is_cluster_model_open = false;
  }

  toggleFilters() {
    this.modelToggle("is_filteroptions_open");
  }

  toggleSubsummary() {
    this.modelToggle("is_subsummary_open");
  }

  toggleClusterSummary() {
    this.modelToggle("is_cluster_summary_open");
  }

  openSubsummary() {
    this.is_subsummary_open = true;
    for (const model of MODELS) {
      if (model !== "is_subsummary_open") {
        this[model] = false;
      }
    }
  }

  openSubCluster() {
    this.is_cluster_summary_open = true;
    for (const model of MODELS) {
      if (model !== "is_cluster_summary_open") {
        this[model] = false;
      }
    }
  }

  closeSubsummary() {
    this.is_subsummary_open = false;
  }

  closeClusterSummary() {
    this.is_cluster_summary_open = false;
  }

  addBanner(b) {
    this.banners.push(b);
  }

  removeBanner(index) {
    if (index) {
      this.banners = this.banners.filter((k) => k.id !== index);
    } else {
      this.banners.shift();
    }
  }

  openEditfilters() {
    this.is_editfilters_open = true;
  }

  closeEditFilters() {
    this.is_editfilters_open = false;
  }

  toggleSubDetail() {
    this.modelToggle("is_subdetail_open");
  }

  toggleClusterDetail() {
    this.modelToggle("is_cluster_detail_open");
  }

  openSubDetail() {
    this.is_subdetail_open = true;
  }

  openClusterDetail() {
    this.is_cluster_detail_open = true;
  }

  closeDetailPanel() {
    this.is_subdetail_open = false;
    this.is_cluster_detail_open = false;
  }

  openSideNav() {
    this.is_sidenav_open = true;
  }

  sideNavToggle() {
    const newState = !this.is_sidenav_open;
    if (newState) {
      for (const model of MODELS) {
        if (model !== "is_sidenav_open") {
          this[model] = false;
        }
      }
    }
    this.is_sidenav_open = newState;
  }

  closeSideNav() {
    this.is_sidenav_open = false;
  }

  setMaskData(e, data) {
    this.maskData = data;
  }
}

export default UiStore;
