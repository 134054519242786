import { useRef, useState } from "react";
import {
  Grow,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Typography,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    textTransform: "capitalize !important",
  },
  menuSelected: {
    background: `${theme.palette.background.selected} !important`,
  },
  ButtonDropDown: {
    ...theme.typography.body1,
    textTransform: "none",
  },
}));
function ButtonDropdown({
  options,
  selectedValue,
  color = "inherit",
  btncolor = "inherit",
  optionDisabled = () => false,
  endIcon = null,
  getOptionValue = (option) => option,
  style,
  disabled,
  onChange,
  width,
  className,
  translationNs,
  ...restProps
}) {
  const [menuOpen, setOpen] = useState(false);
  const anchorRef = useRef();
  const classes = useStyles({ disabled });
  const { t } = useTranslation([translationNs]);

  function toggleMenuOpen() {
    setOpen((current) => !current);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSelectItem(_, value) {
    onChange(value);
    setOpen(false);
  }

  const EndIcon = endIcon !== null ? endIcon : menuOpen ? <ArrowDropUp /> : <ArrowDropDownIcon />;

  return (
    <div style={{ ...style }}>
      <Button
        ref={anchorRef}
        key="button"
        onClick={() => toggleMenuOpen()}
        endIcon={EndIcon}
        color={btncolor}
        variant="outlined"
        aria-controls={menuOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={clsx(classes.ButtonDropDown, className)}
        disabled={disabled}
        {...restProps}
      >
        {t(options.get(selectedValue), { ns: translationNs })
          ? t(options.get(selectedValue), { ns: translationNs })
          : t(selectedValue, { ns: translationNs }) || ""}
      </Button>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        key="popper"
        disablePortal
        style={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Box component={Paper} width={width}>
              <ClickAwayListener onClickAway={() => handleClose()}>
                <MenuList
                  color={color}
                  sx={{ maxHeight: "400px", overflowY: options.size >= 15 && "scroll" }}
                >
                  {Array.from(options.entries()).map((option) => {
                    const isDisabled = optionDisabled(option);
                    return (
                      <MenuItem
                        key={getOptionValue(option[0])}
                        selected={option[0] === selectedValue}
                        disabled={isDisabled}
                        onClick={(event) => handleSelectItem(event, option[0])}
                        classes={{
                          root: classes.menuItem,
                          selected: classes.menuSelected,
                        }}
                        data-testid={`${restProps["data-testid"] || "dropdown-option"}__${t(
                          option[0],
                          { ns: translationNs }
                        )}`}
                      >
                        <Typography variant="body1" color="inherit">
                          {t(option[1], { ns: translationNs })}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default ButtonDropdown;
