import { createRouterState, RouterStore } from "mobx-state-router";

import { routes } from "../conf/routes";
import ForeCastStore from "../pages/ForeCast/store";
import Production from "../pages/Production/store";
import RtAnalysis from "../pages/RTAnalysis/rtstore";
import DistDashStore from "../pages/Distribution/store";
import ProfileStore from "../pages/Profile/store";
import Dash from "../pages/dash/store";
import SubListStore from "../pages/MapView/store";
import PricingStore from "../pages/Pricing/store";
import PricingReportStore from "../pages/PricingReports/store";
import SalesDashStore from "../pages/SalesDash/store";
import MeteringDashStore from "../pages/MeteringDash/Dashboard/store";
import OptimizationStore from "../pages/Optimization/store";
import SettingsStore from "../pages/Profile/Settings/store";

import Networks from "./networks";
import UiStore from "./ui_store";
import Session from "./session";
import Filters from "./filters";
import Interactions from "./interaction";
import Substation from "./substation";
import Cache from "./cache";
import UserPreferences from "./user_preferences";
import Api from "./api";
import UtfApi from "./utfapi";
import OptApi from "./opt_api";
import FaultDetectionApi from "./apis/FaultDetection/FaultDetection.api";
import NotificationStore from "./notification.store";
import LanguageStore from "./language.store";

const notFound = createRouterState("notFound");

const USING_CACHE = ["networks"];

class RootStore {
  constructor() {
    this.initialized = false;
    this.cache = new Cache(this);
    this.filters = new Filters(this);
    this.session = new Session(this);
    this.routerStore = new RouterStore(routes, notFound, { parent: this });
    this.ui = new UiStore(this);
    this.pricing = new PricingStore(this);
    this.preport = new PricingReportStore(this);

    this.networks = new Networks(this);
    this.sub = new Substation(this);
    this.app = new Interactions(this);
    this.notifStore = new NotificationStore(this);
    this.frcst = new ForeCastStore(this);
    this.production = new Production(this);
    this.rta = new RtAnalysis(this);
    this.sublist = new SubListStore(this);
    this.language = new LanguageStore(this);

    // APIs
    this.utfapi = new UtfApi(this); /* low level api access */
    this.newapi = new Api(this); /* high level api access */
    this.faultDetectionApi = new FaultDetectionApi(this);

    this.distdash = new DistDashStore(this);
    this.auth = new ProfileStore(this);
    this.dash = new Dash(this);
    this.sales = new SalesDashStore(this);
    this.meter = new MeteringDashStore(this);
    this.opt_api = new OptApi(this);
    this.opt_store = new OptimizationStore(this);
    this.preferences = new UserPreferences(this);
    this.settingsStore = new SettingsStore(this);

    this.startup = this.startup.bind(this);
  }

  async startup() {
    if (!this.initialized) {
      await this.app.startup();
      await this.networks.check();
      await this.filters.loadFromCache();
      await this.networks.keepFilteredUpdated();
      this.initialized = true;
    }
  }

  async clearCache() {
    USING_CACHE.forEach((store) => {
      this[store].clearCache();
    });
  }
}

const rootStoreInstance = new RootStore();

if (window.Cypress) {
  window.store = rootStoreInstance;
}

export default rootStoreInstance;
