import React from 'react';
import {observer} from 'mobx-react';
import withStyles from '@mui/styles/withStyles';
import { DateTime } from 'luxon';
import Typography from "@mui/material/Typography";

const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        borderBottom: ["1px", "solid", theme.palette.grey.blue4].join(' '),
        minHeight: 0,
        backgroundColor: theme.palette.grey.white,
        flexGrow: 1,
        cursor: "pointer",
        maxWidth: '100%',
        alignItems: "flex-start",
        overflow: "visible",
    },
    ts: {
        margin: theme.spacing(2),
        border: ["2px", "solid", theme.palette.grey.blue4].join(' '),
        padding: theme.spacing(2),
        height: theme.spacing(10),
        textAlign: "center"
    },
    date: {
        marginBottom: theme.spacing(1)
    },
    detail: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        overflow: "hidden"
    },
    title: {
        marginBottom: theme.spacing(1)
    },
})

class Update extends React.Component {
    onClick = () => {
        const { data } = this.props;
        if (typeof window !== 'undefined') {
            window.open(data.link, "_blank")
        }
    }

    render() {
        const { data, classes } = this.props;

        return (
            <div href={data.link} className={classes.root} onClick={this.onClick}>
                <div className={classes.ts}>
                    <Typography variant="body1" className={classes.date}>{DateTime.fromISO(data.published).toFormat("MMM")}</Typography>
                    <Typography variant="body1" >{DateTime.fromISO(data.published).toFormat("d")}</Typography>
                </div>
                <div className={classes.detail}>
                    <Typography component="div" variant="subtitle2" className={classes.title}>
                        {data.title}
                    </Typography>
                    <Typography component="div" variant="body2" >{data.summary}</Typography>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(observer(Update));