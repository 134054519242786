import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { i18nextPlugin } from "translation-check";
// import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  /**
   * ! Only Enable this once there is more then one language !
   * and make sure you double-check your Network logs since ...
   * this may cause duplicate request, you may see something like;
   * - localhost:3000/i18n/_message/en.json
   * - localhost:3000/i18n/_message/en_US.json
   *
   * which second one is unwanted/not-exist.
   *
   */
  // .use(LanguageDetector)
  .use(initReactI18next)
  .use(i18nextPlugin)
  .init({
    partialBundledLanguages: true,
    fallbackLng: "en",
    debug: process.env.DEBUG_I18NEXT === "true",
    backend: {
      loadPath: "i18n/{{ns}}/{{lng}}.json",
    },
    // The NS's listed below will always be imported
    // Only include the NS's that common the rest will be...
    // imported automatically via "partialBundledLanguages" option
    ns: [
      "_action",
      "_common",
      "_messages",
      "navbar",
      "sideMenu",
      "filters",
      "extendView",
      "home",
      "profile",
      // "metering",
      // "sales",
      // "distribution",
      // "production",
      // "dataLibrary",
      // "mapview",
    ],
    defaultNS: "_common",
    fallbackNS: "_common",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ",",
      skipOnVariables: false, // enable nested translation
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
