import { Component } from 'react';
import { inject, observer } from 'mobx-react';
import withStyles from '@mui/styles/withStyles';
import SelectBlog from './FeaturedBlogSelect';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const styles = theme => ({
    root: {
        display: 'flex',
        justifyContent: "space-between",
        flexDirection: "row",
        backgroundColor: "white",
        height: "51px",
        marginTop: theme.spacing(1)
    },
    dotsContainer: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        flexDirection: "row",
    },
});

class FeaturedBlogCarouselStrip extends Component {

    render() {
        const { classes, dash } = this.props;
        return (
            <div className={classes.root}>
                <IconButton onClick={dash.prevBlog} size="large">
                    <ChevronLeftIcon size="large" />
                </IconButton>
                <div className={classes.dotsContainer}>
                    {dash.featuredBlogs.map((blog, idx) => <SelectBlog key={blog.cms_id} idx={idx} blog={blog} />)}
                </div>
                <IconButton onClick={dash.nextBlog} size="large">
                    <ChevronRightIcon size="large" />
                </IconButton>
            </div>
        );
    }
}



export default inject('dash')(withStyles(styles)(observer(FeaturedBlogCarouselStrip)));