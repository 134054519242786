import { observer, useLocalObservable } from "mobx-react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import StarIcon from "../../icons/Star";
import StarFullIcon from "../../icons/Starfull";
import useStores from "../../hooks/useStores";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.grey.white,
    justifyContent: "flex-start",
    alignItems: "center",
    height: "auto",
    padding: theme.spacing(1, 1, 1, 2),
    minHeight: theme.spacing(7),
  },
  text: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    maxWidth: "85%",
  },
  strip: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

function SubHeader({ nameAttr, addressAttr, isSubstation }) {
  const classes = useStyles();
  const { preferences, networks, sub } = useStores();
  const local = useLocalObservable(() => ({
    get isFavorite() {
      const currentNetwork = networks.current_network.uid;
      const currentNetworksList = preferences.fav_subs.get(currentNetwork);
      if (currentNetworksList) {
        return currentNetworksList.has(sub.current_substation);
      }
      return false;
    },

    handleClicked: () =>
      preferences.toggleFavSub(networks.current_network.uid, sub.current_substation),
  }));

  return (
    <div className={classes.root}>
      <Tooltip title={nameAttr || ""} TransitionProps={{ "data-maskdata": "true" }}>
        <div className={classes.text} data-maskdata>
          <div className={classes.strip}>
            <Typography variant="h5" noWrap>
              {[nameAttr]}
            </Typography>
          </div>
          <div className={classes.strip}>
            <Typography variant="h6">{[addressAttr]}</Typography>
          </div>
        </div>
      </Tooltip>
      {isSubstation && (
        <IconButton
          color={local.isFavorite ? "secondary" : "primary"}
          onClick={local.handleClicked}
        >
          {local.isFavorite ? <StarFullIcon /> : <StarIcon />}
        </IconButton>
      )}
    </div>
  );
}

export default observer(SubHeader);
