import { Component } from 'react';
import Tabs from '@mui/material/Tabs';
import { observer, inject } from 'mobx-react'
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
    root: {
        //maxWidth: 'none'
        //flexGrow: 1,
        boxShadow: '0 1px 3px 0 #00000029',
    },
    scrollable: {
        flexGrow: 1
    }
})

class UtfTabs extends Component {

    render() {
        const { children, ui, ...restProps } = this.props;

        return (
            <Tabs sizechange={ui.grid_area} {...restProps}>
                {children}
            </Tabs>);
    }
}

export default inject("ui")(withStyles(styles)(observer(UtfTabs)));