import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react";
import makeStyles from "@mui/styles/makeStyles";

import useStores from "../hooks/useStores";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    height: "100vh",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: "url('login-logo.png')",
    backgroundPosition: "center",
  },
  loginButton: {
    "&&": {
      border: "2px solid #FFFFFF",
      borderRadius: "10px",
      backgroundColor: "transparent",
      width: 180,
      height: 50,
      fontSize: "135%",
    },
  },
}));

function LoginPage() {
  const classes = useStyles();
  const store = useStores();
  return (
    <Box className={classes.container}>
      <Box display="flex" flexDirection="column" justifyContent="center" mt={26}>
        <Button
          variant="contained"
          onClick={store.session.login}
          color="primary"
          size="large"
          classes={{ root: classes.loginButton }}
          data-testid="login-button"
        >
          Sign in
        </Button>
        <Box mt={2}>
          <Typography variant="h6" color="secondary">
            {store.session.err_msg}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default observer(LoginPage);
