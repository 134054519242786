import SubRow from "../SubRow";

function renderRow(props) {
  const { data, index, style, isCluster } = props;
  const subUid = data.list[index];
  return (
    <div key={index} style={style} data-maskdata>
      <SubRow index={index} subUid={subUid} reader={data.reader} isCluster={isCluster} />
    </div>
  );
}

export default renderRow;
