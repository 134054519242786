import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { autorun, runInAction } from "mobx";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { ButtonGroup, Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import UtfAccordion from "../../ui/Accordion";
import { debounce } from "../../../core/utils";
import useStores from "../../../hooks/useStores";
import ButtonDropdown from "../../ui/ButtonDropdown";
import { withErrorBoundary } from "../../ui/ErrorBoundary";

import useConsumption from "./state";
import * as CONSUMPTION from "./Constants";
import ChartNavigator from "./ChartNavigator";
import ConsumptionDatePicker from "./ConsumptionDatePicker";

const CHART_NAVIGATOR_OFFSET = 96;

const useStyles = makeStyles((theme) => ({
  root: {
    "&&": { position: "fixed", backgroundColor: theme.palette.primary.main, zIndex: 2 },
  },
  section: {
    display: "flex",
    width: "auto",
    alignItems: "center",
    padding: theme.spacing(2.87, 2),
    color: theme.palette.grey.white,
    gap: "10px",
  },
  button: {
    height: "32px",
    border: `${theme.palette.grey.white} 1px solid !important`,
  },
  graphContainer: {
    width: "100%",
    height: "31px",
    margin: "0 22px",
    "& > div": {
      borderTop: `${theme.palette.grey.white} 1px solid`,
      borderBottom: `${theme.palette.grey.white} 1px solid`,
    },
  },
  selectedOption: {
    "&&": {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  datePicker: {
    border: ({ disabled }) =>
      `${disabled ? theme.palette.grey.blue28 : theme.palette.grey.white} 1px solid`,
    "& .MuiInputBase-root": {
      color: theme.palette.grey.white,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: `${theme.palette.grey.white} !important`,
      "-webkit-text-fill-color": `${theme.palette.grey.white} !important`,
    },
    "&&": {
      width: "165px",
      height: "32px",
      paddingLeft: "10px",
      "& svg": {
        fontSize: "28px",
        color: "white",
        marginTop: "6px",
        paddingRight: "4px",
      },
    },
  },
}));

function FilterBar() {
  const navigator = useRef();
  const { ui } = useStores();
  const { t } = useTranslation(["extendView"]);
  const { extremes, interval, onIntervalChange, setReaderRange, fetchedRange, isDataAvailable } =
    useConsumption();
  const classes = useStyles({ disabled: !isDataAvailable });

  const [speedOption, setSpeedOption] = useState("");
  const [isSpeedButtonActive, setIsSpeedButtonActive] = useState(false);

  const updateNavigatorExtremes = (start, end) => {
    if (!navigator?.current) return;
    navigator.current.chart.xAxis[0].setExtremes(start, end, true, false);
  };

  useEffect(() => {
    autorun(() => {
      updateNavigatorExtremes(fetchedRange.start.toMillis(), fetchedRange.end.toMillis());
    });
  }, [fetchedRange.end, fetchedRange.start]);

  const handleButtonDropdown = (value) => {
    onIntervalChange(value);
    setIsSpeedButtonActive(false);
  };

  const speedDates = useMemo(() => {
    const dates = {};
    CONSUMPTION.SPEED_OPTIONS.forEach(({ value, label, type }) => {
      const end = fetchedRange.end.set({ hours: 9, minute: 0, second: 0, millisecond: 0 });
      const start = end
        .plus({ [type]: value * -1 })
        .set({ hours: 8, minute: 0, second: 0, millisecond: 0 });

      dates[label] = { start, end };
    });
    return dates;
  }, [fetchedRange.end]);

  const toggleSpeedOption = (label) => {
    if (speedOption === label) {
      const { dataMin, dataMax } = navigator.current?.chart?.xAxis[0] || {};
      setSpeedOption("");
      updateNavigatorExtremes(dataMin, dataMax);
      setIsSpeedButtonActive(false);
    } else {
      setIsSpeedButtonActive(true);
      const { start, end } = speedDates[label];
      updateNavigatorExtremes(start.toMillis(), end.toMillis());
    }
  };

  const handleStartDatePick = (dt) => {
    if (!dt || dt?.invalid) return;

    let newStartDate = dt;
    if (dt > extremes.end) {
      newStartDate = DateTime.fromMillis(extremes.end?.toMillis()).minus({ hours: 6 });
    }

    setSpeedOption("");
    updateNavigatorExtremes(newStartDate.toMillis(), extremes.end?.toMillis());
  };

  const handleEndDatePick = (dt) => {
    if (!dt || dt?.invalid) return;

    let newEndDate = dt;
    if (dt < extremes.start) {
      newEndDate = DateTime.fromMillis(extremes.start?.toMillis()).plus({
        hours: 6,
      });
    }

    setSpeedOption("");
    updateNavigatorExtremes(extremes.start?.toMillis(), newEndDate.toMillis());
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSetReaderRange = useCallback(
    debounce((start, end) => {
      if (!end) return;
      setReaderRange(start, end);

      Object.keys(speedDates).forEach((label) => {
        if (
          speedDates[label].start.ordinal === start.ordinal &&
          speedDates[label].end.ordinal === end.ordinal &&
          speedDates[label].start.year === start.year &&
          speedDates[label].end.year === end.year
        ) {
          setSpeedOption(label);
        }
      });
    }, 200),
    [speedDates]
  );

  useEffect(() => {
    setSpeedOption("");
    runInAction(() => {
      handleSetReaderRange(extremes?.start, extremes?.end);
    });
  }, [extremes, handleSetReaderRange]);

  const fetchedRangeStartAndEndDiff = fetchedRange.end
    .diff(fetchedRange.start, ["months"])
    .toObject();

  const chartWidth = ui.is_subdetail_open || ui.is_cluster_detail_open ? ui.max_drawer_width : 0;

  const DATA_PICKER_VIEW = [
    "year",
    "month",
    "day",
    ...(interval === CONSUMPTION.INTERVAL_HOURLY ? ["hours"] : []),
  ];

  const [endDatePicker, setEndDatePicker] = useState(extremes?.end);

  useEffect(() => {
    if (!extremes?.end) return;
    const isPassHalfAnHour = DateTime.now().minute > 30;

    if (!isSpeedButtonActive) {
      setEndDatePicker(
        extremes?.end
          .plus({ hour: isPassHalfAnHour ? 1 : 0 })
          .set({ minute: isPassHalfAnHour ? 0 : 30 })
      );
    } else {
      setEndDatePicker(extremes?.end);
    }
  }, [isSpeedButtonActive, extremes?.end]);

  return (
    <UtfAccordion
      header={
        <div className={classes.graphContainer}>
          <ChartNavigator chartRef={navigator} width={chartWidth - CHART_NAVIGATOR_OFFSET} />
        </div>
      }
      width={chartWidth}
      className={classes.root}
      data-testid="consumption-filterbar"
    >
      <div className={classes.section}>
        <Typography>{t("text_resolution")}:</Typography>
        <ButtonDropdown
          variant="outlined"
          options={CONSUMPTION.INTERVAL_OPTIONS}
          selectedValue={interval}
          onChange={handleButtonDropdown}
          style={{ height: "32px" }}
          disabled={!isDataAvailable}
          data-testid="interval-btn"
          translationNs="_common"
        />
      </div>
      <div className={classes.section}>
        <ButtonGroup size="large" disabled={!isDataAvailable}>
          {CONSUMPTION.SPEED_OPTIONS.map(({ value, label, type, disableMonth }) => (
            <Button
              key={`speed-button-${type}`}
              value={value}
              className={clsx(classes.button, {
                [classes.selectedOption]: speedOption === label && isDataAvailable,
              })}
              onClick={() => toggleSpeedOption(label)}
              color="primary"
              variant="contained"
              disabled={fetchedRangeStartAndEndDiff.months <= disableMonth}
              data-testid={`speed-button-${type}`}
            >
              {t(label)}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <div className={classes.section}>
        <ConsumptionDatePicker
          minDate={fetchedRange?.start}
          maxDate={fetchedRange?.end}
          value={extremes?.start}
          onChange={handleStartDatePick}
          views={DATA_PICKER_VIEW}
          disabled={!isDataAvailable}
          classname={classes.datePicker}
          id="extremes-start"
        />

        <ConsumptionDatePicker
          minDate={fetchedRange?.start}
          maxDate={fetchedRange?.end}
          value={endDatePicker}
          onChange={handleEndDatePick}
          views={DATA_PICKER_VIEW}
          disabled={!isDataAvailable}
          classname={classes.datePicker}
          id="extremes-end"
        />
      </div>
    </UtfAccordion>
  );
}

export default withErrorBoundary(observer(FilterBar));
