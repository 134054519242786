import { observer, inject } from "mobx-react";
import Dialog from "@mui/material/Dialog";
import Drawer from "@mui/material/Drawer";
import makeStyles from "@mui/styles/makeStyles";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import SubstationSummary from "../SubList/Summary";
import SubPane from "../SubPane";
import EditFilters from "../Filters/EditFilters";
import SubstationFilters from "../SubList/SubstationFilters";
import SubstationExplore from "../SubList/Explore/Substation";
import SideBarNestedItem from "../ui/SideBarNestedItem";
import { DRAWER_WIDTH } from "../../conf/ui_constants";
import ClusterExplore from "../SubList/Explore/Cluster";

import NavDrawer from "./NavDrawer";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  paperRoot: {
    "&&": {
      backgroundColor: "#f5f5f5",
      color: theme.palette.grey.blue72,
      width: DRAWER_WIDTH,
    },
  },
}));

function ModalManager({ rootStore: { ui, networks }, session }) {
  const classes = useStyles();
  const isSubOrClusterDetailOpen = ui.is_subdetail_open || ui.is_cluster_detail_open;
  if (networks.ready) {
    return (
      <>
        <Drawer
          open={ui.is_substation_model_open}
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.paperRoot }}
          data-testid="drawer-substation-explore"
        >
          <div className={classes.toolbar} />
          {ui.is_substation_model_open && <SubstationExplore />}
        </Drawer>
        <Drawer
          open={ui.is_sidenav_open}
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.paperRoot }}
          data-testid="drawer-navigation"
        >
          <div className={classes.toolbar} />
          <NavDrawer />
        </Drawer>
        <Drawer
          open={ui.is_cluster_model_open}
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.paperRoot }}
        >
          <div className={classes.toolbar} />
          {ui.is_cluster_model_open && <ClusterExplore />}
        </Drawer>
        <Drawer
          open={ui.is_filteroptions_open}
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.paperRoot }}
          data-testid="drawer-substation-filters"
        >
          <div className={classes.toolbar} />
          {ui.is_filteroptions_open && <SubstationFilters />}
        </Drawer>
        <Drawer
          open={ui.is_subsummary_open}
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.paperRoot }}
          data-testid="drawer-substation-summary"
        >
          {ui.is_subsummary_open && <SubstationSummary type="substation" />}
        </Drawer>
        <Drawer
          open={ui.is_cluster_summary_open}
          anchor="left"
          variant="persistent"
          classes={{ paper: classes.paperRoot }}
        >
          {ui.is_cluster_summary_open && <SubstationSummary type="cluster" />}
        </Drawer>
        <Dialog open={ui.is_editfilters_open} onClose={ui.closeEditFilters} maxWidth="lg">
          {ui.is_editfilters_open && <EditFilters open={ui.is_editfilters_open} />}
        </Dialog>
        <Drawer
          open={isSubOrClusterDetailOpen}
          anchor="left"
          variant="persistent"
          elevation={1}
          classes={{ paper: classes.paperRoot }}
          PaperProps={{ style: { width: isSubOrClusterDetailOpen ? ui.max_drawer_width : 0 } }}
          data-testid="drawer-substation-detail"
        >
          <div className={classes.toolbar} />
          {isSubOrClusterDetailOpen && <SubPane />}
        </Drawer>
      </>
    );
  }

  return (
    <Drawer
      open={ui.is_sidenav_open}
      anchor="left"
      variant="persistent"
      classes={{ paper: classes.paperRoot }}
      data-testid="drawer-profile"
    >
      <div className={classes.toolbar} />
      <SideBarNestedItem
        key="profile"
        routes={{
          route: "profile",
          text: "Profile/Settings",
          Icon: PersonOutlineIcon,
          children: [
            {
              route: "logout",
              text: "Logout",
              cb: () => {
                session.logout();
              },
            },
          ],
        }}
      />
    </Drawer>
  );
}

export default inject("rootStore", "session")(observer(ModalManager));
