import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: theme.spacing(7),
    height: "auto",
    display: "flex",
    flexFlow: "wrap",
    flexShrink: 0,
    justifyContent: "space-between",
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    zIndex: 1,
    margin: theme.spacing(1, 0),
  },
  gap: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  flat: {
    boxShadow: "none",
  },
  root_grow: {
    flexGrow: 1,
  },
  root_noGrow: {
    flexGrow: 0,
  },
  root_fullWidth: {
    width: "100%",
  },
  root_dark: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey.white,
  },
  root_light: {
    backgroundColor: theme.palette.grey.white,
    color: theme.palette.grey.blue72,
  },

  addonsRoot: {
    height: "auto",
    display: "flex",
    flexFlow: "wrap",
    "& > div": {
      width: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderLeft: 0,
      padding: theme.spacing(0, 2),
      borderRight: `2px solid ${theme.palette.grey.blue2}`,
      "&:first-child": {
        borderLeft: ({ withTitle }) => `${withTitle ? 2 : 0}px solid ${theme.palette.grey.blue2}`,
      },
      "&:last-child": {
        paddingRight: 0,
        borderRight: 0,
      },
    },
  },
  addOnContainer: {
    maxWidth: "auto",
  },
}));

function HeadingBar({
  variant,
  grow,
  fullWidth,
  flat,
  gap,
  children,
  title,
  withTitle,
  titleUpperCase,
  className,
  addons,
  ...props
}) {
  const classes = useStyles({ withTitle });

  return (
    <div
      className={clsx(
        classes.root,
        classes[`root_${variant}`],
        classes[`root_${grow}`],
        fullWidth && classes.fullWidth,
        flat && classes.flat,
        gap && classes.gap,
        className
      )}
      {...props}
    >
      {withTitle && (
        <Typography
          variant="h5"
          data-testid={`${title}-heading-bar`}
          sx={{
            textTransform: titleUpperCase ? "uppercase" : "capitalize",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
        </Typography>
      )}
      <div className={classes.addonsRoot}>
        {children ||
          (addons &&
            addons.map((addon, index) => (
              <div className={classes.addOnContainer} key={`addon-${String(index)}`}>
                {addon.icon && (
                  <Box mt={0.5} mr={addon.text && 1}>
                    {addon.icon}
                  </Box>
                )}
                {addon.text && <Typography variant="h6">{addon.text}</Typography>}
                {addon.action && <Box mx={addon.text && 1}>{addon.action}</Box>}
              </div>
            )))}
      </div>
    </div>
  );
}

HeadingBar.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["light", "dark"]),
  withTitle: PropTypes.bool,
  grow: PropTypes.oneOf(["grow", "noGrow"]),
  titleUpperCase: PropTypes.bool,
  fullWidth: PropTypes.bool,
  flat: PropTypes.bool,
  gap: PropTypes.bool,
  className: PropTypes.string,
  addons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      icon: PropTypes.node,
      action: PropTypes.node,
    })
  ),
  children: PropTypes.node,
};

HeadingBar.defaultProps = {
  variant: "light",
  grow: "grow",
  fullWidth: false,
  flat: false,
  withTitle: true,
  gap: true,
  className: null,
  titleUpperCase: true,
  addons: [],
  children: null,
};
export default observer(HeadingBar);
