/**
 * Sections for block columns
 */
const COLUMN_SECTION = {
  building: "text_building",
  customer: "text_customer",
  energy: "text_energy",
  flow: "text_flow",
  temperatures: "text_temperatures",
  volume: "text_volume",
  location: "text_location",
  design_load: "text_design_load",
  usage_patterns: "text_usage_patterns",
};

/**
 * Period filters for period-limited columns
 */
const COLUMN_PERIOD = {
  year: "y",
  month: "m",
  r12: "r",
};

export { COLUMN_SECTION, COLUMN_PERIOD };
