import {observer} from 'mobx-react';
import Box from '@mui/material/Box';


function FeaturedBlogCarouselItem ({ blog }){
    return (
        <Box component="a"
            href={blog.link}
            target="_blank"
            display="block"
            height="100%"
            style={{
                textDecoration: 'none',
                backgroundImage: `url(${blog.hero_image_link})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        />
    );
}

export default observer(FeaturedBlogCarouselItem);