/* eslint-disable camelcase */
import { observer } from "mobx-react";
import GoogleMapReact from "google-map-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";

import CONF from "../../../conf/conf";
import { formatNumberForLocale, valid_coords as validCoords } from "../../../core/utils";
import useSubstationStores from "../../../hooks/useSubstationStores";
import SummaryHeader from "../SubHeader";
import Marker from "../../analytics/Marker";
import DumbbellIcon from "../../../icons/Dumbbell";
import SubstationIcon from "../../../icons/Substation";
import ClusterIcon from "../../../icons/Cluster";
import { NETWORK_SUBSTATION, SUBSTATION } from "../constant";
import useSubExplore from "../Explore/useSubExplore";
import { blkReader } from "../../../conf/blocks";
import { SubHeader } from "../../../shared_components/ui/layout";

import { SectionTitle, SectionItem } from "./components";
import useSummary from "./useSummary";

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    height: "100%",
    background: theme.palette.grey.blue2,
  },
  mapContainer: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    height: 192,
    alignItems: "center",
    border: `1px solid ${theme.palette.grey.blue4}`,
    borderLeft: 0,
    borderRight: 0,
  },
  container: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey.white,
    borderBottom: `1px solid ${theme.palette.grey.blue4}`,
  },
  sectionDetail: {
    display: "flex",
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1.2),
    borderTop: `1px solid ${theme.palette.grey.blue4}`,
    borderBottom: `1px solid ${theme.palette.grey.blue4}`,
  },
  icon: {
    fontSize: theme.typography.subtitle2.fontSize,
    margin: theme.spacing(0, 0.5),
  },
}));

function SubstationSummary({ type }) {
  const classes = useStyles();
  const summary = useSummary({ type });
  const { ui, sub } = useSubstationStores();
  const { t } = useTranslation(["_action"]);
  const explore = useSubExplore({ type: NETWORK_SUBSTATION });

  const isSubstation = type === SUBSTATION;
  const reader = blkReader(explore.substations, explore.colSpecs);
  const { id, customer, install_address, pricing, core, epcore_normalized, ep_design_24h } =
    summary.data;

  const substationId = `${t("text_substation_id")}: ${id || "..."}`;
  const totalSubstationInCluster = `${
    explore?.substationsUid?.length === 0
      ? "..."
      : explore?.substationsUid?.length !== undefined
      ? explore?.substationsUid?.length
      : 0
  } ${t("text_substations")}`;

  return (
    <Box className={classes.root} id="topsub" data-testid="sidebar-summary-panel">
      <div className={classes.toolbar} />
      <Box display="flex" flexDirection="column">
        <SubHeader
          wikiUrl="https://wiki.energypredict.com/global_features/substation_explore/substation_explore_basics"
          endComponent={
            <Button
              endIcon={<ChevronRightIcon />}
              onClick={isSubstation ? ui.openSubDetail : ui.openClusterDetail}
              color="inherit"
              variant="text"
              data-testid="open-extend-panel"
            >
              {t("action_extend", { ns: "_action" })}
            </Button>
          }
        >
          {ui.is_subsummary_open && <SubstationIcon />}
          {ui.is_cluster_summary_open && <ClusterIcon />}
          <Button
            style={{ marginLeft: 8 }}
            onClick={summary.goToExplore}
            startIcon={<ChevronLeftIcon />}
            color="primary"
            variant="contained"
            disableElevation
            data-testid="back-from-summary-to-explore"
          >
            {t("action_back", { ns: "_action" })}
          </Button>
        </SubHeader>
        {!summary.dataFetched && <LinearProgress />}
        <SummaryHeader
          store={summary.data}
          nameAttr={isSubstation ? customer?.name : sub.current_cluster.name}
          addressAttr={install_address?.street}
          tempAttr="returntemp_flowweighted_avg"
          energyAttr="heat_energy_sum"
          isSubstation={isSubstation}
        />
        <Box className={classes.mapContainer} data-maskdata>
          {ui.is_subsummary_open && validCoords(install_address, "coordinates") && (
            <GoogleMapReact
              defaultZoom={15}
              center={{
                lat: install_address?.coordinates[0],
                lng: install_address?.coordinates[1],
              }}
              yesIWantToUseGoogleMapApiInternals
              bootstrapURLKeys={{ key: CONF.gmaps_key }}
            >
              <Marker lat={install_address?.coordinates[0]} lng={install_address?.coordinates[1]} />
            </GoogleMapReact>
          )}
          {ui.is_cluster_summary_open && (
            <GoogleMapReact
              defaultZoom={8}
              center={{
                lat:
                  validCoords(reader(explore.filteredList[0]), "coordinates") &&
                  reader(explore.filteredList[0])?.coordinates[0],
                lng:
                  validCoords(reader(explore.filteredList[0]), "coordinates") &&
                  reader(explore.filteredList[0])?.coordinates[1],
              }}
              yesIWantToUseGoogleMapApiInternals
              bootstrapURLKeys={{ key: CONF.gmaps_key }}
            >
              {explore.substationsUid?.map(
                (substation) =>
                  validCoords(reader(substation.uid), "coordinates") && (
                    <Marker
                      key={reader(substation.uid)?.coordinates}
                      lat={reader(substation.uid)?.coordinates[0]}
                      lng={reader(substation.uid)?.coordinates[1]}
                    />
                  )
              )}
            </GoogleMapReact>
          )}
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" width="100%" flexGrow={1} overflow="auto">
        {/* ID - Section */}
        <Box className={classes.container} padding={2}>
          <Box component={Typography} variant="body1" color="grey.blue50" data-maskdata>
            {isSubstation ? substationId : totalSubstationInCluster}
          </Box>
        </Box>

        {/* Section */}
        {isSubstation && (
          <div className={classes.container}>
            <SectionTitle type={type} id="financial">
              {t("text_financials")}
            </SectionTitle>
            <SectionItem
              title={t("text_price_tariff")}
              id="price-tariff"
              value={pricing?.tariff_id}
              type={type}
            />
          </div>
        )}

        {/* Section */}
        <div className={classes.container}>
          <SectionTitle type={type} id="consumption">
            {t("text_consumption")}
          </SectionTitle>
          <SectionItem
            title={t("text_energy")}
            id="energy"
            type={type}
            value={formatNumberForLocale(core?.heat_energy_sum)}
            unit="kWh"
            year={summary.year}
          />
          <SectionItem
            title={t("text_normal_year_energy")}
            id="normal-year-energy"
            type={type}
            value={formatNumberForLocale(epcore_normalized?.heat_energy_sum)}
            unit="kWh"
            hasIcon
            year={summary.year}
          />
          <SectionItem
            title={t("text_design_peak_24h")}
            id="design-peak-24h"
            type={type}
            value={formatNumberForLocale(ep_design_24h?.heat_energy)}
            unit="kW"
            hasIcon
            year={summary.year}
          />
          <SectionItem
            title={t("text_full_load_normal_year")}
            id="full-load-hours-normal-year"
            type={type}
            value={summary.calculateFullLoadEnergy(
              epcore_normalized?.heat_energy_sum,
              ep_design_24h?.heat_energy
            )}
            unit="h"
            hasIcon
            year={summary.year}
          />
        </div>

        {/* Section */}
        <div className={classes.container}>
          <SectionTitle type={type} id="temp-avg">
            {t("text_temperature_averages")}
          </SectionTitle>
          <Typography variant="body1" className={classes.sectionDetail}>
            {t("text_measured")} | {summary.year} | {t("text_flow-weighted")}
          </Typography>
          <SectionItem
            title={`${t("text_return_temp")}.`}
            id="measure-return-temp"
            type={type}
            value={formatNumberForLocale(core?.returntemp_flowweighted_avg)}
            unit="°C"
            isDense
          />
          <SectionItem
            title={`${t("text_supply_temp")}.`}
            id="measure-supply-temp"
            type={type}
            value={formatNumberForLocale(core?.supplytemp_flowweighted_avg)}
            unit="°C"
            isDense
          />
          <SectionItem
            title={`${t("text_delta_temp")}.`}
            id="measure-delta-temp"
            type={type}
            value={summary.calculateDeltaTemp(
              core?.supplytemp_flowweighted_avg,
              core?.returntemp_flowweighted_avg
            )}
            unit="°C"
            isDense
          />

          {/* Section */}
          <Typography variant="body1" className={classes.sectionDetail}>
            {t("text_normal_year")} |<DumbbellIcon className={classes.icon} /> {summary.year} |{" "}
            {t("text_flow-weighted")}
          </Typography>
          <SectionItem
            title={`${t("text_return_temp")}.`}
            id="normal-return-temp"
            type={type}
            value={formatNumberForLocale(epcore_normalized?.returntemp_flowweighted_avg)}
            unit="°C"
            isDense
          />
          <SectionItem
            title={`${t("text_supply_temp")}.`}
            id="normal-supply-temp"
            type={type}
            value={formatNumberForLocale(epcore_normalized?.supplytemp_flowweighted_avg)}
            unit="°C"
            isDense
          />
          <SectionItem
            title={`${t("text_delta_temp")}.`}
            id="normal-delta-temp"
            type={type}
            value={summary.calculateDeltaTemp(
              epcore_normalized?.supplytemp_flowweighted_avg,
              epcore_normalized?.returntemp_flowweighted_avg
            )}
            unit="°C"
            isDense
          />
        </div>
      </Box>
    </Box>
  );
}

export default observer(SubstationSummary);
