import React from 'react';
import { inject, observer } from 'mobx-react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';

const styles = theme => ({
    root: {
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        marginLeft: theme.spacing(.5),
        marginRight: theme.spacing(.5),
        width: theme.spacing(2),
        height: theme.spacing(2)
    },
    active: {
        backgroundColor: theme.palette.secondary.main,
    }
})

class SelectBlog extends React.Component {
    onClick = () => {
        const { dash, idx } = this.props;
        dash.selectCurrentBlog(idx);
    }
    render() {
        const { classes, dash, idx } = this.props;
        const is_active = idx === dash.current_blog_id;
        return (
            <div className={clsx(classes.root, is_active && classes.active)} onClick={this.onClick} ></div>
        );
    }
}

export default inject('dash')(withStyles(styles)(observer(SelectBlog)));