import { observer } from "mobx-react";
import PropTypes from "prop-types";
import { Box, Button, IconButton, Typography } from "@mui/material";

function PageTitle({ title, pageIcon, paperColor, onClick }) {
  return (
    <Box flexGrow={1}>
      <Button onClick={onClick} sx={{ color: paperColor }} component="div" fullWidth>
        {pageIcon && <IconButton sx={{ color: paperColor }}>{pageIcon}</IconButton>}
        <Typography variant="h4" data-testid="page-title">
          {title}
        </Typography>
      </Button>
    </Box>
  );
}

PageTitle.defaultProps = {
  pageIcon: null,
  paperColor: "",
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  pageIcon: PropTypes.element,
  paperColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default observer(PageTitle);
