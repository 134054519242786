
import {
    COL_PARSERS,
    BLOCK, SPLIT_TYPE, SPLIT_CATEGORY
} from '../conf/constants';
import {blkReader, COLTYPE, UID_TYPE} from '../conf/blocks';
import { have_blocks } from '../core/utils';


export function sortByIndex(index, direction = "asc", type = "number") {
    let ascending = direction === "asc";
    let options = {};
    options['numeric'] = type === "number";
    let cmpf = new Intl.Collator("sv", options).compare

    function sort(a, b) {
        let x = a[index], y = b[index];
        return ascending ? cmpf(x, y) : cmpf(y, x);
    }
    return sort;
}

export function splitDf(rdf, block_definitions) {
    /*
    split the received df into component blocks and transform/read them.
     */
    block_definitions = block_definitions || BLOCK;
    const mainColumns = rdf.columns;
    const mainRows = rdf.data;
    let blockNames = new Map();

    for (let colIdx = 0; colIdx < mainColumns.length; colIdx++) {
        for (let specName of Object.keys(block_definitions)) {
            const BLKSPEC = block_definitions[specName]
            let blkParts = BLKSPEC.parse_block_name(mainColumns[colIdx])
            if (blkParts && BLKSPEC.columns.hasOwnProperty(blkParts.col)) {
                let meta;
                const currentColMeta = {
                    col: blkParts.col,
                    idx: colIdx,
                    blk: blkParts.blk,
                    blkName: blkParts.blkname,
                    spec: BLKSPEC.columns[blkParts.col]
                }
                if (blockNames.has(blkParts.blkname)) {
                    meta = blockNames.get(blkParts.blkname);
                    meta.columns.push(currentColMeta)
                } else {
                    let indexCol = mainColumns.indexOf(BLKSPEC.index)
                    meta = {
                        blk: block_definitions[blkParts.blk],
                        name: BLKSPEC.name,
                        columns: [
                            {
                                col: BLKSPEC.index,
                                idx: indexCol,
                                blkName: blkParts.blkname,
                                blk: blkParts.blk,
                                spec: BLKSPEC.columns[BLKSPEC.index]
                            },
                            currentColMeta]
                    }
                }
                blockNames.set(blkParts.blkname, meta)
            }
        }
    }
    blockNames = Array.from(blockNames)
    let allBlocks = [];
    for (let [blkName, blkMeta] of blockNames) {
        const indexColumnName = blkMeta.blk.index;
        let cblk = {
            columns: {},
            data: [],
            idx: indexColumnName,
            name: blkName,
            blockname: blkMeta.name,
            idxMap: new Map()
        };
        for (let i = 0; i < blkMeta.columns.length; i++) {
            let m = blkMeta.columns[i];
            cblk.columns[m.col] = { idx: i, spec: m.spec }
        }
        for (let rowIdx = 0; rowIdx < mainRows.length; rowIdx++) {
            let row = mainRows[rowIdx]
            cblk.data.push(blkMeta.columns.map(m => {
                let val = COL_PARSERS[m.spec.type](row[m.idx]);
                if (m.col === indexColumnName) {
                    cblk.idxMap.set(val, rowIdx)
                }
                return val
            }
            ))
        }
        allBlocks.push([cblk.name, cblk]);
    }
    return allBlocks;
}
export function splitDfV4(rdf, block_definitions,as_object=false) {
    /*
    this transforms new json style blocks returned from mdslv4
    to old df style block storage to decrease the memory used
    and to not need to rewrite all components for new api.

    this also parses to correct datatype format using predefined column definitions.

    if column doesn't exists in block, value will be undefined.
    if column exists and value doesn't -- it will be null.

     */
    block_definitions = block_definitions || BLOCK;

    let blockCache = new Map();
    let blockNameCache = new Map();

    for (let cblock of rdf) {
        if (!blockNameCache.has(cblock.block_name)) {
            for (let [, BLKSPEC] of Object.entries(block_definitions)) {
                let blkParts = BLKSPEC.parse_block_name(cblock.block_name)
                if (blkParts !== null) {
                    blockNameCache.set(cblock.block_name, { spec: BLKSPEC, ...blkParts })
                    break
                }
            }
        }
        let cblock_def = blockNameCache.get(cblock.block_name);
        if(typeof cblock_def === "undefined"){
            throw Error(`block not recognised ${cblock.block_name}`)
        }

        if (!blockCache.has(cblock.block_name)) {
            let idx = 0;
            let blkDef = {
                columns: {uid:{
                    col:'uid',
                        idx:idx,
                        blk:cblock_def.blk,
                        blkName:cblock_def.blkname,
                        spec:UID_TYPE
                    }},
                data: [],
                idx: 'uid',
                blk: cblock_def.blk,
                blkName: cblock_def.blkname,
                idxMap: new Map()
            };
            for(let [cln,clSpec] of Object.entries(cblock_def.spec.columns)){
                idx += 1;
                blkDef.columns[cln] = {
                    col: cln,
                    idx: idx,
                    blk: cblock_def.blk,
                    blkName: cblock_def.blkname,
                    spec: clSpec
                }
            }
            blockCache.set(cblock.block_name, blkDef)
        }
        let blk = blockCache.get(cblock.block_name);
        let blkColumns = Object.keys(blk.columns)
        if(typeof blk === 'undefined'){
            throw Error(`block definition not generated ${cblock.block_name}`)
        }
        if(!(cblock.block_data === null || cblock.block_data === undefined)){
            let row = [];
            row.push(cblock.uid)
            blk.idxMap.set(cblock.uid, blk.data.length);
            let cuid =null,ccol = null;
            try{
                Object.values(blk.columns).map(m => {
                    cuid = cblock.uid
                    ccol = m.col
                    if (m.col !== blk.idx) {
                        const value = m.spec.type === COLTYPE.virtual ?
                            m.spec.valueFunction(row, blkColumns, cblock.block_data[m.col]) :
                            cblock.block_data[m.col];
                        row.push(COL_PARSERS[m.spec.valueType || m.spec.type](value));
                    }
                    return true
                })
            }catch(err){
                console.log(blk,cuid,ccol,err,cblock.block_data)
            }
            blk.data.push(row)
        }

    }
    if(as_object){
        return Object.fromEntries(blockCache)
    }else{
        return Array.from(blockCache);
    }
}

export function calculate_subs(tree, subs, blocks) {
    tree.node.subs = subs;
    if (tree.node.split === SPLIT_TYPE.categorical && tree.node.source) {
        let sourceMeta = SPLIT_CATEGORY[tree.node.source]
        let blockName = BLOCK[sourceMeta.block].to_block_name({})
        if (have_blocks(blocks, [blockName])) {
            const spReader = blkReader(blocks, [
                ['name',[blockName,sourceMeta.column], 'value'],
            ])
            let childBins = {}
            let childIds = Object.keys(tree.children);
            for (let childNodeId of childIds) {
                childBins[childNodeId] = new Set()
            }
            let is_missing_bin = null;
            let childValues = {}
            for (let childNodeId of childIds) {
                if (tree.children[childNodeId].node.condition.include_missing) {
                    is_missing_bin = childNodeId;
                }
                childValues[childNodeId] = new Set(tree.children[childNodeId].node.condition.data.values)
            }
            for (let sub of subs) {
                let data = spReader(sub)
                if (data && data.value) {
                    for (let childId of childIds) {
                        if (childValues[childId].has(data.value)) {
                            childBins[childId].add(sub)
                        }
                    }
                } else {
                    childBins[is_missing_bin].add(sub)
                }
            }
            for (let childId of childIds) {
                calculate_subs(tree.children[childId], childBins[childId], blocks)
            }
        } else {

        }
    }
}



export function xrange(start,end,delta){
    let currentIdx = start;
    return {
        next: function(){
            let temp = currentIdx
            currentIdx += delta;
            return {value:temp,done:currentIdx>end}
        },
        [Symbol.iterator]:function(){
            return this
        }
    }
}


export function any(iterator,condition,reader){
    for(let idx of iterator){
        if( (reader && condition(reader(idx))) || condition(idx) ){
            return [true,idx]
        }
    }
    return [false,null];
}

export function anygroup(iterator,conditions,reader){
    let requiredCount = conditions.length;
    let resolvedCount = 0
    let resolved = default_array(requiredCount, false)
    let resolutions = Array(requiredCount)
    for(let idx of iterator){
        if(resolvedCount === requiredCount){
            break
        }
        for(let cidx=0;cidx<requiredCount;cidx++){
            const condition = conditions[cidx]
            if(!resolved[cidx] && ( (reader && condition(reader(idx))) || condition(idx)) ){
                    resolutions[cidx] = idx;
                    resolved[cidx] = true;
                    resolvedCount ++

            }
        }
    }
    return [resolutions,resolved]
}

export function default_array(size,fill){
    const arr = Array(size)
    for(let i=0;i<size;i++){
        arr[i] = fill
    }
    return arr
}
