import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { AppBar, Box, IconButton, Tooltip } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import useStores from "../../../../hooks/useStores";

/**
 * - This component is used to hold any page specific controls.
 * - It also houses the link to the wiki page for the page.
 */
function SubHeader(props) {
  const { children, endComponent, wikiUrl } = props;
  const { routerStore, networks } = useStores().rootStore;

  const getWikiUrl = () => {
    // Check if the wikiUrl has been provided.
    if (wikiUrl) {
      return wikiUrl;
    }
    // Otherwise, use the routerStore to get the wikiUrl.
    const { routerState } = routerStore;
    const stateWikiUrl = routerState.options.wikiUrl;
    if (stateWikiUrl) {
      // We do this instead of using the routerState.queryParams since the section selector doesnt
      // do a navigation, so the queryParams are not updated.
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.has("tab")) {
        const tab = searchParams.get("tab");
        return `${stateWikiUrl.base}/${stateWikiUrl.tabs[tab] || ""}`;
      }
      return stateWikiUrl.base;
    }
    return null;
  };

  const openWikiPage = () => {
    const wikiLink = getWikiUrl();
    window.open(wikiLink, "_blank");
  };

  return (
    <AppBar position="sticky">
      <Box
        minHeight={56}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        {/* Left Side Components */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          height="100%"
          flexGrow={1}
          pl={3}
          pr={1}
          py={1}
          gap={1}
          style={{ flexFlow: "wrap" }}
        >
          {networks.ready && children}
        </Box>
        {/* Right Side Components */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          height="100%"
        >
          {endComponent && (
            <Box
              borderLeft={2}
              borderColor="primary.dark"
              height="inherit"
              display="flex"
              flexDirection="row"
              justifyContent="start"
              alignItems="center"
              px={1}
              py={1}
            >
              {endComponent}
            </Box>
          )}
          <Box borderLeft={2} borderColor="primary.dark" height="inherit">
            {!!getWikiUrl() && (
              <IconButton
                onClick={openWikiPage}
                color="inherit"
                style={{ height: "100%" }}
                size="large"
              >
                <Tooltip title="Information">
                  <InfoOutlined />
                </Tooltip>
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
}

SubHeader.propTypes = {
  children: PropTypes.node,
  endComponent: PropTypes.node,
  wikiUrl: PropTypes.string,
};

SubHeader.defaultProps = {
  children: null,
  endComponent: null,
  wikiUrl: null,
};

export default observer(SubHeader);
