import { makeStyles } from "@mui/styles";
import { darken } from "@mui/system";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    minHeight: theme.spacing(31),
  },
  root_cover: {
    flexGrow: 1,
    height: "100%",
  },
  root__haveData: {
    backgroundColor: theme.palette.common.white,
  },
  root__padding: {
    padding: theme.spacing(2),
  },
  "@keyframes pulseEffect": {
    "0%": {
      backgroundColor: theme.palette.common.white,
    },
    "50%": {
      backgroundColor: darken(theme.palette.common.white, 0.05),
    },
    "100%": {
      backgroundColor: theme.palette.common.white,
    },
  },
  root__notHaveData: {
    animationName: "$pulseEffect",
    animationDuration: "3s",
    animationIterationCount: "infinite",
  },
  nodata: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.error.main,
  },
}));

function InfoCard({
  haveData = false,
  haveError = false,
  errmsg,
  cover = true,
  padding = true,
  children,
}) {
  const styles = useStyles();

  return (
    <div
      className={clsx(
        styles.root,
        haveData && styles.root__haveData,
        !haveData && !haveError && styles.root__notHaveData,
        padding && styles.root__padding,
        cover && styles.root_cover
      )}
    >
      {haveData && children}
      {haveError && <div className={styles.nodata}>{errmsg || "No data available"}</div>}
    </div>
  );
}

export default InfoCard;
