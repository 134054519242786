import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {observer} from "mobx-react";
import Typography from "@mui/material/Typography";

const styles = theme => ({
    section: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent:"flex-start"
    },
})


class CardBottomText extends Component {
    render(){
        const { classes,startIcon,suffix,value,prefix,color='secondary'} = this.props;
        return (
            <div className={classes.section}>
                {startIcon}
                <Typography component="span" variant="h3" color={color} style={{marginLeft:8}}>
                    {prefix} {value} {suffix}
                </Typography>
            </div>
        );
    }
}

export default withStyles(styles)(observer(CardBottomText));