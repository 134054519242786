import { useMemo } from "react";

import useStores from "../../../hooks/useStores";

import SubstationConsumption from "./SubstationConsumption";
import { ConsumptionStore, StateContext } from "./state";

function ConsumptionOverview() {
  const rootStore = useStores();
  const state = useMemo(() => new ConsumptionStore(rootStore), [rootStore]);
  return (
    <StateContext.Provider value={state}>
      <SubstationConsumption />
    </StateContext.Provider>
  );
}

export default ConsumptionOverview;
