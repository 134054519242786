import HandleExport from "../pages/Substations/HandleExport";

export default function exportGraph(rows, xMin, xMax, exportAs, chartName) {
  const rowsInExtremes = rows.filter(
    (row) => typeof row.x !== "number" || (row.x >= xMin && row.x <= xMax)
  );
  // Column headers are always the first row
  const headerLabels = rowsInExtremes.shift();
  const labeledRows = rowsInExtremes.map((row) => {
    const newRow = {};
    row.forEach((val, i) => {
      newRow[headerLabels[i]] = val;
    });
    return newRow;
  });

  if (exportAs === "CSV") {
    HandleExport.asCSVFile(labeledRows, chartName, "CSV_WITH_DOT_SEPARATOR", headerLabels);
  } else {
    HandleExport.asXLSXFile(labeledRows, chartName, headerLabels);
  }
}
