import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(1, 0, 4, 0),
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
  },
  title: {
    padding: theme.spacing(2, 3),
    border: "none",
    ...theme.typography.h6,
  },
  header: {
    height: theme.spacing(6),
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.grey.white,
    marginBottom: "2px",
    borderRadius: "2px 2px 0px 0px",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    gap: theme.spacing(2),
    borderLeft: `2px solid ${theme.palette.grey.blue2}`,
    height: "100%",
  },
  noData: {
    ...theme.typography.h4,
    height: "400px",
    textAlign: "center",
    paddingTop: theme.spacing(4),
    color: theme.palette.error.main,
  },
}));

function GraphContainer({ title, subTitles, children, hasData, ...props }) {
  const classes = useStyles();
  const testId = props["data-testid"];
  return (
    <div className={classes.container} data-testid={testId}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        <div className={clsx({ [classes.subTitle]: subTitles !== null })}>{subTitles}</div>
      </div>
      {hasData ? <div>{children}</div> : <div className={classes.noData}>No data available</div>}
    </div>
  );
}

GraphContainer.defaultProps = {
  subTitles: null,
  hasData: true,
  "data-testid": "graph-container",
};

GraphContainer.propTypes = {
  title: PropTypes.string.isRequired,
  subTitles: PropTypes.element,
  children: PropTypes.element.isRequired,
  hasData: PropTypes.bool,
  "data-testid": PropTypes.string,
};

export default GraphContainer;
