// Copyright (C) 2019 Utilifeed AB. All Rights Reserved.

import SvgIcon from "@mui/material/SvgIcon";

export default function GlobeIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="d9v51etnka">
            <path
              data-name="Rectangle 7133"
              transform="translate(1168 102.746)"
              style={{ stroke: "#707070" }}
              d="M0 0h24v24H0z"
            />
          </clipPath>
        </defs>
        <g>
          <path
            d="M9.372 0A9.372 9.372 0 1 1 0 9.372 9.371 9.371 0 0 1 9.372 0zm0 17.572c.611 0 1.479-.527 2.266-2.1a10.231 10.231 0 0 0 .849-2.588H6.223a11.715 11.715 0 0 0 .882 2.588c.787 1.571 1.655 2.1 2.266 2.1zm-3.357-5.857h6.714a17.122 17.122 0 0 0 .157-2.343 17.122 17.122 0 0 0-.157-2.343H6.015a17.515 17.515 0 0 0 0 4.686zm6.472-5.857a9.818 9.818 0 0 0-.849-2.589c-.787-1.571-1.655-2.1-2.266-2.1s-1.479.526-2.266 2.1a11.162 11.162 0 0 0-.882 2.589zm1.391 1.171a13.941 13.941 0 0 1 .179 2.343 13.941 13.941 0 0 1-.179 2.343h3.353a8.232 8.232 0 0 0 0-4.686zM11.989 1.6a10.854 10.854 0 0 1 1.728 4.259h3.064A8.213 8.213 0 0 0 11.989 1.6zm-5.235 0a8.224 8.224 0 0 0-4.793 4.257h3.065A10.854 10.854 0 0 1 6.754 1.6zM1.171 9.372a8.207 8.207 0 0 0 .34 2.343h3.321a18.817 18.817 0 0 1 0-4.686H1.511a8.207 8.207 0 0 0-.34 2.343zm15.61 3.514h-3.064a10.84 10.84 0 0 1-1.728 4.258 8.2 8.2 0 0 0 4.792-4.258zm-11.755 0H1.961a8.215 8.215 0 0 0 4.794 4.258 10.84 10.84 0 0 1-1.728-4.258z"
            transform="translate(3 2.256)"
            data-name="Mask Group 446"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
