const MDSL_URL = process.env.REACT_APP_MDSL_URL;
const MDSL_V4_API = process.env.REACT_APP_MDSL_V4_URL;
const WEATHER_URL = process.env.REACT_APP_WEATHER_URL;
const EP_URL = process.env.REACT_APP_EP_URL;
const CMS_URL = process.env.REACT_APP_CMS_URL;
const PRICING_URL = process.env.REACT_APP_PRICING_URL;
const OPT_URL = process.env.REACT_APP_OPT_URL;

export default {
  mdsl: MDSL_URL,
  mdslv4: MDSL_V4_API,
  weather: WEATHER_URL,
  ep: EP_URL,
  cms: CMS_URL,
  pricing: PRICING_URL,
  baseOptimization: OPT_URL,
  optimization: `${OPT_URL}/optimization`,
  scenarioAnalysis: `${OPT_URL}/scenario-analysis`,
};
