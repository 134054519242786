import { COLUMN_SECTION } from "./constants";

const CUSTOMER_COLUMNS = {
  name: {
    label: "text_customer_name",
    info: "text_customer_name_desc",
    section: COLUMN_SECTION.customer,
  },
};

const INSTALL_ADDRESS_COLUMNS = {
  street: {
    label: "text_install_address",
    info: "text_install_address_desc",
    section: COLUMN_SECTION.location,
  },
  coordinates: {
    label: "text_coordinates",
    info: "text_coordinates_desc",
    section: COLUMN_SECTION.location,
    sortType: (rowA, rowB, columnId) => {
      let a = rowA.values[columnId];
      let b = rowB.values[columnId];

      if (!Array.isArray(a)) a = [0, 0];
      if (!Array.isArray(b)) b = [0, 0];

      // Force to strings (or "" for unsupported types)
      a = a.toString(a).replace("NaN", 0);
      b = b.toString(b).replace("NaN", 0);

      if (a === b) return 0;
      return a > b ? 1 : -1;
    },
  },
  zip: {
    label: "text_zip_code",
    info: "text_zip_code_desc",
    section: COLUMN_SECTION.location,
  },
};

const DISTRIBUTION_COLUMNS = {
  grid_area: {
    label: "text_grid_rea",
    info: "text_grid_rea_desc",
    section: COLUMN_SECTION.location,
  },
};

export { CUSTOMER_COLUMNS, INSTALL_ADDRESS_COLUMNS, DISTRIBUTION_COLUMNS };
