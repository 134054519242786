import { Component } from "react";
import { observer, inject } from "mobx-react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  button: {
    ...theme.typography.body1,
    display: "flex",
    justifyContent: "space-between",
    width: "inherit",
    border: `solid 1px ${theme.palette.primary.main}`,
    color: theme.palette.grey.blue72,
  },
});

class NetworkSelect extends Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  handleSelect(value) {
    const { onChange } = this.props;
    this.setState({ anchorEl: null });
    onChange(value);
  }

  render() {
    const {
      rootStore: { networks },
      width,
      classes,
      currentNetwork,
    } = this.props;
    const { anchorEl } = this.state;
    const EndIcon = anchorEl ? <ArrowDropUp /> : <ArrowDropDownIcon />;

    return (
      <>
        <Button
          style={{ width: `${width - 10}px` }}
          className={classes.button}
          endIcon={EndIcon}
          variant="outlined"
          onClick={this.handleClick}
          data-testid="network-select"
        >
          {currentNetwork.name}
        </Button>

        <Menu
          id="network-select-topbar"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {networks.network_names &&
            networks.network_names.map((network) => (
              <MenuItem
                style={{ width: `${width - 10}px` }}
                key={network.uid}
                selected={network.uid === currentNetwork.uid}
                onClick={() => this.handleSelect(network)}
                data-testid={`network-option-${network?.name}`}
              >
                {network.name}
              </MenuItem>
            ))}
        </Menu>
      </>
    );
  }
}

export default inject("rootStore", "networks", "ui")(withStyles(styles)(observer(NetworkSelect)));