/* Copyright (C) 2019 Utilifeed AB. All Rights Reserved. */

import { createRef, Component } from 'react';
import { computed, reaction, makeObservable, runInAction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';

import HighCharts from 'highcharts/highstock'
import withTheme from '@mui/styles/withTheme';
import highchartsMore from 'highcharts/highcharts-more';
import Histogram from 'highcharts/modules/histogram-bellcurve';
import { formatNumberForLocale } from '../../core/utils';
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

const hcExport = require('highcharts/modules/exporting');
const hcExportData = require('highcharts/modules/export-data');
const boost = require('highcharts/modules/boost');
window.Highcharts = HighCharts;

class GraphMetrics extends Component {

    constructor(props) {
        super(props)

        makeObservable(this, {
            yAxis: computed,
            chartOptions: computed
        });

        this.chartContainer = createRef();
        this.reflowTimer = createRef();
        this.chart = null;
    }

    componentDidMount() {
        const { callback, constructorType = "chart", ui } = this.props;
        hcExport(HighCharts);
        hcExportData(HighCharts);
        Histogram(HighCharts);
        highchartsMore(HighCharts);
        boost(HighCharts);
        this.setupXLSX(HighCharts);
        runInAction(() => {
            this.chart = new HighCharts[constructorType](
                this.chartContainer.current,
                this.chartOptions,
                callback
            );
            disposeOnUnmount(this, reaction(
                () => this.chartOptions,
                () => {
                    this.chart.update(this.chartOptions, false, true, false);
                    this.chart.redraw();
                }
            ));
            disposeOnUnmount(
              this,
              reaction(
                () => ui.is_sidepagemodels_open,
                () => {
                  this.reflowTimer.current = setTimeout(() => this.chart.reflow())
                }
              )
            )
        });
    }

    setupXLSX(HighCharts) {
        // Set the download function handler
        HighCharts.Chart.prototype.downloadXLSX = function () {
            var filename = 'chart',
                headerLabels = [],
                rows;
            // Get the data
            rows = this.getDataRows() || [];
            // Column headers are always the first row
            headerLabels = rows.shift();
            // Format the rows
            rows = rows.map((row) => {
                const newRow = {};
                // Here we want to return the row object with the title as the key
                row.forEach((val, i) => {
                    newRow[headerLabels[i]] = val;
                });
                return newRow;
            });
            // Get the filename, copied from the Chart.fileDownload function
            if (this.options.exporting.filename) {
                filename = this.options.exporting.filename;
            } else if (this.title && this.title.textStr) {
                filename = this.title.textStr.replace(/ /g, '-').toLowerCase();
            }
            const ws = XLSX.utils.json_to_sheet(rows, { header: headerLabels });
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
            const _data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" })
            FileSaver.saveAs(_data, filename + ".xlsx")
        };

        // Default lang string, overridable in i18n options
        HighCharts.getOptions().lang.downloadXLSX = 'Download XLSX';

        // Add the menu item handler
        HighCharts.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
            textKey: 'downloadXLSX',
            onclick: function () {
                this.downloadXLSX();
            }
        };

        // Replace the menu item
        var menuItems = HighCharts.getOptions().exporting.buttons.contextButton.menuItems;
        menuItems[menuItems.indexOf('downloadXLS')] = 'downloadXLSX';
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.destroy();
        }
        if (this.reflowTimer.current) {
            clearTimeout(this.reflowTimer.current)
            this.reflowTimer.current = undefined
        }
    }

    get yAxis() {
        const { chartData = [], yAxis = [], theme, yAxisTitle, yAxisAppend, yAxisOptions, scaleYAxis = false } = this.props;

        const yAxisArr = yAxis.length > 0 ? yAxis : [{
            title: {
                text: yAxisTitle || '',
                style: {
                    fontSize: theme.typography.caption.fontSize,
                    fontFamily: theme.typography.caption.fontFamily,
                    fontWeight: theme.typography.subtitle2.fontWeight,
                }
            },
            labels: {
                formatter: function () {
                    return yAxisAppend ? formatNumberForLocale(this.value) + yAxisAppend : formatNumberForLocale(this.value);
                }
            },
            ...yAxisOptions
        }];
        // If scaleYAxis is true and there is a column series type, dynamically scale the axis.
        if (scaleYAxis) {
            for (let i = 0; i < yAxisArr.length; i++) {
                let chart_data = chartData.filter(cd => (cd?.yAxis || 0) === i);
                let minv = null;
                if (chart_data.filter(cd => cd.type === 'column').length > 0) {
                    chart_data.forEach(rowdata => {
                        (rowdata?.data || []).forEach(val => {
                            if (minv === null || (val !== null && minv > val)) {
                                minv = val;
                            }
                        })
                    });
                    yAxisArr[i].min = minv ? minv * .9 : 0;
                    yAxisArr[i].startOnTick = true;
                }
            }
        }
        return yAxisArr;

    }

    get chartOptions() {
        const {
            chartData = [],
            title,
            titleAlign,
            titleOptions = {},
            type,
            xAxisTitle,
            step,
            xAxisAppend,
            isLog = false,
            widthBar,
            average,
            isAverage,
            xAxisType,
            legend,
            xAxis,
            theme,
            yAxisOptions,
            hoverShow = false,
            boost = true,
            xAxisOptions,
            plotlines = [],
            plotOptions = {},
            seriesOptions = {},
            chartOptions = {},
            constructorType,
            classes,
            focusable,
            exporting = { enabled: true },
            ui, // do not include "ui" store to extraOptions
            ...extraOptions
        } = this.props;
        const chartSeries = chartData;
        chartData?.forEach((cData, idx) => {
          const data = [];
          cData.data?.forEach((d) => data.push(d === undefined ? null : d));
          chartSeries[idx].data = data;
        });
        return {
            chart: {
                type: type || '',
                zoomType: 'x',
                style: {
                    overflow: 'visible'
                },
                boost: {
                    enabled: boost,
                    seriesThreshold: boost ? 1 : 0,
                },
                ...chartOptions
            },
            credits: {
                enabled: false
            },
            title: {
                text: title || '',
                align: titleAlign || 'center',
                style: {
                    fontSize: theme.typography.h6.fontSize,
                    fontFamily: theme.typography.h6.fontFamily,
                    fontWeight: theme.typography.h6.fontWeight,
                },
                ...titleOptions
            },
            subtitle: {
                text: isAverage ? `Average : ${average}` : '',
                x: 120,
                y: 12,
                style: {
                    color: theme.palette.primary.main,
                    fontFamily: theme.typography.caption.fontFamily,
                    fontSize: theme.typography.caption.fontSize,
                    position: 'absolute',
                    zIndex: 2,
                    right: '125px',
                    top: 8,
                }
            },
            yAxis: this.yAxis,
            xAxis: xAxis ? xAxis : {
                type: xAxisType || 'linear',
                title: {
                    text: xAxisTitle || '',
                    style: {
                        fontSize: theme.typography.caption.fontSize,
                        fontFamily: theme.typography.caption.fontFamily,
                        fontWeight: theme.typography.subtitle2.fontWeight,
                    }
                },
                labels: {
                    formatter: function () {
                        let xValue = this.value;
                        if (isLog) {
                            xValue = Math.pow(Math.E, this.value);
                        }
                        if(xAxisType === 'datetime') {
                            return HighCharts.dateFormat('%d %b - %H:%M', this.value);
                            // return this.value;
                        }
                        xValue = formatNumberForLocale(xValue);
                        return xAxisAppend ? xValue + xAxisAppend : xValue
                    }
                },
                plotLines: plotlines,
                ...xAxisOptions
            },
            legend: legend ? legend : {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
            },
            navigation: {
                menuStyle: {
                    zIndex: 6,
                    overflow: "visible",
                },
            },
            plotOptions: {
                series: {
                    stickyTracking: false,
                    events: {},
                    label: {
                        connectorAllowed: false
                    },
                    color: theme.palette.primary.light,
                    animation: false,
                    step: (step && 'right') || '',
                    turboThreshold: boost ? 1 : 0,
                    boostBlending: true,
                    pointWidth: widthBar ? widthBar : undefined,
                    cursor: 'pointer',
                    states: hoverShow ? {
                        hover: {
                            halo: {
                                attributes: {
                                    'stroke-width': 6,
                                    stroke: theme.palette.primary.light,
                                },
                                size: 7
                            },
                        }
                    } : {},
                    marker: {
                        states: {
                            select: {
                                radius: 7,
                                lineWidth: 4,
                                lineColor: theme.palette.secondary.dark,
                                fillColor: theme.palette.secondary.main,
                            }
                        }
                    },
                    ...seriesOptions
                },
                line: {
                    boostThreshold: boost ? 1 : 0,
                    turboThreshold: boost ? 1 : 0,
                },
                histogram: {
                    turboThreshold: boost ? 1 : 0,
                },
                area: {
                    fillOpacity: 0.5
                },
                ...plotOptions,
            },
            exporting,
            series: chartSeries,
            ...extraOptions
        };
    }

    render() {
        const { style = {} } = this.props;
        return (
            <div
                style={{ width: '100%', ...style }}
                ref={this.chartContainer}
            />
        )
    }
}

export default inject("ui")(withTheme(observer(GraphMetrics)))
