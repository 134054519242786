import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";

import PageWrapper from "../components/app/PageWrapper";

function FeatureAccessDeniedPage() {
  return (
    <PageWrapper>
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
        data-testid="feature-access-denied-page"
      >
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          maxWidth={1 / 3}
        >
          <Typography variant="h1" color="secondary" align="center" mb={2}>
            Whoops!
          </Typography>
          <Typography variant="subtitle2" color="secondary" align="center">
            You are looking at one of our premiums features. If you have access to it, we hope you
            love it.
          </Typography>
          <Typography variant="subtitle2" color="secondary" align="center">
            If you don&#39;t, get in touch with your Customer Success Manager to book a demo -
            <Link href="mailto:sales@utilifeed.com"> click here!</Link>
          </Typography>
        </Box>
      </Box>
    </PageWrapper>
  );
}

export default FeatureAccessDeniedPage;
