import { useMemo } from "react";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import GoogleMapReact from "google-map-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

import Text from "../app/Text";
import Marker from "../analytics/Marker";
import CONF, { is_feature_flag_on } from "../../conf/conf";
import StarIcon from "../../icons/Star";
import StarfullIcon from "../../icons/Starfull";
import { valid_coords as validCoords } from "../../core/utils";
import SubstationIcon from "../../icons/Substation";
import ClusterIcon from "../../icons/Cluster";
import { CLUSTER, NETWORK_SUBSTATION, SUBSTATION } from "../SubList/constant";
import useStores from "../../hooks/useStores";
import useSummary from "../SubList/Summary/useSummary";
import { SectionSelector, SubHeader } from "../../shared_components/ui/layout";
import useSubExplore from "../SubList/Explore/useSubExplore";
import { blkReader } from "../../conf/blocks";
import features from "../../conf/feature_access";
import useMobxSelector from "../../hooks/useMobxSelector";
import { getUrlParam, removeUrlParam } from "../../conf/routes";

import SubstationOverview from "./SubstationOverview";
import SubstationConsumption from "./ConsumptionOverview";
import FaultDetectionOverview from "./FaultDetectionOverview/FaultDetectionOverview.tab";

function SubPane() {
  const { networks, sub, ui, preferences } = useStores();
  const { t } = useTranslation();
  const isSubSummaryOpen = ui.is_subsummary_open;
  const isClusterSummaryOpen = ui.is_cluster_summary_open;
  const summary = useSummary({ type: isSubSummaryOpen ? SUBSTATION : CLUSTER });
  const explore = useSubExplore({ type: NETWORK_SUBSTATION });

  const substation = t("text_substation");
  const cluster = t("text_cluster");
  const SECTION_TAB_LABELS = useMemo(() => {
    const tabs = [
      {
        title: `${isSubSummaryOpen ? substation : cluster} ${t("text_over_view")}`,
        id: "overview",
        permissions: [isSubSummaryOpen ? features.substation_overview : features.cluster_overview],
        component: SubstationOverview,
      },
      {
        title: `${isSubSummaryOpen ? substation : cluster} ${t("text_consumption")}`,
        id: "consumption",
        permissions: [
          isSubSummaryOpen ? features.substation_consumption : features.cluster_consumption,
        ],
        component: SubstationConsumption,
      },
    ];
    // Only for substations
    if (isSubSummaryOpen && is_feature_flag_on("FAULT_DETECTION")) {
      tabs.push({
        title: t("text_fault_detection"),
        id: "fault_detection",
        permissions: [features.substation_fault_detection],
        component: FaultDetectionOverview,
      });
    }
    return tabs;
  }, [isSubSummaryOpen]);

  const reader = blkReader(explore.substations, explore.colSpecs);
  const { customer, install_address: installAddress, dataFetched } = summary.data;

  const isFavorite = useMobxSelector(() => {
    const networkUid = networks.current_network.uid;
    const currentNetworkList = preferences.fav_subs.get(networkUid);
    if (currentNetworkList) {
      return currentNetworkList.has(sub.current_substation);
    }
    return false;
  });

  function handleClicked() {
    runInAction(() =>
      preferences.toggleFavSub(networks.current_network.uid, sub.current_substation)
    );
  }

  const colorIcon = isFavorite ? "secondary" : "primary";
  const Icon = isFavorite ? <StarfullIcon /> : <StarIcon />;

  let mapZoom = 15;
  let mapCenter;
  let mapMarkers = null;
  if (Array.isArray(installAddress?.coordinates)) {
    mapCenter = {
      lat: installAddress.coordinates[0],
      lng: installAddress.coordinates[1],
    };
    mapMarkers = <Marker lat={mapCenter.lat} lng={mapCenter.lng} />;
  }

  if (isClusterSummaryOpen) {
    mapZoom = 8;

    const firstClusterSubstation = explore.filteredList[0];
    if (validCoords(reader(firstClusterSubstation), "coordinates")) {
      mapCenter = {
        lat: reader(firstClusterSubstation).coordinates[0],
        lng: reader(firstClusterSubstation).coordinates[1],
      };
    }

    mapMarkers = explore.substationsUid?.map((substation) => {
      const substationReader = reader(substation.uid);
      if (!validCoords(substationReader, "coordinates")) return null;
      return (
        <Marker
          key={`map-marker-${substation.uid}`}
          lat={substationReader.coordinates[0]}
          lng={substationReader.coordinates[1]}
        />
      );
    });
  }

  let totalSubstationInCluster = "...";
  if (explore.dataFetched) {
    const substationsLength = explore?.substationsUid?.length;
    totalSubstationInCluster = substationsLength === 0 ? "..." : substationsLength;
  }

  const MapComponent = dataFetched ? (
    <Skeleton variant="rect" height={155} />
  ) : (
    <GoogleMapReact
      defaultZoom={mapZoom}
      center={mapCenter}
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{ key: CONF.gmaps_key }}
      options={{
        scrollwheel: false,
      }}
    >
      {mapMarkers}
    </GoogleMapReact>
  );

  if (dataFetched) return <LinearProgress />;

  const onMinimizeButtonClick = () => {
    ui.closeDetailPanel();
    if (getUrlParam("investigate")) {
      removeUrlParam("investigate");
    }
    removeUrlParam("explore_tab");
  };

  return (
    <>
      {/* Panel Header */}
      <SubHeader
        wikiUrl="https://wiki.energypredict.com/global_features/substation_explore/substation_overview"
        endComponent={
          <Button
            startIcon={<ChevronLeftIcon />}
            onClick={onMinimizeButtonClick}
            color="primary"
            variant="contained"
            disableElevation
          >
            {t("action_minimize", { ns: "_action" })}
          </Button>
        }
      >
        {isSubSummaryOpen && <SubstationIcon />}
        {ui.is_cluster_summary_open && <ClusterIcon />}
        <Text variant="h6" color={["grey", "white"]} ml={2} data-testid="extend-panel-title">
          {isSubSummaryOpen ? summary.data.id : sub.current_cluster?.name}
        </Text>
      </SubHeader>

      {/* Panel Sub-Header */}
      <Box
        display="flex"
        flexDirection="row"
        pl={3}
        justifyContent="space-between"
        boxShadow={1}
        bgcolor="white"
        zIndex={1}
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          bgcolor="paper"
          alignItems="center"
          minHeight="56px"
          data-testid="extend-header-info"
        >
          {isSubSummaryOpen && (
            <>
              <Text variant="h5" data-maskdata color={["grey", "blue72"]} ml={2}>
                {customer?.name}
              </Text>
              <Text variant="h6" data-maskdata color={["grey", "blue72"]} ml={2}>
                {installAddress?.street}
              </Text>
            </>
          )}
        </Box>

        {isSubSummaryOpen ? (
          <Box mr={3}>
            {/* Favorite Icon Button */}
            <IconButton color={colorIcon} onClick={() => handleClicked()}>
              {Icon}
            </IconButton>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            minWidth="80px"
            justifyContent="space-around"
            borderLeft={1}
            height="100%"
            px={1.5}
            borderColor="grey.blue4"
            data-testid="extend-panel-total-count"
          >
            {/* Total Substation Count in Cluster */}
            {totalSubstationInCluster}
            <SubstationIcon />
          </Box>
        )}
      </Box>

      {/* Google Maps */}
      <Box minHeight="256px" data-maskdata>
        {MapComponent}
      </Box>
      <SectionSelector sections={SECTION_TAB_LABELS} saveToRoute routeSelector="explore_tab" />
    </>
  );
}

export default observer(SubPane);
