import { observable, action, makeObservable } from "mobx";

class LanguageStore {
  languageOptions = new Map([["ENG", "English"]]);

  isLanguageChanging = false;
  currentLanguage = null;

  constructor(parent) {
    makeObservable(this, {
      isLanguageChanging: observable,
      currentLanguage: observable,

      updateCurrentLanguage: action.bound,
    });

    this.parent = parent;
    this.isLanguageChanging = false;
    this.currentLanguage = [...this.languageOptions][0][0]; // TEMP
  }

  updateCurrentLanguage(newLanguage) {
    this.currentLanguage = newLanguage;
  }
}

export default LanguageStore;
