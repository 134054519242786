import React from "react";
import { Flag } from "@mui/icons-material";

import { ENERGY_TYPE, FLOW_TYPE, CORE_ROLLING_YEARLY_BLOCK } from "../../../conf/blocks";

export default [
  {
    id: "street",
    accessor: "install_address.street_name",
    width: 180,
    blockName: "install_address",
    path: "install_address.street_name",
    Header: "text_install_address",
    sortType: "alphanumeric",
    type: "string",
    info: "text_install_address_desc",
    translateNs: "_common",
    spec: {
      type: "str",
      physical: "name",
      unit: null,
    },
    isVisible: true,
  },
  {
    id: "name",
    accessor: "customer.name",
    width: 180,
    blockName: "customer",
    path: "customer.name",
    Header: "text_customer_name",
    sortType: "alphanumeric",
    type: "string",
    info: "text_customer_name_desc",
    translateNs: "_common",
    hideable: true,
    spec: {
      type: "str",
      physical: "name",
      unit: null,
    },
    isVisible: true,
  },
  {
    id: "fault_detection.fault_level",
    accessor: "fault_detection.fault_level",
    width: 180,
    Header: "text_fault_detection",
    sortType: "alphanumeric",
    type: "icon",
    info: "text_fault_detection_desc",
    translateNs: "_common",
    hideable: true,
    isVisible: true,
    align: "right",
    iconMapping: {
      fy: <Flag color="secondary" />,
      fr: <Flag color="error" />,
      fg: <Flag color="primary" />,
    },
  },
  {
    id: "fault_detection.ignored",
    accessor: "fault_detection.ignored",
    width: 180,
    Header: "text_fault_detection_relevance",
    sortType: "alphanumeric",
    type: "string",
    info: "text_fault_detection_relevance_desc",
    translateNs: "_common",
    hideable: true,
    spec: {
      type: "str",
      physical: "name",
      unit: null,
    },
    isVisible: true,
  },
  {
    id: "heat_energy_sum",
    accessor: "core.heat_energy_sum",
    width: 180,
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    path: "core.heat_energy_sum",
    Header: "text_energy_kwh",
    sortType: "alphanumeric",
    type: "number",
    info: "text_energy_kwh_desc",
    translateNs: "_common",
    hideable: true,
    spec: ENERGY_TYPE,
    isVisible: true,
    align: "right",
  },
  {
    id: "volume_sum",
    accessor: "core.volume_sum",
    width: 180,
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    path: "core.volume_sum",
    Header: "text_volume_m3",
    sortType: "alphanumeric",
    type: "number",
    info: "text_volume_m3_desc",
    translateNs: "_common",
    hideable: true,
    spec: FLOW_TYPE,
    isVisible: true,
    align: "right",
  },
  {
    id: "supplytemp_flowweighted_avg",
    accessor: "core.supplytemp_flowweighted_avg",
    width: 180,
    Header: "text_supply_temp_c",
    sublabel: "sub_text_supply_temp_c_flow_weighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    path: "core.supplytemp_flowweighted_avg",
    sortType: "alphanumeric",
    type: "number",
    info: "text_fd_supply_temp_desc",
    translateNs: "_common",
    hideable: true,
    spec: ENERGY_TYPE,
    isVisible: true,
    align: "right",
  },
  {
    id: "returntemp_flowweighted_avg",
    accessor: "core.returntemp_flowweighted_avg",
    width: 180,
    Header: "text_return_temp_c",
    sublabel: "sub_text_return_temp_c_flow_weighted",
    blockName: (year) => CORE_ROLLING_YEARLY_BLOCK.to_block_name(year),
    path: "core.returntemp_flowweighted_avg",
    sortType: "alphanumeric",
    type: "number",
    info: "text_fd_return_temp_desc",
    translateNs: "_common",
    hideable: true,
    spec: ENERGY_TYPE,
    isVisible: true,
    align: "right",
  },
  {
    id: "delta_temp",
    accessor: "core.delta_temp",
    width: 180,
    path: "core.delta_temp",
    Header: "text_delta_temp_c",
    sublabel: "sub_text_delta_temp_c_flow_weighted",
    sortType: "alphanumeric",
    type: "number",
    info: "text_fd_delta_temp_desc",
    translateNs: "_common",
    hideable: true,
    spec: ENERGY_TYPE,
    isVisible: true,
    align: "right",
  },
];
