import { useRef, useLayoutEffect } from "react"

export default function useIsMounted() {
  const isMounted = useRef(false)

  useLayoutEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  }, [])

  return isMounted
}
