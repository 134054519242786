import PropTypes from "prop-types";
import { Box, CircularProgress, Grid, Paper, Typography } from "@mui/material";

/**
 * - This component is used as the base for all other cards.
 * - It is used to display a title, as well as any children passed to it.
 * - In addition to the data, the component also displays a loading indicator and an error message.
 * */
function BaseCard({ title, titleAddon, children, loading, error, errorMessage, testId, minHeight }) {
  const renderComponents = () => {
    if (error) {
      return (
        <Typography variant="h6" color="error">
          {errorMessage}
        </Typography>
      );
    }
    return loading ? <CircularProgress color="primary" size="3.5rem" /> : children;
  };

  return (
    <Paper elevation={1} style={{ height: "100%", minHeight: minHeight }} data-testid={testId}>
      <Box flexGrow={1} height="inherit" minHeight={minHeight}>
        <Grid
          style={{ height: "inherit" }}
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <Grid item style={{ maxWidth: "100%" }} zIndex={1}>
            <Paper elevation={1}>
              <Box
                px={2}
                flexGrow={1}
                height={48}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  sx={{ flexGrow: 1, textTransform: "capitalize" }}
                  data-testid={`${testId}__title`}
                >
                  {title}
                </Typography>
                {titleAddon && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      paddingLeft: 2,
                      marginRight: -1,
                      borderLeft: 1,
                      borderColor: "grey.blue4",
                    }}
                  >
                    <Typography
                      color="secondary"
                      variant="subtitle2"
                      data-testid={`${testId}__subtitle`}
                    >
                      {titleAddon}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs style={{ maxWidth: "100%" }} zIndex={0}>
            <Box
              flexGrow={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="100%"
              py={2}
              data-testid={`${testId}__body`}
            >
              {renderComponents()}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

BaseCard.defaultProps = {
  title: "",
  titleAddon: "",
  children: null,
  error: false,
  errorMessage: "Unable to load data",
  loading: false,
  testId: "",
  minHeight: 0,
};

BaseCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  titleAddon: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  testId: PropTypes.string,
  minHeight: PropTypes.number,
};

export default BaseCard;
