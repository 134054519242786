import { Component } from 'react';
import GraphMetrics from "./GraphMetrics";
import withTheme from '@mui/styles/withTheme';
import { inject, observer } from "mobx-react";
import { isValue } from '../../core/utils';
import FUnitValueHtml from '../../components/analytics/FunitValueHTML';

class GraphColCompare extends Component {
    render() {
        const { series1, series2, unit, width, categories, theme, dense, scaleYAxis = false } = this.props;
        return (
            <GraphMetrics
                scaleYAxis={{scaleYAxis}}
                xAxisTitle=""
                boost={false}
                xAxis={{
                    gridLineWidth: 0,
                    lineWidth: 0,
                    categories: categories
                }}
                yAxisOptions={{
                    gridLineWidth: 0, lineWidth: 0
                }}
                chartOptions={{
                    height: dense ? 180 : 240,
                    marginTop: 20,
                    zoomType: "",
                    width: width,
                    backgroundColor: 'white',
                    boost: {
                        seriesThreshold: 1000,
                    },
                }}
                seriesOptions={{ marker: { enabled: false } }}
                tooltip={{
                    shared: true,
                    formatter: function () {
                        let ttip = ``
                        if (this.points[0] && isValue(this.points[0].y)) {
                            ttip += `<span style="color:${this.points[0].color};">&#9679;&nbsp;${this.points[0].series.userOptions.label} ${FUnitValueHtml(this.points[0].y, unit)} </span><br/>`;
                        }
                        if (this.points[1] && isValue(this.points[1].y)) {
                            ttip += `<span style="color:${this.points[1].color};">&#9679;&nbsp;${this.points[1].series.userOptions.label} ${FUnitValueHtml(this.points[1].y, unit)} </span><br/>`;
                        }
                        if (this.points[1] && this.points[0] && isValue(this.points[1].y) && isValue(this.points[0].y)) {
                            ttip += `<span>&nbsp;&nbsp;&nbsp;Difference ${FUnitValueHtml(this.points[0].y - this.points[1].y, unit)}</span>`
                        }
                        return ttip;
                    },
                    useHTML: true
                }}
                legend={{ enabled: false }}
                chartData={[{

                    type: "column",
                    color: theme.palette.graph.blue,
                    showInLegend: true,
                    pointWidth: 24,
                    ...series1,
                }, {

                    type: "column",
                    pointPlacement: -0.2,
                    lineWidth: 3,
                    showInLegend: true,
                    pointWidth: 24,
                    color: theme.palette.secondary.main,
                    ...series2,
                }]}
            />
        );
    }
}

export default inject('rootStore')(withTheme(observer(GraphColCompare)));