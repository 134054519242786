import PropTypes from "prop-types";
import { Box, Grid, Tooltip, Typography } from "@mui/material";

import LargeNumber from "../../../../../components/analytics/LargeNumber";
import BaseCard from "../BaseCard/BaseCard";
import {
  fmtRef,
  formatNumberForLocale,
  formatNumberForUnitMainLabel,
} from "../../../../../core/utils";
import CardBottomText from "../../../../../components/analytics/CardBottomText";
import ArrowDeviation from "../../../../../components/analytics/ArrowDeviation";
import Peak from "../../../../../icons/Peak";

/**
 * - This card is built on top of the BaseCard component.
 * - The card displays a number with a unit.
 * - The unit is displayed in the right side of the value.
 * - The card can take a subtitle, displayed below the value.
 * - The card can also take an exponential value.
 * - The card show peak value and icon tooltip on the left .
 * - The card show trend value and icon (base on the value )tooltip on the right .
 */
function InfoCard({
  title,
  titleAddon,
  subtitle,
  value,
  unit,
  loading,
  error,
  errorMessage,
  color,
  size,
  peak,
  trend,
  testId,
}) {
  let formattedValue = value;
  let formattedUnit = unit;
  let formattedExp = 0;
  let formattedPrefix = "";

  // If it is a number and the unit is not empty, format the value and unit.
  if (!loading && typeof value === "number" && unit) {
    [formattedValue, formattedExp, formattedPrefix, formattedUnit] = formatNumberForUnitMainLabel(
      value,
      unit
    );
  }
  let refNumber = "";
  let refExp = 0;
  let refPrefix = "";
  let refUnit = "";
  [refNumber, refExp, refPrefix, refUnit] = fmtRef(peak.value, "kW", formattedExp, formattedPrefix);

  return (
    <BaseCard
      title={title}
      titleAddon={titleAddon}
      loading={loading}
      error={error}
      errorMessage={errorMessage}
      testId={testId}
      minHeight={210}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="stretch"
        width="100%"
        height="100%"
        px={2}
      >
        {/* Header */}
        <div>
          {/* This is just mock to that the space-between works. */}
        </div>
        {/* Content */}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <LargeNumber
            value={formattedValue}
            unit={formattedPrefix + formattedUnit}
            exp={formattedExp}
            color={color}
            size={size}
            tooltip={`${new Intl.NumberFormat("se-SE").format(value)} ${unit}`}
          />
          <Typography variant={size === "small" ? "caption" : "subtitle2"} color={color}>
            {subtitle}
          </Typography>
        </Box>
        {/* Footer */}
        <Grid container>
          <Grid item xs={6} justifyContent="flex-start">
            {peak.title && peak.value && (
              <CardBottomText
                startIcon={
                  <Tooltip title={peak.title}>
                    <Box>
                      <Peak fontSize="large" />
                    </Box>
                  </Tooltip>
                }
                value={`${formatNumberForLocale(refNumber + refExp)}`}
                suffix={refPrefix + refUnit}
              />
            )}
          </Grid>
          <Grid item xs={6} display="flex" justifyContent="flex-end" maxHeight={100}>
            {trend.title && trend.value && (
              <Tooltip title={trend.title}>
                <ArrowDeviation value={trend.value} suffix={trend.suffix} />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Box>
    </BaseCard>
  );
}

InfoCard.defaultProps = {
  title: "",
  titleAddon: "",
  subtitle: "",
  value: "",
  unit: "",
  loading: false,
  error: false,
  errorMessage: "Unable to load data",
  color: "secondary",
  size: "default",
  peak: { title: "", value: null },
  trend: { title: "", value: null, suffix: "" },
  testId: "info-card",
};

InfoCard.propTypes = {
  title: PropTypes.string,
  titleAddon: PropTypes.string,
  subtitle: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unit: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf(["default", "small"]),
  peak: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.number || null,
  }),
  trend: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.number || null,
    suffix: PropTypes.string,
  }),
  testId: PropTypes.string,
};

export default InfoCard;
