import { SUBSTATION_BLOCK_TYPES as SBT, UNIT } from "../blocks";
import { R12 } from "../../pages/RTAnalysis/constants";
import { isValue } from "../../core/utils";

import { COLUMN_SECTION, COLUMN_PERIOD } from "./constants";
import { DESIGN_LOAD_COLUMNS_1H, DESIGN_LOAD_COLUMNS_24H } from "./DesignLoad.columns";
import {
  SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  SYSTEM_DESIGN_LOAD_COLUMNS_24H,
} from "./SystemDesignLoad.columns";
import CORE_COLUMNS from "./Core.columns";
import EP_CORE_COLUMNS from "./EPCore.columns";
import { CUSTOMER_COLUMNS, INSTALL_ADDRESS_COLUMNS, DISTRIBUTION_COLUMNS } from "./Base.columns";
import PRICING_COLUMNS from "./Pricing.columns";
import BUILDING_COLUMNS from "./Building.columns";
import { AVERAGE_PEAK_1H_COLUMNS, AVERAGE_PEAK_24H_COLUMNS } from "./AveragePeak.columns";
import MEASURED_PEAK_POWER_COLUMNS from "./MeasuredPeakPower.columns";
import ENERGY_SIGNATURE_COLUMNS from "./EnergySignature.columns";

// Sort utils
function getRowValueByColumnID(row, columnId) {
  return row.values[columnId];
}
function compareBasic(a, b) {
  // eslint-disable-next-line no-nested-ternary
  return a === b ? 0 : a > b ? 1 : -1;
}

function numberSort(rowA, rowB, columnId) {
  const a = getRowValueByColumnID(rowA, columnId);
  const b = getRowValueByColumnID(rowB, columnId);
  if (a === b) return 0;

  if (!isValue(a)) {
    return -1;
  }
  if (!isValue(b)) {
    return 1;
  }

  return compareBasic(a, b);
}

function numberSortWithCustom(rowA, rowB, columnId) {
  const a = getRowValueByColumnID(rowA, columnId);
  const b = getRowValueByColumnID(rowB, columnId);
  const customNegatives = ["N/A"];

  if (customNegatives.includes(a)) {
    return -1;
  }

  if (customNegatives.includes(b)) {
    return 1;
  }

  return numberSort(rowA, rowB, columnId);
}

/**
 * Meta information of substation block columns
 */
const sbtColumnMeta = {
  // -- CORE ROLLING YEARLY
  core: CORE_COLUMNS,
  core_rolling_yearly: CORE_COLUMNS,
  // EP Design Columns
  ep_design_1h: DESIGN_LOAD_COLUMNS_1H,
  ep_design_24h: DESIGN_LOAD_COLUMNS_24H,
  ep_design_noflowlimiter_1h: DESIGN_LOAD_COLUMNS_1H,
  ep_design_nopowerlimiter_1h: DESIGN_LOAD_COLUMNS_1H,
  // EP System Design Columns
  ep_systemdesign_1h: SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  ep_systemdesign_24h: SYSTEM_DESIGN_LOAD_COLUMNS_24H,
  ep_systemdesign_noflowlimiter_1h: SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  ep_systemdesign_nopowerlimiter_1h: SYSTEM_DESIGN_LOAD_COLUMNS_1H,
  // Energy Signature Columns
  energy_signature: ENERGY_SIGNATURE_COLUMNS,
  // --- EPCORE_NORMALIZED
  epcore_normalized: EP_CORE_COLUMNS,
  // --- BUILDING
  building: BUILDING_COLUMNS,
  // --- DISTRIBUTION
  distribution: DISTRIBUTION_COLUMNS,
  // --- CUSTOMER
  customer: CUSTOMER_COLUMNS,
  // --- INSTALL ADDRESS
  install_address: INSTALL_ADDRESS_COLUMNS,
  // --- PRICING
  pricing: PRICING_COLUMNS,
  // --- PEAK POWER
  measured_peak_power: MEASURED_PEAK_POWER_COLUMNS,
  // --- AVERAGE PEAK
  avg_peak_1h: AVERAGE_PEAK_1H_COLUMNS,
  avg_peak_24h: AVERAGE_PEAK_24H_COLUMNS,
  // --- COMPUTED
  // (Columns with custom calculations based on other columns)
  computed: {
    deltatemp_flowweighted_avg: {
      output: (row) => {
        let blockName = "core";
        if (!Object.prototype.hasOwnProperty.call(row, "core")) {
          blockName = "core_rolling_yearly";
        }

        if (
          row[blockName]?.supplytemp_flowweighted_avg &&
          row[blockName]?.returntemp_flowweighted_avg
        ) {
          return (
            row[blockName].supplytemp_flowweighted_avg - row[blockName].returntemp_flowweighted_avg
          );
        }
        return null;
      },
      label: "text_delta_temp_c",
      sublabel: "sub_text_delta_temp_c_flow_weighted",
      info: "sub_text_delta_temp_c_flow_weighted_desc",
      type: "number",
      sortType: numberSort,
      align: "right",
      spec: {
        unit: UNIT.temp,
      },
      section: COLUMN_SECTION.temperatures,
    },
    deltatemp_flowweighted_avg_normal: {
      output: (row) => {
        if (
          row.epcore_normalized?.supplytemp_flowweighted_avg &&
          row.epcore_normalized?.returntemp_flowweighted_avg
        ) {
          return (
            row.epcore_normalized.supplytemp_flowweighted_avg -
            row.epcore_normalized.returntemp_flowweighted_avg
          );
        }
        return null;
      },
      label: "text_delta_temp_c",
      sublabel: "sub_text_delta_temp_c_normal_year",
      info: "sub_text_delta_temp_c_normal_year_desc",
      type: "number",
      sortType: numberSort,
      align: "right",
      spec: {
        unit: UNIT.temp,
      },
      section: COLUMN_SECTION.temperatures,
      periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
    },
    full_load_hours_1h_design: {
      output: (row) => {
        if (row.epcore_normalized?.heat_energy_sum && row.ep_design_1h?.heat_energy) {
          return row.epcore_normalized.heat_energy_sum / row.ep_design_1h.heat_energy;
        }
        return null;
      },
      label: "text_full_load_hour_h",
      sublabel: "sub_text_full_load_hour_h_1h",
      info: "text_full_load_hour_h_1h_desc",
      section: COLUMN_SECTION.usage_patterns,
      type: "number",
      periods: [COLUMN_PERIOD.year],
    },
    full_load_hours_24h_design: {
      output: (row) => {
        if (row.epcore_normalized?.heat_energy_sum && row.ep_design_24h?.heat_energy) {
          return row.epcore_normalized.heat_energy_sum / row.ep_design_24h.heat_energy;
        }
        return null;
      },
      label: "text_full_load_hour_h",
      sublabel: "sub_text_full_load_hour_h_24h",
      info: "text_full_load_hour_h_24h_desc",
      section: COLUMN_SECTION.usage_patterns,
      type: "number",
      periods: [COLUMN_PERIOD.year],
    },
  },
};

/**
 * Factory function for column definitions
 *
 * @param {*} {
 *   id,
 *   block = SBT.core,
 *   label,
 *   info,
 *   section,
 *   type = "string",
 *   ...definitions
 * }
 * @return {Object} Column object
 */
function generateColumnDefinition({
  id,
  block = SBT.core,
  label,
  info,
  section,
  type,
  ...definitions
}) {
  // generate block name based on period
  const blockName = (activePeriod = { year: null, month: null }) => {
    const { year } = activePeriod;
    const { month } = activePeriod;

    // populate period arguments for name generators
    const periodArgs = { year, month: null };
    if ((activePeriod.year !== R12 && activePeriod.month) !== null && activePeriod.month !== 0) {
      periodArgs.month = month;
    }
    if (activePeriod.year === R12) {
      periodArgs.roll = true;
    }

    if (typeof block === "function") {
      return block(periodArgs);
    }
    return block.to_block_name({ ...activePeriod, ...periodArgs }); // -> "{block}_{YEAR}-{MONTH}" -> "core_2021-12"
  };

  return {
    id,
    blockName,
    blockKey: block.name,
    label,
    info,
    section,
    type,
    ...definitions,
  };
}

/**
 * Generates column information from all available block types of substation
 * It combines data from block definitions with the meta information...
 * needed for presentational components like UtfDataTable
 *
 * @param {Array} [substationBlockTypes=Object.keys(SBT)]
 * @return {Array} Column definitions
 */
function generateSBTColumnsFromBlocks(substationBlockTypes = Object.keys(SBT)) {
  const colDef = new Map();

  substationBlockTypes.forEach((blockKey) => {
    const block = SBT[blockKey];
    const blockColumns = block?.columns;

    if (blockColumns) {
      Object.keys(blockColumns).forEach((colId) => {
        // Ignore the blocks without meta defined
        if (!(blockKey in sbtColumnMeta)) {
          console.error(`No meta information for block ${blockKey}`);
          return;
        }

        const spec = blockColumns[colId];
        // convert incompatible types
        const colType = spec.type.replace("num", "number").replace("str", "string");
        const defaultMeta = {
          id: colId,
          type: colType,
          block,
          spec,
        };

        // Label, Info, Section,...
        const colMeta = sbtColumnMeta[blockKey][colId];
        // Do not add the columns with empty or missing meta
        if (!colMeta || !("label" in colMeta)) return;

        const path = `${blockKey}.${colId}`;
        colDef.set(path, generateColumnDefinition({ ...defaultMeta, ...colMeta, path }));
      });
    }
  });

  return Array.from(colDef.values());
}

function getCoreBlockName(roll) {
  return roll ? "core_rolling_yearly" : "core";
}

/**
 * Generate combined data with requested block types and predefined computed columns
 *
 * @param {string} [COLUMN_PERIOD.year]
 * @param {array} sbtBlocks default blocks to be included
 * @return {Array} Column definitions
 */
function generateColumnsBySBT(activePeriod = COLUMN_PERIOD.year, sbtBlocks = []) {
  const sbtBlocksYearly = [getCoreBlockName()];

  // Set blocks based on active period
  let activeBlocks = [...sbtBlocks, ...sbtBlocksYearly];
  if (activePeriod === COLUMN_PERIOD.r12) {
    const sbtBlocksRolling1Y = [getCoreBlockName(true)];
    activeBlocks = sbtBlocks.concat(sbtBlocksRolling1Y);
  } else if (activePeriod === COLUMN_PERIOD.month) {
    const sbtBlocksMonthly = [getCoreBlockName()];
    activeBlocks = sbtBlocks.concat(sbtBlocksMonthly);
  }

  // Column definitions
  let columns;
  const computedColumns = [];
  const sbtColumns = generateSBTColumnsFromBlocks(activeBlocks);

  const computedColumnsMeta = sbtColumnMeta.computed;
  Object.keys(computedColumnsMeta).forEach((colId) => {
    const { output, ...colMeta } = computedColumnsMeta[colId];
    const metaData = {
      id: colId,
      path: colId,
      block: () => output,
      ...colMeta,
    };
    computedColumns.push(generateColumnDefinition(metaData));
  });

  // !!! Computed columns must be always placed after other columns since they depends their data
  columns = [...sbtColumns, ...computedColumns];
  columns = columns.map(({ periods = null, ...col }) => {
    let isDisabled = false;
    if (Array.isArray(periods) && !periods.includes(activePeriod)) {
      isDisabled = true;
    }
    return {
      disabled: isDisabled,
      periods,
      ...col,
    };
  });

  return columns;
}

function generateBlockNames(columns, periodArgs) {
  const blockNames = columns
    // shallow-copy
    .slice()
    // do not include "derive" type of block name generators
    .filter((col) => typeof col?.blockName(periodArgs) !== "function")
    .map((col) => col.blockName(periodArgs));

  return [...new Set(blockNames)];
}

function getPeriodArgs(inputYear, inputMonth, activePeriod, networks) {
  let year = inputYear;
  let month = inputMonth;

  if (activePeriod === COLUMN_PERIOD.r12) {
    year = networks.lpMonth.year;
    month = networks.lpMonth.month;
  }
  if (inputMonth === null) return { year };

  return { year, month };
}

function generateSpecs(columns, periodArgs) {
  return (
    columns
      .slice()
      .map((col) => {
        if (!col.blockName) return null;
        let blockName = null;
        if (typeof col.blockName === "function") {
          blockName = col.blockName(periodArgs);
        } else {
          blockName = col.blockName;
        }

        const blockType = typeof blockName === "function" ? "derive" : "name";

        return [
          // type
          blockType,
          // spec
          blockType === "derive" ? blockName : [blockName, col.id],
          // dest
          col.path,
        ];
      })
      // filter nullish
      .filter(Boolean)
  );
}

export {
  generateColumnsBySBT,
  generateBlockNames,
  generateColumnDefinition,
  getPeriodArgs,
  getCoreBlockName,
  generateSpecs,
  numberSort,
  numberSortWithCustom,
};
