import { observer } from "mobx-react";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BaseCard } from "../../../shared_components/ui/analytics/cards";
import { SUBSTATION_STATES } from "../../../pages/MeteringDash/FaultDetection/FaultDetection.constants";
import useStores from "../../../hooks/useStores";
import useFaultDetectionStore from "../../../pages/MeteringDash/FaultDetection/FaultDetection.store";
import useReaction from "../../../hooks/useReaction";

function FaultDetectionOverview() {
  const { t } = useTranslation(["extendView", "_messages"]);
  const { networks, sub, notifStore } = useStores();
  const faultDetectionStore = useFaultDetectionStore();
  // Status variable
  const [status, setStatus] = useState("");

  // Get the status of the substation from the store
  useReaction(
    () => sub.current_substation,
    async (substation) => {
      if (substation) {
        setStatus(await faultDetectionStore.getSubstationFaultStatus(substation));
      }
    },
    { fireImmediately: true }
  );
  // Change handler
  const handleChange = (event) => {
    try {
      faultDetectionStore.setSubstationFaultStatus(
        networks.current_network.uid,
        sub.current_substation,
        event.target.value
      );
      setStatus(event.target.value);
      notifStore.success(t("message_substation_status_updated_successfully", { ns: "_messages" }));
    } catch (e) {
      notifStore.error(t("message_substation_status_update_failed", { ns: "_messages" }));
    }
  };

  return (
    <Box my={4} mx={3} width={400} data-maskdata>
      <BaseCard title="Ignored Status">
        <Box px={3} width="100%">
          <FormControl fullWidth>
            <InputLabel id="fault-detection-status">
              {t("text_current_fault_status", { ns: "extendView" })}
            </InputLabel>
            <Select
              labelId="fault-detection-status"
              id="fault-detection-status-select"
              value={status}
              label={t("text_current_fault_status", { ns: "extendView" })}
              onChange={handleChange}
            >
              {SUBSTATION_STATES.map((state) => (
                <MenuItem value={state.value}>{t(state.label)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </BaseCard>
    </Box>
  );
}
export default observer(FaultDetectionOverview);
