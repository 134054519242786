import { autorun, makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";

export default class NotificationStore {
  /*
  / This is the notification store
  /  https://material.io/components/banners#usage
  / @param {object} parent - The parent store
  */
  snackbars = [];
  types = ["success", "info", "warning", "error"];
  constructor(parent) {
    this.parent = parent;
    makeAutoObservable(this);
    autorun(() => this.displaySnackbar());
  }

  /*
  / Public Methods
  */

  /*
  / This function displays a info notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  info(msg, options = {}) {
    this.addSnackbar("info", msg, options);
  }

  /*
  / This function displays a success notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  success(msg, options = {}) {
    this.addSnackbar("success", msg, options);
  }

  /*
  / This function displays an error notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  error(msg, options = { autoClose: 10000 }) {
    this.addSnackbar("error", msg, options);
  }

  /*
  / This function displays a warning notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  warning(msg, options = {}) {
    this.addSnackbar("warning", msg, options);
  }

  /*
  / Internal Methods
  */

  /*
  / This function adds a notification to the store
  / @param {string} type - The type of notification
  / @param {string} msg - The message to display
  / @param {object} options - The options to pass to the toast
  */
  addSnackbar(type, msg, options = {}) {
    if (!this.types.includes(type)) {
      throw new Error("Invalid notification type");
    }
    this.snackbars.push({
      type,
      msg,
      // Random id
      id: Math.random().toString(36),
      options,
    });
  }

  /*
  / If a notification is added to the store, this function will display it
  */
  displaySnackbar() {
    if (this.snackbars.length > 0) {
      const notification = this.snackbars[0];
      toast[notification.type](notification.msg, {
        toastId: notification.id,
        ...notification.options,
      });
      this.removeSnackbar(notification.id);
    }
  }

  /*
  / This function removes a notification from the store
  / @param {number} id - The id of the notification to remove
  */
  removeSnackbar(id) {
    if (id) {
      runInAction(() => {
        this.snackbars = this.snackbars.filter((k) => k.id !== id);
      });
    }
  }

  /*
  / This function removes all snackbars from the store
  */
  removeAllSnackbars() {
    this.snackbars = [];
  }
}
