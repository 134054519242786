import ReactDOM from "react-dom";
import { configure } from "mobx";

import App from "./app";
import "./i18n";

require("typeface-roboto");
require("typeface-roboto-condensed");
require("typeface-montserrat");

configure({
  enforceActions: "always",
  computedRequiresReaction: true,
  reactionRequiresObservable: true,
  observableRequiresReaction: true,
  disableErrorBoundaries: true,
});

ReactDOM.render(<App />, document.getElementById("root"));
