import { COLUMN_PERIOD, COLUMN_SECTION } from "./constants";

function getColumns(type) {
  return [
    {
      id: "a",
      label: "a",
      type: "number",
      info: "text_signature_a_desc",
    },
    {
      id: "b",
      label: "b",
      type: "number",
      info: "text_signature_b_desc",
    },
    {
      id: "t0",
      label: "t₀",
      type: "number",
      info: "text_signature_t₀_desc",
    },
    // TODO: add energy signature column back in
    // {
    //   id: "r2",
    //   label: "R²",
    //   type: "number",
    //   info: `Squared error between given meter data and ${type} signature value`,
    // },
  ];
}

const ENERGY_SIGNATURE_COLUMNS = {
  changepoint_heat_energy: {
    label: "text_energy_signature",
    sublabel: "sub_text_signature",
    type: "object",
    section: COLUMN_SECTION.energy,
    columns: getColumns("Energy"),
    periods: [COLUMN_PERIOD.year],
    info: "text_energy_signature",
    translateOptions: { type: "Energy" },
  },
  changepoint_volume: {
    label: "text_flow_signature",
    sublabel: "sub_text_signature",
    type: "object",
    section: COLUMN_SECTION.flow,
    columns: getColumns("Flow"),
    periods: [COLUMN_PERIOD.year],
    info: "text_flow_signature",
  },
  changepoint_returntemp: {
    label: "text_return_temperature_signature",
    sublabel: "sub_text_signature",
    type: "object",
    section: COLUMN_SECTION.temperatures,
    columns: getColumns("Return Temperature"),
    periods: [COLUMN_PERIOD.year],
    info: "text_return_temperature_signature",
  },
  changepoint_supplytemp: {
    label: "text_supply_temperature_signature",
    sublabel: "sub_text_signature",
    type: "object",
    section: COLUMN_SECTION.temperatures,
    columns: getColumns("Supply Temperature"),
    periods: [COLUMN_PERIOD.year],
    info: "text_supply_temperature_signature",
  },
};

export default ENERGY_SIGNATURE_COLUMNS;
