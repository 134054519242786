const baseUrl = "https://wiki.energypredict.com";

const defaultLang = "en";

const wikiData = {
  dash: {
    base: `${baseUrl}/${defaultLang}/home`,
  },
  metering: {
    base: `${baseUrl}/${defaultLang}/metering/metering_dashboard`,
  },
  sales: {
    base: `${baseUrl}/${defaultLang}/sales/sales_dashboard`,
  },
  pricing: {
    base: `${baseUrl}/${defaultLang}/sales/pricing`,
  },
  distribution: {
    base: `${baseUrl}/${defaultLang}/distribution/distribution_dashboard`,
  },
  production: {
    base: `${baseUrl}/${defaultLang}/production/production_dashboard`,
  },
  forecast: {
    base: `${baseUrl}/${defaultLang}/production/forecast`,
  },
  data_library: {
    base: `${baseUrl}/${defaultLang}/general/data_library`,
  },
  mapview: {
    base: `${baseUrl}/${defaultLang}/general/map_view`,
  },
  rt_analysis: {
    base: `${baseUrl}/${defaultLang}/distribution/return_temperature_analysis`,
    // Tabs are appended to the base url for the section
    tabs: {
      analysis: "analysis",
      table: "table",
      production_savings: "production_savings",
    },
  },
  design_load: {
    base: `${baseUrl}/${defaultLang}/distribution/design_load`,
    tabs: {
      trend: "design_load_trend",
      analysis: "design_load_analysis",
    },
  },
};

export default wikiData;
