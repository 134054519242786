import { useState } from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";

import { APP_BAR_HEIGHT } from "../../../../conf/ui_constants";
import useStores from "../../../../hooks/useStores";
import GlobeIcon from "../../../../icons/Globe";
import FilterIcon from "../../../../icons/Filter";
import NetworkIcon from "../../../../icons/Networks";
import SubstationIcon from "../../../../icons/Substation";
import * as CONSTANTS from "../constants";

import SettingOptions from "./SettingOptions";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    "&&": {
      ...theme.typography.Button,
      height: APP_BAR_HEIGHT,
      borderRadius: 0,
      display: "flex",
      gap: theme.spacing(1),
      borderRight: `1px solid rgb(0, 68, 104)`,
      padding: theme.spacing(0, 2.3),
      "&:first-child": {
        borderLeft: `1px solid rgb(0, 68, 104)`,
      },
      "&:last-child": {
        borderRight: "none",
      },
      color: theme.palette.background.paper,
      "&:hover": {
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.secondary.main,
      },
      pointerEvents: "auto",
    },
  },
  menuItem: {
    display: "inherit",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(1),
    "& > svg:nth-child(2)": {
      paddingBottom: theme.spacing(0.6),
    },
  },
  activeMenu: {
    "&&": {
      background: theme.palette.secondary.main,
    },
  },
  filteredSub: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.background.paper,
    },
  },
  icon: { width: "20px", height: "20px" },
  subStationIcon: { width: "17px", height: "17px" },
}));

function PageSetting() {
  const classes = useStyles();
  const { networks, ui, language } = useStores();
  const [settingType, setSettingType] = useState("");
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [isSubstationBtnHover, setSubstationBtnHover] = useState(false);

  const openPopover = Boolean(popoverAnchorEl);
  const popoverId = openPopover ? `${settingType}-popover` : undefined;

  const handleClick = (event, type) => {
    setPopoverAnchorEl(event.currentTarget);
    setSettingType(type);
  };

  const handleClose = () => {
    setPopoverAnchorEl(null);
    // without this it will show a blank screen for like 0.1sec
    setTimeout(() => {
      setSettingType(null);
    }, 100);
  };

  return (
    <>
      <Box display="flex" justifyContent="end">
        {/* Language */}
        <Button
          className={clsx(
            classes.menuButton,
            settingType === CONSTANTS.LANGUAGE && classes.activeMenu
          )}
          sx={{ minWidth: "96px" }}
          onClick={(e) => handleClick(e, CONSTANTS.LANGUAGE)}
          disabled={!networks.ready}
          data-testid={`${CONSTANTS.LANGUAGE}-setting-button`}
        >
          {language.currentLanguage} <GlobeIcon className={classes.icon} />
        </Button>

        {/* Substation */}
        <Button
          sx={{ minWidth: "96px" }}
          className={clsx(
            classes.menuButton,
            settingType === CONSTANTS.SUBSTATION && classes.activeMenu
          )}
          onClick={(e) => handleClick(e, CONSTANTS.SUBSTATION)}
          disabled={!networks.ready}
          data-testid={`${CONSTANTS.SUBSTATION}-setting-button`}
          onMouseEnter={() => setSubstationBtnHover(true)}
          onMouseLeave={() => setSubstationBtnHover(false)}
        >
          <Box display="flex" flexDirection="column">
            <div className={classes.menuItem}>
              {networks.active_substations.size !== networks.current_substations.size && (
                <>
                  {networks.ready ? (
                    <span
                      className={
                        !isSubstationBtnHover &&
                        !settingType &&
                        ui.is_filteroptions_open &&
                        classes.filteredSub
                      }
                    >
                      {networks.active_substations.size}
                    </span>
                  ) : (
                    "..."
                  )}
                  <FilterIcon className={classes.icon} />
                </>
              )}
            </div>
            <div className={classes.menuItem}>
              {networks.ready ? networks.current_substations.size : "..."}
              <SubstationIcon className={classes.subStationIcon} />
            </div>
          </Box>
        </Button>

        {/* Networks */}
        <Button
          className={clsx(
            classes.menuButton,
            settingType === CONSTANTS.NETWORK && classes.activeMenu
          )}
          onClick={(e) => handleClick(e, CONSTANTS.NETWORK)}
          disabled={!networks.ready}
          data-testid={`${CONSTANTS.NETWORK}-setting-button`}
        >
          {networks.ready ? networks.current_network.name : "..."}
          <NetworkIcon className={classes.icon} />
        </Button>
      </Box>
      <SettingOptions
        id={popoverId}
        open={openPopover}
        settingType={settingType}
        anchorEl={popoverAnchorEl}
        handleClose={handleClose}
      />
    </>
  );
}

export default observer(PageSetting);
