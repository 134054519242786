import { observable, action, flow, computed, makeObservable } from 'mobx';
import {SUBSTATION_BLOCK_TYPES as SBT,blkReader} from '../../conf/blocks'

class Store {
    search_text = "";
    is_data_available = false;
    reader = null;

    updateSearchText(val) {
        this.search_text = val;
    }

    constructor(parent) {
        makeObservable(this, {
            search_text: observable,
            is_data_available: observable,
            reader: observable,
            updateSearchText: action.bound,
            blockNames: computed,
            colSpecs: computed
        });

        this.parent = parent;
        this.block_data = {}
    }

    get blockNames() {
        const networks = this.parent.networks;
        const year = networks.lpYear.year
        return [
            SBT.building.to_block_name(),
            SBT.core.to_block_name({year}),
            SBT.customer.to_block_name(),
            SBT.install_address.to_block_name()
        ]
    }

    get colSpecs() {
        const networks = this.parent.networks;
        const year = networks.lpYear.year
        return [
            [
                'derive',
                (_,indexValue)=>networks.current_substations.get(indexValue),
                'name'
            ],
            [
                'derive',
                (_,indexValue)=>indexValue,
                'uid'
            ],
            [
                'name',
                [
                    SBT.core.to_block_name({year}),
                    SBT.core.col.heat_energy_sum
                ],
                'energy'
            ],
            [
                'name',
                [
                    SBT.core.to_block_name({year}),
                    SBT.core.col.returntemp_flowweighted_avg
                ],
                'ret_temp'
            ],
            [
                'name',
                [
                    SBT.customer.to_block_name({year}),
                    SBT.customer.col.name
                ],
                'cust'
            ],
            [
                'name',
                [
                    SBT.install_address.to_block_name(),
                    SBT.install_address.col.street_name
                ],
                'st_name'
            ],
            [
                'name',
                [
                    SBT.install_address.to_block_name(),
                    SBT.install_address.col.coordinates
                ],
                'coords'
            ]
        ]
    }

    fetchData = flow(function* () {
        
        this.is_data_available = false;
        this.reader = null;
        this.block_data = yield this.parent.newapi.getInfoBlocksV4({
            resource_type: 'network_substations',
            resource_id: this.parent.networks.current_network.uid,
            block_names: this.blockNames
        })
        this.reader = blkReader(this.block_data,this.colSpecs)
        this.is_data_available = true;
    })

}

export default Store;