import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { Button, Popover, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import NetworkSelect from "../../../../components/Networks/NetworkSelect";
import ButtonDropdown from "../../../../components/ui/ButtonDropdown";
import useStores from "../../../../hooks/useStores";
import GlobeIcon from "../../../../icons/Globe";
import ClockIcon from "../../../../icons/Clock";
import FilterIcon from "../../../../icons/Filter";
import NetworkIcon from "../../../../icons/Networks";
import CurrencyIcon from "../../../../icons/Currency";
import SubstationIcon from "../../../../icons/Substation";
import * as CONSTANTS from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "360px",
  },
  icon: { width: "20px", height: "20px", fill: theme.palette.grey.blue72 },
  subStationIcon: { width: "17px", height: "17px" },
  mainContainer: {
    width: "100%",
    padding: theme.spacing(2, 3),
    borderBottom: `${theme.palette.grey.blue4} solid 1px`,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.25),
  },
  sectionContainer: {
    display: "inherit",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  buttonDropdown: {
    "&&": {
      ...theme.typography.body1,
      width: "inherit",
      borderColor: theme.palette.primary.main,
      borderRadius: "2px",
      display: "flex",
      justifyContent: "space-between",
    },
  },
  popoverActon: {
    height: theme.spacing(8),
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
  },
  popoverActonButton: {
    width: "140px",
    height: "30px",
  },
  infoText: { marginLeft: theme.spacing(3.5) },
  timeZoneName: {
    textTransform: "capitalize",
  },
}));

function SettingPopover({ id, open, anchorEl, handleClose, children }) {
  const classes = useStyles();

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transitionDuration={200}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <div className={classes.root}>{children}</div>
    </Popover>
  );
}
SettingPopover.defaultProps = {
  id: "",
  open: null,
  anchorEl: null,
};
SettingPopover.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  anchorEl: PropTypes.oneOfType([PropTypes.bool, PropTypes.node, PropTypes.object]),
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

function SettingOptions({ id, open, anchorEl, handleClose, settingType }) {
  const classes = useStyles();
  const { t } = useTranslation(["navbar", "_action"]);
  const {
    ui,
    networks,
    language,
    rootStore: { filters },
  } = useStores();
  const [selectedNetwork, setSelectedNetwork] = useState(networks.current_network);

  useEffect(() => {
    if (!open) setSelectedNetwork(networks.current_network);
  }, [open, anchorEl]);

  const handleViewFilter = () => {
    if (ui.is_filteroptions_open) {
      handleClose();
      return;
    }

    ui.toggleFilters();
    handleClose();
  };

  const handleResetFilter = () => {
    filters.resetFilters();
    handleClose();
  };

  const handleSelectNetwork = () => {
    // close if network option not changing
    if (selectedNetwork.uid === networks.current_network.uid) {
      handleClose();
      return;
    }

    if (!ui.is_sidenav_open) ui.sideNavToggle();
    networks.selectNetwork(selectedNetwork);
    handleClose();
  };

  const handleNetworkSettingClose = () => {
    setSelectedNetwork(networks.current_network);
    handleClose();
  };

  // eslint-disable-next-line no-underscore-dangle
  const timeZone = DateTime.now()._zone.name;

  return (
    <SettingPopover id={id} open={open} anchorEl={anchorEl} handleClose={handleClose}>
      {/* Language */}
      {settingType === CONSTANTS.LANGUAGE && (
        <section>
          <div className={classes.mainContainer}>
            <div className={classes.sectionContainer}>
              <GlobeIcon className={classes.icon} />
              <Typography variant="caption">{t("text_language", { ns: "navbar" })}:</Typography>
            </div>
            <ButtonDropdown
              style={{ width: "inherit" }} // btn width
              className={classes.buttonDropdown}
              selectedValue={language.currentLanguage}
              onChange={language.updateCurrentLanguage}
              width={CONSTANTS.DROPDOWN_WIDTH} // dp width
              options={language.languageOptions}
            />
          </div>
          <div className={classes.popoverActon}>
            <Button
              variant="contained"
              color="primary"
              className={classes.popoverActonButton}
              onClick={handleClose} // no real action yet
              data-testid={`${CONSTANTS.LANGUAGE}-confirm-setting-button`}
            >
              {t("action_confirm", { ns: "_action" })}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.popoverActonButton}
              onClick={handleClose}
              data-testid={`${CONSTANTS.LANGUAGE}-cancel-setting-button`}
            >
              {t("action_cancel", { ns: "_action" })}
            </Button>
          </div>
        </section>
      )}

      {/* Substation */}
      {settingType === CONSTANTS.SUBSTATION && (
        <section>
          <div className={classes.mainContainer}>
            <div className={classes.sectionContainer}>
              <SubstationIcon className={classes.subStationIcon} />
              <Typography variant="caption">
                {t("text_substation_in_the_network", { ns: "navbar" })}:
              </Typography>
            </div>
            <Typography variant="body" className={classes.infoText}>
              {networks.ready && networks.current_substations.size}
            </Typography>
          </div>
          <div className={classes.mainContainer}>
            <div className={classes.sectionContainer}>
              <FilterIcon className={classes.subStationIcon} />
              <Typography variant="caption">
                {t("text_substation_being_filtered", { ns: "navbar" })}:
              </Typography>
            </div>
            <Typography variant="body1" className={classes.infoText}>
              {networks.ready && networks.active_substations.size}
            </Typography>
          </div>
          <div className={classes.popoverActon}>
            <Button
              variant="contained"
              color="primary"
              className={classes.popoverActonButton}
              onClick={handleViewFilter}
              data-testid={`${CONSTANTS.SUBSTATION}-view-filter-setting-button`}
            >
              {t("action_view_filter", { ns: "_action" })}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.popoverActonButton}
              disabled={networks.active_substations.size === networks.current_substations.size}
              onClick={handleResetFilter}
              data-testid={`${CONSTANTS.SUBSTATION}-reset-setting-button`}
            >
              {t("action_reset_filter", { ns: "_action" })}
            </Button>
          </div>
        </section>
      )}

      {/* Network */}
      {settingType === CONSTANTS.NETWORK && (
        <section>
          <div className={classes.mainContainer}>
            <div className={classes.sectionContainer}>
              <NetworkIcon className={classes.icon} />
              <Typography variant="caption">{t("text_network", { ns: "navbar" })}:</Typography>
            </div>

            <div className={classes.sectionContainer}>
              <NetworkSelect
                width={CONSTANTS.DROPDOWN_WIDTH}
                currentNetwork={selectedNetwork}
                onChange={setSelectedNetwork}
              />
            </div>
          </div>
          <div className={classes.mainContainer}>
            <div className={classes.sectionContainer}>
              <ClockIcon className={classes.icon} />
              <Typography variant="caption">{t("text_time_zone", { ns: "navbar" })}:</Typography>
            </div>
            <Typography variant="body" className={classes.infoText}>
              {networks.ready && (
                <>
                  <span className={classes.timeZoneName}>{networks.tz_name}</span> ({timeZone})
                </>
              )}
            </Typography>
          </div>
          <div className={classes.mainContainer}>
            <div className={classes.sectionContainer}>
              <CurrencyIcon className={classes.icon} />
              <Typography variant="caption">{t("text_currency", { ns: "navbar" })}:</Typography>
            </div>
            <Typography variant="body1" className={classes.infoText}>
              {/* 👇 Should "Currency" be from BE? */}
              Swedish Krona (SEK)
            </Typography>
          </div>
          <div className={classes.popoverActon}>
            <Button
              variant="contained"
              color="primary"
              className={classes.popoverActonButton}
              onClick={handleSelectNetwork}
              data-testid={`${CONSTANTS.NETWORK}-confirm-setting-button`}
              disabled={selectedNetwork.uid === networks.current_network.uid}
            >
              {t("action_confirm", { ns: "_action" })}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.popoverActonButton}
              onClick={handleNetworkSettingClose}
              data-testid={`${CONSTANTS.NETWORK}-cancel-setting-button`}
            >
              {t("action_cancel", { ns: "_action" })}
            </Button>
          </div>
        </section>
      )}
    </SettingPopover>
  );
}

SettingOptions.defaultProps = {
  id: "",
  open: null,
  anchorEl: null,
  settingType: null,
};
SettingOptions.propTypes = {
  id: SettingPopover.propTypes.id,
  open: SettingPopover.propTypes.open,
  anchorEl: SettingPopover.propTypes.anchorEl,
  handleClose: PropTypes.func.isRequired,
  settingType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default observer(SettingOptions);
