import FileSaver from "file-saver";
import * as XLSX from "xlsx";

const XLSXFILETYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const CSVFILETYPE = "text/plain;charset=UTF-8";

const HandleExport = {
  asXLSXFile: (data, filename, header) => {
    const ws = XLSX.utils.json_to_sheet(data, { header })
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
    const _data = new Blob([excelBuffer], { type: XLSXFILETYPE })
    FileSaver.saveAs(_data, filename + ".xlsx")
  },
  asCSVFile: (data, filename, downloadOption, header) => {
    let ws = XLSX.utils.json_to_sheet(data, { header })
    let range = XLSX.utils.decode_range(ws["!ref"])
    //* loop over all of the cell
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let cell = ws[XLSX.utils.encode_cell({ r: R, c: C })]
        if (!cell || cell.t !== "n") continue
        //* replace the separator base on the option that the user picked
        cell.w = cell.v
          .toString(10)
          .replace(/\./, downloadOption === "CSV_WITH_DOT_SEPARATOR" ? "." : ",")
      }
    }
    const csv = XLSX.utils.sheet_to_csv(ws)
    const blob = new Blob([csv], { type: CSVFILETYPE })
    FileSaver.saveAs(blob, filename + ".CSV")
  },
}

export default HandleExport;
