import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useTheme } from "@mui/styles";
import HighCharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { formatNumberForLocale } from "../../../core/utils";
import exportGraph from "../../../hooks/useExportFile";
import { addAnnotations } from "../../../shared_components/ui/analytics/charts/utils";
import { ChartToolbox, GraphContainer } from "../../../shared_components/ui/analytics/charts";
import { withErrorBoundary } from "../../ui/ErrorBoundary";

import useConsumption from "./state";
import * as CONSUMPTION from "./Constants";
import { legendItemClickHandler } from "./utils";

require("highcharts/modules/boost")(HighCharts);
require("highcharts/modules/exporting")(HighCharts);
require("highcharts/modules/export-data")(HighCharts);
require("highcharts/modules/annotations")(HighCharts);

const HEIGHT = 550;

function MeterChartLines() {
  const { t } = useTranslation(["extendView"]);
  const {
    flowLimiter,
    isDataAvailable,
    areReadingsAveragedForDaily,
    registerChart,
    extremes,
    chartWidth,
    readerRange,
    seriesInExtremes,
  } = useConsumption();
  const theme = useTheme();
  const chartRef = useRef();
  const [isViewDataTable, setIsViewDataTable] = useState(false);
  const [meterDataToolbox, setMeterDataToolbox] = useState([]);
  const [visibleSeries, setVisibleSeries] = useState({
    outdoor: true,
    vol: true,
    heat: true,
    rt: true,
    st: true,
  });

  registerChart(chartRef);

  const visibleSeriesCount = Object.keys(visibleSeries).reduce((prev, current) => {
    if (visibleSeries[current]) return prev + 1;
    return prev;
  }, 0);

  // How many of RT or ST series are visible
  const visibleCombinedCount = Object.keys(visibleSeries).reduce((prev, current) => {
    if (current !== "rt" && current !== "st") return prev;
    if (visibleSeries[current]) return prev + 1;
    return prev;
  }, 0);

  const chartData = useMemo(() => {
    let outdoorTempData = [];
    let timeStamp = [];
    let flow = [];
    let heatEnergy = [];
    let returnTemp = [];
    let supplyTemp = [];

    if (seriesInExtremes) {
      outdoorTempData = seriesInExtremes.map(({ outdoor }) => outdoor);
      timeStamp = seriesInExtremes.map(({ ts }) => ts);
      flow = seriesInExtremes.map(({ vol }) => vol);
      heatEnergy = seriesInExtremes.map(({ heat }) => heat);
      returnTemp = seriesInExtremes.map(({ rt }) => rt);
      supplyTemp = seriesInExtremes.map(({ st }) => st);
    }

    const series = [];
    const yAxis = [];

    let topOffset = 60; // the height of the legend bar
    const bottomOffset = 62; // the height of the yAxis labels
    const seriesHeight = (HEIGHT - topOffset - bottomOffset) / visibleSeriesCount;
    const combinedSeriesHeight = seriesHeight * visibleCombinedCount;

    const pointInterval = areReadingsAveragedForDaily
      ? CONSUMPTION.DAY_MILIS
      : CONSUMPTION.HOUR_MILIS;
    const pointStart = readerRange?.start?.ts;

    const yAxisProps = {
      opposite: false,
      offset: 0,
      lineWidth: 1,
      resize: {
        enabled: true,
      },
      labels: {
        align: "right",
        x: -5,
        step: 1,
      },
      tickAmount: 3,
    };

    // -- Outdoor Temp
    series.push({
      id: "outdoor",
      type: "spline",
      name: t("text_outdoor_temp", { ns: "extendView" }),
      pointInterval,
      pointStart,
      color: theme.palette.graph.darkYellow,
      chartColor: theme.palette.graph.yellow,
      data: outdoorTempData,
      timeStamp,
      tooltip: {
        valueSuffix: "°C",
      },
      yAxis: 0,
      visible: visibleSeries.outdoor,
    });

    yAxis.push({
      ...yAxisProps,
      title: {
        text: `${t("text_outdoor_temp", { ns: "extendView" })} °C`,
      },
      visible: visibleSeries.outdoor,
      labels: {
        ...yAxisProps.labels,
        color: theme.palette.graph.darkYellow,
      },
      chartColor: theme.palette.graph.yellow,
      top: topOffset,
      height: seriesHeight,
    });

    // -- Flow
    series.push({
      id: "vol",
      name: t("text_flow", { ns: "extendView" }),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.graph.green,
      chartColor: theme.palette.graph.lightGreen,
      data: flow,
      timeStamp,
      tooltip: {
        valueSuffix: "m³/h",
      },
      yAxis: 1,
      visible: visibleSeries.vol,
    });

    if (visibleSeries.outdoor) topOffset += seriesHeight;
    const yAxisFlow = {
      ...yAxisProps,
      title: {
        text: `${t("text_flow", { ns: "extendView" })} <br> m³/h`,
      },
      visible: visibleSeries.vol,
      labels: {
        ...yAxisProps.labels,
        color: theme.palette.graph.green,
      },
      chartColor: theme.palette.graph.lightGreen,
      top: topOffset,
      height: seriesHeight,
    };

    if (flowLimiter) {
      yAxisFlow.plotLines = [
        {
          value: flowLimiter,
          width: 1,
        },
      ];
    }
    yAxis.push(yAxisFlow);

    // -- Heat Energy
    series.push({
      id: "heat",
      name: t("text_heat_energy", { ns: "extendView" }),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.secondary.main,
      chartColor: theme.palette.graph.lightOrange,
      data: heatEnergy,
      timeStamp,
      tooltip: {
        valueSuffix: "kWh/h",
      },
      yAxis: 2,
      visible: visibleSeries.heat,
    });

    if (visibleSeries.vol) topOffset += seriesHeight + 1;
    yAxis.push({
      ...yAxisProps,
      title: {
        text: `${t("text_heat_energy", { ns: "extendView" })} <br> kWh/h`,
      },
      visible: visibleSeries.heat,
      labels: {
        ...yAxisProps.labels,
        color: theme.palette.graph.orange,
      },
      chartColor: theme.palette.graph.lightOrange,
      top: topOffset,
      height: seriesHeight,
    });

    // -- Supply Temperature
    series.push({
      id: "st",
      name: t("text_supply_temperature", { ns: "extendView" }),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.graph.pink,
      chartColor: theme.palette.graph.lightPink,
      data: supplyTemp,
      timeStamp,
      tooltip: {
        valueSuffix: "°C",
      },
      yAxis: 3,
      visible: visibleSeries.st,
    });

    // -- Return Temperature
    series.push({
      id: "rt",
      name: t("text_return_temperature", { ns: "extendView" }),
      type: "spline",
      pointStart,
      pointInterval,
      color: theme.palette.graph.blue,
      chartColor: theme.palette.graph.lightblue,
      data: returnTemp,
      timeStamp,
      tooltip: {
        valueSuffix: "°C",
      },
      yAxis: 3,
      visible: visibleSeries.rt,
    });

    if (visibleSeries.heat) topOffset += seriesHeight;
    yAxis.push({
      ...yAxisProps,
      title: {
        text: `${t("text_temperature")} <br> °C`,
      },
      visible: visibleSeries.st || visibleSeries.rt,
      labels: {
        ...yAxisProps.labels,
        color: theme.palette.graph.pink,
      },
      chartColor: theme.palette.graph.lightPink,
      top: topOffset,
      height: combinedSeriesHeight,
    });

    return { series, yAxis };
  }, [
    areReadingsAveragedForDaily,
    flowLimiter,
    seriesInExtremes,
    readerRange,
    theme.palette,
    visibleSeries,
    visibleSeriesCount,
    visibleCombinedCount,
    t,
  ]);

  useEffect(() => {
    if (!chartRef.current?.chart) return;

    if (!isDataAvailable) {
      chartRef.current.chart.showLoading();
      return;
    }
    chartRef.current.chart.hideLoading();
  }, [isDataAvailable]);

  const filename = `${extremes?.start.year}-${extremes?.start.month} to ${extremes?.end.year}-${extremes?.end.month}`;

  const chartId = "meter";

  /* eslint-disable react/no-this-in-sfc */
  return (
    <GraphContainer
      title={t("text_meter_data", { ns: "extendView" })}
      hasData
      subTitles={
        <ChartToolbox
          values={meterDataToolbox}
          setValues={setMeterDataToolbox}
          disabled={!isDataAvailable}
          data-testid={`${chartId}-toolbox`}
        />
      }
      data-testid={`chart-${chartId}-container`}
    >
      <HighchartsReact
        ref={chartRef}
        highcharts={HighCharts}
        constructorType="stockChart"
        options={{
          chart: {
            id: chartId,
            height: `${HEIGHT}px`,
            width: chartWidth,
            panning: {
              enabled: false,
              type: "x",
            },
          },
          plotOptions: {
            series: {
              dataGrouping: {
                units: [
                  ["hour", [1, 2, 3, 4, 6, 8, 12]],
                  ["day", [1]],
                  ["month", [1, 3, 6]],
                  ["year", null],
                ],
              },
              events: {
                legendItemClick: legendItemClickHandler(setVisibleSeries),
              },
            },
          },
          rangeSelector: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          legend: {
            enabled: true,
            layout: "horizontal",
            useHTML: true,
            align: "left",
            verticalAlign: "top",
            y: 0,
            labelFormatter() {
              const { color, name, userOptions } = this;
              return `<div data-testid="${chartId}__legend__${userOptions.id}" style="border-bottom: 3px solid ${color}">${name}</div>`;
            },
            itemStyle: {
              ...theme.typography.body2,
            },
          },
          navigator: {
            enabled: false,
          },
          scrollbar: {
            enabled: false,
          },
          tooltip: {
            split: true,
            formatter() {
              const x = DateTime.fromMillis(this.points[0].x).toFormat(
                CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD_HH_MM
              );
              const k = [`<b>${x}</b>`];
              this.points.forEach((point) => {
                const unit = point.series.tooltipOptions.valueSuffix;
                const exactPointVal =
                  point.series.yData[point.series.xData.indexOf(point.x)] || point.y;
                const valY = formatNumberForLocale(exactPointVal);
                k.push(`<b> ${point.series.name} ${valY} ${unit}</b>`);
              });
              return k;
            },
          },
          yAxis: chartData.yAxis,
          xAxis: {
            type: "datetime",
            tickInterval: areReadingsAveragedForDaily
              ? CONSUMPTION.DAY_MILIS
              : CONSUMPTION.HOUR_MILIS,
            minRange: 1,
            gridLineWidth: meterDataToolbox.includes(CONSUMPTION.GRAPH_GRID) ? 1 : 0,
          },
          exporting: {
            enabled: true,
            buttons: {
              contextButton: {
                menuItems: [
                  ...HighCharts.getOptions().exporting.buttons.contextButton.menuItems.filter(
                    (item) => !["downloadCSV", "downloadXLSX", "viewData"].includes(item)
                  ),
                  {
                    text: "Download XLSX",
                    onclick() {
                      const rows = this.getDataRows() || [];
                      const xMin = this.xAxis[0].min;
                      const xMax = this.xAxis[0].max;
                      exportGraph(rows, xMin, xMax, "XLSX", filename);
                    },
                  },
                  {
                    text: "Download CSV",
                    onclick() {
                      const rows = this.getDataRows() || [];
                      const xMin = this.xAxis[0].min;
                      const xMax = this.xAxis[0].max;
                      exportGraph(rows, xMin, xMax, "CSV", filename);
                    },
                  },
                  {
                    text: `${isViewDataTable ? "Hide" : "View"} data table`,
                    onclick() {
                      if (this.isDataTableVisible) {
                        setIsViewDataTable(false);
                        this.hideData();
                      } else {
                        setIsViewDataTable(true);
                        this.viewData();
                      }
                    },
                  },
                ],
              },
            },
          },
          series: chartData.series,
          annotations: addAnnotations({
            series: chartData.series,
            options: meterDataToolbox,
          }),
        }}
      />
    </GraphContainer>
  );
}

export default withErrorBoundary(observer(MeterChartLines));
