import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import { useTheme } from "@mui/styles";
import { Skeleton, hexToRgb } from "@mui/material";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import useConsumption from "./state";
import * as CONSUMPTION from "./Constants";

require("highcharts/modules/boost")(Highcharts);

function ChartNavigator({ chartRef, width }) {
  const theme = useTheme();
  const consumption = useConsumption();
  const { areReadingsAveragedForDaily, fetchedRange, setExtremes, seriesData, isDataAvailable } =
    consumption;
  const startDateTs = fetchedRange.start.ts;
  const interval = areReadingsAveragedForDaily ? CONSUMPTION.DAY_MILIS : CONSUMPTION.HOUR_MILIS;

  if (!isDataAvailable) return <Skeleton variant="rectangular" height={30} />;

  const series = [
    {
      type: "spline",
      name: "",
      pointStart: startDateTs,
      pointInterval: interval,
      data: seriesData.original.map((row) => row.outdoor),
      lineWidth: 0,
      marker: {
        enabled: false,
      },
      states: {
        hover: {
          enabled: false,
        },
      },
      yAxis: 0,
    },
  ];

  return (
    <HighchartsReact
      ref={chartRef}
      highcharts={Highcharts}
      constructorType="stockChart"
      options={{
        chart: {
          id: "navigator",
          height: 29,
          width,
          spacing: [0, 0, 0, 0],
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.text.primary,
        },
        navigator: {
          enabled: true,
          xAxis: {
            labels: {
              style: {
                color: theme.palette.common.white,
              },
            },
          },
          height: 20,
          outlineColor: theme.palette.text.primary,
          maskFill: hexToRgb(`${theme.palette.graph.orange}80`),
        },
        scrollbar: {
          height: 10,
          trackBackgroundColor: theme.palette.primary.main,
          barBackgroundColor: theme.palette.graph.orange,
          rifleColor: theme.palette.text.primary,
          buttonBackgroundColor: theme.palette.common.white,
          buttonBorderWidth: 0,
          buttonArrowColor: theme.palette.primary.main,
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        title: {
          text: "",
        },
        rangeSelector: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        yAxis: {
          title: null,
          gridLineWidth: 0,
          visible: false,
          labels: {
            enabled: false,
          },
        },
        xAxis: {
          type: "datetime",
          tickInterval: interval,
          labels: {
            enabled: false,
          },
          tickLength: 0,
          lineWidth: 0,
          minRange: 1,
          events: {
            afterSetExtremes(e) {
              const { chart } = consumption.chartRef.current;

              let start = DateTime.fromMillis(e.min).set({
                minute: 0,
                second: 0,
                millisecond: 0,
              });
              let end = DateTime.fromMillis(e.max).set({
                minute: 0,
                second: 0,
                millisecond: 0,
              });

              if (areReadingsAveragedForDaily) {
                // Set hours to midnight on daily interval
                start = start.set({ hour: 0 });
                end = end.set({ hour: 0 });
              }

              if (start < fetchedRange.start) start = fetchedRange.start;

              // Update the store
              setExtremes(start, end);

              // Update the chart
              chart.xAxis[0].setExtremes(start.ts, end.ts, true, false);
            },
          },
        },
        series,
      }}
    />
  );
}

ChartNavigator.defaultProps = {
  chartRef: null,
  width: 0,
};

ChartNavigator.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  chartRef: PropTypes.shape({ current: PropTypes.object }),
  width: PropTypes.number,
};

export default observer(ChartNavigator);
