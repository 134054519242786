import { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const HEIGHT = 60;

const Accordion = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    position: "fixed",
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    "&&": {
      backgroundColor: theme.palette.primary.main,
      alignItems: "stretch",
      minHeight: HEIGHT,
      height: HEIGHT,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
      "&$expanded": {
        minHeight: HEIGHT,
      },
    },
  },
  content: {
    cursor: "default",
  },
  expanded: {},
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "0 !important",
    border: "0 !important",
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "46.5px !important",
    height: HEIGHT,
    "& > div": {
      boxShadow: "0 1px 0 0 rgba(0, 0, 0, 0.16) ",
      borderRight: `1px solid ${theme.palette.primary.dark}`,
      "&:first-child": {
        borderLeft: `1px solid ${theme.palette.primary.dark}`,
      },
      "&:last-child": {
        borderRight: 0,
        boxShadow: "1px -1px 5px 0 rgb(0 0 0 / 16%) ",
      },
    },
  },
}))(MuiAccordionDetails);

export default function UtfAccordion({ header, width, children, ...props }) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen((prev) => !prev);
  };
  return (
    <Accordion square expanded={isOpen} {...props} style={{ width }}>
      <AccordionSummary
        sx={{ width }}
        expandIcon={
          <ExpandMoreIcon
            onClick={toggleAccordion}
            style={{
              color: "white",
              pointerEvents: "auto",
              height: isOpen ? "100px" : "auto",
              marginTop: isOpen ? "-90px" : 0,
              transform: "rotate(180deg)",
            }}
          />
        }
      >
        {header}
      </AccordionSummary>
      <AccordionDetails sx={{ width }}>{children}</AccordionDetails>
    </Accordion>
  );
}

UtfAccordion.propTypes = {
  header: PropTypes.element.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  width: PropTypes.number.isRequired,
};
