import { observable, action, flow, makeObservable } from 'mobx';

class Store {
    current_blog_id = 0;
    featuredBlogs = [];
    news = [];

    nextBlog() {
        if (this.featuredBlogs.length > this.current_blog_id + 1) {
            this.current_blog_id = this.current_blog_id + 1
        } else {
            this.current_blog_id = 0
        }
    }

    prevBlog() {
        if (this.current_blog_id === 0) {
            this.current_blog_id = this.featuredBlogs.length - 1
        } else {
            this.current_blog_id = this.current_blog_id - 1;
        }
    }
    fetchData = flow(function* () {
        const blogs = ((yield this.parent.utfapi.getCmsBlogs()) || [])
            .sort((a, b) => (-(a['published']>b['published']) || +(a['published']<b['published'])));
        if (blogs) {
            this.featuredBlogs = blogs.filter(b => b.is_featured)
            this.news = blogs.filter(b => !b.is_featured)
            if (this.featuredBlogs.length > 0) {
                this.current_blog_id = 0
            }
            if (this.featuredBlogs.length > 4) {
                this.news = this.featuredBlogs.slice(-(this.featuredBlogs.length-4)).concat(this.news);
                this.featuredBlogs = this.featuredBlogs.slice(0, 4)

            }
        }
        yield true;
    })
    constructor(parent) {
        makeObservable(this, {
            current_blog_id: observable,
            featuredBlogs: observable,
            news: observable,
            nextBlog: action.bound,
            prevBlog: action.bound,
            selectCurrentBlog: action.bound
        });

        this.parent = parent;
    }

    selectCurrentBlog(index) {
        this.current_blog_id = index;
    }

}

export default Store;