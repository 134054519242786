/* See: https://utilifeed.atlassian.net/l/cp/7xp11Zd1 for further details on meta data names. */

import { DateTime } from "luxon";

import {
  BUILDING_BLOCK,
  BUILDING_ECONOMY,
  COLTYPE as COLTYPE_ORIG,
  CORE_BLOCK,
  CORE_MONTHLY_BLOCK,
  CORE_ROLLING_YEARLY_BLOCK,
  CORE_YEARLY_BLOCK,
  CUSTOMER_BLOCK,
  DISTRIBUTION_BLOCK,
  EP_PEAK_POWER_BLOCK,
  EPCORE_BLOCK,
  EPCORE_NORMALIZED_BLOCK,
  EPCORE_NORMALIZED_MONTHLY_BLOCK,
  INSTALL_ADDRESS_BLOCK,
  LOCATION_BLOCK,
  MEASURED_PEAK_POWER_BLOCK,
  METERING_LATEST_UPLOAD_BLOCK,
  PRICING_BLOCK,
  SUBSTATION_COUNT_BLOCK,
  AVG_PEAK_1H_BLOCK,
} from "./blocks";

export const METER_TYPE = "meter_type";

export const FLOW_REDUCTION_CONSTANT = 1.16;
export const COLTYPE = COLTYPE_ORIG;

export const COL_PARSERS = {
  [COLTYPE.str](k) {
    return k;
  },
  [COLTYPE.number](k) {
    const v = parseFloat(k);
    if (!Number.isNaN(v) && Number.isFinite(v)) {
      return v;
    }
    return undefined;
  },
  [COLTYPE.coord](d) {
    if (d) {
      const coords = d.split(",").map((k) => parseFloat(k));
      // This is to make sure that the coordinates are valid
      // since there are some discrepancies in the data
      if (coords.length === 2 && coords.every((k) => !Number.isNaN(k) && Number.isFinite(k))) {
        return coords;
      }
    }
    return null;
  },
  [COLTYPE.datetime_s](d) {
    if (d) {
      const v = DateTime.fromISO(d, { zone: "UTC+00:00" });
      return v.isValid ? v.toMillis() : null;
    }
    return null;
  },
  [COLTYPE.ts_s](d) {
    return d ? d * 1000 : null;
  },
  [COLTYPE.date](d) {
    if (d) {
      const v = DateTime.fromFormat(d, "yyyy-MM-dd HH:mm:ss", { zone: "UTC+00:00" });
      return v.isValid ? v : null;
    }
    return null;
  },
  [COLTYPE.datestr](d) {
    if (d) {
      const v = DateTime.fromFormat(d, "yyyy-MM-dd HH:mm:ss", { zone: "UTC+00:00" });
      return v.isValid ? v : null;
    }
    return null;
  },
  [COLTYPE.datestrn](d) {
    if (d) {
      const v = DateTime.fromISO(d, { zone: "UTC+00:00" });
      return v.isValid ? v : null;
    }
    return null;
  },
  [COLTYPE.timezone](d) {
    if (d) {
      return `UTC${d}`;
    }
    return null;
  },
  [COLTYPE.yearmonth](d) {
    if (d) {
      return d.split("-").map((k) => parseInt(k));
    }
    return null;
  },
  [COLTYPE.json](d) {
    if (d) {
      // Check if it is a object, otherwise parse
      return typeof d === "object" ? d : JSON.parse(d);
    }
    return null;
  },
  [COLTYPE.yearweek](d) {
    if (d) {
      const [y, w] = d.split("-").map((k) => parseInt(k));
      return DateTime.fromObject({ weekYear: y, weekNumber: w });
    }
    return null;
  },
};

export const T = "t";
export const TIME_WEATHER = "datetime";
export const CHART_MAPPER = {
  histogram: "bar",
  categorical: "scatter",
};

export const IDX_COL = "resource_name";
export const VALUETYPES = {
  normal: "epc_",
  measured: "",
};

export const VALUETYPES_OPTIONS = new Map([
  [VALUETYPES.normal, "Normal"],
  [VALUETYPES.measured, "Measured"],
]);

export const AVERAGETYPES = {
  flow_weighted: "flowweighted_avg",
  non_weighted: "unweighted_avg",
  power_weighted: "powerweighted_avg",
};

export const AVERAGETYPES_OPTIONS = new Map([
  [AVERAGETYPES.flow_weighted, "Flow Weighted"],
  [AVERAGETYPES.non_weighted, "Non Weighted"],
  [AVERAGETYPES.power_weighted, "Power Weighted"],
]);

export const MONTH_INT = {
  all: null,
  jan: "01",
  feb: "02",
  mar: "03",
  apr: "04",
  may: "05",
  jun: "06",
  jul: "07",
  aug: "08",
  sep: "09",
  oct: "10",
  nov: "11",
  dec: "12",
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const MONTH_LBL = {
  all: "All year",
  jan: "Jan",
  feb: "Feb",
  mar: "Mar",
  apr: "Apr",
  may: "May",
  jun: "Jun",
  jul: "Jul",
  aug: "Aug",
  sep: "Sep",
  oct: "Oct",
  nov: "Nov",
  dec: "Dec",
};

export const MONTH_CHOICES = [
  "all",
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

export const MONTH_OPTIONS = new Map(
  MONTH_CHOICES.map((month) => [MONTH_INT[month], MONTH_LBL[month]])
);

export const RESOURCE_OPTIONS = new Map([
  ["substation", "Substation"],
  ["cluster", "Cluster"],
]);

export const BLOCK = {
  core_monthly: CORE_MONTHLY_BLOCK,
  core_yearly: CORE_YEARLY_BLOCK,
  core: CORE_BLOCK,
  install_address: INSTALL_ADDRESS_BLOCK,
  epcore: EPCORE_BLOCK,
  epcore_normalized: EPCORE_NORMALIZED_BLOCK,
  epcore_normalized_monthly: EPCORE_NORMALIZED_MONTHLY_BLOCK,
  ep_peak_power: EP_PEAK_POWER_BLOCK,
  measured_peak_power: MEASURED_PEAK_POWER_BLOCK,
  location: LOCATION_BLOCK,
  avg_peak_1h: AVG_PEAK_1H_BLOCK,
  building: BUILDING_BLOCK,
  customer: CUSTOMER_BLOCK,
  economy: BUILDING_ECONOMY,
  pricing: PRICING_BLOCK,
  distribution: DISTRIBUTION_BLOCK,
  metering_latest_upload: METERING_LATEST_UPLOAD_BLOCK,
  substation_count: SUBSTATION_COUNT_BLOCK,
  core_rolling_yearly: CORE_ROLLING_YEARLY_BLOCK,
};

export const BLOCKS = [
  "core_monthly",
  "core_yearly",
  "install_address",
  "epcore",
  "ep_peak_power",
  "measured_peak_power",
  "location",
  "customer",
  "location",
  "pricing",
  "distribution",
];

export const SPLIT_TYPE = {
  categorical: "categorical",
  measurement: "measurement",
};

export const SPLIT_CATEGORY = {
  grid_area: {
    block: "distribution",
    column: "grid_area",
    label: "Grid Area",
  },
  segment: {
    block: "pricing",
    column: "segment",
    label: "Segment",
  },
  customer_name: {
    block: "customer",
    column: "name",
    label: "Customer Name",
  },
  tariff: {
    block: "pricing",
    column: "tariff_id",
    label: "Tariff Id",
  },
  price_area: {
    block: "pricing",
    column: "price_area",
    label: "PricingArea",
  },
  power_calc_method: {
    block: "pricing",
    column: "power_calc_method",
    label: "Power Calculation Method",
  },
};

export const PRICING_POWER_COSTUNIT = {
  subscribed_power: "subscribed_power",
  flow_limit: "flow_limit",
  wn_value: "wn_value",
};

export const PRICING_POWER_COSTUNIT_LABELS = {
  [PRICING_POWER_COSTUNIT.subscribed_power]: "kW",
  [PRICING_POWER_COSTUNIT.flow_limit]: "m³",
  [PRICING_POWER_COSTUNIT.wn_value]: "Wn",
};

export const PRICING_POWER_COSTUNITS_OPTIONS = Object.keys(PRICING_POWER_COSTUNIT).map((k) => ({
  value: k,
  label: PRICING_POWER_COSTUNIT_LABELS[k],
}));

export const PRICING_POWER_COMPONENT_TYPE = {
  measured: "measured",
  power_signature: "power",
  subscription: "subscription",
};

export const PRICING_POWER_COMPONENT_TYPE_LABELS = {
  [PRICING_POWER_COMPONENT_TYPE.measured]: "Measured",
  [PRICING_POWER_COMPONENT_TYPE.power_signature]: "Power signature",
  [PRICING_POWER_COMPONENT_TYPE.subscription]: "Subscription",
};

export const PRICING_POWER_PERIOD = {
  calendar_year: "calendar_year",
  twelve_month: "twelve_month",
  one_month: "one_month",
};

export const PRICING_POWER_PERIOD_LABELS = {
  [PRICING_POWER_PERIOD.calendar_year]: "Calendar year",
  [PRICING_POWER_PERIOD.twelve_month]: "Last 12 months",
  [PRICING_POWER_PERIOD.one_month]: "Present month",
};

export const DATETIME_FORMAT_ISO_SHORT = "yyyy-MM-dd'T'HH:mm";
export const DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS = "yyyy-MM-dd'T'HH:mm:'00'";
