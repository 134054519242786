import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from "mobx-react";
import Update from './Update';

const styles = theme => ({
    root: {
        boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
        backgroundColor: theme.palette.grey.blue4,
        flexGrow: "1",
        height: 0,
        minHeight: "300px",
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        overflowY: "auto"
    }
})

class UpdatesContainer extends Component {
    render() {
        const { rootStore: { dash }, classes } = this.props;

        return (
            <div className={classes.root}>
                <div style={{ maxHeight: 200 }}>
                    {dash.news.map(
                        update => <Update key={update.cms_id} data={update} />
                    )}
                </div>
            </div>
        )
    }
}
export default inject('rootStore')(withStyles(styles)(observer(UpdatesContainer)));