import { formatNumberForUnitMainLabel, isValue, } from '../../core/utils';

export default function FUnitValue(props) {
    if (isValue(props.num)) {
        const [num, exp, pre, unit] = formatNumberForUnitMainLabel(props.num, props.unit)
        return <span>{num}{exp ? (<><span> x10</span><sup>{exp}</sup></>) : null} {pre}{unit}</span>
    } else {
        return null
    }
}
