import React from "react";
import { reaction, observable, computed, action, flow, makeObservable } from "mobx";
import { observer, inject, disposeOnUnmount } from "mobx-react";
import Box from "@mui/material/Box";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import withTheme from "@mui/styles/withTheme";
import { withTranslation } from "react-i18next";

import GraphMetrics from "../analytics/GraphMetrics";
import SelectYear from "../ui/SelectYear";
import SelectMonth from "../ui/SelectMonth";
import { FILTER_HEIGHT } from "../../conf/ui_constants";
import SlowInput from "../ui/SlowInput";
import { prepareHistogramData } from "../graphs/hist";
import { isValue } from "../../core/utils";
import { SUBSTATION_BLOCK_TYPES, getValueFromBlock } from "../../conf/blocks";

class HistogramFilter extends React.Component {
  data_available = false;
  data_processing_done = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      data_available: observable,
      data_processing_done: observable,
      dataMin: computed,
      dataMax: computed,
      areMissingExcluded: computed,
      toggleScale: action.bound,
      afterChartCreated: action.bound,
      onChangeDataMax: action.bound,
      selectPointsByDrag: action.bound,
      unselectByClick: action.bound,
      onChangeDataMin: action.bound,
      missingAction: action.bound,
      series: computed,
    });

    this.block_data = {};
    this.chartData = { data: [] };

    this.processData = this.processData.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  get dataMin() {
    const { spec } = this.props;
    const fstate = spec.get("state");
    if (fstate.min) {
      return fstate.min;
    }
    if (this.data_processing_done) {
      return this.chartData.chartMin || 0;
    }
    return 0;
  }

  get dataMax() {
    // if graph loaded , get from graph.
    const { spec } = this.props;
    const fstate = spec.get("state");
    if (fstate.max) {
      return fstate.max;
    }
    if (this.data_processing_done) {
      return this.chartData.chartMax || 0;
    }
    return 0;
  }

  get areMissingExcluded() {
    const { spec } = this.props;
    const fstate = spec.get("state");
    return fstate.exclude
      ? ["text_missing", "text_missing_tooltip_desc"]
      : ["text_normal", "text_normal_tooltip_desc"];
  }

  toggleScale() {
    const { spec } = this.props;
    const fstate = spec.get("state");
    if (fstate.scale === "linear") {
      fstate.scale = "logarithmic";
    } else {
      fstate.scale = "linear";
    }
  }

  afterChartCreated(chart) {
    this.internalChart = chart;
  }

  missingAction() {
    const {
      spec,
      rootStore: { filters },
    } = this.props;
    filters.toggleMissing(spec.get("param"));
  }

  fetchData = flow(function* () {
    const {
      spec,
      rootStore: { networks, newapi },
    } = this.props;
    this.block_data = {};
    const fstate = spec.get("state");
    const year = fstate.year || networks.lpYear.year;
    const month = fstate.month || null;
    this.data_available = false;
    const blockSpec = SUBSTATION_BLOCK_TYPES[spec.get("block")];
    const block_name = blockSpec.to_block_name({ year, month });
    const result = yield newapi.getInfoBlocksV4({
      resource_type: "network_substations",
      resource_id: networks.current_network.uid,
      block_names: [block_name],
    });
    if (result !== null) {
      this.block_data = result;
      this.data_available = true;
    }
  });

  get series() {
    if (this.data_processing_done) {
      return [
        {
          data: this.chartData.data,
        },
      ];
    }
    return [
      {
        data: [],
      },
    ];
  }

  processData = flow(function* () {
    const {
      spec,
      rootStore: { networks },
    } = this.props;
    const fstate = spec.get("state");
    const year = fstate.year || networks.lpYear.year;
    const month = fstate.month || null;
    this.data_processing_done = false;

    const blockSpec = SUBSTATION_BLOCK_TYPES[spec.get("block")];
    const block_name = blockSpec.to_block_name({ year, month });
    const column = spec.get("param");
    const filteredRows = [];
    for (const sub of networks.active_substations.keys()) {
      const value = getValueFromBlock(this.block_data, block_name, sub, column);
      if (isValue(value)) {
        filteredRows.push([sub, value]);
      }
    }
    this.chartData = prepareHistogramData({
      rows: filteredRows,
      xcolIdx: 1,
      scale: fstate.scale,
      xAxisText: `${spec.get("param")} `,
      yUnit: "",
      bins: 75,
    });
    this.data_processing_done = true;
    yield true;
  });

  onActiveButtonChange = (event) => {
    const {
      spec,
      rootStore: { filters },
    } = this.props;
    filters.updFilterActive(spec.get("param"), event.target.checked);
    this.internalChart = null;
  };

  unselectByClick() {
    const {
      spec,
      rootStore: { filters },
    } = this.props;
    filters.releaseBounds(spec.get("param"));
  }

  selectPointsByDrag(e) {
    const {
      spec,
      rootStore: { filters },
    } = this.props;
    filters.selectBounds(spec.get("param"), e.xAxis[0].min, e.xAxis[0].max);
    return false; // Don't zoom
  }

  onChangeDataMin(value) {
    const {
      spec,
      rootStore: { filters },
    } = this.props;
    filters.setMin(spec.get("param"), parseFloat(value));
  }

  onChangeDataMax(value) {
    const {
      spec,
      rootStore: { filters },
    } = this.props;
    filters.setMax(spec.get("param"), parseFloat(value));
  }

  componentDidMount() {
    const {
      spec,
      rootStore: { networks },
    } = this.props;

    disposeOnUnmount(
      this,
      reaction(
        () => {
          const fstate = spec.get("state");
          return [fstate.year, fstate.month, networks.current_network];
        },
        flow(function* () {
          yield this.fetchData();
        }).bind(this),
        {
          fireImmediately: true,
        }
      )
    );

    disposeOnUnmount(
      this,
      reaction(
        () => {
          const fstate = spec.get("state");
          return [this.data_available, fstate.scale, networks.active_substations];
        },
        flow(
          function* () {
            if (this.data_available) {
              yield this.processData();
            }
          }.bind(this)
        ),
        {
          fireImmediately: false,
        }
      )
    );
  }

  render() {
    const { t, spec } = this.props;
    const fstate = spec.get("state");
    return (
      <Box
        position="relative"
        borderBottom="1px solid #ddd"
        display="flex"
        flexDirection="column"
        bgcolor="white"
        py={2}
      >
        <Box
          display="flex"
          flexDirection="column"
          px={2}
          height="100%"
          mb={1}
          alignItems="flex-start"
          justifyContent="start"
        >
          <Box display="flex" flexGrow={1} flexDirection="row" alignItems="flex-start">
            <Typography variant="button">{t(spec.get("label"), { ns: "filters" })}</Typography>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end" mt={1}>
            <ButtonGroup color="primary" size="small" style={{ marginBottom: 8 }}>
              <Tooltip
                title={
                  fstate.scale === "linear"
                    ? t("text_show_values_on_logarithmic", { ns: "filters" })
                    : t("text_show_value_on_linear", { ns: "filters" })
                }
              >
                <Button onClick={this.toggleScale}>
                  {fstate.scale === "linear"
                    ? t("text_log", { ns: "filters" })
                    : t("text_linear", { ns: "filters" })}
                </Button>
              </Tooltip>
              <Tooltip title={t("text_reset_filters_to_initial_state", { ns: "filters" })}>
                <Button onClick={this.unselectByClick}>
                  {t("action_reset", { ns: "_action" })}
                </Button>
              </Tooltip>
              <Tooltip title={t(this.areMissingExcluded[1], { ns: "filters" })}>
                <Button onClick={this.missingAction}>
                  {t(this.areMissingExcluded[0], { ns: "filters" })}
                </Button>
              </Tooltip>
              <SelectYear store={fstate} name="year" />
              <SelectMonth store={fstate} name="month" />
            </ButtonGroup>
          </Box>
        </Box>
        <Box height={`${FILTER_HEIGHT}`} px={2}>
          <GraphMetrics
            type="column"
            boost={false}
            xAxis={{ type: fstate.scale }}
            yAxis={{ title: "" }}
            exporting={{
              enabled: false,
            }}
            plotOptions={{
              column: {
                pointWidth: 2,
              },
              pointWidth: 2,
            }}
            seriesOptions={{
              animation: false,
            }}
            chartOptions={{
              height: FILTER_HEIGHT,
              events: {
                selection: this.selectPointsByDrag,
              },
              // reflow: true
            }}
            callback={this.afterChartCreated}
            chartData={this.series}
            legend={{ enabled: false }}
            tooltip={{
              enabled: false,
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" px={2} mt={2}>
          <SlowInput
            value={this.dataMin == null ? "" : this.dataMin}
            wait={1000}
            Child={TextField}
            label={t("text_minimum")}
            size="small"
            onChange={this.onChangeDataMin}
            variant="outlined"
            InputProps={{ style: { fontSize: 13 } }}
            type="number"
          />
          <div style={{ width: 8 }} />
          <SlowInput
            value={this.dataMax || ""}
            wait={1000}
            Child={TextField}
            onChange={this.onChangeDataMax}
            variant="outlined"
            label={t("text_maximum")}
            InputProps={{ style: { fontSize: 13 } }}
            size="small"
            type="number"
          />
        </Box>
      </Box>
    );
  }
}

export default withTranslation(["filters", "_action"])(
  inject("rootStore")(withTheme(observer(HistogramFilter)))
);
