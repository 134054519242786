/*
  Copyright (C) 2022 Utilifeed AB. All Rights Reserved.

  core utilities not tied to framework to be used across all apps.
  Initial Author: Arun Tigeraniya <tigeraniya@gmail.com>
 */
import { runInAction } from "mobx";

export function isValue(value) {
  return (
    !Number.isNaN(Number(value)) &&
    value !== "" &&
    value !== null &&
    value !== Infinity &&
    value !== -Infinity
  );
}

export function range(start, stop, step) {
  const result = [];
  if (typeof stop === "undefined") {
    stop = start;
    start = 0;
  }
  if (typeof step === "undefined") {
    step = 1;
  }
  if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
    return [];
  }
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }
  return result;
}

/**
 * Formats given number based on locale and amount of digits.
 *
 * - Space should be used as a 1000-separator;
 *      1 475 for 1475,123123
 *      1 234 567 890 for 1234567890,111
 *
 * - All numerical input with 2 or less digits before the decimal separator should be rounded to 3 non-zero digits;
 *      54,1 for 54,08
 *      0,000145 for 0,000145123212
 *      100 for 99,99
 *      99,9 for 99,94
 *
 * - All numercal input with 3 or more digits before the decimal separator should show zero decimal;
 *      142 130 for 142129,5876
 *      -679 for -678,6842
 *
 * - Invalid and/or non-numeric data (if {@link isValue(number)} falsy) returns nodata argument as output.
 *
 * @param {number} number
 * @param {string} [locale="sv-SE"]
 * @param {string} [nodata=""]
 * @param {boolean} fractionalDigits : max digits after decimal allowed
 * @return {string} formatted string representation of the number argument or @param nodata as fallback
 */
export const formatNumberForLocale = (
  number,
  locale = "sv-SE",
  nodata = "",
  fractionalDigits = null
) => {
  if (isValue(number)) {
    const num = Math.abs(number);
    if (num > 99.95) {
      return number.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionalDigits !== null ? fractionalDigits : 0,
      });
    }

    if (num > 9) {
      return number.toLocaleString(locale, {
        minimumFractionDigits: fractionalDigits !== null ? fractionalDigits : 1,
        maximumFractionDigits: fractionalDigits !== null ? fractionalDigits : 1,
      });
    }

    if (num >= 1) {
      return number.toLocaleString(locale, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fractionalDigits !== null ? fractionalDigits : 2,
      });
    }

    return number.toLocaleString(locale, {
      maximumSignificantDigits: 3,
    });
  }
  return nodata;
};

export function toBlkMonth(month) {
  return parseInt(month).toString().padStart(2, "0");
}

export function formatDateAPI(date) {
  /* as per spec api dates should be in utc time */
  return date.setZone("UTC+00:00").toFormat("yyyy-MM-dd'T'HH:mm:ss");
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const SI_PREFIXES_REV = {
  [-12]: "p",
  [-9]: "n",
  [-6]: "μ",
  [-3]: "m",
  0: "",
  3: "k",
  6: "M",
  9: "G",
  12: "T",
  15: "P",
};

const SI_PREFIXES = {
  p: -12,
  n: -9,
  μ: -6,
  m: -3,
  "": 0,
  k: 3,
  M: 6,
  G: 9,
  T: 12,
  P: 15,
};

const CURRENCY_PREFIX = {
  B: 9,
  M: 6,
  T: 3,
  "": 0,
};

const CURRENCY_REV_PREFIX = {
  9: "B",
  6: "M",
  3: "T",
  0: "",
};

const UNIT_TO_SI_PREFIX = {
  kWh: ["k", "Wh", "si", 3],
  kW: ["k", "W", "si", 3],
  W: ["", "W", "si", 3],
  "m³": ["", "m³", "e", 3],
  "m³/h": ["", "m³/h", "e", 3],
  "kWh/h": ["k", "Wh/h", "si", 3],
  "°C": ["", "°C", "t", ""],
  "%": ["", "%", "e", 3],
  SEK: ["", "SEK", "c", 3],
  "SEK/year": ["", "SEK/year", "c", 3],
  "SEK/kWh": ["k", "SEK/Wh", "c/si", 3],
  "": ["", "", "n", 3],
  h: ["", "h", ""],
};

export function formatNumberForUnitMainLabel(inum, iunit) {
  if (!Object.keys(UNIT_TO_SI_PREFIX).includes(iunit)) {
    // [formattedValue, formattedExp, formattedPrefix, formattedUnit]
    return [inum, "", "", iunit];
  }
  let [prefix, sunit, strategy, precision] = UNIT_TO_SI_PREFIX[iunit];
  let cnum = Math.abs(inum);
  const sign = inum >= 0 ? 1 : -1;
  let exp = 0;
  if (isValue(inum)) {
    if (strategy === "si") {
      while (cnum > 1000) {
        cnum /= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] + 3];
      }
      while (cnum < 1 && cnum > 0) {
        cnum *= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] - 3];
      }
    }
    if (strategy === "c/si") {
      while (cnum > 1000) {
        cnum /= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] - 3];
      }
      while (cnum < 1 && cnum > 0) {
        cnum *= 1000;
        prefix = SI_PREFIXES_REV[SI_PREFIXES[prefix] + 3];
      }
      prefix = `${iunit.split("/")[0]}/${prefix}`;
    }
    if (strategy === "e") {
      if (cnum > 1000) {
        while (cnum > 1000) {
          cnum /= 1000;
          exp += 3;
        }
      } else if (cnum < 0.1 && cnum !== 0) {
        while (cnum < 0.1) {
          cnum *= 1000;
          exp -= 3;
        }
      }
    }
    
    if (strategy === "c") {
      while (cnum >= 1000) {
        cnum /= 1000;
        prefix = CURRENCY_REV_PREFIX[CURRENCY_PREFIX[prefix] + 3];
      }
    }
    switch (strategy) {
      case "t":
        //Temperature has no other special calculations, it should just be rounded to a single decimal.
        return [
          parseFloat((cnum * sign).toPrecision()).toLocaleString("sv-SE", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1,
          }),
          exp,
          prefix,
          sunit,
        ];
      case "n":
        return [cnum.toLocaleString("sv-SE"), exp, prefix, sunit];
      default:
        return [
          (sign * parseFloat(cnum.toPrecision(precision))).toLocaleString("sv-SE"),
          exp,
          prefix,
          sunit,
        ];
    }
  } else {
    return ["", 0, "", iunit];
  }
}

/*
  coerces a value/unit to main unit/exponent
*/
export function fmtRef(inum, iunit, exp, prefix) {
  const [iprefix, sunit, strategy, precision] = UNIT_TO_SI_PREFIX[iunit];
  let cnum = inum;
  if (strategy === "e" || strategy === "si" || strategy === "t") {
    cnum /= 10 ** (SI_PREFIXES[prefix] - SI_PREFIXES[iprefix]);
    cnum /= 10 ** exp;
  }
  if (strategy === "c") {
    cnum /= 10 ** (CURRENCY_PREFIX[prefix] - CURRENCY_PREFIX[iprefix]);
  }
  return [parseFloat(cnum.toPrecision(precision)), exp, prefix, sunit];
}

export function sortRows(rows, property, direction, deviation = false, includeNulls = false) {
  /* rows format: should be array of json */
  if (!includeNulls) {
    rows = rows.filter((r) => isValue(r[property]));
  }
  return rows.sort((a, b) => {
    let x = a[property];
    let y = b[property];
    if (x === y) {
      return 0;
    }
    if (!isValue(x)) {
      return direction === "asc" ? -1 : 1;
    }
    if (!isValue(y)) {
      return direction === "asc" ? 1 : -1;
    }
    if (deviation) {
      x = Math.abs(x);
      y = Math.abs(y);
    }
    if (direction === "asc") {
      return x < y ? -1 : 1;
    }
    return x < y ? 1 : -1;
  });
}

/**
 * Sort object by given property.
 * Relays on Intl.Collator.compare in order to generate compareFunction.
 *
 * @see https://mzl.la/3jDNHZS
 *
 * @param {*} property Object property for sort comparision
 * @param {string} [direction="asc"] Direction
 * @param {string} [type="number"]
 * @param {string} [locale="sv"]
 * @return {function} compareFunction to pass Array.sort method
 */
export function localeObjectSort(property, direction = "asc", type = "number", locale = "sv") {
  const ascending = direction === "asc";
  const options = {
    numeric: type === "number",
  };
  const cmpf = new Intl.Collator(locale, options).compare;

  function compareFunction(a, b) {
    const x = a[property];
    const y = b[property];
    if (x === y) {
      return 0;
    }

    return ascending ? cmpf(x, y) : cmpf(y, x);
  }

  return compareFunction;
}

export function have_blocks(blks, blocknames) {
  if (blks) {
    for (const blk of blocknames) {
      if (!blks.hasOwnProperty(blk)) {
        return false;
      }
    }
    return true;
  }
  return false;
}

export function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction(...args) {
    const context = this;

    const later = function laterFn() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

function grn(c) {
  const r = (Math.random() * 16) | 0;
  const v = c === "x" ? r : (r & 0x3) | 0x8;
  return v.toString(16);
}

export function randstring(len) {
  return range(0, len)
    .map((i) => grn(i))
    .join("");
}

export function jsonEquals(x, y) {
  // if both are function
  if (x instanceof Function) {
    if (y instanceof Function) {
      return x.toString() === y.toString();
    }
    return false;
  }
  if (x === null || x === undefined || y === null || y === undefined) {
    return x === y;
  }
  if (x === y || x.valueOf() === y.valueOf()) {
    return true;
  }

  // if one of them is date, they must had equal valueOf
  if (x instanceof Date) {
    return false;
  }
  if (y instanceof Date) {
    return false;
  }

  // if they are not function or strictly equal, they both need to be Objects
  if (!(x instanceof Object)) {
    return false;
  }
  if (!(y instanceof Object)) {
    return false;
  }

  const p = Object.keys(x);
  return Object.keys(y).every((i) => p.indexOf(i) !== -1)
    ? p.every((i) => jsonEquals(x[i], y[i]))
    : false;
}

export function affectEvent(object, attr) {
  return function affectEventAction() {
    runInAction(() => {
      object[attr] = object[attr] < 1000 ? (object[attr] += 1) : 1;
    });
  };
}

export function downloadFile(exportObject, filename) {
  const contentType = "application/json;charset=utf-8;";
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(exportObject)))], {
      type: contentType,
    });
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    a.download = filename;
    a.href = `data:${contentType},${encodeURIComponent(JSON.stringify(exportObject))}`;
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

export function export_current_config(pricingStore) {
  const cfg = {};
  const pcfg = pricingStore.current_conf;
  cfg.name = pcfg.name;
  cfg.models = pcfg.price_models.map((mdl) => {
    const mdlcmp = mdl.components.map((c) => c.id);
    const mdlcgs = mdl.customer_groups.map((cg) => cg.id);
    return { id: mdl.id, name: mdl.name, components: mdlcmp, groups: mdlcgs };
  });
  cfg.components = pricingStore.pricing_components.map((cg) => ({
    id: cg.id,
    name: cg.name,
    parameters: cg.parameters,
    type: cg.type,
  }));
  cfg.groups = pricingStore.customer_groups.map((cg) => ({
    id: cg.id,
    condition: cg.condition,
    name: cg.name,
    path: cg.path,
    source: cg.source,
    split: cg.split,
  }));
  return cfg;
}

function default_map(fns) {
  const r = {};
  for (const fname of fns) {
    r[fname] = [];
  }
  return r;
}

function default_agg(array) {
  const tot_sum = array.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return tot_sum / array.length;
}

export function tsderive({ reader, start, end, delta, fns }) {
  const r = {};
  for (const [columnName] of fns) {
    r[columnName] = [];
  }

  for (let ts = start; ts < end; ts += delta) {
    fns.forEach(([columnName, fn]) => {
      fn(reader(ts), r[columnName]);
    });
  }

  const result = [];
  const columns = Object.keys(r);
  const max = r[columns[0]].length;

  for (let index = 0; index < max; index++) {
    const row = {};
    columns.forEach((columnName) => {
      row[columnName] = r[columnName][index];
    });
    result.push(row);
  }

  return result;
}

export function deriveByArray({ reader, arr, fns, mapper = default_map }) {
  const r = mapper(fns.map((f) => f[0]));
  for (const idx of arr) {
    for (const [fname, fn] of fns) {
      fn(reader(idx), r[fname]);
    }
  }
  return r;
}

export function tsavgderive({
  reader,
  start = 0,
  end,
  delta = 1,
  avgdelta,
  fns,
  agg = default_agg,
  mapper = default_map,
}) {
  const fnames = fns.map((f) => f[0]);
  const r = mapper(fnames);
  let cavgarr = default_map(fnames);
  let cavgcut = start + avgdelta;

  for (let i = start; i < end; i += delta) {
    const v = reader(i);
    for (const [fname, fn] of fns) {
      fn(v, cavgarr[fname]);
    }
    if (i >= cavgcut - delta) {
      cavgcut += avgdelta;
      agg(cavgarr, fnames, r);
      cavgarr = default_map(fnames);
    }
  }
  return r;
}

export function printObject(object) {
  console.log(JSON.parse(JSON.stringify(object)));
}

/**
 * Returns an array with arrays of the given size.
 *
 * Example;
 * // Split in group of 3 items
 * var result = chunkArray([1,2,3,4,5,6,7,8], 3);
 * // Returns : [ [1,2,3] , [4,5,6] ,[7,8] ]
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
export function chunkArray(myArray, chunk_size) {
  const results = [];

  while (myArray.length) {
    results.push(myArray.splice(0, chunk_size));
  }

  return results;
}

export function valid_coords(ds, prop) {
  return (
    ds &&
    ds.hasOwnProperty(prop) &&
    ds[prop] !== undefined &&
    ds[prop] !== null &&
    ds[prop].length === 2 &&
    isValue(ds[prop][0]) &&
    isValue(ds[prop][1])
  );
}

/**
 * Returns an array with sum of arrays at the length of the longest array.
 *
 * Example;
 * sum 2 arrays
 * var result = sumArrays([1,1,1,1,1], [1,2,1,1]);
 * // Returns : [2,3,2,2,1]
 *
 * @param arrays {Array} Arrays to sum
 */
export function sumArrays(arrays) {
  if (!Array.isArray(arrays) || arrays?.length === 0) {
    return [];
  }
  let longest_array = 0;
  arrays.forEach((arr) => {
    longest_array = arr.length > longest_array ? arr.length : longest_array;
  });
  let result = Array.from({ length: longest_array });
  result = result.map((_, i) => arrays.map((xs) => xs[i] || 0));
  if (result.length > 0 && result[0].length > 0) {
    result = result.map((i) => i.reduce((sum, x) => sum + x, 0));
  }
  return result;
}

/**
 * Returns an array with minimum and maximum values for the y axis
 * for lining up graphs with multiple data sets at 0.
 *
 * Scaled minimums for 3 arrays of minimum and maximum values
 * var result = scaleMinimums([0,10], [1, 50], [-2, 5]]);
 * // Returns: [{min: -4, max: 10}, {min: -20, max: 50}, {min: -2, max: 5}]
 *
 * @param arrays {Array[Array]} Arrays of minimum and maximum values for each data set.
 */
export function scaleMinimums(arrays) {
  let minRatio = 0;
  arrays.forEach((s) => {
    s[0] = typeof s[0] === "number" && s[0] < 0 ? s[0] : 0;
    s[1] = typeof s[1] === "number" && s[1] > 0 ? s[1] : 0;
    if (!s[0] <= 0 && s[1] !== s[0]) {
      const ratio = (0 - s[0]) / (s[1] - s[0]);
      minRatio = ratio > minRatio ? ratio : minRatio;
    }
  });
  return arrays.map((s) => {
    if (minRatio !== 0 && minRatio !== 1) {
      const min = (minRatio / (minRatio - 1)) * (s[1] || 0);
      return { min, max: s[1] };
    }
    return { min: 0, max: s[1] };
  });
}

/* deep equality check for arrays */
function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

/* helper function to fire reaction only in event of values change, not value assign */
export function valueChangeReact(init, fn, debug = false) {
  let oldValue = init;
  let lastOut = false;
  return () => {
    const newVal = fn();
    if (debug) {
      console.log("value change", oldValue, newVal);
    }
    if (!arrayEquals(newVal, oldValue)) {
      oldValue = newVal;
      lastOut = !lastOut;
    }
    return lastOut;
  };
}

export function week_string(dt) {
  if (dt && dt.isValid) {
    return `w.${dt.weekNumber} (${dt.startOf("week").toFormat("MMM dd")} - ${dt
      .endOf("week")
      .toFormat("MMM dd")})`;
  }
  return "";
}

export function have_cols(row, columnNames) {
  for (const colName of columnNames) {
    if (!isValue(row[colName])) {
      return false;
    }
  }
  return true;
}

/**
 * convert a number from 0.00 to 0_00 for csv file naming
 * @param number
 * @returns {string}
 */
export function to_csv_filename(number) {
  return number.toString().replace(".", "_");
}

/**
 * calculate sum taking care of divide by zero and edge cases
 * @param sum
 * @param over
 * @returns {null|number}
 */
export function calcAvg(sum, over) {
  if (over === 0 || !isValue(sum) || !isValue(over)) {
    return null;
  }
  return sum / over;
}

/**
 * Omits array of keys from the object
 *
 * @param keys {Array}
 * @param obj {Object}
 * @returns {null|object} the object without keys
 */
export function omit(keys, obj) {
  return keys.reduce((a, e) => {
    const { [e]: omitted, ...rest } = a || { [e]: "" };
    return rest;
  }, obj);
}

/**
 * Replaces all undefined values inside a single dimensional or nested array with null
 *
 * Useful with Highcharts since Highcharts connects undefined values
 *
 * @param {*} originalArray
 * @returns an array with null as undefined
 */
export function replaceUndefinedWithNulls(originalArray) {
  return (originalArray || []).map((a) =>
    Array.isArray(a) ? replaceUndefinedWithNulls(a) : a === undefined ? null : a
  );
}


/**
 * Checks if the given parameter is a Number
 *
 * @export
 * @param {*} number
 * @return {*} boolean
 */
export function numbersOnly(number){return !Number.isNaN(parseFloat(number)) && Number.isFinite(number)};
