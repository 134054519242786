import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { runInAction } from "mobx";

import useStores from "../hooks/useStores";

function NotFoundPage() {
  const {
    rootStore: { routerStore },
  } = useStores();

  const handleClick = () => {
    runInAction(() => {
      routerStore.goTo("dash");
    });
  };

  return (
    <Box display="flex" height="100%" flexGrow={1} justifyContent="center" alignItems="center">
      <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
        <Typography variant="h1" color="secondary">
          404
        </Typography>
        <Typography variant="h4" color="secondary">
          NOT FOUND
        </Typography>
        <Box mt={4}>
          <Button onClick={handleClick} variant="contained" color="primary">
            BACK TO HOME
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default NotFoundPage;
