import { Component } from "react";
import { inject, observer } from "mobx-react";
import { Box } from "@mui/material";
import { withTranslation } from "react-i18next";

import PageWrapper from "../../components/app/PageWrapper";
import { HeadingBar } from "../../shared_components/ui/layout";

import UpdatesContainer from "./UpdatesContainer";
import FeaturedBlogCarousel from "./FeaturedBlogCarousel";

class DashPage extends Component {
  async componentDidMount() {
    const {
      rootStore: { dash },
    } = this.props;
    await dash.fetchData();
  }

  render() {
    const { t, ...restProps } = this.props;
    return (
      <PageWrapper {...restProps}>
        <Box display="flex" flexDirection="row" mt={6} mx={4} mb={6} height="100%" flexGrow={0}>
          <Box display="flex" flexDirection="column" flexGrow={1} width="60%">
            <HeadingBar title={t("text_feature", { ns: "home" })} grow="noGrow" />
            <FeaturedBlogCarousel />
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexShrink: 0,
              flexGrow: 0,
              flexBasis: "8px",
            }}
          />
          <Box display="flex" flexGrow={1} minHeight={0} flexDirection="column" width="30%">
            <HeadingBar title={t("text_news", { ns: "home" })} grow="noGrow" />
            <UpdatesContainer />
          </Box>
        </Box>
      </PageWrapper>
    );
  }
}

export default inject("rootStore")(observer(withTranslation(["home"])(DashPage)));
