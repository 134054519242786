import useStores from "./useStores";

export default function useConsumptionStores() {
  const { networks, sub, ui, newapi, preferences } = useStores();
  return {
    networks,
    sub,
    ui,
    newapi,
    preferences,
  };
}
