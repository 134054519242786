import { formatNumberForLocale, numbersOnly } from "../../../../core/utils";

export const AVERAGE = "average";
export const MIN = "min";
export const MAX = "max";

const getAverageValues = (values) => values.reduce((a, b) => a + b, 0) / values.length || 0;

const addShapeLine = ({ color, yAxis, value }) => ({
  type: "path",
  stroke: color,
  dashStyle: "LongDash",
  points: [
    function annotationPointStart({ chart }) {
      return {
        x: chart.xAxis[0].min,
        y: value,
        xAxis: 0,
        yAxis,
      };
    },
    function annotationPointEnd({ chart }) {
      return {
        x: chart.xAxis[0].max,
        y: value,
        xAxis: 0,
        yAxis,
      };
    },
  ],
});

const addLabel = ({ idx, label, yPos, valueSuffix, value, yAxis }) => ({
  y: yPos,
  x: 30,
  padding: 4,
  shape: "rect",
  useHTML: true,
  formatter() {
    const { chart } = this.series;
    const chartId = chart.userOptions.chart.id;
    const seriesId = chart.series[idx].userOptions.id;
    return `<div data-testid="chart-${chartId}_series-${seriesId}_label-${label}">${label}: ${formatNumberForLocale(
      value || this.y
    )} ${valueSuffix}</div>`;
  },
  style: {
    color: "white",
    fontSize: "11px",
    fontFamily: "Roboto",
  },
  point({ chart }) {
    return {
      x: chart.xAxis[0].min,
      y: value,
      xAxis: 0,
      yAxis,
    };
  },
});

export function addAnnotations({ series, options }) {
  if (options.length === 0) return [];

  // Create "timestamp" to "value" array of series
  const rows = [];
  try {
    series.forEach((serie, seriesIndex) => {
      if (!serie.timeStamp) return;

      if (!rows[seriesIndex]) rows[seriesIndex] = [];

      const isObject = serie.data[0] !== null && typeof serie.data[0] === "object";
      serie.timeStamp.forEach((x, xIndex) => {
        let y = serie.data[xIndex];
        if (isObject) {
          y = y?.y;
        }

        if (!numbersOnly(y)) return;

        rows[seriesIndex].push(y);
      });
    });
  } catch (e) {
    console.error(e);
    throw Error("Cannot generate timeseries data for calculations!");
  }

  const annotations = [];

  const defaultAnnotation = (idx) => ({
    draggable: "x",
    labelOptions: {
      allowOverlap: true,
      backgroundColor: series[idx].color,
      opacity: 1,
      borderColor: "transparent",
    },
  });

  rows.forEach((yAxisData, idx) => {
    const { tooltip, yAxis } = series[idx];
    const { valueSuffix } = tooltip;
    if (yAxisData?.length <= 0) return;

    options.forEach((option) => {
      let value = 0;
      switch (option) {
        case MIN:
          value = Math.min.apply(null, yAxisData);
          annotations.push({
            ...defaultAnnotation(idx),
            shapes: [
              addShapeLine({
                color: series[idx].chartColor,
                yAxis,
                value,
              }), //* min line
            ],
            labels: [
              addLabel({
                idx,
                type: MIN,
                label: "Min",
                yPos: 22,
                valueSuffix,
                value,
                yAxis,
              }), //* min value
            ],
          });
          break;
        case MAX:
          value = Math.max.apply(null, yAxisData);
          annotations.push({
            ...defaultAnnotation(idx),
            shapes: [
              addShapeLine({
                color: series[idx].chartColor,
                yAxis,
                value,
              }), //* max line
            ],
            labels: [
              addLabel({
                idx,
                type: MAX,
                label: "Max",
                yPos: -1,
                valueSuffix,
                value,
                yAxis,
              }), //* max value
            ],
          });
          break;
        case AVERAGE:
          value = getAverageValues(yAxisData);
          annotations.push({
            ...defaultAnnotation(idx),
            shapes: [
              addShapeLine({
                color: series[idx].chartColor,
                yAxis,
                value,
              }), //* avg line
            ],
            labels: [
              addLabel({
                idx,
                type: AVERAGE,
                label: "Avg",
                yPos: 9,
                valueSuffix,
                value,
                yAxis,
              }), //* avg value
            ],
          });
          break;
        default:
          break;
      }
    });
  });

  return annotations;
}
