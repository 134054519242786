import { observer } from "mobx-react";
import { Box, InputBase } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList } from "react-window";
import { useTranslation } from "react-i18next";

import SearchIcon from "../../../icons/Search";
import { MAX_MENU_WIDTH } from "../../../conf/ui_constants";
import { CLUSTER } from "../constant";

import renderRow from "./renderRow";
import useSubExplore from "./useSubExplore";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    height: "100%",
    backgroundColor: theme.palette.grey.white,
  },
  searchBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    height: "112px",
    backgroundColor: theme.palette.grey.blue2,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey.white,
    height: "48px",
    marginBottom: "12px",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: "inherit",
    height: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    fontSize: 13,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  list: {
    backgroundColor: theme.palette.grey.white,
  },
  clusterCount: {
    ...theme.typography.h6,
  },
}));

function ClusterExplore() {
  const classes = useStyles();
  const explore = useSubExplore({ type: CLUSTER });
  const { t } = useTranslation(["_action"]);
  const reader = explore?.clusters;

  return (
    <div className={classes.root}>
      <div className={classes.searchBox} data-testid="clusters-search-container">
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            value={explore.inputText}
            onChange={explore.onInputChange}
            placeholder={t("search_type_to_find_a_cluster", { ns: "_action" })}
            fullWidth
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            data-testid="clusters-search-input"
          />
        </div>
        <div className={classes.clusterCount}>{`${t("text_showing")} ${
          explore.filteredList.length
        }/${explore.clusters.length} ${t("text_clusters")}`}</div>
      </div>

      <Box height={16} bgcolor="white" />

      {explore.dataFetched && (
        <div data-testid="cluster-list-container">
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height - 32}
                width={width}
                itemSize={MAX_MENU_WIDTH}
                itemData={{ list: explore.filteredList, reader }}
                itemCount={explore.filteredList.length}
                className={classes.list}
              >
                {(props) => renderRow({ ...props, isCluster: true })}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
}

export default observer(ClusterExplore);
