import clsx from "clsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

import DumbbellIcon from "../../../icons/Dumbbell";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    display: "flex",
    alignItems: "center",
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.grey.white,
    color: theme.palette.grey.blue72,
    borderTop: `1px solid ${theme.palette.grey.blue4}`,
    textTransform: "uppercase",
  },
  sectionItemContainer: {
    "&&": {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      padding: theme.spacing(1, 2),
      borderTop: `1px solid ${theme.palette.grey.blue4}`,
      height: theme.spacing(8),
    },
  },
  sectionItemTitle: {
    "&&": { ...theme.typography.body1, color: theme.palette.grey.blue72, fontWeight: "normal" },
  },
  sectionItemValue: {
    "&&": {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.body1.fontSize,
      fontFamily: theme.typography.body1.fontFamily,
      fontWeight: "normal",
      marginTop: theme.spacing(0.5),
    },
  },
  sectionItemIcon: {
    "&&": {
      fontSize: theme.typography.subtitle2.fontSize,
      marginRight: theme.spacing(0.5),
    },
  },
  isDense: {
    "&&": {
      border: 0,
      padding: theme.spacing(0.2, 2),
      marginBottom: theme.spacing(0.5),
      height: theme.spacing(6),
    },
  },
}));

export function SectionTitle({ type, id, children, ...props }) {
  const classes = useStyles();
  return (
    <Typography
      variant="h6"
      className={classes.sectionTitle}
      data-testid={`${type}-${id}-title`}
      {...props}
    >
      {children}
    </Typography>
  );
}

export function SectionItem({ id, type, title, value, unit, isDense, hasIcon, year, ...props }) {
  const classes = useStyles();
  return (
    <div className={clsx(classes.sectionItemContainer, { [classes.isDense]: isDense })} {...props}>
      <Typography
        variant="body1"
        className={classes.sectionItemTitle}
        data-testid={`${type}-${id}-item`}
      >
        {title}
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box
          component={Typography}
          display="flex"
          variant="body1"
          data-testid={`${type}-${id}-item-year`}
        >
          {hasIcon && <DumbbellIcon className={classes.sectionItemIcon} />}
          {year}
        </Box>
        <Typography variant="body1" className={classes.sectionItemValue} data-maskdata>
          {value && value} {value && unit}
        </Typography>
      </Box>
    </div>
  );
}
