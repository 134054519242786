import clsx from "clsx";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@emotion/react";
import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { APP_BAR_HEIGHT, DRAWER_WIDTH } from "../../../../conf/ui_constants";
import useStores from "../../../../hooks/useStores";
import FEATURE from "../../../../conf/feature_access";
import MenuIcon from "../../../../icons/Menu";
import FilterIcon from "../../../../icons/Filter";
import ClusterIcon from "../../../../icons/Cluster";
import LockIconOriginal from "../../../../icons/Lock";
import SubstationIcon from "../../../../icons/Substation";

const useStyles = makeStyles((theme) => ({
  activeMenu: {
    "&&": {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
  menuButton: {
    "&&": {
      width: DRAWER_WIDTH / 4,
      height: APP_BAR_HEIGHT,
      borderRadius: 0,
      borderRight: `1px solid rgb(0, 68, 104)`,
      padding: theme.spacing(0, 2.3),
      color: theme.palette.grey.white,
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
      pointerEvents: "auto",
    },
  },
}));

function LockIcon() {
  return <LockIconOriginal viewBox="0 0 18 18" />;
}

function MenuButton({ tooltipText, disabled, onClick, className, style, ...props }) {
  const theme = useTheme();
  const classes = useStyles();
  const disabledColor = theme.palette.grey.blue50;
  const paperColor = theme.palette.background.paper;

  return (
    <Tooltip title={tooltipText}>
      <IconButton
        className={clsx(classes.menuButton, className)}
        style={{ color: disabled ? disabledColor : paperColor, ...style }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        {...{
          disabled,
          component: disabled ? "div" : undefined,
          onClick: disabled ? undefined : onClick,
        }}
      />
    </Tooltip>
  );
}
MenuButton.defaultProps = {
  tooltipText: "",
  disabled: false,
  onClick: undefined,
  className: "",
  style: {},
};
MenuButton.propTypes = {
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape({ overflow: PropTypes.string }),
};

function PageAppBar({ filterAffects, paperColor }) {
  const theme = useTheme();
  const classes = useStyles();
  const { ui, networks } = useStores();
  const { t } = useTranslation(["navbar"]);

  const filtersDisabled = !networks.haveAccess([FEATURE.substationlist]);

  let filterToolTipText = filterAffects
    ? t("text_the_global_filter_will_apply_to_the_data")
    : t("text_the_global_filter_will_not_affect_the_data");

  if (filtersDisabled) filterToolTipText = t("text_global_filters");

  const disabledColor = theme.palette.grey.blue50;
  const filterIconColor = !filtersDisabled && filterAffects ? paperColor : disabledColor;
  const substationPanelActive =
    ui.is_substation_model_open || ui.is_subsummary_open || ui.is_subdetail_open;
  const clusterPanelActive =
    ui.is_cluster_model_open || ui.is_cluster_summary_open || ui.is_cluster_detail_open;
  const substationExplorerDisabled = !networks.haveAccess([
    FEATURE.substation_overview,
    FEATURE.substation_consumption,
  ]);
  const clusterExplorerDisabled = !networks.haveAccess([FEATURE.clusterlist]);

  return (
    <>
      {/* Sidebar */}
      <IconButton
        onClick={ui.sideNavToggle}
        className={clsx(classes.menuButton, ui.is_sidenav_open && classes.activeMenu)}
        data-testid="sidebar-toggle-button"
      >
        <MenuIcon />
      </IconButton>

      {/* Filter */}
      <MenuButton
        tooltipText={filterToolTipText}
        onClick={ui.toggleFilters}
        style={{
          color: filterIconColor,
        }}
        className={clsx(ui.is_filteroptions_open && classes.activeMenu)}
        disabled={filtersDisabled}
        data-testid="filter-toggle-button"
        data-inactive={!filterAffects}
      >
        {filtersDisabled ? <LockIcon /> : <FilterIcon />}
      </MenuButton>

      {/* Substation */}
      <MenuButton
        tooltipText={t("text_substation_explore")}
        onClick={ui.toggleSubstations}
        className={clsx(substationPanelActive && classes.activeMenu)}
        disabled={substationExplorerDisabled}
        data-testid="substations-toggle-button"
      >
        {substationExplorerDisabled ? <LockIcon /> : <SubstationIcon />}
      </MenuButton>

      {/* Cluster */}
      <MenuButton
        tooltipText={t("text_cluster_explore")}
        onClick={!clusterExplorerDisabled ? ui.toggleCluster : undefined}
        style={{ color: clusterExplorerDisabled ? disabledColor : paperColor }}
        className={clsx(clusterPanelActive && classes.activeMenu)}
        disabled={clusterExplorerDisabled}
        data-testid="clusters-toggle-button"
      >
        {clusterExplorerDisabled ? <LockIcon /> : <ClusterIcon />}
      </MenuButton>
    </>
  );
}
PageAppBar.defaultProps = {
  filterAffects: true,
  paperColor: "",
};
PageAppBar.propTypes = {
  filterAffects: PropTypes.bool,
  paperColor: PropTypes.string,
};

export default observer(PageAppBar);
