import { Component } from 'react';
import { reaction } from 'mobx';
import { observer, disposeOnUnmount } from 'mobx-react';
import { debounce } from '../../core/utils';

class SlowInput extends Component {
    constructor(props) {
        super(props);
        this.state = { value: this.props.value }
        this.onChange = this.onChange.bind(this);
        this.debounced_func = debounce(this.props.onChange, this.props.wait)
    }

    componentDidMount() {

        disposeOnUnmount(
            this,
            reaction(() => this.props.value,
                () => {
                    this.setState({ value: this.props.value });
                })
        )
    }

    onChange(event) {
        let val = event.target.value;
        this.setState({ value: val });
        this.debounced_func(val);
    }

    render() {
        const { value, Child, onChange, wait, ...props } = this.props;
        return <Child value={this.state.value} onChange={this.onChange} {...props} />
    }
}

export default observer(SlowInput);