import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import LargeNumber from "../../../../../components/analytics/LargeNumber";
import BaseCard from "../BaseCard/BaseCard";
import { formatNumberForUnitMainLabel } from "../../../../../core/utils";

/**
 * - This card is built on top of the BaseCard component.
 * - The card is used to compare up to 3 values.
 * - The card displays a number with a unit as the titleAddon
 * - The value has a title that is displayed below the value.
 * - The input values is an array of objects with the following structure:
 *  {
 *    title: "Title",
 *    value: 123,
 *  }
 * - The unit is shared between all the values.
 */
export default function CompareCard({ title, values, unit, loading, error, errorMessage, testId }) {
  const [formattedValues, setFormattedValues] = useState([]);
  const [internalError, setError] = useState(error);
  const [internalErrorMessage, setErrorMessage] = useState(errorMessage);
  const colorArray = ["graph.blue", "graph.pink", "graph.teal"];

  // If it is a number and the unit is not empty, format the value and unit.
  useEffect(() => {
    if (!loading && typeof values[0].value === "number" && unit) {
      const result = values.map((value) => {
        const [formattedValue, formattedExp, formattedPrefix, formattedUnit] =
          formatNumberForUnitMainLabel(value.value, unit);
        return {
          ...value,
          formattedValue,
          formattedExp,
          formattedPrefix,
          formattedUnit,
        };
      });
      setFormattedValues(result);
    }
  }, [loading, values, unit]);

  // if the units dont match, set error to true
  useEffect(() => {
    // TODO: This can be handled better if we need to handle different units
    if (formattedValues.length > 1) {
      const resultUnits = formattedValues[0].formattedUnit;
      const result = formattedValues.filter((value) => value.formattedUnit !== resultUnits);
      if (result.length > 0) {
        setError(true);
        setErrorMessage("Units do not match");
      }
    }
  }, [formattedValues]);

  return (
    <BaseCard
      title={title}
      titleAddon={
        formattedValues.length > 0 ?
          formattedValues[0].formattedUnit : unit
      }
      loading={loading}
      error={internalError}
      errorMessage={internalErrorMessage}
      testId={testId}
    >
      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        justifyContent="space-between"
        alignItems="stretch"
        width="100%"
        height="100%"
        p={2}
      >
        {/* Content */}
        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          justifyContent="center"
          alignContent="center"
          py={2}
        >
          {formattedValues.map((value, index) => (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              px={2}
              key={value.title}
            >
              <Box display="flex" flexDirection="row" justifyContent="center">
                <LargeNumber
                  value={value.formattedValue}
                  exp={value.formattedExp}
                  color={colorArray[index]}
                  tooltip={`${new Intl.NumberFormat("se-SE").format(value.value)} ${unit}`}
                />
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                color={colorArray[index]}
              >
                <Typography variant="subtitle2">{value.title}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </BaseCard>
  );
}

CompareCard.defaultProps = {
  title: "",
  unit: "",
  loading: false,
  error: false,
  errorMessage: "Unable to load data",
  testId: "compare-card",
};

CompareCard.propTypes = {
  title: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.number || null,
    })
  ).isRequired,
  unit: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  testId: PropTypes.string,
};
