/* eslint-disable react/jsx-no-bind */
import { useRef, useState } from "react";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import { useTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";

import { MONTH_OPTIONS } from "../../conf/constants";

const style = {
  endIcon: {
    marginLeft: 0,
  },
};

export const dateSelectStyles = makeStyles((theme) => ({
  root: {
    "&&": {
      ...theme.typography.button,
      color: theme.palette.primary.main,
      lineHeight: 1,
      border: "1px solid rgba(26, 94, 138, 0.5)",
      justifyContent: "space-between",
      padding: "5px 10px",
      textTransform: "capitalize",
      "&:disabled": {
        borderColor: theme.palette.grey.blue28,
      },
      "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  menuItem: {
    textTransform: "capitalize !important",
  },
  menuSelected: {
    background: `${theme.palette.background.selected} !important`,
  },
}));

function SelectMonth(props) {
  const {
    store,
    name,
    classes,
    classesOverride = {},
    disabled,
    selectedValue,
    menuItemClasses,
    onChange,
    disableAllOption,
    ...rest
  } = props;
  const defaultClasses = dateSelectStyles();
  const nclasses = { ...defaultClasses, ...classes, ...classesOverride };
  const [selectedIndex, setSelected] = useState(() => store[name] || null);
  const [menuOpen, setOpen] = useState(false);
  const anchorRef = useRef();
  const theme = useTheme();
  const options = new Map(MONTH_OPTIONS);

  if (disableAllOption) {
    options.delete(null);
  }

  const arrayOptions = Array.from(options.entries());

  function toggleMenuOpen() {
    setOpen((current) => !current);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSelectItem(_, index) {
    setSelected(index);
    if (typeof onChange === "function") {
      onChange(index, name);
    }
    // eslint-disable-next-line no-prototype-builtins
    if (store?.hasOwnProperty(name)) {
      runInAction(() => {
        store[name] = index;
      });
    }
    setOpen(false);
  }
  return (
    <>
      <Button
        ref={anchorRef}
        onClick={toggleMenuOpen}
        color="inherit"
        {...rest}
        className={clsx(defaultClasses.root, classesOverride)}
        classes={nclasses}
        disabled={store.is_data_processed === false || disabled}
        endIcon={menuOpen ? <ArrowDropUp /> : <ArrowDropDownIcon />}
        data-testid="select-month-button"
      >
        {options.get(selectedIndex)}
      </Button>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: theme.zIndex.drawer + 1 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList data-testid="select-month-list">
                  {arrayOptions.map((option) => (
                    <MenuItem
                      key={option[0]}
                      selected={option[0] === selectedIndex}
                      onClick={(event) => handleSelectItem(event, option[0])}
                      classes={{ root: nclasses.menuItem, selected: nclasses.menuSelected }}
                      data-testid={`month-option-${option[0]}`}
                    >
                      {option[1]}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

SelectMonth.defaultProps = {
  name: "month",
  store: {},
  disabled: false,
  disableAllOption: false,
};

export default withStyles(style)(observer(SelectMonth));
