

import SvgIcon from '@mui/material/SvgIcon';

export default function Icon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M 0.581,0.774 H 1.065 A 0.581,0.581 0 0 1 1.646,1.355 V 5.934 A 12,12 0 1 1 3.96,20.908 0.582,0.582 0 0 1 3.937,20.064 L 4.279,19.722 A 0.58,0.58 0 0 1 5.079,19.7 10.354,10.354 0 1 0 2.796,7.258 H 7.552 A 0.581,0.581 0 0 1 8.133,7.839 V 8.323 A 0.581,0.581 0 0 1 7.552,8.904 H 0.581 A 0.581,0.581 0 0 1 0,8.322 V 1.355 A 0.581,0.581 0 0 1 0.581,0.774 Z m 15.563,15.253 0.228,-0.313 A 0.581,0.581 0 0 0 16.244,14.903 L 12.774,12.38 V 5.226 A 0.581,0.581 0 0 0 12.193,4.645 h -0.387 a 0.581,0.581 0 0 0 -0.581,0.581 v 7.943 l 4.107,2.987 a 0.581,0.581 0 0 0 0.811,-0.128 z" />
        </SvgIcon>
    )
}