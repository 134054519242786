/* eslint-disable no-console */
const lib = require("@mrtkrcm/debug");

export const logger = lib("utf", { format: "[%n] %m" });
export const log = (...msg) => logger.extend("app")(...msg);

const error = logger.extend("error");
error.log = console.error.bind(console);
const info = logger.extend("info");
info.log = console.info.bind(console);
const warn = logger.extend("warn");
warn.log = console.warn.bind(console);
const debug = logger.extend("debug");
debug.log = console.debug.bind(console);

export default {
  error,
  info,
  warn,
  debug,
};
