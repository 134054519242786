import React from "react";
import { observer, inject } from "mobx-react";
import { observable, makeObservable, action } from "mobx";
import withStyles from "@mui/styles/withStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import { withTranslation } from "react-i18next";

const styles = () => ({
  label: {
    fontWeight: 400,
  },
});

class StarSubFilter extends React.Component {
  is_data_fetched = false;
  is_ready = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      is_data_fetched: observable,
      is_ready: observable,
      filterStar: action.bound,
    });
  }

  filterStar(e) {
    const {
      rootStore: { filters },
      spec,
    } = this.props;
    filters.updateFilterState(spec.get("param"), "all", e.target.value);
  }

  render() {
    const { t, spec, classes } = this.props;

    const state = spec.get("state");
    return (
      <Box
        borderBottom="1px solid #ddd"
        display="flex"
        flexDirection="column"
        bgcolor="white"
        fontWeight="heavy"
        fontFamily="Roboto"
        py={2}
        px={2}
      >
        <Box
          fontSize="h6.fontSize"
          display="flex"
          flexDirection="row"
          pr={2}
          alignItems="center"
          justifyContent="start"
        >
          <Typography variant="button">{t(spec.get("label"))}</Typography>
          <Box flexGrow={1} />
        </Box>
        <RadioGroup name="filter" value={state.all} onChange={this.filterStar}>
          <FormControlLabel
            value="fav"
            control={<Radio />}
            label={t("text_favorite")}
            classes={{ label: classes.label }}
          />
          <FormControlLabel
            value="non-fav"
            control={<Radio />}
            label={t("text_non_favorite")}
            classes={{ label: classes.label }}
          />
          <FormControlLabel
            value="all"
            control={<Radio />}
            label={t("text_exclude_filter")}
            classes={{ label: classes.label }}
          />
        </RadioGroup>
      </Box>
    );
  }
}

export default withTranslation(["filters"])(
  inject("rootStore")(withStyles(styles)(observer(StarSubFilter)))
);
