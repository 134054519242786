import { forwardRef } from "react";
import { useTheme } from "@mui/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { formatNumberForUnitMainLabel } from "../../core/utils";

const useStyles = makeStyles(() => ({
  section: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  upIcon: {
    marginTop: "-8.5px",
  },
  downIcon: {
    marginBottom: "-1rem",
  },
}));

export default forwardRef(
  ({ suffix, value, prefix, default_up = true, color = "secondary", ...restProps }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const up = default_up ? value >= 0 : value > 0;

    let mainNumber = "";
    let mainPrefix = prefix;
    let mainUnit = suffix;
    if (value) {
      [mainNumber, , mainPrefix, mainUnit] = formatNumberForUnitMainLabel(Math.abs(value), suffix);
    }

    const colorText = typeof color !== "string" ? theme.palette[color[0]][color[1]] : color;
    const colorProps = {};
    const styleProps = {};
    if (typeof color === "string") {
      colorProps.color = color;
    } else {
      styleProps.color = colorText;
    }
    return (
      <div
        className={classes.section}
        style={{ ...styleProps, display: "flex" }}
        {...restProps}
        ref={ref}
      >
        {up ? (
          <div className={classes.upIcon}>
            <ArrowDropUpIcon fontSize="large" data-testid="arrow-up" {...colorProps} />
          </div>
        ) : (
          <div className={classes.downIcon}>
            <ArrowDropDownIcon fontSize="large" data-testid="arrow-down" {...colorProps} />
          </div>
        )}
        <Typography component="span" variant="h3" {...colorProps}>
          {mainPrefix} {mainNumber} {mainUnit}
        </Typography>
      </div>
    );
  }
);
