import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import useTheme from "@mui/material/styles/useTheme";
import HighCharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

import { formatNumberForLocale, omit } from "../../../../../core/utils";
import useReaction from "../../../../../hooks/useReaction";
import useStores from "../../../../../hooks/useStores";
import { withErrorBoundary } from "../../../../../components/ui/ErrorBoundary";

require("highcharts/modules/exporting")(HighCharts);
require("highcharts/modules/export-data")(HighCharts);
require("highcharts/modules/histogram-bellcurve")(HighCharts);
require("highcharts/highcharts-more")(HighCharts);
require("highcharts/modules/boost")(HighCharts);
require("highcharts/modules/annotations")(HighCharts);

/* eslint-disable no-param-reassign */
function setupXLSX(HighChartsInstance) {
  // Set the download function handler
  HighChartsInstance.Chart.prototype.downloadXLSX = function downloadXLSX() {
    let filename = "chart";
    let headerLabels = [];
    let rows;
    // Get the data
    rows = this.getDataRows() || [];
    // Column headers are always the first row
    headerLabels = rows.shift();
    // Format the rows
    rows = rows.map((row) => {
      const newRow = {};
      // Here we want to return the row object with the title as the key
      row.forEach((val, i) => {
        newRow[headerLabels[i]] = val;
      });
      return newRow;
    });
    // Get the filename, copied from the Chart.fileDownload function
    if (this.options.exporting.filename) {
      filename = this.options.exporting.filename;
    } else if (this.title && this.title.textStr) {
      filename = this.title.textStr.replace(/ /g, "-").toLowerCase();
    }
    const ws = XLSX.utils.json_to_sheet(rows, { header: headerLabels });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(dataBlob, `${filename}.xlsx`);
  };

  // Default lang string, overridable in i18n options
  HighChartsInstance.getOptions().lang.downloadXLSX = "Download XLSX";

  // Add the menu item handler
  // eslint-disable-next-line no-param-reassign
  HighChartsInstance.getOptions().exporting.menuItemDefinitions.downloadXLSX = {
    textKey: "downloadXLSX",
    onclick() {
      this.downloadXLSX();
    },
  };

  // Replace the menu item
  const { menuItems } = HighChartsInstance.getOptions().exporting.buttons.contextButton;
  menuItems[menuItems.indexOf("downloadXLS")] = "downloadXLSX";
}
/* eslint-enable no-param-reassign */

setupXLSX(HighCharts);

const HighchartsBase = forwardRef(
  (
    {
      options,
      chart,
      title,
      subtitle,
      legend,
      tooltip,
      boost,
      plotOptions,
      series,
      yAxis,
      yAxisAppend,
      yAxisScale,
      yAxisOptions,
      xAxis,
      xAxisAppend,
      disableBoost,
      isLog,
      annotations,
      customMenu,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const chartRef = useRef();
    const reflowTimer = useRef();
    const { ui } = useStores();
    useImperativeHandle(ref, () => chartRef.current);
    const [isViewDataTable, setIsViewDataTable] = useState(false);

    /* eslint-disable react/no-this-in-sfc */
    const chartOptions = useMemo(() => {
      function getYaxisOptions() {
        const yAxisArr =
          yAxis.length > 0
            ? [...yAxis]
            : [
                {
                  title: {
                    style: {
                      fontSize: theme.typography.caption.fontSize,
                      fontFamily: theme.typography.caption.fontFamily,
                      fontWeight: theme.typography.subtitle2.fontWeight,
                    },
                  },
                  labels: {
                    formatter() {
                      return formatNumberForLocale(this.value) + yAxisAppend;
                    },
                  },
                  ...yAxisOptions,
                },
              ];

        // If scaleYAxis is true and there is a column series type, dynamically scale the axis.
        if (yAxisScale) {
          for (let i = 0; i < yAxisArr.length; i++) {
            const yAxisData = series.filter((cd) => (cd?.yAxis || 0) === i);
            let minv = null;
            if (yAxisData.filter((cd) => cd.type === "column").length > 0) {
              yAxisData.forEach((rowdata) => {
                (rowdata?.data || []).forEach((val) => {
                  if (minv === null || (val !== null && minv > val)) {
                    minv = val;
                  }
                });
              });
              yAxisArr[i].min = minv ? minv * 0.9 : 0;
              yAxisArr[i].startOnTick = true;
            }
          }
        }

        return yAxisArr;
      }

      const optionsWithoutDefaults = omit(
        [
          "chart",
          "boost",
          "title",
          "subtitle",
          "yAxis",
          "xAxis",
          "legend",
          "navigation",
          "plotOptions",
        ],
        options
      );
      const plotOptionsWithoutDefaults = omit(["series", "line", "histogram", "area"], plotOptions);

      return {
        chart: options?.chart || {
          zoomType: "x",
          style: {
            overflow: "visible",
          },
          ...chart,
        },
        boost: options?.boost || {
          enabled: !disableBoost,
          seriesThreshold: !disableBoost ? 50 : 0,
          ...boost,
        },
        title: options?.title || {
          text: "",
          align: "center",
          style: {
            fontSize: theme.typography.h6.fontSize,
            fontFamily: theme.typography.h6.fontFamily,
            fontWeight: theme.typography.h6.fontWeight,
          },
          ...title,
        },
        subtitle: options?.subtitle || {
          x: 120,
          y: 12,
          style: {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.caption.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            position: "absolute",
            zIndex: 2,
            right: "125px",
            top: 8,
          },
          ...subtitle,
        },
        yAxis: getYaxisOptions(),
        xAxis: options?.xAxis || {
          type: "linear",
          title: {
            style: {
              fontSize: theme.typography.caption.fontSize,
              fontFamily: theme.typography.caption.fontFamily,
              fontWeight: theme.typography.subtitle2.fontWeight,
            },
            ...(xAxis?.title || {}),
          },
          labels: {
            formatter() {
              let xValue = this.value;
              if (isLog) {
                xValue = Math.E ** this.value;
              }
              if (xAxis.type === "datetime") {
                return HighCharts.dateFormat("%d %b - %H:%M", this.value);
              }
              xValue = formatNumberForLocale(xValue);
              return xValue + xAxisAppend;
            },
            ...(xAxis?.labels || {}),
          },
          ...xAxis,
        },
        legend: options?.legend || {
          layout: "vertical",
          align: "right",
          verticalAlign: "middle",
          ...legend,
        },
        navigation: options?.navigation || {
          menuStyle: {
            zIndex: 6,
            overflow: "visible",
          },
        },
        credits: {
          enabled: false,
        },
        tooltip,
        plotOptions: options?.plotOptions || {
          series: {
            animation: false,
            stickyTracking: false,
            label: {
              connectorAllowed: false,
            },
            cursor: "pointer",
            color: theme.palette.secondary.light,
            turboThreshold: !disableBoost ? 100000 : 0,
            marker: {
              states: {
                select: {
                  radius: 7,
                  lineWidth: 4,
                  lineColor: theme.palette.secondary.dark,
                  fillColor: theme.palette.secondary.main,
                },
              },
            },
            ...(plotOptions?.series || {}),
          },
          line: {
            boostThreshold: !disableBoost ? 1 : 0,
            turboThreshold: !disableBoost ? 1 : 0,
            ...(plotOptions?.line || {}),
          },
          histogram: {
            turboThreshold: !disableBoost ? 1 : 0,
            ...(plotOptions?.histogram || {}),
          },
          area: {
            fillOpacity: 0.5,
            ...(plotOptions?.area || {}),
          },
          ...(plotOptionsWithoutDefaults || {}),
        },
        series,
        annotations,
        exporting: {
          enabled: true,
          buttons: {
            contextButton: {
              menuItems: [
                ...HighCharts.getOptions().exporting.buttons.contextButton.menuItems.filter(
                  (item) => !["viewData", ...customMenu.names].includes(item)
                ),
                ...customMenu.options,
                {
                  text: `${isViewDataTable ? "Hide" : "View"} data table`,
                  onclick() {
                    if (this.isDataTableVisible) {
                      setIsViewDataTable(false);
                      this.hideData();
                    } else {
                      setIsViewDataTable(true);
                      this.viewData();
                    }
                  },
                },
              ],
            },
          },
        },
        ...(optionsWithoutDefaults || {}),
      };
    }, [
      boost,
      chart,
      disableBoost,
      isLog,
      legend,
      options,
      plotOptions,
      series,
      subtitle,
      theme.palette.primary.main,
      theme.palette.secondary.dark,
      theme.palette.secondary.light,
      theme.palette.secondary.main,
      theme.typography.caption.fontFamily,
      theme.typography.caption.fontSize,
      theme.typography.h6.fontFamily,
      theme.typography.h6.fontSize,
      theme.typography.h6.fontWeight,
      theme.typography.subtitle2.fontWeight,
      title,
      tooltip,
      xAxis,
      xAxisAppend,
      yAxis,
      yAxisAppend,
      yAxisOptions,
      yAxisScale,
      annotations,
      isViewDataTable,
      customMenu,
    ]);
    /* eslint-enable react/no-this-in-sfc */

    useReaction(
      () => ui.is_sidepagemodels_open,
      () => {
        reflowTimer.current = setTimeout(() => chartRef.current.chart.reflow());
      }
    );

    useEffect(
      () => () => {
        if (reflowTimer.current) {
          clearTimeout(reflowTimer.current);
          reflowTimer.current = undefined;
        }
      },
      []
    );

    // make sure that dataTable are not overflowing
    useEffect(() => {
      const { dataTableDiv } = chartRef.current.chart;
      if (!dataTableDiv) return;

      dataTableDiv.style.width = "inherit";
      dataTableDiv.style.overflowX = "scroll";
    }, [isViewDataTable]);

    return (
      <HighchartsReact
        ref={chartRef}
        highcharts={HighCharts}
        options={chartOptions}
        data-maskdata
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  }
);

HighchartsBase.defaultProps = {
  xAxis: {},
  yAxis: {},
  yAxisScale: false,
  yAxisOptions: {},
  yAxisAppend: "",
  xAxisAppend: "",
  disableBoost: false,
  isLog: false,
  options: {},
  tooltip: {},
  customMenu: { names: [], options: [null] },
};

const optionPropTypes = {
  chart: PropTypes.shape({
    zoomType: PropTypes.string,
    style: PropTypes.shape({ overflow: PropTypes.string }),
    boost: PropTypes.shape({
      enabled: PropTypes.bool,
      seriesThreshold: PropTypes.number,
    }),
  }),
  boost: PropTypes.shape({
    enabled: PropTypes.bool,
    seriesThreshold: PropTypes.number,
  }),
  title: PropTypes.shape({
    text: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
    useHTML: PropTypes.bool,
    floating: PropTypes.bool,
  }),
  subtitle: PropTypes.shape({
    text: PropTypes.string,
    align: PropTypes.oneOf(["left", "center", "right"]),
    useHTML: PropTypes.bool,
    floating: PropTypes.bool,
  }),
  legend: PropTypes.shape({
    layout: PropTypes.oneOf(["horizontal", "vertical", "proximate"]),
    align: PropTypes.oneOf(["left", "center", "right"]),
    verticalAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
    floating: PropTypes.bool,
    reversed: PropTypes.bool,
    shadow: PropTypes.bool,
    title: PropTypes.shape({
      text: PropTypes.string,
    }),
    useHTML: PropTypes.bool,
    squareSymbol: PropTypes.bool,
  }),
  plotOptions: PropTypes.shape({
    series: {
      animation: PropTypes.bool,
      stickyTracking: PropTypes.bool,
      label: {
        connectorAllowed: PropTypes.bool,
      },
      cursor: PropTypes.oneOf(["default", "pointer"]),
    },
  }),
};

HighchartsBase.propTypes = {
  ...optionPropTypes,
  xAxis: PropTypes.shape({
    type: PropTypes.oneOf(["linear", "logarithmic", "datetime", "category"]),
    title: PropTypes.shape({
      text: PropTypes.string,
      align: PropTypes.oneOf(["left", "center", "right"]),
      useHTML: PropTypes.bool,
    }),
  }),
  yAxis: PropTypes.shape({
    type: PropTypes.oneOf(["linear", "logarithmic", "datetime", "category"]),
    title: PropTypes.shape({
      text: PropTypes.string,
      align: PropTypes.oneOf(["left", "center", "right"]),
      useHTML: PropTypes.bool,
    }),
  }),
  yAxisOptions: PropTypes.shape({}),
  yAxisScale: PropTypes.bool,
  yAxisAppend: PropTypes.string,
  xAxisAppend: PropTypes.string,
  disableBoost: PropTypes.bool,
  isLog: PropTypes.bool,
  tooltip: PropTypes.shape({
    enabled: PropTypes.bool,
    seriesThreshold: PropTypes.number,
  }),
  options: PropTypes.shape(optionPropTypes),
  customMenu: PropTypes.shape({
    names: PropTypes.oneOf([
      "viewFullscreen",
      "printChart",
      "separator",
      "downloadPNG",
      "downloadJPEG",
      "downloadPDF",
      "downloadSVG",
      "separator",
      "downloadCSV",
      "downloadXLS",
      "downloadXLSX",
      "viewData",
    ]),
    options: PropTypes.shape([{ text: PropTypes.string, onclick: PropTypes.func }]),
  }),
};

export default withErrorBoundary(HighchartsBase);
