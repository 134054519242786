import { inject, observer } from "mobx-react";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material";

import Themes from "./themes";

function MbxThemeProvider({ rootStore, themeName, children, ...restProps }) {
  const theme = createTheme(Themes[themeName || rootStore.ui.theme || "new"]);
  theme.components.MuiCssBaseline.styleOverrides = {
    ...theme.components.MuiCssBaseline.styleOverrides,
    // If ui.maskData is true, then we want to mask the data in the UI.
    // We just blur the elementst that have the data-maskdata attribute.
    ...(rootStore.ui.maskData && {
      "*[data-maskdata]": {
        filter: "blur(9px)",
      },
    }),
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme} {...restProps}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const themeProvider = inject("rootStore")(observer(MbxThemeProvider));
export default themeProvider;
