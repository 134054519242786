import { useEffect, useReducer, useCallback } from "react";

import useIsMounted from "./useIsMounted"
import useSafeDispatch from "./useSafeDispatch"

/**
 * @typedef {object} State The state of asynchronous hooks.
 * @property {object | null} error The error.
 * @property {boolean} pending Whether the call is pending.
 * @property {any | null} result The result of the asynchronous call.
 */

/** @type {State} */
const initialState = {
  error: null,
  pending: true,
  data: null,
}

/**
 * The reducer of asynchronous hooks.
 *
 * @param {State} state The current state.
 * @param {{ type: string, data?: any, error?: object }} action The action.
 * @returns {State} The new state.
 */
function reducer(state, action) {
  switch (action.type) {
    case "START": {
      return { ...state, pending: true }
    }
    case "SUCCESS": {
      return { ...state, pending: false, error: null, data: action.data }
    }
    case "ERROR":
    default: {
      return { ...state, pending: false, error: action.error }
    }
  }
}

/**
 * @callback AsyncMemoCallback
 * @returns {any} The memoized value.
 */

/**
 * Asynchronous version of `React.useMemo`.
 *
 * @param {AsyncMemoCallback} callback The callback.
 * @param {any[]} [deps] The dependencies.
 * @returns {[any, State]}
 */
export function useAsyncMemo(callback, deps, defaultState) {
  const [run, state] = useAsyncCallback(callback, deps, defaultState)

  useEffect(
    () => {
      run()
    },
    // We don't add `dispatch` and `callback` to deps to let the caller manage
    // them himself.
    // This is _ok_ as `dispatch` will never change and the latest `callback`
    // will only be used if `deps` changes, which is the behaviour of
    // `React.useMemo`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  return [state.data, state]
}
/**
 * Asynchronous version of `React.useCallback`.
 *
 * @param {AsyncCallbackCallback} callback The callback.
 * @param {any[]} [deps] The dependencies.
 * @returns {[AsyncCallbackCallback, State]}
 */
export function useAsyncCallback(callback, deps, defaultData = initialState.data) {
  const [state, _dispatch] = useReducer(reducer, { ...initialState, data: defaultData })
  const dispatch = useSafeDispatch(_dispatch)
  const mounted = useIsMounted()

  const run = useCallback(
    async (...args) => {
      if (!mounted.current) return

      dispatch({ type: "START" })

      try {
        const data = await callback(...args)
        dispatch({ type: "SUCCESS", data })
        return data
      } catch (error) {
        dispatch({ type: "ERROR", error })

        if (process.env.NODE_ENV !== "production") {
          console.error("useAsyncCallback ERROR: ", error)
        }

        return error
      }
    },
    // We don't add `dispatch` and `callback` to deps to let the caller manage
    // them himself.
    // This is _ok_ as `dispatch` will never change and the latest `callback`
    // will only be used if `deps` changes, which is the behaviour of
    // `React.useEffect`.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  )

  return [run, state]
}
