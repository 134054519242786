// Copyright (C) 2019 Utilifeed AB. All Rights Reserved.

import SvgIcon from "@mui/material/SvgIcon";

export default function ClockIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs>
          <clipPath id="e7dnhxopta">
            <path data-name="Rectangle 7137" transform="translate(1048 240)" d="M0 0h24v24H0z" />
          </clipPath>
        </defs>
        <g>
          <path
            d="M8.95 9.547a.6.6 0 0 1 .265-.5L12.8 6.664a.6.6 0 0 1 .664.992l-3.32 2.212v5.049a.6.6 0 0 1-1.193 0zm-8.95 0a9.547 9.547 0 1 1 9.547 9.547A9.546 9.546 0 0 1 0 9.547zM9.547 17.9a8.354 8.354 0 1 0-8.354-8.353A8.354 8.354 0 0 0 9.547 17.9z"
            transform="translate(2.453 2.453)"
            data-name="Mask Group 451"
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
