import SvgIcon from "@mui/material/SvgIcon";

export default function Icon(props) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path d="M22.5,0H1.5A1.5,1.5,0,0,0,.44,2.56L9,11.12v8.76A1.49,1.49,0,0,0,9.51,21l3,2.62A1.5,1.5,0,0,0,15,22.48V11.12l8.56-8.56A1.5,1.5,0,0,0,22.5,0Zm-9,10.5v12l-3-2.62V10.5l-9-9h21Z" />
    </SvgIcon>
  );
}
