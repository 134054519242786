import { flow, makeAutoObservable } from "mobx";

import ERR, { CODES } from "../conf/errors";

class Interaction {
  constructor(parent) {
    makeAutoObservable(this, {}, { autoBind: true });

    this.current_notf = 0;
    this.parent = parent;
  }

  nextId() {
    // each new notification has a unique id for dom
    this.current_notf = this.current_notf > 1000 ? 0 : this.current_notf + 1;
    return this.current_notf;
  }

  gotError(code, meta = {}) {
    this.parent.ui.banners.push({
      ...ERR[code],
      code,
      ...meta,
      id: this.nextId(),
    });
  }

  showMsg(level, msg) {
    this.parent.ui.addBanner({
      level,
      msg,
      id: this.nextId(),
    });
  }

  onOnlineStatusChange() {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "offline") {
      this.gotError(CODES.offline);
    } else {
      this.gotError(CODES.online);
    }
  }

  startup = flow(function* startup() {
    window.addEventListener("online", this.onOnlineStatusChange);
    window.addEventListener("offline", this.onOnlineStatusChange);

    yield;
  });
}

export default Interaction;
