import { useEffect, useState } from "react";
import { observer, inject } from "mobx-react";
import { runInAction } from "mobx";
import withTheme from "@mui/styles/withTheme";
import withStyles from "@mui/styles/withStyles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import LockIcon from "../../icons/Lock";
import { permissionToComponentMap } from "../../conf/route_mappings";

const styles = () => ({
  listItem: {
    height: 48,
  },
});

function SideBarNestedItem(props) {
  // Members from props
  const {
    routes,
    theme,
    classes,
    rootStore: {
      routerStore: {
        routerState: { routeName },
      },
      routerStore,
      networks,
    },
  } = props;
  const { route, Icon, text, children = [], cb = null } = routes;

  // Calculated members
  const hasChildren = children.length > 0;
  const haveAccess = networks.haveAccess(routes.access);
  const isCurrentRoute = route === routeName;
  const childrenHaveRoute = isCurrentRoute || !!children.find((child) => child.route === routeName);
  const [open, setOpen] = useState(childrenHaveRoute);

  const navigateToRoute = () => {
    if (haveAccess) {
      if (cb) {
        cb();
      }
      if (hasChildren) {
        if (childrenHaveRoute) {
          setOpen(!open);
        } else {
          routerStore.goTo(children[0].route);
        }
      }
      if (route) {
        routerStore.goTo(route);
      } else {
        // TODO: handle this case with an error
        console.warn("No route for this item");
      }
    }
  };

  const id = route || children[0]?.route;

  /**
   * Preload chunks for page components based on network feature set
   * This will enable smoother transition by avoiding flicker on navigation start
   * while only loading the pages a user can reach
   */
  useEffect(() => {
    const childrenAccesses = [];
    children.forEach((child) => (child?.access || []).forEach((cha) => childrenAccesses.push(cha)));

    const routeAccess = routes?.access || [];

    [...routeAccess, ...childrenAccesses].forEach((feature) => {
      if (!(feature in permissionToComponentMap)) return;

      if (!networks.haveAccess([feature])) return;

      const pageComponent = permissionToComponentMap[feature];
      if (pageComponent?.preload) pageComponent.preload();
    });
  }, [children, haveAccess, networks, routes.access]);

  return (
    <div style={{ marginBottom: 12 }}>
      <ListItem
        button
        classes={{ root: classes.listItem }}
        style={{
          zIndex: 1,
          boxShadow: hasChildren && open ? "0px 2px 4px rgba(0, 0, 0, 0.12)" : "none",
          backgroundColor: childrenHaveRoute ? theme.palette.grey.blue12 : theme.palette.grey.blue4,
        }}
        elevation={hasChildren && open ? 0 : 1}
        selected={childrenHaveRoute}
        onClick={navigateToRoute}
        data-testid={`sidebar-item-${id}`}
      >
        <ListItemIcon>
          <Icon color={childrenHaveRoute ? "secondary" : "primary"} />
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{
            color: childrenHaveRoute ? "secondary" : "primary",
            variant: "button",
          }}
        />
        {hasChildren && haveAccess && (
          <div>
            {open ? (
              <ExpandLessIcon data-testid="lessIcon" color="primary" />
            ) : (
              <ExpandMoreIcon data-testid="moreIcon" color="primary" />
            )}
          </div>
        )}
        {!haveAccess && (
          <div data-testid="sidebar-lock-icon">
            <LockIcon />
          </div>
        )}
      </ListItem>
      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            style={{
              backgroundColor: childrenHaveRoute
                ? theme.palette.grey.blue12
                : theme.palette.grey.blue4,
            }}
          >
            {children.map((child) => {
              const childColor = child.route === routeName ? "secondary" : "primary";
              const haveAccessToChild = networks.haveAccess(child.access);
              return (
                <ListItem
                  key={child.route}
                  button
                  classes={{ root: classes.listItem }}
                  onClick={() => {
                    if (haveAccessToChild) {
                      if (child.cb) {
                        child.cb();
                      } else {
                        runInAction(() => routerStore.goTo(child.route));
                      }
                    }
                  }}
                  data-testid={`sidebar-item-child-${child.route}`}
                  selected={child.route === routeName}
                >
                  <ListItemText
                    inset
                    primary={child.text}
                    primaryTypographyProps={{
                      color: childColor,
                      variant: "button",
                    }}
                  />
                  {!haveAccessToChild && <LockIcon data-testid="sidebar-lock-icon" />}
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </div>
  );
}

export default inject("rootStore")(withTheme(withStyles(styles)(observer(SideBarNestedItem))));
