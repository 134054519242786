import { useEffect } from "react"
import { reaction } from "mobx"

/**
 * reaction, but ensures to run the disposer function when the component is
 * unmounted
 *
 * @param expression What values the reaction is based on
 * @param effect What effect the reaction does
 * @param opts
 * @param deps If provided, the reaction will be recreated each time a
 * value in the list is changed
 */
const useReaction = (expression, effect, opts, deps = []) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reaction(expression, effect, opts), deps)
}

export default useReaction
