import { observable, action, flow, makeObservable } from 'mobx';
import { sleep } from '../core/utils';

class UserPreferences {
    fav_subs = new Map();

    constructor(parent) {
        makeObservable(this, {
            fav_subs: observable,
            resetPref: action.bound,
            fromJson: action.bound
        });

        this.parent = parent;
        this._enabled = true;
        this._update_pending = false

        this.disableSync = this.disableSync.bind(this);
    }

    disableSync() {
        this._endabled = false;
    }

    toggleFavSub = flow(function* (network_id, sub_id) {
        let cnet = null;
        if (this.fav_subs.has(network_id)) {
            cnet = this.fav_subs.get(network_id)
        } else {
            cnet = new Set()
            this.fav_subs.set(network_id, cnet)
        }
        if (cnet.has(sub_id)) {
            cnet.delete(sub_id)
        } else {
            cnet.add(sub_id)
        }

        if (!this._update_pending && this._enabled) {
            this._update_pending = true;
            yield sleep(10);
            yield this.parent.utfapi.setPreferences(this.toJson())
            this._update_pending = false;
        }
    })

    resetPref() {
        /* reset preferences to initial */
        this.fav_subs = new Map()
    }

    clearFavorites = flow(function* (network_id) {
        this.fav_subs.set(network_id, new Set())
        if (!this._update_pending && this._enabled) {
            this._update_pending = true;
            yield sleep(10);
            yield this.parent.utfapi.setPreferences(this.toJson())
            this._update_pending = false;
        }
    })

    fromJson(jsn) {
        if (jsn.hasOwnProperty('fav_subs')) {
            this.fav_subs = new Map();
            for (let net of Object.keys(jsn.fav_subs)) {
                this.fav_subs.set(net, new Set(jsn.fav_subs[net]))
            }
        }
    }

    toJson() {
        const resjson = { fav_subs: {} }
        for (let net_fav_sub of this.fav_subs.entries()) {
            resjson.fav_subs[net_fav_sub[0]] = Array.from(net_fav_sub[1])
        }
        return resjson;
    }
}

export default UserPreferences;