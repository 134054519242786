import { forwardRef } from "react";
import SvgIcon from "@mui/material/SvgIcon";

import themes from "../themes";

const WHITE = themes.new.palette.grey.white;

const ClusterIcon = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props} ref={ref}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <defs>
        <clipPath id="37qfpm6zga">
          <path data-name="Path 2566" d="M0 0h24v24H0z" style={{ fill: "none" }} />
        </clipPath>
      </defs>
      <g data-name="Group 2914" style={{ clipPath: "url(#37qfpm6zga)" }}>
        <path
          data-name="Path 2560"
          d="m10 14-3.8 3.81"
          style={{ stroke: WHITE, strokeWidth: "1.44px", fill: "none" }}
        />
        <path
          data-name="Path 2561"
          d="M8.8 9.18H3.3"
          style={{ stroke: WHITE, strokeWidth: "1.44px", fill: "none" }}
        />
        <path
          data-name="Path 2562"
          d="M15.54 20.41v-5.2"
          style={{ stroke: WHITE, strokeWidth: "1.44px", fill: "none" }}
        />
        <path
          data-name="Path 2563"
          d="M14.58 16.52h-.38A7.35 7.35 0 0 1 13 2.08v1.51A5.88 5.88 0 0 0 14.2 15h.62a5.87 5.87 0 0 0 5.59-4h1.51a7.33 7.33 0 0 1-7.1 5.51zm.23-2.94a4.4 4.4 0 1 1 4.4-4.4 4.4 4.4 0 0 1-4.4 4.4zM14.8 6A3.23 3.23 0 1 0 18 9.2 3.23 3.23 0 0 0 14.8 6zM24 9.18h-3.31a5.86 5.86 0 0 0-5.84-5.88V0A9.18 9.18 0 0 1 24 9.18z"
          style={{ fill: WHITE }}
        />
        <circle
          data-name="Ellipse 421"
          cx="2.3"
          cy="2.3"
          r="2.3"
          transform="translate(0 6.9)"
          style={{ fill: "none" }}
        />
        <circle
          data-name="Ellipse 422"
          cx="1.58"
          cy="1.58"
          r="1.58"
          transform="translate(.72 7.62)"
          style={{ stroke: WHITE, strokeWidth: "1.44px", fill: "none" }}
        />
        <path
          data-name="Path 2564"
          d="M5.46 16.53a2.3 2.3 0 1 1-2.3 2.3 2.3 2.3 0 0 1 2.3-2.3z"
          style={{ fill: "none" }}
        />
        <circle
          data-name="Ellipse 423"
          cx="1.58"
          cy="1.58"
          r="1.58"
          transform="translate(3.88 17.25)"
          style={{ stroke: WHITE, strokeWidth: "1.44px", fill: "none" }}
        />
        <path
          data-name="Path 2565"
          d="M15.52 19.4a2.3 2.3 0 1 1-2.3 2.3 2.3 2.3 0 0 1 2.3-2.3z"
          style={{ fill: "none" }}
        />
        <circle
          data-name="Ellipse 424"
          cx="1.58"
          cy="1.58"
          r="1.58"
          transform="translate(13.94 20.12)"
          style={{ stroke: WHITE, strokeWidth: "1.44px", fill: "none" }}
        />
      </g>
    </svg>
  </SvgIcon>
));

export default ClusterIcon;
