/*
    this file is created to unify all business logics used in frontend code for 
    easy reviewing/unification and changes.

    Initial Author: Arun Tigeraniya <tigeraniya@gmail.com>
*/
import { Duration, DateTime } from "luxon";

const FLOW_REDUCTION_CONSTANT = 1.16;

export function cost_saving_potential(row, energyAttr, tempAttr, energyTarget, tempTarget) {
  /*
        energy_attr  attribute to get energy
        temp_attr  attribute to get return temperature flow weighted
        energy_target
        temperature target
        row comprises of data in json format for single substation
        return saving potential
    */
  if (!row[energyAttr] || !row[tempAttr]) return 0;
  const k = (row[energyAttr] / 1000) * energyTarget * (row[tempAttr] - tempTarget);
  return Math.max(0, k);
}

export function flow_reduction(row, energyAttr, stAttr, rtAttr, tempTarget) {
  /*
        row : values object
        st_attr : supply temperature attr flow weighted
        rt_attr : return temperature attr flow weighted
        energy_attr : heat energy sum attr
    */
  if (!row[energyAttr] || !row[rtAttr] || !row[stAttr]) return 0;
  if (row[stAttr] - row[rtAttr] === 0 || row[stAttr] - tempTarget === 0) {
    return null;
  }
  const k =
    row[energyAttr] / ((row[stAttr] - row[rtAttr]) * FLOW_REDUCTION_CONSTANT) - // actual
    row[energyAttr] / ((row[stAttr] - tempTarget) * FLOW_REDUCTION_CONSTANT); // if rt was best
  return Math.max(k, 0);
}

export function getLatestProcessedMonth(currentTime, networkMonth, active = false) {
  /*
    if five days have passed since month started we can be assured jobs for last month 
    have run and use it, otherwise we use month prior to it.
    Args:
        currentTime is current time
    Returns:
        [ year, month ]  : year,month of Last processed month or latest blocks available
                           month
    */
  if (networkMonth && !active) {
    return DateTime.fromObject({ year: networkMonth[0], month: networkMonth[1], day: 5 });
  }
  const daysSinceLastMonth = Duration.fromMillis(
    currentTime - currentTime.startOf("month")
  ).shiftTo("days").days;
  let LastProcessedMonth = null;
  if (daysSinceLastMonth >= 7) {
    LastProcessedMonth = currentTime.plus({ months: -1 });
  } else {
    LastProcessedMonth = currentTime.plus({ months: -2 });
  }
  return LastProcessedMonth;
}

export function getLatestProcessedYear(currentTime, networkYear, active = false) {
  if (networkYear && !active) {
    return DateTime.fromObject({ year: networkYear, month: 12, day: 5 });
  }
  const lpm = getLatestProcessedMonth(currentTime);
  if (lpm.month < 12) {
    return lpm.plus({ years: -1 });
  }
  return lpm;
}

const TZRGX = /UTC(?<sign>[+-])(?<hour>\d+):(?<minutes>\d+)/;

export function timezoneToMinutes(timezone) {
  /*
    required to convert from UTC+1:00 format to tzoffset format to 
    be used in graphing library
    */
  const vals = TZRGX.exec(timezone);
  const sign = vals.groups.sign === "+" ? -1 : 1;
  const hour = parseInt(vals.groups.hour) * 60;
  const minutes = parseInt(vals.groups.minutes);
  return sign * (hour + minutes);
}
