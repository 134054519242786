import { useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { makeStyles, useTheme } from "@mui/styles";
import { AppBar, Toolbar, Box, LinearProgress } from "@mui/material";

import { APP_BAR_HEIGHT, DRAWER_WIDTH } from "../../conf/ui_constants";
import Banner from "../../shared_components/banner";
import useStores from "../../hooks/useStores";
import UtfErrorBoundary from "../ui/ErrorBoundary";
import { SubHeader, PageAppBar, PageTitle, PageSetting } from "../../shared_components/ui/layout";

import ModalManager from "./ModalManager";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    color: theme.palette.primary.contrastText,
    maxHeight: "100vh",
  },
  appToolBar: {
    "&&": { padding: 0 },
  },
  appBar: {
    "&&": {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: theme.palette.primary.dark,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  },
  toolbar: {
    "&&": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
  },
  content: {
    "&&": {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      color: theme.palette.grey.blue72,
      paddingTop: APP_BAR_HEIGHT,
    },
  },
  drawerOpen: {
    marginLeft: DRAWER_WIDTH,
    overflowX: "auto",
  },
}));

function PageWrapper({ title, pageIcon, filterAffects, formComponents, children }) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    ui,
    networks,
    rootStore: { startup },
  } = useStores();

  useEffect(() => {
    startup();
  }, [startup]);

  const paperColor = theme.palette.background.paper;

  return (
    <div className={classes.root}>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className={classes.appToolBar}>
          {/* Drawer top left */}
          <PageAppBar filterAffects={filterAffects} paperColor={paperColor} />

          {/* page title */}
          <PageTitle
            title={title}
            pageIcon={pageIcon}
            paperColor={paperColor}
            onClick={ui.sideNavToggle}
          />

          {/* Setting top right */}
          <PageSetting />
        </Toolbar>
      </AppBar>

      {/* Modal Manager */}
      <ModalManager />

      {/* Main/Route Content */}
      <main className={clsx(classes.content, ui.is_sidepagemodels_open && classes.drawerOpen)}>
        {/* Legacy Notifications */}
        <Banner />

        {/* Body */}
        <Box height="100%" overflow="auto" flexGrow={1}>
          {!networks.ready && <LinearProgress data-testid="page-loader" />}

          {/* 
            Top-level error boundary to avoid full-page/white-screen crashes.
            In case of unhandled error, this boundary will protect top and side navigations.
          */}
          <UtfErrorBoundary>{networks.ready && children}</UtfErrorBoundary>
        </Box>

        {/* Page specific controls & wiki */}
        <SubHeader>{formComponents}</SubHeader>
      </main>
    </div>
  );
}

PageWrapper.defaultProps = {
  title: "",
  pageIcon: null,
  filterAffects: true,
  formComponents: null,
};

PageWrapper.propTypes = {
  title: PropTypes.string,
  pageIcon: PropTypes.element,
  filterAffects: PropTypes.bool,
  formComponents: PropTypes.element,
  children: PropTypes.element.isRequired,
};

export default observer(PageWrapper);
