import { useRef, useState } from "react";
import clsx from "clsx";
import {
  Grow,
  Button,
  ClickAwayListener,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Checkbox,
  ListItemText,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { PropTypes } from "prop-types";

import ToolIcon from "../../../../../icons/Tool";
import { useTranslation } from "react-i18next";

export const TOOLBOX_DEFAULT_OPTIONS = new Map([
  ["graphGrid", "action_graph_grid"],
  ["max", "action_maximum_value"],
  ["min", "action_minimum_value"],
  ["average", "action_average_value"],
]);

const useStyles = makeStyles((theme) => ({
  ButtonDropDown: {
    width: theme.spacing(4),
    minWidth: theme.spacing(4),
    border: "none !important",
  },
  menuItem: {
    padding: theme.spacing(1, 1.5),
    gap: theme.spacing(2),
  },
  checkboxRoot: {
    "&$checked": {
      "& > svg": {
        fill: "white",
        background: theme.palette.secondary.main,
        borderRadius: theme.spacing(0.5),
        width: theme.spacing(1.75),
        height: theme.spacing(1.75),
      },
    },
    color: theme.palette.primary.main,
    width: theme.spacing(1.75),
    height: theme.spacing(1.75),
    "& > svg": {
      strokeWidth: 2,
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
  checked: {}, // without this the checkboxRoot class wont apply
}));

export default function ChartToolbox({
  options,
  disabled,
  values,
  setValues,
  className,
  ...restProps
}) {
  const [menuOpen, setOpen] = useState(false);
  const anchorRef = useRef();
  const classes = useStyles({ disabled });
  const { t } = useTranslation(["_action"]);

  function toggleMenuOpen() {
    setOpen((current) => !current);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSelectItem(value) {
    if (!values.includes(value)) {
      setValues((prev) => [...prev, value]);
    } else {
      setValues((current) => current.filter((item) => item !== value));
    }
  }

  return (
    <div>
      <Button
        ref={anchorRef}
        key="button"
        onClick={() => toggleMenuOpen()}
        color={menuOpen ? "secondary" : "primary"}
        variant="contained"
        aria-controls={menuOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        className={clsx(classes.ButtonDropDown, className)}
        disabled={disabled}
        {...restProps}
      >
        <ToolIcon />
      </Button>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        key="popper"
        disablePortal
        placement="bottom-end"
        sx={{ zIndex: 2 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "bottom top" : "center top",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => handleClose()}>
                <MenuList sx={{ maxHeight: "400px", overflowY: options.size >= 15 && "scroll" }}>
                  {Array.from(options.entries()).map((option) => (
                    <MenuItem
                      key={option[0]}
                      onClick={() => handleSelectItem(option[0])}
                      className={classes.menuItem}
                      data-testid={`${restProps["data-testid"] || "dropdown-option"}__${option[0]}`}
                    >
                      <ListItemText primary={t(option[1])} />
                      <Checkbox
                        checked={values.includes(option[0])}
                        classes={{
                          root: classes.checkboxRoot,
                          checked: classes.checked,
                        }}
                        size="small"
                        disableRipple
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

ChartToolbox.propTypes = {
  options: PropTypes.instanceOf(Map),
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  setValues: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

ChartToolbox.defaultProps = {
  options: TOOLBOX_DEFAULT_OPTIONS,
  disabled: false,
  className: null,
};
