/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { DateTime } from "luxon";
import { makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";

import CalendarIcon from "../../../icons/CalendarIcon";

import * as CONSUMPTION from "./Constants";
import useConsumption from "./state";

const useStyles = makeStyles(() => ({
  textField: {
    "& .MuiInput-root": {
      "&, &:before, &:after": {
        borderBottom: "none",
      },
      "&:hover": {
        "&, &:before, &:after": {
          borderBottom: "none",
        },
      },
    },
  },
}));

function ConsumptionDatePicker({
  variant,
  minDate,
  maxDate,
  views,
  value,
  onChange,
  format,
  classname,
  disabled,
  id,
  ...props
}) {
  const classes = useStyles({ disabled });
  const { interval } = useConsumption();

  const defaultFormat =
    interval === CONSUMPTION.INTERVAL_HOURLY
      ? CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD_HH_MM
      : CONSUMPTION.DATETIME_FORMAT_YYYY_MM_DD;

  // here we force re-mounting the datepicker in order to make it correctly render the new format.
  // TODO: this is very ugly... Track changes in MUI-x package and remove this once they fixed it.
  const [unMount, setUnmount] = useState(false);
  useEffect(() => {
    setUnmount(true);
    setTimeout(() => {
      setUnmount(false);
    }, 10);
  }, [defaultFormat]);

  if (unMount) return null;

  return (
    <DesktopDateTimePicker
      minDate={minDate}
      maxDate={maxDate}
      ampm={false}
      views={views}
      value={value}
      onChange={onChange}
      autoOk
      disabled={disabled}
      inputFormat={format ?? defaultFormat}
      components={{
        OpenPickerIcon: CalendarIcon,
      }}
      renderInput={({ InputProps, inputProps, inputRef }) => (
        <TextField
          {...{ inputRef, inputProps }}
          variant={variant}
          className={clsx(classes.textField, classname)}
          InputProps={{
            ...InputProps,
            "data-testid": `date-input__${id}`,
          }}
        />
      )}
      {...props}
    />
  );
}

ConsumptionDatePicker.defaultProps = {
  variant: "standard",
  minDate: undefined,
  maxDate: undefined,
  views: ["year", "month", "day"],
  format: null,
  disabled: false,
  classname: null,
  id: "consumption",
  value: undefined,
};

ConsumptionDatePicker.propTypes = {
  variant: PropTypes.string,
  minDate: PropTypes.instanceOf(DateTime),
  maxDate: PropTypes.instanceOf(DateTime),
  views: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.instanceOf(DateTime),
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  classname: PropTypes.string,
  id: PropTypes.string,
};

export default observer(ConsumptionDatePicker);
