import { computed, flow, makeObservable } from "mobx";

import CalculatorIcon from "../../../icons/Calculator";
import TempIcon from "../../../icons/RetTemp";

export default class Store {
  constructor(parent) {
    makeObservable(this, {
      allSettings: computed,
      updateSettingValue: flow.bound,
    });

    this.parent = parent;
  }

  get allSettings() {
    return [
      {
        id: "rta",
        title: "title_heading_bar_return_temperature",
        settings: [
          {
            id: "target_temp",
            title: "text_targe_return_temp",
            hint: "text_targe_return_temp_desc",
            value: this.parent.rta.target_temp,
            option: {
              type: "number",
              icon: TempIcon,
              updateValue: (value) => this.parent.rta.updateTargetTemperature(value),
              changeDisclaimer: ["text_setting_change_disclaimer"],
            },
          },
          {
            id: "target_energy",
            title: "text_lava_calculus_coefficient",
            hint: "text_lava_calculus_coefficient_desc",
            value: this.parent.rta.target_energy,
            option: {
              type: "number",
              icon: CalculatorIcon,
              updateValue: (value) => this.parent.rta.updateTargetEnergy(value),
              changeDisclaimer: ["text_setting_change_disclaimer"],
            },
          },
        ],
      },
    ];
  }

  updateSettingValue = flow(function* (section, setting, value) {
    const settingObj = this.allSettings
      .find((s) => s.id === section)
      .settings.find((s) => s.id === setting);
    yield settingObj.option.updateValue(value);
    settingObj.value = value;
  });
}
