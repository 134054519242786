/*

initial author: Arun Tigeraniya<tigeraniya@gmail.com>
further read :
https://utilifeed.atlassian.net/wiki/spaces/ADWA/pages/898727937/Filter+Bar+2.0
https://utilifeed.atlassian.net/wiki/spaces/ADWA/pages/734593025/Filter+paramteres

*/
import { ObservableMap, observable, action, computed, makeObservable } from "mobx";
import CategoricalFilter from '../components/Filters/CategorialFilter';
import HistogramFilter from '../components/Filters/HistogramFilter';
import StarSubFilter from '../components/Filters/StarSubFilter';
import {SUBSTATION_BLOCK_TYPES as SBT} from '../conf/blocks';
const FILTER_LIST = require("./filters.json")


export const FILTER_MAP = {
    categorical: CategoricalFilter,
    histogram: HistogramFilter,
    favs: StarSubFilter
}

class Filters {

    filters = new ObservableMap();
    favorites = new Set();

    constructor(parent) {
        makeObservable(this, {
            filters: observable,
            favorites: observable,
            toggleFilterIsActive: action.bound,
            addSelectedOption: action.bound,
            removeSelectedOption: action.bound,
            toggleFilterOption: action.bound,
            updateFilterState: action.bound,
            addSelectedOptions: action.bound,
            selectAllOptions: action.bound,
            selectNoneOfOption: action.bound,
            selectBounds: action.bound,
            releaseBounds: action.bound,
            setMin: action.bound,
            setMax: action.bound,
            toggleMissing: action.bound,
            updFilterActive: action.bound,
            filterGroups: computed,
            activeFilters: computed,
            allFilters: computed,
            isDirty: computed,
            loadAllFilters: action.bound,
            resetFilters: action.bound,
            fromJson: action.bound,
            toJson:action.bound,
        });

        this.parent = parent;
        this.COMPONENTS = FILTER_MAP;
    }

    getSpec(param) {
        return this.filters.get(param);
    }

    toggleFilterIsActive(param) {
        let flt = this.filters.get(param);
        flt.set('is_active', !flt.get('is_active'));
    }

    addSelectedOption(param, option, optionCount) {
        let flt_state = this.filters.get(param).get("state");
        flt_state.selected.add(option);
        flt_state.all = flt_state.selected.size === optionCount
    }

    removeSelectedOption(param, option) {
        let flt_state = this.filters.get(param).get("state");
        flt_state.selected.delete(option);
        flt_state.all = false;
    }

    toggleFilterOption(param, option) {
        if (this.filters.get(param).get("state").selected.has(option)) {
            this.removeSelectedOption(param, option);
        } else {
            this.addSelectedOption(param, option);
        }
    }

    updateFilterState(param, key, val) {
        let flt_state = this.filters.get(param).get("state");
        flt_state[key] = val;
    }


    addSelectedOptions(param, options) {
        let flt_state = this.filters.get(param).get("state");
        for (let option of options) {
            flt_state.selected.add(option);
        }
    }

    selectAllOptions(param, options) {
        let flt_state = this.filters.get(param).get('state');
        flt_state.all = true;
        for (let option of options) {
            flt_state.selected.add(option);
        }
    }

    selectNoneOfOption(param) {
        let flt_state = this.filters.get(param).get('state');
        flt_state.selected.clear();
        flt_state.all = false;
    }

    selectBounds(param, min, max) {
        let flt_state = this.filters.get(param).get('state');
        flt_state.min = min
        flt_state.max = max
        flt_state.all = false;
    }

    releaseBounds(param) {
        const filter = this.filters.get(param);
        let flt_state = filter.get('state');
        const net = this.parent.networks;
        let year, month;
        if (filter.get("lb") === "yearly") {
            year = net.lpYear.year;
            month = null;
        } else {
            year = net.lpMonth.year;
            month = net.lpMonth.month;
        }
        flt_state.all = true;
        flt_state.min = null;
        flt_state.max = null;
        const blockName = SBT[filter.get('block')].to_block_name({ year, month })
        filter.set('colname', `${blockName}$${param}`);
    }

    setMin(param, min) {
        let flt_state = this.filters.get(param).get('state');
        flt_state.min = min;
        flt_state.all = false;
    }

    setMax(param, max) {
        let flt_state = this.filters.get(param).get('state');
        flt_state.max = max;
        flt_state.all = false;
    }

    toggleMissing(param) {
        let flt_state = this.filters.get(param).get('state');
        flt_state.exclude = !flt_state.exclude;
    }

    updFilterActive(param, state) {
        let flt_state = this.filters.get(param);
        flt_state.set('is_active', state);
    }

    async loadFromCache() {
        this.loadAllFilters();
    }

    get filterGroups() {
        let fg = new Set();
        this.filters.forEach((spec) => {
            fg.add(spec.get('group'))
        })
        return Array.from(fg);
    }

    get activeFilters() {
        let ac = []
        this.filters.forEach((spec) => {
            if (spec.get('is_active')) {
                ac.push(spec)
            }
        })
        return ac;
    }

    get allFilters() {
        let ac = []
        this.filters.forEach((spec) => {
            ac.push(spec)
        })
        return ac;
    }

    // Check whetever any filter actively used
    get isDirty() {
        return (
          this.parent.networks.current_substations.size !==
          this.parent.networks.active_substations.size
        );
    }

    loadAllFilters() {
        const networks = this.parent.networks;
        if (!networks.ready) {
            return
        }

        for (let fspec of FILTER_LIST) {
            const lastBlock = fspec.lb || "yearly"

            let year, month;
            if (lastBlock === "yearly") {
                year = networks.lpYear.year;
                month = null;
            } else {
                year = networks.lpMonth.year;
                month = networks.lpMonth.month;
            }

            let colName = ""

            if(fspec.type !== "favs"){
                const blockName = SBT[fspec.block].to_block_name({ year, month })
                colName = `${blockName}$${fspec.param}`;
            }

            this.filters.set(fspec.param,
                new ObservableMap([
                    ['is_active', fspec.is_default],
                    ['label', fspec.label],
                    ['param', fspec.param],
                    ['block', fspec.block],
                    ['type', fspec.type],
                    ['colname', colName],
                    ['group', fspec.group],
                    ['lb', lastBlock],
                    ['is_recommended', fspec.is_recommended],
                    ['state', {
                        // Histograms do not have all selected by default
                        all:fspec.type === "favs"?"all": fspec.type === "histogram" ? false : true,
                        exclude: false,
                        selected: new Set(),
                        min: null,
                        max: null,
                        scale: 'linear',
                        year: year,
                        month: month,
                    }]
                ]))
        }
    }

    resetFilters() {
        this.filters.forEach((spec, param) => {
            if (spec.get('is_active')) {
                let type = spec.get("type")
                let state = spec.get('state')
                state.selected.clear();
                state.all = type === "favs" ? "all": type === "histogram" ? false : true;
                state.exclude = false;
                state.min = null;
                state.max = null;
            }
        })
    }

    printState() {
        for (let [p, sp] of this.filters.entries()) {
            let state = sp.get("state")
            console.log(p,
                ",is_active:", sp.get('is_active'),
                ",label:", sp.get("label"),
                ",param:", sp.get("param"),
                ",block:", sp.get("block"),
                ",type:", sp.get("type"),
                ",All:", state.all,
                ",exclude", state.exclude,
                ",selected:", Array.from(state.selected));
        }
    }

    toJson() {
        let network = this.parent.networks.current_network;
        let jsn = {};
        for (let [param, flt_data] of this.filters.entries()) {
            jsn[param] = {
                is_active: flt_data.get('is_active'),
                all: flt_data.get('state').all,
                selected: Array.from(flt_data.get('state').selected),
                min: flt_data.get('state').min,
                max: flt_data.get('state').max,
                exclude: flt_data.get('state').exclude
            }
        }
        return { network: network, filters: jsn };
    }

    fromJson(jsn) {
        if (jsn.hasOwnProperty('filters')) {
            let ifilters = jsn.filters;
            for (let param in ifilters) {
                if (this.filters.has(param)) {
                    let fltr = this.filters.get(param)
                    if (ifilters[param].hasOwnProperty('is_active')) {
                        fltr.set('is_active', ifilters[param]['is_active'])
                    }
                    if (ifilters[param].hasOwnProperty('all')) {
                        fltr.get('state').all = ifilters[param]['all']
                    }
                    if (ifilters[param].hasOwnProperty('selected')) {
                        fltr.get('state').selected = new Set(ifilters[param]['selected'])
                    }
                    if (ifilters[param].hasOwnProperty('min')) {
                        fltr.get('state').min = ifilters[param]['min']
                    }
                    if (ifilters[param].hasOwnProperty('max')) {
                        fltr.get('state').max = ifilters[param]['max']
                    }
                    if (ifilters[param].hasOwnProperty('exclude')) {
                        fltr.get('state').exclude = ifilters[param]['exclude']
                    }
                }
            }
        }
    }
}

export default Filters;
