export const MainTab = {
  operational: "operational",
  simulation: "simulation",
  config: "config",
};

export const ConfigViewTab = {
  overview: "overview",
  deviations: "deviations",
  electricity: "electricity",
  fuel: "fuel",
};

export const DeviationViewTab = {
  general: "general",
  technical: "technical",
  economic: "economic",
  operational: "operational",
};

export const DefaultConfTab = {
  components: "components",
  electricity: "electricity",
  fuel: "fuel",
  settings: "settings",
};

export const ParameterType = {
  TECHNICAL: "TECHNICAL",
  ECONOMIC: "ECONOMIC",
  CALCULATED: "CALCULATED",
  OPERATIONAL: "OPERATIONAL",
  STATE_INPUT: "STATE_INPUT",
};

export const ConfigCategory = {
  DEFAULT: "Default",
  OFFICIAL: "Official",
  PRIVATE: "Private",
};

export const PropertyType = {
  MIN_EL_MAX_HEAT: "op_point_min_el_max_heat",
  MAX_EL_MAX_HEAT: "op_point_max_el_max_heat",
  MAX_EL_MIN_HEAT: "op_point_max_el_min_heat",
  MIN_EL_MIN_HEAT: "op_point_min_el_min_heat",
  ENERGY_MAX_OUT: "out",
};

export const ComponentType = {
  SIMPLE_EXCHANGE: "SIMPLE_EXCHANGE",
  CHP: "CHP",
  CHILLER: "CHILLER",
  HOB: "HOB",
  THERMAL_DEMAND: "THERMAL_DEMAND",
  COOLING_DEMAND: "COOLING_DEMAND",
  EXTERNAL_CONNECTION: "EXTERNAL_CONNECTION",
  HP: "HP",
  HEAT_PUMP: "HEAT_PUMP",
  ACCUMULATOR: "ACCUMULATOR",
  ABS_CHILLER: "ABS_CHILLER",
  POOL: "POOL",
  DEFAULT_BUILDING_DEMAND: "DEFAULT_BUILDING_DEMAND",
  DEFAULT_BITES: "DEFAULT_BITES",
};

export const CoolingProductionComponents = {
  ABS_CHILLER: "ABS_CHILLER",
  CHILLER: "CHILLER",
};

/**
 * Maps a component type to its energy production key
 */
export const EnergyOutMapping = {
  [ComponentType.CHP]: "out",
  [ComponentType.EXTERNAL_CONNECTION]: "Q_se_out",
  [ComponentType.HOB]: "Q_HOB_out",
  [ComponentType.ABS_CHILLER]: "Q_ACS_in",
  [ComponentType.CHILLER]: "Q_HP_in",
};

export const ChargeDischargeMapping = {
  [ComponentType.POOL]: "Q_pool_control",
  [ComponentType.HP]: "Q_HP_out",
};

export const StoredEnergyMapping = {
  [ComponentType.POOL]: "pool_stored",
  [ComponentType.ACCUMULATOR]: "E_HS",
};

export const HEAT_PRODUCING_COMPONENTS = [
  ComponentType.CHP,
  ComponentType.HOB,
  ComponentType.HEAT_PUMP,
  ComponentType.EXTERNAL_CONNECTION,
];

export const COOLING_PRODUCING_COMPONENTS = [ComponentType.ABS_CHILLER, ComponentType.CHILLER];

export const DEMAND_COMPONENTS = [
  ComponentType.THERMAL_DEMAND,
  ComponentType.COOLING_DEMAND,
  ComponentType.DEFAULT_BUILDING_DEMAND,
  ComponentType.DEFAULT_BITES,
];

// Result Sections
export const RS_COMBINED_SYSTEM = "combined_system";
export const RS_HEATING_NODE = "heating_node";
export const RS_COOLING_NODE = "cooling_node";

export const RESULT_SECTIONS = new Map([
  [RS_COMBINED_SYSTEM, "text_combined_system"],
  [RS_HEATING_NODE, "text_heating_node"],
  [RS_COOLING_NODE, "text_cooling_node"],
]);
