import SvgIcon from "@mui/material/SvgIcon"

export default function SupportIcon(props) {
  return (
    <SvgIcon {...props}>
      <defs>
        <clipPath id="7odufar0za">
          <path data-name="Rectangle 6735" transform="translate(408 1512)" d="M0 0h24v24H0z" />
        </clipPath>
        <clipPath id="jll1jm3srb">
          <path data-name="Rectangle 4850" transform="translate(432 368)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 8613">
        <g>
          <path
            d="M21 0H3a3 3 0 0 0-3 3v13.5a3 3 0 0 0 3 3h4.5v3.938a.561.561 0 0 0 .563.562.538.538 0 0 0 .333-.112L14.25 19.5H21a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3zm1.5 16.5A1.5 1.5 0 0 1 21 18h-7.252l-.4.3L9 21.563V18H3a1.5 1.5 0 0 1-1.5-1.5V3A1.5 1.5 0 0 1 3 1.5h18A1.5 1.5 0 0 1 22.5 3z"
            data-name="Mask Group 402"
          />
        </g>
        <g>
          <path
            d="M6.314 10.33a1.025 1.025 0 1 0 1.025 1.025 1.025 1.025 0 0 0-1.025-1.025zm.286-.878h-.591a.439.439 0 0 1-.439-.439V9c0-2.575 2.835-2.329 2.835-3.933 0-.733-.65-1.472-2.1-1.472a2.42 2.42 0 0 0-2.171 1.049.438.438 0 0 1-.595.087L3.058 4.4a.439.439 0 0 1-.1-.629A3.842 3.842 0 0 1 6.3 2.129c1.916 0 3.568 1.089 3.568 2.937C9.87 7.534 7.034 7.4 7.034 9v.014a.439.439 0 0 1-.439.439z"
            transform="translate(6.095 2.871)"
            data-name="Mask Group 167"
          />
        </g>
      </g>
    </SvgIcon>
  )
}
