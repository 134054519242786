/* eslint-disable camelcase */
import { action, flow, observable, makeObservable } from "mobx";
import { DateTime } from "luxon";

import { printObject } from "../../core/utils";
import { DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS } from "../../conf/constants";

import {
  ComponentType,
  ConfigViewTab,
  DefaultConfTab,
  DeviationViewTab,
  MainTab,
  ParameterType,
} from "./constants";

const DATE_FORMAT = "yyyy-MM-dd'T'HH:00:00";

function default_date_to_iso_shortened(added_hours) {
  // No minutes, seconds or milliseconds
  const now = DateTime.local().plus({ hours: added_hours });
  return now.minus({ minutes: now.minute });
}

const DEFAULT_START = default_date_to_iso_shortened(0);
const DEFAULT_STOP = default_date_to_iso_shortened(168);

// noinspection JSPotentiallyInvalidUsageOfClassThis
class OptimizationStore {
  main_tab = MainTab.simulation;
  opt_status = {};
  opt_result = null;
  fetching = false;
  saving_operational_plan = false;
  fetch_start_date = default_date_to_iso_shortened(0);
  fetch_stop_date = default_date_to_iso_shortened(168 - 6);
  training_start_date = null;
  training_stop_date = null;
  current_network = null;
  networkComponents = [];
  simulationStateInput = [];

  confSortId = "name";
  confSortDirection = "desc";
  configurations = [];
  conf_page_no = 0;
  configRowPerPage = 10;
  current_conf = null;
  conf_detail_tab = ConfigViewTab.overview;
  conf_default_settings_tab = DefaultConfTab.components;

  current_comp = null;
  current_event = null;
  changeable_event = null;
  event_tab = DeviationViewTab.general;

  fetch_noda = false;
  send_noda = false;

  selected_simulation_config_id = -1;

  operational_plan = null;

  show_component_view = false;
  updatable_default_component = { properties: [] };
  component_types = [];
  in_default_edit_mode = false;

  connection_matrix = "";
  network_settings = { el_price_forecast_enabled: false };

  // Run settings
  sim_page_number = 0;
  sim_configs_per_page = 10;

  configId = [];

  constructor(parent) {
    makeObservable(this, {
      configId: observable,
      main_tab: observable,
      opt_status: observable,
      fetching: observable,
      saving_operational_plan: observable,
      fetch_start_date: observable,
      fetch_stop_date: observable,
      training_start_date: observable,
      training_stop_date: observable,
      current_network: observable,
      networkComponents: observable,
      simulationStateInput: observable,
      confSortId: observable,
      confSortDirection: observable,
      configurations: observable,
      conf_page_no: observable,
      configRowPerPage: observable,
      current_conf: observable,
      conf_detail_tab: observable,
      conf_default_settings_tab: observable,
      current_comp: observable,
      current_event: observable,
      changeable_event: observable,
      event_tab: observable,
      fetch_noda: observable,
      send_noda: observable,
      selected_simulation_config_id: observable,
      operational_plan: observable,
      show_component_view: observable,
      updatable_default_component: observable,
      in_default_edit_mode: observable,
      connection_matrix: observable,
      network_settings: observable,
      sim_page_number: observable,
      sim_configs_per_page: observable,
      setCurrentNetwork: action.bound,
      resetToDefaults: action.bound,
      updateFetchStartDate: action.bound,
      updateFetchEndDate: action.bound,
      updateTrainingStartDate: action.bound,
      updateTrainingEndDate: action.bound,
      selectConf: action.bound,
      selectComponent: action.bound,
      selectEvent: action.bound,
      cancelEventChanges: action.bound,
      changeRowsPerPage: action.bound,
      changePageNo: action.bound,
      changeSimRowsPerPage: action.bound,
      changeSimPageNumber: action.bound,
      changeSortDirection: action.bound,
      changeConfDetailTab: action.bound,
      changeDefaultSettingsTab: action.bound,
      changeEventTab: action.bound,
      changeMainTab: action.bound,
      selectConfigId: action.bound,
      updateStateInputStart: action.bound,
      updateStateInputEnd: action.bound,
      clickFetchNoda: action.bound,
      clickSendNoda: action.bound,
      closeComponentView: action.bound,
      openComponentView: action.bound,
      resetUpdatableDefaultComponent: action.bound,
      resetComponentView: action.bound,
      setUpdatableDefaultComponent: action.bound,
      setDefaultComponentInEditMode: action.bound,
      setConnectionMatrix: action.bound,
      setElPriceEnabled: action.bound,
    });

    this.parent = parent;
  }

  selectConfigId(id) {
    this.configId = id;
  }

  setCurrentNetwork(network) {
    this.current_network = network;
  }

  resetToDefaults() {
    const activeYear = this.parent.networks.lpMonth;
    this.fetch_start_date = DEFAULT_START;
    this.fetch_stop_date = DEFAULT_STOP;
    this.training_start_date = activeYear.minus({ month: 18 });
    this.training_stop_date = activeYear;

    this.opt_result = null;
    this.opt_status = {};
    this.fetching = false;
    this.saving_operational_plan = false;
  }

  updateFetchStartDate(start_date) {
    this.fetch_start_date = start_date;
  }

  updateFetchEndDate(end_date) {
    this.fetch_stop_date = end_date;
  }

  updateTrainingStartDate(start_date) {
    this.training_start_date = start_date;
  }

  updateTrainingEndDate(end_date) {
    this.training_stop_date = end_date;
  }

  selectConf(config) {
    this.current_conf = config;
    this.current_comp = null;
    this.current_event = null;
    this.show_component_view = false;
  }

  selectComponent(component) {
    this.current_comp = JSON.parse(JSON.stringify(component));
    this.current_event = null;
  }

  selectEvent(event) {
    this.current_event = event;
    this.changeable_event = JSON.parse(JSON.stringify(this.current_event));
  }

  cancelEventChanges() {
    this.changeable_event = JSON.parse(JSON.stringify(this.current_event));
  }

  changeRowsPerPage(event) {
    this.configRowPerPage = parseInt(event.target.value, 10);
    this.conf_page_no = 0;
  }

  changePageNo(e, value) {
    console.log(`new page: ${value}`);
    this.conf_page_no = 1;
    this.conf_page_no = value;
  }

  changeSimRowsPerPage(event) {
    this.sim_configs_per_page = parseInt(event.target.value, 10);
    this.sim_page_number = 0;
  }

  changeSimPageNumber(e, value) {
    console.log(`new page: ${value}`);
    this.sim_page_number = 1;
    this.sim_page_number = value;
  }

  changeSortDirection(colId, direction) {
    this.confSrtId = colId;
    this.confSortDirection = direction;
  }

  changeConfDetailTab(tab) {
    this.conf_detail_tab = tab;
  }

  changeDefaultSettingsTab(tab) {
    this.conf_default_settings_tab = tab;
  }

  changeEventTab(tab) {
    this.event_tab = tab;
  }

  changeMainTab(tab) {
    this.main_tab = tab;
  }

  updateStateInputStart(component_id, property_id, new_value) {
    const prop = this.simulationStateInput.find(
      (p) => p.comp_id === component_id && p.prop_id === property_id
    );
    prop.start_state = new_value;
  }

  updateStateInputEnd(component_id, property_id, new_value) {
    const prop = this.simulationStateInput.find(
      (p) => p.comp_id === component_id && p.prop_id === property_id
    );
    prop.end_state = new_value;
  }

  clickFetchNoda() {
    this.fetch_noda = !this.fetch_noda;
  }

  clickSendNoda() {
    this.send_noda = !this.send_noda;
  }

  closeComponentView() {
    this.show_component_view = false;
  }

  openComponentView() {
    this.show_component_view = true;
  }

  resetUpdatableDefaultComponent() {
    this.updatable_default_component = {
      name: "",
      external_id: "",
      comp_type: {},
      properties: [],
    };
  }

  resetComponentView() {
    this.resetUpdatableDefaultComponent();
    this.selectComponent(null);
    this.setDefaultComponentInEditMode(false);
  }

  setUpdatableDefaultComponent(component) {
    this.updatable_default_component = component;
  }

  setDefaultComponentInEditMode(inEditMode) {
    this.in_default_edit_mode = inEditMode;
  }

  setConnectionMatrix(matrix) {
    this.connection_matrix = matrix;
  }

  setElPriceEnabled(new_value) {
    this.network_settings.el_price_forecast_enabled = new_value;
  }

  loadOptimizationStatus = flow(function* () {
    this.opt_result = yield this.parent.opt_api.getOptimizationStatus();
  });

  fetchOptimization = flow(function* () {
    const simStartDate = DateTime.fromISO(this.fetch_start_date);
    const simEndDate = DateTime.fromISO(this.fetch_stop_date);
    const trainStartDate = DateTime.fromISO(this.training_start_date);
    const trainEndDate = DateTime.fromISO(this.training_end_date);

    const diffOnSimulationDate = simEndDate.diff(simStartDate, ["days", "hours"]).toObject();
    const diffOnTrainingDate = trainEndDate.diff(trainStartDate, ["days", "hours"]).toObject();

    if (diffOnSimulationDate.days > 15) {
      this.parent.notifStore.error("Date span cannot be more than 2 weeks");
      return;
    }
    if (diffOnSimulationDate.hours < 0 || diffOnSimulationDate.days < 0) {
      this.parent.notifStore.error("Simulation: Start date must be before end date");
      return;
    }
    if (diffOnTrainingDate.hours < 0 || diffOnTrainingDate.days < 0) {
      this.parent.notifStore.error("Training: Start date must be before end date");
      return;
    }

    if (this.hasEmptyStartState()) {
      this.parent.notifStore.error("All state input rows need a start state");
      return;
    }

    this.fetching = true;
    try {
      const res = yield this.parent.opt_api.runSimulationAndGetResult(
        this.fetch_start_date.toFormat(DATE_FORMAT),
        this.fetch_stop_date.toFormat(DATE_FORMAT),
        this.training_start_date.toFormat(DATE_FORMAT),
        this.training_stop_date.toFormat(DATE_FORMAT),
        this.getCurrentNetworkId(),
        this.getCurrentNetworkName(),
        this.selected_simulation_config_id,
        this.getFormattedStateInput(),
        this.fetch_noda,
        false,
        false
      );
      console.log("fetched opt done");
      printObject(res);
      res.summary = this.createSummary(res);

      this.opt_result = res;
      this.fetching = false;
      this.parent.notifStore.success("Simulation completed successfully");
    } catch (err) {
      this.fetching = false;
      this.opt_result = null;
      this.parent.notifStore.error(`Simulation Failed: ${err.message}`);
      throw err;
    }
  });

  hasEmptyStartState() {
    if (!this?.simulationStateInput) {
      return true;
    }

    for (let i = 0; i < this.simulationStateInput.length; i++) {
      const { start_state } = this.simulationStateInput[i];
      if (start_state === null || start_state === "" || start_state === " ") {
        return true;
      }
    }
    return false;
  }

  getFormattedStateInput() {
    return this.simulationStateInput.map((row) => ({
      comp_id: row.comp_id,
      prop_id: row.prop_id,
      start_state: row.start_state,
      end_state: row.end_state,
    }));
  }

  loadOperationalPlan = flow(function* () {
    try {
      console.log("Loading operational plan");
      const result = yield this.parent.opt_api.getOperationalPlan(this.getCurrentNetworkId());
      if (result == null) {
        this.operational_plan = null;
        return;
      }
      result.data.summary = yield this.createSummary(result.data);
      this.operational_plan = result.data;
    } catch (err) {
      this.parent.notifStore.error(`Error during loading operational plan: ${err.error}`);
    }
  });

  saveOperationalPlan = flow(function* () {
    const { start_time } = this.opt_result.input_parameters;
    const { stop_time } = this.opt_result.input_parameters;
    this.saving_operational_plan = true;
    try {
      yield this.parent.opt_api.runSimulationAndGetResult(
        start_time,
        stop_time,
        this.training_start_date,
        this.training_stop_date,
        this.getCurrentNetworkId(),
        this.getCurrentNetworkName(),
        this.selected_simulation_config_id,
        this.getFormattedStateInput(),
        this.fetch_noda,
        this.send_noda,
        true
      );
      this.saving_operational_plan = false;
      this.loadOperationalPlan();
      this.changeMainTab(MainTab.operational);
    } catch (err) {
      this.saving_operational_plan = false;
      this.parent.notifStore.error(`Error during saving operational plan: ${err.error}`);
    }
  });

  loadConfigurations = flow(function* () {
    try {
      this.configurations = yield this.parent.opt_api.getOptConfigurations(
        this.getCurrentNetworkId()
      );
    } catch (err) {
      this.parent.notifStore.error(`Error during loading configurations: ${err.error}`);
    }
  });

  createNewConfig = flow(function* () {
    try {
      const created_config = yield this.parent.opt_api.createNewOptConfig(
        this.getCurrentNetworkId()
      );
      if (created_config != null && created_config.id != null) {
        this.parent.notifStore.success("Configuration created");
        this.loadConfigurations();
      } else {
        this.parent.notifStore.error("Error creating new configuration");
      }
    } catch (err) {
      this.parent.notifStore.error(`Error creating new configuration: ${err.error}`);
    }
  });

  deleteConfig = flow(function* (config_id) {
    try {
      yield this.parent.opt_api.deleteConfig(this.getCurrentNetworkId(), config_id);
      yield this.loadConfigurations();
    } catch (err) {
      this.parent.notifStore.error(`Error deleting configuration: ${err.error}`);
    }
  });

  updateConfiguration = flow(function* (config_id, new_name) {
    try {
      yield this.parent.opt_api.updateConfig(this.getCurrentNetworkId(), config_id, new_name);
      const config_to_update = this.configurations.find((c) => c.id === config_id);
      config_to_update.name = new_name;
    } catch (err) {
      this.parent.notifStore.error(`Error updating configuration: ${err.error}`);
    }
  });

  loadNetworkComponents = flow(function* () {
    try {
      console.log("loading networks");
      this.networkComponents = yield this.parent.opt_api.getNetworkComponents(
        this.getCurrentNetworkId()
      );
      this.simulationStateInput = this.networkComponents
        .map((c) =>
          c.properties
            .filter((p) => p.type === ParameterType.STATE_INPUT)
            .map((p) => ({
              comp_id: c.id,
              comp_name: c.network_name,
              prop_id: p.id,
              prop_name: p.name,
              start_state: "",
              end_state: "",
            }))
        )
        .flat();
    } catch (err) {
      this.parent.notifStore.error(`Error loading network components: ${err.error}`);
    }
  });

  loadDeviationsForConfig = flow(function* () {
    try {
      this.current_conf.deviations = yield this.parent.opt_api.getDeviationsForConfig(
        this.getCurrentNetworkId(),
        this.current_conf.id
      );
    } catch (err) {
      this.parent.notifStore.error(`Error loading deviations for config: ${err.error}`);
    }
  });

  createDeviationEvent = flow(function* () {
    try {
      const created_event = yield this.parent.opt_api.createDeviationEvent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_comp.id
      );
      if (created_event != null) {
        this.current_comp.events.unshift(created_event);
      }
    } catch (err) {
      this.parent.notifStore.error(`Error creating deviation event: ${err.error}`);
    }
  });

  loadEvents = flow(function* () {
    try {
      this.current_comp.events = yield this.parent.opt_api.getEventsForComponent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_comp.id
      );
    } catch (err) {
      this.parent.notifStore.error(`Error loading events: ${err.error}`);
    }
  });

  saveEvent = flow(function* () {
    try {
      const newEvent = JSON.parse(JSON.stringify(this.changeable_event));

      // this can be removed if backend initially created the start_date with DATETIME_FORMAT_ISO_SHORT format
      newEvent.start_date = DateTime.fromISO(this.changeable_event.start_date, {
        zone: "local",
      }).toFormat(DATETIME_FORMAT_ISO_SHORT_FIXED_SECONDS);

      yield this.parent.opt_api.saveEvent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_comp.id,
        newEvent
      );
      this.current_event = JSON.parse(JSON.stringify(this.changeable_event));

      this.selectEvent(this.changeable_event);
      this.loadEvents();
      this.opt_result = null;
    } catch (err) {
      this.parent.notifStore.error(`Error saving event: ${err.error}`);
    }
  });

  deleteEvent = flow(function* () {
    try {
      yield this.parent.opt_api.deleteEvent(
        this.getCurrentNetworkId(),
        this.current_conf.id,
        this.current_event
      );
      this.current_comp.events = this.current_comp.events.filter(
        (e) => e.id !== this.current_event.id
      );
      this.current_event = null;
      this.changeable_event = null;
      this.opt_result = null;
    } catch (err) {
      this.parent.notifStore.error(`Error deleting event: ${err.error}`);
    }
  });

  updateDeviationProperty = flow(function* (property_id, new_value) {
    try {
      if (new_value === "" || new_value === null) {
        yield this.parent.opt_api.deleteDeviationProperty(
          this.getCurrentNetworkId(),
          this.current_event.id,
          property_id
        );
        this.current_conf.deviations = this.current_conf.deviations.filter(
          (d) => !(d.event_id === this.current_event.id && d.property_id === property_id)
        );
      } else {
        const deviation = yield this.parent.opt_api.upsertDeviationProperty(
          this.getCurrentNetworkId(),
          this.current_event.id,
          property_id,
          new_value
        );
        this.current_conf.deviations = this.current_conf.deviations.filter(
          (d) => !(d.event_id === this.current_event.id && d.property_id === property_id)
        );
        this.current_conf.deviations.push(deviation);
      }
      this.opt_result = null;
    } catch (err) {
      this.parent.notifStore.error(`Error updating deviation property: ${err.error}`);
    }
  });

  upsertDefaultPropertyValue = flow(function* (property, new_value) {
    try {
      if (new_value === null || new_value === "") {
        return;
      }
      yield this.parent.opt_api.upsertDefaultPropertyValue(
        this.getCurrentNetworkId(),
        this.current_comp.id,
        property.id,
        new_value
      );
      property.default_value = new_value;
    } catch (err) {
      this.parent.notifStore.error(`Error updating default property value: ${err.error}`);
    }
  });

  loadComponentTypes = flow(function* () {
    try {
      this.component_types = yield this.parent.opt_api.getComponentTypes(
        this.getCurrentNetworkId()
      );
    } catch (err) {
      this.parent.notifStore.error(`Error loading component types: ${err.error}`);
    }
  });

  saveNewComponent = flow(function* () {
    try {
      const postData = this.createComponentApiData();
      yield this.parent.opt_api.postComponent(this.getCurrentNetworkId(), postData);
      yield this.loadNetworkComponents();
    } catch (err) {
      this.parent.notifStore.error(`Error saving new component: ${err.error}`);
    }
  });

  updateComponent = flow(function* () {
    try {
      const postData = this.createComponentApiData();
      yield this.parent.opt_api.putComponent(this.getCurrentNetworkId(), postData);
      yield this.loadNetworkComponents();
    } catch (err) {
      this.parent.notifStore.error(`Error updating component: ${err.error}`);
    }
  });

  deleteComponent = flow(function* () {
    try {
      yield this.parent.opt_api.deleteComponent(this.getCurrentNetworkId(), this.current_comp.id);
      yield this.loadNetworkComponents();
      this.resetComponentView();
      this.closeComponentView();
    } catch (err) {
      this.parent.notifStore.error(`Error deleting component: ${err.error}`);
    }
  });

  loadConnectionMatrix = flow(function* () {
    try {
      this.connection_matrix = yield this.parent.opt_api.getConnectionMatrix(
        this.getCurrentNetworkId()
      );
    } catch (err) {
      this.parent.notifStore.error(`Error loading connection matrix: ${err.error}`);
    }
  });

  saveConnectionMatrix = flow(function* () {
    try {
      yield this.parent.opt_api.postConnectionMatrix(
        this.getCurrentNetworkId(),
        // Remove spaces and newlines from the matrix
        this.connection_matrix
          .replace(/(\r\n|\n|\r)/gm, ",")
          .replace(/,,/g, ",")
          .replace(/\s/g, "")
      );
    } catch (err) {
      this.parent.notifStore.error(`Error saving connection matrix: ${err.error}`);
    }
  });

  loadNetworkSettings = flow(function* () {
    try {
      const settings_result = yield this.parent.opt_api.getNetworkSettings(
        this.getCurrentNetworkId()
      );
      if (settings_result === null || settings_result === undefined) {
        console.log("optimization settings do not exist");
        this.parent.notifStore.error("Optimization settings do not exist");
        return;
      }
      const settings = {
        el_price_forecast_enabled: settings_result.el_price_forecast_enabled ?? false,
      };
      this.network_settings = settings;
    } catch (err) {
      this.parent.notifStore.error(`Error loading network settings: ${err.error}`);
    }
  });

  saveNetworkSettings = flow(function* () {
    try {
      yield this.parent.opt_api.postNetworkSettings(
        this.getCurrentNetworkId(),
        this.network_settings
      );
    } catch (err) {
      this.parent.notifStore.error(`Error saving network settings: ${err.error}`);
    }
  });

  createComponentApiData() {
    const component = this.updatable_default_component;
    const properties = this.updatable_default_component.properties.map((p) => ({
      id: p.id,
      default_value: p.default_value,
    }));
    for (let i = 0; i < properties.length; i++) {
      const p = properties[i];
      if (Array.isArray(p.default_value)) {
        properties[i].default_value = p.default_value.toString();
      }
    }
    let ext_id = null;
    if (component.external_id !== "" && component.external_id !== " ") {
      ext_id = component.external_id;
    }
    return {
      id: component.id,
      type_id: component.comp_type.id,
      name: component.name,
      external_id: ext_id,
      properties,
    };
  }

  createSummary(optData) {
    const summary = {};
    const { kpis } = optData;
    const config = this.configurations.find((c) => c.id === this.configId[0]);
    summary.configName = config.name;
    summary.electricitySoldRevenue = kpis.sold_electricity_revenue;
    summary.productionCost = kpis.cost;
    summary.totalHeatProduction = kpis.heat_produced;
    summary.totalCoolingProduction = kpis.cooling_produced || 0;
    summary.electricityGeneration = kpis.electricity_produced;
    // Net cost per unit of energy = (production cost - rev sold electricity)/(heat generated + cooling generated)
    summary.netUnitCost =
      (summary.productionCost - summary.electricitySoldRevenue) /
      (summary.totalHeatProduction + summary.totalCoolingProduction);

    summary.electricityTotalUse = kpis.electricity_consumed / 1000;
    return summary;
  }

  get_components_by_type(opt_data, type) {
    const components = [];
    for (const key in opt_data.components) {
      const comp = opt_data.components[key];
      if (comp.type === type) {
        components.push(comp);
      }
    }
    return components;
  }

  find_key_by_type(opt_data, type) {
    for (const c in opt_data.components) {
      if (c.type === type) {
        return c;
      }
    }
  }

  getCurrentNetworkId() {
    return this.current_network.uid;
  }

  getCurrentNetworkName() {
    return this.current_network.name;
  }
}

export default OptimizationStore;
