import { flow, observable, makeObservable } from "mobx";
import createAuth0Client from "@auth0/auth0-spa-js";
import { createRouterState } from "mobx-state-router";

import { REACT_APP_DUMMY_TOKEN, is_feature_flag_on as isFeatureFlagOn } from "../conf/conf";

class Session {
  err_msg = null;
  user_id = null;

  constructor(parent) {
    makeObservable(this, {
      err_msg: observable,
      user_id: observable,
    });

    this.parent = parent;
    this.audience = process.env.REACT_APP_AUTH0_AUDIENCE;
    this.domain = process.env.REACT_APP_AUTH0_DOMAIN;
    this.client_id = process.env.REACT_APP_AUTH0_CLIENTID;
    this.redirect_uri = window.location.origin;
    this.client = null;
    this.getToken = this.getToken.bind(this);

    if (isFeatureFlagOn("DUMMYAUTH")) {
      this.checkSession = this.checkDevSession.bind(this);
      this.authHeaders = this.authDevHeaders.bind(this);
      this.getUserProfile = this.getUserProfileDummy.bind(this);
    } else {
      this.checkSession = this.checkProdSession.bind(this);
      this.authHeaders = this.authProdHeaders.bind(this);
      this.getUserProfile = this.getUserProfileAuth0.bind(this);
    }
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);

    // We always need to set this to the "memory" on production due to security
    // However, in order to make login programatic with Cypress we need to use "localstorage"
    const cacheLocation = process.env.NODE_ENV === "production" ? "memory" : "localstorage";
    this.authContext = {
      domain: this.domain,
      client_id: this.client_id,
      redirect_uri: this.redirect_uri,
      responseType: "token",
      audience: this.audience,
      useRefreshTokens: true,
      cacheLocation,
    };
  }

  checkProdSession = flow(function* checkProdSession() {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    return yield this.client.isAuthenticated();
  });

  checkDevSession = flow(function* checkDevSession() {
    yield true;
    return true;
  });

  login = flow(function* login() {
    this.client = yield createAuth0Client(this.authContext);
    let isSuccessful = false;
    try {
      this.err_msg = null;
      yield this.client.loginWithRedirect();
      isSuccessful = true;
    } catch (err) {
      if (err.error === "unauthorized") {
        this.err_msg = err.error_description;
      }
    }
    if (isSuccessful) {
      yield this.parent.startup();
      this.parent.routerStore.goTo(createRouterState("dash"));
    }
  });

  getUserProfileAuth0 = flow(function* getUserProfileAuth0() {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    return yield this.client.getUser();
  });

  getUserProfileDummy = flow(function* getUserProfileDummy() {
    yield null;
    return { email: `dummy_${REACT_APP_DUMMY_TOKEN}@dispostable.com` };
  });

  handleRedirectCallback = flow(function* handleRedirectCallback() {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    try {
      yield this.client.handleRedirectCallback();
    } catch (err) {
      this.err_msg = err.message;
    }
  });

  getToken = flow(function* getToken() {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    try {
      return yield this.client.getTokenSilently();
    } catch (err) {
      return null;
    }
  });

  authProdHeaders = flow(function* authProdHeaders() {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    try {
      const token = yield this.client.getTokenSilently();
      return { authorization: `Bearer ${token}` };
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("ERROR in getting token", err);
      if (err.error === "unauthorized") {
        this.err_msg = err.error_description;
        this.parent.routerStore.goTo(createRouterState("login"));
        return false;
      }
    }
    return null;
  });

  authDevHeaders = flow(function* authDevHeaders() {
    yield;
    return { authorization: `Bearer ${REACT_APP_DUMMY_TOKEN}` };
  });

  logout = flow(function* logout() {
    if (!this.client) {
      this.client = yield createAuth0Client(this.authContext);
    }
    this.client.logout({ returnTo: window.location.origin });
    yield this.parent.clearCache();
  });
}

export default Session;
