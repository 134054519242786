import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

import { formatNumberForUnitMainLabel } from "../../../core/utils";
import LargeNumber from "../../analytics/LargeNumber";
import ArrowDeviation from "../../analytics/ArrowDeviation";
import HistoryIcon from "../../../icons/History";
import GraphTop from "../../../pages/Distribution/GraphTop";
import GraphColCompare from "../../analytics/ColumnSeriesCompare";
import DumbbellIcon from "../../../icons/Dumbbell";
import InfoCard from "../../analytics/InfoCard";

export const useStyles = makeStyles((theme) => ({
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: theme.spacing(6),
    paddingLeft: theme.spacing(2),
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    position: "relative",
  },
  subTitle: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    gap: theme.spacing(1),
    borderLeft: `solid 2px ${theme.palette.grey.blue2}`,
    height: "inherit",
  },
  colCompareCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.16)",
    padding: theme.spacing(3, 2),
    height: theme.spacing(6),
    gridGap: theme.spacing(1.25),
  },
  formWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
    borderLeft: `solid 2px ${theme.palette.grey.blue2}`,
    padding: theme.spacing(0, 2),
  },
}));

export function Card(props) {
  const classes = useStyles();
  const {
    errorMsg,
    title,
    data,
    dataStatus,
    temperatureUnit,
    showSub = true,
    subtext,
    trendSuffix = "%",
    dataTooltip,
    loading,
  } = props;
  let mainNumber;
  let mainExp = 0;
  let mainPrefix = "";
  let mainUnit = "";

  if (dataStatus.isData) {
    [mainNumber, mainExp, mainPrefix, mainUnit] = formatNumberForUnitMainLabel(data.value, "kWh");
  }
  const unit = temperatureUnit || mainPrefix + mainUnit;
  let haveData = true;
  let hveError = false;

  if (!loading && mainNumber !== "0") {
    haveData = mainNumber !== "0";
    hveError = mainNumber === "0";
  } else if (!loading && mainNumber === "0") {
    haveData = mainNumber !== "0";
    hveError = mainNumber === "0";
  } else {
    haveData = false;
    hveError = false;
  }

  return (
    <InfoCard
      haveData={haveData ?? dataStatus.isData}
      haveError={hveError ?? dataStatus.isError}
      errmsg={errorMsg}
    >
      <Box className={classes.head} margin={-2}>
        <Typography variant="h6">{title}</Typography>
        {showSub && (
          <div className={classes.subTitle}>
            <DumbbellIcon />
            <Typography variant="h6">{subtext}</Typography>
          </div>
        )}
      </Box>

      <LargeNumber value={mainNumber} exp={mainExp} unit={unit} />

      {data.trend ? (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <div />
          <Tooltip title={dataTooltip}>
            <ArrowDeviation value={data.trend} suffix={trendSuffix} />
          </Tooltip>
        </Box>
      ) : null}
    </InfoCard>
  );
}

export function ColCompareCard(props) {
  const { title, dataStatus, errorMsg, units, legend, categories, series1, series2 } = props;
  const classes = useStyles();
  return (
    <InfoCard
      haveData={dataStatus.isData}
      haveError={Boolean(dataStatus.isError)}
      errmsg={errorMsg}
    >
      <Box margin={-2} className={classes.colCompareCard}>
        <HistoryIcon />
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box>
        <GraphTop unit={units.mainUnit} legend={legend} />
        <GraphColCompare
          series1={{
            ...series1,
            pointWidth: 12,
          }}
          series2={{
            ...series2,
            type: "line",
            linewidth: 3,
            marker: {
              enabled: true,
              radius: 6,
            },
            pointPlacement: 0,
          }}
          unit={units.comparisonUnit}
          categories={categories}
        />
      </Box>
    </InfoCard>
  );
}
