/* eslint-disable react/no-this-in-sfc */
export const { REACT_APP_GMAPS_KEY } = process.env;

export const APP_VERSION = `${process.env.REACT_APP_BUILDDATE}:${process.env.REACT_APP_GIT_SHA}`;
export const REACT_APP_IBS_CACHING = process.env.REACT_APP_IBS_CACHING === "true";
export const GA_TRACKID = process.env.REACT_APP_GOOGLE_TRACKING_CODE;
export const NUMBER_FORMAT = "0,0.00";
export const EPO_CHECK_DURATION = 5000; // in ms
export const REACT_APP_DUMMY_TOKEN = `${process.env.REACT_APP_DUMMY_TOKEN || "dummy"}`;
const featurelist = `${process.env.REACT_APP_FEATURES}`.split(",");
// eslint-disable-next-line camelcase
export function is_feature_flag_on(flagname) {
  return featurelist.indexOf(flagname) >= 0;
}

const MODULE = {
  gmaps_key: REACT_APP_GMAPS_KEY,
};
export default MODULE;
