import { action, flow, makeObservable } from "mobx";
import { DateTime } from "luxon";

import URLS from "../conf/urls";
import { sleep } from "../core/utils";
import { ConfigCategory } from "../pages/Optimization/constants";

function ApiDetailError(obj) {
  return {
    error: obj.error,
    message: obj.details,
  };
}

function ApiError(obj) {
  return {
    error: obj.error ? obj.error : obj,
    message: obj.message,
  };
}

function urlWithParams(nurl, params) {
  const url = new URL(nurl);
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  return url;
}

function StrRepr(msg) {
  if (typeof msg === "object") {
    return JSON.stringify(msg);
  }
  return msg;
}

function parseCategory(official, default_conf) {
  if (default_conf) return ConfigCategory.DEFAULT;
  if (official) return ConfigCategory.OFFICIAL;
  return ConfigCategory.PRIVATE;
}

export default class OptApi {
  processErrors(err, msg) {
    let errorMessage = "";
    if (err.response) {
      if (err.response.status === 401) {
        this.parent.session.logout();
        return;
      }
      errorMessage = StrRepr(msg);
    } else {
      errorMessage = StrRepr(err.message);
    }
    this.parent.notifStore.error(errorMessage);
  }

  constructor(parent) {
    makeObservable(this, {
      processErrors: action.bound,
    });

    this.parent = parent;
  }

  runSimulationAndGetResult = flow(function* (
    start_time,
    stop_time,
    training_start,
    training_stop,
    network_id,
    network_name,
    config_id,
    state_input,
    fetch_noda,
    send_noda,
    make_official,
    is_scenario
  ) {
    const headers = yield this.parent.session.authHeaders();
    const post_data = {
      name: "optimization",
      params: {
        network_name,
        network_id,
        start_time,
        stop_time,
        training_start,
        training_stop,
        config_id,
        state_input,
        fetch_flex: fetch_noda,
        send_flex: send_noda,
        make_official,
        is_scenario,
      },
    };
    console.log(`Opt url: ${URLS.baseOptimization}`);
    const base_url = `${URLS.baseOptimization}/jobs/optimization`;
    const res = yield fetch(urlWithParams(base_url, {}), {
      method: "post",
      headers,
      body: JSON.stringify(post_data),
    });

    const post_response = yield res.json();
    const { job_id } = post_response;
    console.log(`JOB_ID: ${job_id}`);
    const status_url = `${base_url}/${job_id}`;
    let counter = 0;
    let job_done = false;
    while (true) {
      const poll_res = yield fetch(urlWithParams(status_url, {}), {
        headers,
      });
      const poll_json = yield poll_res.json();
      if (poll_res.status === 200 && poll_json.status === "done") {
        if (poll_json.statusCode === "500") {
          throw Error(poll_json.error);
        }
        job_done = true;
        break;
      } else if (poll_res.status >= 400) {
        throw Error(poll_json.error);
      }
      console.log(`http status is: ${poll_res.status} job status is: ${poll_json.status}`);
      if (counter > 150) {
        console.log("Too many polls, exiting");
        break;
      }
      counter++;
      yield sleep(5000);
    }
    if (!job_done) {
      throw Error("Too many polls");
    }
    const result_url = `${base_url}/${job_id}/result`;
    console.log(`trying to fetch result from: ${result_url}`);

    let resData = null;
    try {
      const res = yield fetch(urlWithParams(result_url, {}), { headers });
      resData = yield res.json();
      if (res.status !== 200) {
        throw new ApiDetailError(`Error code during fetch results: ${resData.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }

    if (resData != null) {
      switch (resData.simulation_status) {
        case "Problem infeasible":
          this.parent.notifStore.error("Simulation failed: Problem infeasible", {
            autoClose: false,
          });
          break;
        case "feasible solution but solver timed out":
          this.parent.notifStore.warning("Simulation failed: Solver timed out", {
            autoClose: false,
          });
          break;
        default:
          break;
      }
    }
    console.log("Simulation completed successfully");
    console.log(resData);
    return resData;
  });

  getConfigurations = flow(function* (baseUrl, networkId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${baseUrl}/config/network/${networkId}/configurations`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resultData = yield res.json();
        return resultData.map((c) => ({
          name: c.name,
          category: parseCategory(c.official, c.default),
          id: c.id,
          created: DateTime.fromISO(c.created_at, { zone: "UTC+00:00" }),
          updated: DateTime.fromISO(c.last_modified, { zone: "UTC+00:00" }),
          components: c.components ?? [],
          connection_matrix: c.connection_matrix ?? "",
          fetch_external_prices: c.fetch_external_prices ?? false,
        }));
      }
      throw new ApiDetailError({
        error: res.statusText,
        details: res.status,
      });
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getOptConfigurations = flow(function* (networkId) {
    return yield this.getConfigurations(URLS.optimization, networkId);
  });

  getScenarioAnalysisConfigurations = flow(function* (networkId) {
    return yield this.getConfigurations(URLS.scenarioAnalysis, networkId);
  });

  createNewConfig = flow(function* (baseUrl, networkId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${baseUrl}/config/network/${networkId}/configuration`;
      const res = yield fetch(urlWithParams(url, {}), { method: "post", headers });
      if (res.ok) {
        const resultData = yield res.json();
        return resultData;
      }
      throw new ApiDetailError({
        error: res.statusText,
        details: res.status,
      });
    } catch (err) {
      throw new ApiError(err);
    }
  });

  createNewOptConfig = flow(function* (networkId) {
    return yield this.createNewConfig(URLS.optimization, networkId);
  });

  createNewScenarioAnalysisConfig = flow(function* (networkId) {
    try {
      return yield this.createNewConfig(URLS.scenarioAnalysis, networkId);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  /**
   * @param network_id
   * @param config_id
   */
  deleteConfig = flow(function* (network_id, config_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}`;
      const res = yield fetch(urlWithParams(url, {}), { method: "delete", headers });
      if (res.status !== 204) {
        throw new ApiError(`Error during API call, status code: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
    return null;
  });

  updateConfig = flow(function* (network_id, config_id, new_name) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "PATCH",
        headers,
        body: JSON.stringify({ name: new_name }),
      });
      if (res.status !== 200) {
        throw new ApiError(`Error during API call, status code: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
    return null;
  });

  getNetworkComponents = flow(function* (network_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/components`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resultData = yield res.json();
        return resultData.map((c) => ({
          id: c.component_id,
          type_id: c.component_type_id,
          external_id: c.external_id,
          type: c.type,
          label: c.label,
          flexibility: c.flexibility,
          network_name: c.name,
          events: [],
          properties: c.properties,
        }));
      }
      throw new ApiDetailError({
        error: res.statusText,
        details: res.status,
      });
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getScenarioConfigComponents = flow(function* (networkId, configId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/components`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      if (res.ok) {
        const resultData = yield res.json();
        return resultData.map((c) => ({
          id: c.component_id,
          type_id: c.component_type_id,
          external_id: c.external_id,
          type: c.type,
          label: c.label,
          flexibility: c.flexibility,
          name: c.name,
          events: [],
          properties: c.properties,
        }));
      }
      throw new ApiDetailError({
        error: res.statusText,
        details: res.status,
      });
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getComponentTypes = flow(function* (network_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component-types`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      const data = yield res.json();
      if (res.ok) {
        return data;
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getScenarioAnalysisComponentTypes = flow(function* () {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/components/types`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      const data = yield res.json();
      if (res.ok) {
        return data;
      }
      throw new ApiDetailError({
        error: res.statusText,
        details: res.status,
      });
    } catch (err) {
      throw new ApiError(err);
    }
  });

  createDeviationEvent = flow(function* (network_id, config_id, component_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/component/${component_id}/deviation_event`;
      const res = yield fetch(urlWithParams(url, {}), { method: "post", headers });
      return yield res.json();
    } catch (err) {
      console.log(err);
      throw new ApiError(err);
    }
  });

  getEventsForComponent = flow(function* (network_id, config_id, component_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/component/${component_id}/events`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      const result_data = yield res.json();
      if (res.ok) {
        return result_data.map((e) => ({
          id: e.id,
          config_id: e.config_id,
          component_id: e.component_id,
          name: e.name,
          comment: e.comment,
          disabled: e.disabled,
          start_date: new Date(e.start_date).toISOString(),
          end_date: e.end_date,
          last_modified: e.last_modified,
        }));
      }
      throw new ApiDetailError(result_data);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  saveEvent = flow(function* (network_id, config_id, component_id, event) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/component/${component_id}/event/${event.id}`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(event),
        headers,
      });
      if (!res.ok) {
        throw new ApiError(`Error when saving event: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });

  deleteEvent = flow(function* (network_id, config_id, event) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/event/${event.id}`;
      const res = yield fetch(urlWithParams(url, {}), { method: "DELETE", headers });
      if (!res.ok) {
        throw new ApiError(`Error when saving event: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });

  upsertDeviationProperty = flow(function* (network_id, event_id, property_id, value) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/event/${event_id}/property/${property_id}`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify({ value }),
        headers,
      });
      if (!res.ok) {
        throw new ApiError(`Error when saving event: ${res.status}`);
      }
      return yield res.json();
    } catch (err) {
      throw new ApiError(err);
    }
  });

  deleteDeviationProperty = flow(function* (network_id, event_id, property_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/event/${event_id}/property/${property_id}`;
      const res = yield fetch(urlWithParams(url, {}), { method: "DELETE", headers });
      console.log(res.ok);
      if (res.status !== 204) {
        throw new ApiError(`Error when deleting deviation: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getDeviationsForConfig = flow(function* (network_id, config_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/configuration/${config_id}/deviations`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      const result_data = yield res.json();
      if (res.ok) {
        return result_data.map((d) => ({
          id: d.id,
          event_id: d.event_id,
          property_id: d.property_id,
          value: d.value,
        }));
      }
      throw new ApiDetailError(result_data);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  upsertDefaultPropertyValue = flow(function* (network_id, comp_id, property_id, value) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component/${comp_id}/property/${property_id}/default`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify({ value }),
        headers,
      });
      if (!res.ok) {
        throw new ApiError(`Error when saving event: ${res.status}`);
      }
      return yield res.json();
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getOperationalPlan = flow(function* (network_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/operational-plan`;
      const res = yield fetch(urlWithParams(url, {}), { headers });
      if (!res.ok) {
        throw new ApiError(`Error when fetching operational plan: ${res.status}`);
      }
      const result = yield res.json();
      return {
        ...result,
        created_at: DateTime.fromISO(result.created_at, { zone: "UTC+00:00" }).toISO(),
      };
    } catch (err) {
      throw new ApiError(err);
    }
  });

  postComponent = flow(function* (network_id, component) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify(component),
        headers,
      });
      if (res.status !== 201) {
        throw new ApiError(`Error when creating component, bad response code: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });

  createScenarioAnalysisComponent = flow(function* (networkId, configId, component) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/component`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "POST",
        body: JSON.stringify(component),
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 201) {
        return yield res.json();
      }
      throw new ApiError(`Error when creating component, bad response code: ${res.status}`);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  updateScenarioAnalysisComponent = flow(function* (networkId, configId, component) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/component/${component.id}`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(component),
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        return yield res.json();
      }
      throw new ApiError(`Error when updating component, bad response code: ${res.status}`);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  deleteScenarioAnalysisComponent = flow(function* (networkId, configId, componentId) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${configId}/component/${componentId}`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "DELETE",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 204) {
        return;
      }
      throw new ApiError(`Error when deleting component, bad response code: ${res.status}`);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  saveScenarioAnalysisConfig = flow(function* (networkId, config) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.scenarioAnalysis}/config/network/${networkId}/configuration/${config.id}`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(config),
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200) {
        return yield res.json();
      }
      throw new ApiError(`Error when saving config, bad response code: ${res.status}`);
    } catch (err) {
      throw new ApiError(err);
    }
  });

  putComponent = flow(function* (network_id, component) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component`;
      const res = yield fetch(urlWithParams(url, {}), {
        method: "PUT",
        body: JSON.stringify(component),
        headers,
      });
      if (res.status !== 200) {
        throw new ApiError(`Error when creating component, bad response code: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });

  deleteComponent = flow(function* (network_id, component_id) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const url = `${URLS.optimization}/config/network/${network_id}/component/${component_id}`;
      const res = yield fetch(urlWithParams(url, {}), { method: "delete", headers });
      if (res.status !== 204) {
        throw new ApiError(`Error when creating component, bad response code: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });

  getConnectionMatrix = flow(function* (network_id) {
    const url = `${URLS.optimization}/config/network/${network_id}/con-matrix`;
    return yield this.getData(network_id, url);
  });

  postConnectionMatrix = flow(function* (network_id, matrix) {
    const url = `${URLS.optimization}/config/network/${network_id}/con-matrix`;
    yield this.pushData(network_id, url, "POST", 201, matrix);
  });

  getNetworkSettings = flow(function* (network_id) {
    const url = `${URLS.optimization}/config/network/${network_id}/settings`;
    return yield this.getData(network_id, url);
  });

  postNetworkSettings = flow(function* (network_id, settings) {
    const url = `${URLS.optimization}/config/network/${network_id}/settings`;
    yield this.pushData(network_id, url, "POST", 200, settings);
  });

  getData = flow(function* (network_id, url) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(url, {}), { headers });
      if (!res.ok) {
        throw new ApiError(`Error when fetching operational plan: ${res.status}`);
      }
      return yield res.json();
    } catch (err) {
      throw new ApiError(err);
    }
  });

  pushData = flow(function* (network_id, url, method, accept_status, data) {
    const headers = yield this.parent.session.authHeaders();
    try {
      const res = yield fetch(urlWithParams(url, {}), {
        method,
        body: JSON.stringify(data),
        headers,
      });
      if (res.status !== accept_status) {
        console.log("ERROR");
        throw new ApiError(`Error when creating component, bad response code: ${res.status}`);
      }
    } catch (err) {
      throw new ApiError(err);
    }
  });
}
