import { COLUMN_PERIOD, COLUMN_SECTION } from "./constants";

// DESIGN LOAD
const SYSTEM_DESIGN_LOAD_COLUMNS = {
  volume: {
    label: "text_flow_at_system_design_load_m3",
    section: COLUMN_SECTION.design_load,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
  },
  returntemp: {
    label: "text_return_temp_at_supply_design_load_c",
    section: COLUMN_SECTION.design_load,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
  },
  heat_energy: {
    label: "text_system_design_load_kw",
    section: COLUMN_SECTION.design_load,
    periods: [COLUMN_PERIOD.year, COLUMN_PERIOD.r12],
  },
};

const SYSTEM_DESIGN_LOAD_COLUMNS_1H = {
  volume: {
    ...SYSTEM_DESIGN_LOAD_COLUMNS.volume,
    sublabel: "text_1h_average",
    info: "text_flow_at_system_design_load_m3_1h_desc",
  },
  returntemp: {
    ...SYSTEM_DESIGN_LOAD_COLUMNS.returntemp,
    sublabel: "text_1h_average",
    info: "text_return_temp_at_supply_design_load_c_1h_desc",
  },
  heat_energy: {
    ...SYSTEM_DESIGN_LOAD_COLUMNS.heat_energy,
    sublabel: "text_1h_average",
    info: "text_system_design_load_kw_1h_desc",
  },
};

const SYSTEM_DESIGN_LOAD_COLUMNS_24H = {
  volume: {
    ...SYSTEM_DESIGN_LOAD_COLUMNS.volume,
    sublabel: "text_24h_average",
    info: "text_flow_at_system_design_load_m3_24h_desc",
  },
  returntemp: {
    ...SYSTEM_DESIGN_LOAD_COLUMNS.returntemp,
    sublabel: "text_24h_average",
    info: "text_return_temp_at_supply_design_load_c_24h_desc",
  },
  heat_energy: {
    ...SYSTEM_DESIGN_LOAD_COLUMNS.heat_energy,
    sublabel: "text_24h_average",
    info: "text_system_design_load_kw_24h_desc",
  },
};

export { SYSTEM_DESIGN_LOAD_COLUMNS_1H, SYSTEM_DESIGN_LOAD_COLUMNS_24H };
