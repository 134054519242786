export const SUBSTATION_STATES = [
  {
    label: "text_ignored",
    value: "ignore",
  },
  {
    label: "text_visible",
    value: "visible",
  },
];

export const FILTER_STATES = new Map([
  ["All", "text_all"],
  ["Ignored", "text_ignored"],
  ["Relevant", "text_relevant"],
]);
