import { createRouterState } from "mobx-state-router";
import { flow } from "mobx";

import { is_feature_flag_on } from "./conf";
import FEATURE from "./feature_access";
import wikiData from "./wiki_constants";

const routeEntryHandler = (accessRequired = []) =>
  flow(function* (fromState, toState, routerStore) {
    // First just scroll to the top of the page on navigation.
    window.scrollTo(0, 0);
    // Then we just check if the user is logged in.

    const { session, networks } = routerStore.options.parent;

    // Mouseflow integration
    window._mfq.push(["config", "htmlDelay", 5000]);
    window._mfq.push([toState.routeName, location.pathname]);
    const profile = yield session.getUserProfile();
    if (profile && profile.email) {
      const email_split = profile.email.split("@");
      if (email_split.length > 1) {
        window._mfq.push(["tag", email_split[1]]);
      }
    }
    if (networks.current_network != null && networks.current_network != undefined) {
      window._mfq.push(["tag", networks.current_network.name]);
    }

    let isLoggedIn = yield session.checkSession();
    // The "startup" will fetch networks and features
    // so "networks.haveAccess" can read enabled features
    if (fromState && fromState.routeName === "__initial__" && isLoggedIn) {
      yield routerStore.options.parent.startup();
    }

    if (isLoggedIn) {
      // If the user is logged in, we check if they have access to the page.
      if (networks.haveAccess(accessRequired)) {
        // If they have access, we inject the custom options for the route
        // This is done to have access to things like the wiki url in the routeState
        const toRoute = routerStore.routes.find((route) => route.name === toState.routeName);
        const modifiedToState = { ...toState };
        if (toRoute.options) {
          // Expand options with toRoute.options.
          modifiedToState.options = { ...toRoute.options, ...toState.options };
        }
        return modifiedToState;
      }
      // If they don't have access, we redirect them to the feature access denied page.
      return createRouterState("feature_access_denied");
    }

    // If the user is not logged in, and there is some state query, they might be busy with a login.
    if (window.location.search.includes("state=")) {
      yield session.handleRedirectCallback();
      // So we check if they are logged in now.
      isLoggedIn = yield session.checkSession();
      if (isLoggedIn) {
        // If they are logged in, we drop them on the dashboard
        return createRouterState("dash");
      }
    }
    // If the user is not logged in, then take them to the login page.
    return createRouterState("login");
  });

const routes = [
  {
    name: "dash",
    pattern: "/",
    beforeEnter: routeEntryHandler([FEATURE.home]),
    options: {
      wikiUrl: wikiData.dash,
    },
  },
  {
    name: "metering",
    pattern: "/metering",
    beforeEnter: routeEntryHandler([FEATURE.metering]),
    options: {
      wikiUrl: wikiData.metering,
    },
  },
  {
    name: "distribution",
    pattern: "/distribution",
    beforeEnter: routeEntryHandler([FEATURE.dist]),
    options: {
      wikiUrl: wikiData.distribution,
    },
  },
  {
    name: "rt_analysis",
    pattern: "/distribution/return_temperature_analysis",
    beforeEnter: routeEntryHandler([FEATURE.dist_rta, FEATURE.dist_rtsp]),
    options: {
      wikiUrl: wikiData.rt_analysis,
    },
  },
  {
    name: "design_load",
    pattern: "/distribution/design_load",
    beforeEnter: routeEntryHandler([FEATURE.dist_dload]),
    options: {
      wikiUrl: wikiData.design_load,
    },
  },
  {
    name: "production",
    pattern: "/production",
    beforeEnter: routeEntryHandler([FEATURE.production]),
    options: {
      wikiUrl: wikiData.production,
    },
  },
  {
    name: "forecast",
    pattern: "/forecast",
    beforeEnter: routeEntryHandler([FEATURE.forecast]),
    options: {
      wikiUrl: wikiData.forecast,
    },
  },
  {
    name: "sales",
    pattern: "/sales",
    beforeEnter: routeEntryHandler([FEATURE.sales]),
    options: {
      wikiUrl: wikiData.sales,
    },
  },
  {
    name: "substations",
    pattern: "/substations",
    beforeEnter: routeEntryHandler([FEATURE.datalibrary]),
    options: {
      wikiUrl: wikiData.data_library,
    },
  },
  {
    name: "mapview",
    pattern: "/mapview",
    beforeEnter: routeEntryHandler([FEATURE.mapview]),
    options: {
      wikiUrl: wikiData.mapview,
    },
  },
  {
    name: "login",
    pattern: "/login",
  },
  {
    name: "notFound",
    pattern: "/not-found",
  },
  {
    name: "feature_access_denied",
    pattern: "/feature-access-denied",
  },
  {
    name: "profile",
    pattern: "/profile",
    beforeEnter: routeEntryHandler([FEATURE.profile]),
  },
  {
    name: "settings",
    pattern: "/settings",
    beforeEnter: routeEntryHandler([FEATURE.profile]),
  },
  {
    name: "auth_management",
    pattern: "/auth_management",
    beforeEnter: routeEntryHandler(),
  },
  {
    name: "pricing",
    pattern: "/pricing",
    beforeEnter: routeEntryHandler([FEATURE.pricing]),
    options: {
      wikiUrl: wikiData.pricing,
    },
  },
];

// Test features that are activated by a specific feature flag.
if (is_feature_flag_on("TEST_ADMIN") || is_feature_flag_on("BLOCK_ADMIN")) {
  routes.push({
    name: "testing",
    pattern: "/testing",
    beforeEnter: routeEntryHandler(),
  });
}

if (is_feature_flag_on("TEST_ADMIN")) {
  routes.push({
    name: "test_admin",
    pattern: "/testing/general",
    beforeEnter: routeEntryHandler(),
  });
}
if (is_feature_flag_on("BLOCK_ADMIN")) {
  routes.push({
    name: "block_admin",
    pattern: "/testing/blockexplorer",
    beforeEnter: routeEntryHandler(),
  });
}

if (is_feature_flag_on("OPTIMIZATION")) {
  routes.push({
    name: "optimization",
    pattern: "/optimization",
    beforeEnter: routeEntryHandler([FEATURE.optimization]),
  });
}

if (is_feature_flag_on("FAULT_DETECTION")) {
  routes.push({
    name: "fault_detection",
    pattern: "/fault_detection",
    beforeEnter: routeEntryHandler([FEATURE.fault_detection]),
  });
}

if (is_feature_flag_on("SCENARIO_ANALYSIS")) {
  routes.push({
    name: "scenario_analysis",
    pattern: "/scenario_analysis",
  });
}

export { routes };

/**
 * This adds a new key to the url query string
 *
 * @param {string} key Key used  for the query params
 * @param {string} value Value of the query param
 */
export function insertUrlParam(key, value) {
  if (window.history.pushState) {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    const newurl = `${window.location.origin}${
      window.location.pathname
    }?${searchParams.toString()}`;
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

/**
 * This removes a key from the url query string
 *
 * @param {string} key Key used  for the query params
 */
export function removeUrlParam(key, win = window) {
  if (win.history.pushState) {
    const searchParams = new URLSearchParams(win.location.search);
    searchParams.delete(key);
    const newurl = `${win.location.origin}${win.location.pathname}?${searchParams.toString()}`;
    win.history.pushState({ path: newurl }, "", newurl);
  }
}

/**
 * This gets a value from the url query string
 *
 * @param {string} key Key used  for the query params
 * @returns {string} the value of the query param or null if it doesn't exist
 */
export function getUrlParam(key) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
}
