import React from "react";
import { lazyWithPreload } from "react-lazy-with-preload";

import HomeIcon from "../icons/Home";
import MeteringIcon from "../icons/Meter";
import SalesIcon from "../icons/Sales";
import DistributionIcon from "../icons/Distribution";
import MapIcon from "../icons/Map";
import ProductionIcon from "../icons/Production";
import LoginPage from "../pages/LoginPage";
import NotFoundPage from "../pages/NotFound";
import FeatureAccessDeniedPage from "../pages/FeatureAccessDenied";
import DashPage from "../pages/dash";

import features from "./feature_access";

const MeteringDashPage = lazyWithPreload(() => import("../pages/MeteringDash/Dashboard"));
const ProductionPage = lazyWithPreload(() => import("../pages/Production"));
const ReturnTemperatureAnalysisPage = lazyWithPreload(() => import("../pages/RTAnalysis"));
const ForeCastPage = lazyWithPreload(() => import("../pages/ForeCast"));
const DesignLoadPage = lazyWithPreload(() => import("../pages/DesignLoad"));
const Optimization = lazyWithPreload(() => import("../pages/Optimization"));
const SubstationPage = lazyWithPreload(() => import("../pages/Substations"));
const DistributionDash = lazyWithPreload(() => import("../pages/Distribution"));
const Mapview = lazyWithPreload(() => import("../pages/MapView"));
const SalesDash = lazyWithPreload(() => import("../pages/SalesDash"));
const PricingCombo = lazyWithPreload(() => import("../pages/PricingCombo"));
const FaultDetection = lazyWithPreload(() => import("../pages/MeteringDash/FaultDetection"));
const ScenarioAnalysis = lazyWithPreload(() => import("../pages/ScenarioAnalysis"));
const ProfilePage = lazyWithPreload(() => import("../pages/Profile"));
const SettingsPage = lazyWithPreload(() => import("../pages/Profile/Settings"));

const BlockAdmin = lazyWithPreload(() => import("../pages/BlockAdmin"));
const TestAdmin = lazyWithPreload(() => import("../pages/TestAdmin"));
const OptionalTest = lazyWithPreload(() => import("../pages/OptionalTest"));

// A mapping that needed to preload page chunks
// See: src/stores/interactions "preloadPageChunkByPermissions" method
export const permissionToComponentMap = {
  [features.metering]: MeteringDashPage,
  [features.production]: ProductionPage,
  [features.dist_rta]: ReturnTemperatureAnalysisPage,
  [features.prod_frcst]: ForeCastPage,
  [features.dist_dload]: DesignLoadPage,
  [features.optimization]: Optimization,
  [features.datalibrary]: SubstationPage,
  [features.dist]: DistributionDash,
  [features.sales]: SalesDash,
  [features.pricing]: PricingCombo,
  [features.scenario_analysis]: ScenarioAnalysis,
  [features.fault_detection]: FaultDetection,
  [features.mapview]: Mapview,
  [features.profile]: ProfilePage,
};

function FallbackLoader() {
  return <>...</>;
}

const routeMappings = {
  // Pages loaded on demand
  metering: (
    <React.Suspense fallback={FallbackLoader}>
      <MeteringDashPage title="METERING" pageIcon={<MeteringIcon />} filterAffects={false} />
    </React.Suspense>
  ),
  sales: (
    <React.Suspense fallback={FallbackLoader}>
      <SalesDash title="SALES" pageIcon={<SalesIcon />} filterAffects={false} />
    </React.Suspense>
  ),
  distribution: (
    <React.Suspense fallback={FallbackLoader}>
      <DistributionDash
        title="DISTRIBUTION"
        pageIcon={<DistributionIcon />}
        filterAffects={false}
      />
    </React.Suspense>
  ),
  rt_analysis: (
    <React.Suspense fallback={FallbackLoader}>
      <ReturnTemperatureAnalysisPage title="RETURN TEMP ANALYSIS" pageIcon={<DistributionIcon />} />
    </React.Suspense>
  ),
  design_load: (
    <React.Suspense fallback={FallbackLoader}>
      <DesignLoadPage />
    </React.Suspense>
  ),
  production: (
    <React.Suspense fallback={FallbackLoader}>
      <ProductionPage title="PRODUCTION" pageIcon={<ProductionIcon />} filterAffects={false} />
    </React.Suspense>
  ),
  forecast: (
    <React.Suspense fallback={FallbackLoader}>
      <ForeCastPage title="FORECAST" pageIcon={<ProductionIcon />} filterAffects={false} />
    </React.Suspense>
  ),
  settings: (
    <React.Suspense fallback={FallbackLoader}>
      <SettingsPage />
    </React.Suspense>
  ),
  substations: (
    <React.Suspense fallback={FallbackLoader}>
      <SubstationPage />
    </React.Suspense>
  ),
  mapview: (
    <React.Suspense fallback={FallbackLoader}>
      <Mapview title="MAP VIEW" pageIcon={<MapIcon />} />
    </React.Suspense>
  ),
  pricing: (
    <React.Suspense fallback={FallbackLoader}>
      <PricingCombo title="PRICING" pageIcon={<SalesIcon />} />
    </React.Suspense>
  ),
  profile: (
    <React.Suspense fallback={FallbackLoader}>
      <ProfilePage />
    </React.Suspense>
  ),
  auth_management: (
    <React.Suspense fallback={FallbackLoader}>
      <ProfilePage />
    </React.Suspense>
  ),
  optimization: (
    <React.Suspense fallback={FallbackLoader}>
      <Optimization />
    </React.Suspense>
  ),
  fault_detection: (
    <React.Suspense fallback={FallbackLoader}>
      <FaultDetection />
    </React.Suspense>
  ),
  scenario_analysis: (
    <React.Suspense fallback={FallbackLoader}>
      <ScenarioAnalysis title="SCENARIO ANALYSIS" pageIcon={<ProductionIcon />} />
    </React.Suspense>
  ),

  // Development only routes
  testing: (
    <React.Suspense fallback={FallbackLoader}>
      <OptionalTest />
    </React.Suspense>
  ),
  block_admin: (
    <React.Suspense fallback={FallbackLoader}>
      <BlockAdmin />
    </React.Suspense>
  ),
  test_admin: (
    <React.Suspense fallback={FallbackLoader}>
      <TestAdmin />
    </React.Suspense>
  ),

  // Always loaded routes
  dash: <DashPage title="HOME" pageIcon={<HomeIcon />} filterAffects={false} />,
  login: <LoginPage />,
  notFound: <NotFoundPage />,
  feature_access_denied: <FeatureAccessDeniedPage />,
};

export default routeMappings;
