import makeStyles from '@mui/styles/makeStyles';
import Icon  from "@mui/material/SvgIcon";

const useStyles = makeStyles(() => ({
  fontSizeLarge: {
    fontSize: "1.714rem",
  },
}))


function SvgIcon(props) {
  const styles = useStyles()
  return <Icon classes={styles} {...props} />
}

export default SvgIcon;