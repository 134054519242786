import { observer } from "mobx-react";
import { Box, LinearProgress } from "@mui/material";

import useReaction from "../../../hooks/useReaction";
import useConsumptionStores from "../../../hooks/useConsumptionStores";
import { SUBSTATION, CLUSTER } from "../../SubList/constant";

import useConsumption from "./state";
import FilterBar from "./FilterBar";
import SortedMetrics from "./SortedMetrics";
import ScatterMetrics from "./ScatterMetrics";
import MeterChartLines from "./MeterChartLines";
import FetchRangeSelector from "./FetchRangeSelector";

function SubstationConsumption() {
  const consumption = useConsumption();
  const { networks, ui } = useConsumptionStores();

  useReaction(
    () => [networks.ready],
    () => {
      consumption.reset();
      if (networks.ready) {
        consumption.getGraphsData();
      }
    },
    { fireImmediately: true }
  );

  return (
    <Box p={4}>
      <span className="maskdata">
        <Box>
          <FetchRangeSelector
            title={`${ui.is_subsummary_open ? SUBSTATION : CLUSTER} METER DATA`}
          />
        </Box>
        <Box mb={17}>
          {!consumption.isDataAvailable && <LinearProgress />}

          {/* Meter Data */}
          <MeterChartLines />

          {/* Signature Diagram */}
          <ScatterMetrics />

          {/* Duration Diagram */}
          <SortedMetrics />
        </Box>

        {/* Filter Bar | Interval, Speed Options and Date Ranges */}
        <FilterBar />
      </span>
    </Box>
  );
}

export default observer(SubstationConsumption);
