// https://www.answerminer.com/blog/binning-guide-ideal-histogram
import { extent, histogram, ticks } from 'd3-array';
import { formatNumberForLocale } from '../../core/utils';

export function formatHistogram(values, valuesSelected, binsNumber) {
    // const histogramTicks = scaleLinear().domain(...extent(values)).ticks(binsNumber);
    const histogramTicks = ticks(...extent(values), binsNumber);

    const hist = histogram().domain(extent(values)).thresholds(histogramTicks)(valuesSelected);

    const delta = hist.length > 2 ? hist[1].x1 - hist[1].x0 :
        hist.length === 1 ? hist[0].x1 - hist[0].x0 :
            hist.length === 2 ? Math.max(hist[1].x1 - hist[1].x0, hist[0].x1 - hist[0].x0) : 0;

    const data = hist.map((bin, index) => [index > 0 ? bin.x0 + delta / 2 : bin.x1 - delta / 2, bin.length]);
    const bins = hist.map((bin, index) => index === 0 ? { x0: bin.x1 - delta, x1: bin.x1 } : index === hist.length - 1 ? { x0: bin.x0, x1: bin.x0 + delta } : { x0: bin.x0, x1: bin.x1 });
    return ({ data: data, bins: bins });
}

export function formatLogHistogram(values, valuesSelected, binsNumber) {

    const logValues = values.map(v => (v > 0) ? Math.log(v) : NaN);
    const logValuesSelected = valuesSelected.map(v => (v > 0) ? Math.log(v) : NaN);

    const histogramTicks = ticks(...extent(logValues), binsNumber);

    const hist = histogram().domain(extent(logValues)).thresholds(histogramTicks)(logValuesSelected);

    const delta = hist.length > 2 ? hist[1].x1 - hist[1].x0 :
        hist.length === 1 ? hist[0].x1 - hist[0].x0 :
            hist.length === 2 ? Math.max(hist[1].x1 - hist[1].x0, hist[0].x1 - hist[0].x0) : 0;

    const data = hist.map((bin, index) => [index > 0 ? bin.x0 + delta / 2 : bin.x1 - delta / 2, bin.length]);
    const bins = hist.map((bin, index) => index === 0 ? { x0: bin.x1 - delta, x1: bin.x1 } : index === hist.length - 1 ? { x0: bin.x0, x1: bin.x0 + delta } : { x0: bin.x0, x1: bin.x1 });

    return ({ data: data.map(v => [Math.exp(v[0]), v[1]]), bins: bins.map(v => ({ x0: Math.exp(v.x0), x1: Math.exp(v.x1) })) });
}


export function getMax(data, key) {
    return data.reduce((max, p) => p[key] > max && p[key] !== null ? p[key] : max, 0);
}

// This gives the steps for the histogram bins
export function getRangeWithDynamicBins(max, min, binSize) {
    let rangeList = [];
    let limit = min;
    if (min !== max) {
        while (limit <= max - binSize) {
            rangeList.push(parseFloat(limit));
            limit += binSize;
        }
        // This is done to avoid the last bin to be smaller than the max value
        // Due to the JS rounding error
        rangeList.push(parseFloat(max));
    }
    return rangeList;
}


function calculateLogValue(value) {
    return (value >= 0) ? Math.log(value) : -1;
}
function logSliderOld(position, max) {
    // position will be between 0 and 50
    let minp = 0;
    let maxp = 50;

    // The result should be between 100 an 10000000
    let minv = Math.log(1);
    let maxv = Math.log(max);

    // calculate adjustment factor
    let scale = (maxv - minv) / (maxp - minp);
    return Math.exp(minv + scale * (position - minp));
}

function logSlider(position, min, max) {
    // position will be between 0 and 50
    let minp = 0;
    let maxp = 50;

    // The result should be between 100 an 10000000
    let minv = Math.log(min);
    let maxv = Math.log(max);

    // calculate adjustment factor
    let scale = (maxv - minv) / (maxp - minp);
    return Math.exp(minv + scale * (position - minp));
}

function calculateLogRange(min, max, minValue, maxValue) {
    let rangeArray = [];
    for (let i = min; i < max; i++) {
        rangeArray.push(logSlider(i, minValue, maxValue));
    }
    return rangeArray;
}

function calculateLogRangeOld(min, max, maxValue) {
    let rangeArray = [];
    for (let i = min; i < max; i++) {
        rangeArray.push(logSliderOld(i, maxValue));
    }
    return rangeArray;
}

function calculateRangeData(filteredList, max, min, range, xkey, chartColor, xAxisText, yUnit, isLog) {
    let list = [];
    let cloneFilteredList = filteredList;

    range.forEach(((bar, i) => {
        // First Bucket
        if (i === 0) {
            let filteredArray = cloneFilteredList.filter(substation => {
                let keyValue = substation[xkey];
                return (keyValue <= bar);
            });
            let y = filteredArray.length || 0;
            cloneFilteredList = cloneFilteredList.filter(x => !filteredArray.includes(x));
            list.push({
                x: isLog ? calculateLogValue(bar) : bar,
                y: y,
                color: chartColor,
                tooltip: {
                    count: (y + ' substations'),
                    rangeValue: xAxisText +
                        '<= ' + min + ' ' + yUnit
                },
            });
        }
        // Middle Buckets
        if (i !== (range.length - 1)) {
            let filteredArray = cloneFilteredList.filter(substation => {
                let keyValue = substation[xkey];
                return (bar < keyValue && keyValue <= (range[i + 1]));
            });
            let y = filteredArray.length || 0;
            cloneFilteredList = cloneFilteredList.filter(x => !filteredArray.includes(x));
            list.push({
                x: isLog ? calculateLogValue((bar + range[i + 1]) / 2) : (bar + range[i + 1]) / 2,
                y: y,
                color: chartColor,
                tooltip: {
                    count: (y + ' substations'),
                    rangeValue: xAxisText +
                        formatNumberForLocale(bar) + yUnit +
                        ' to ' + formatNumberForLocale(range[i + 1]) + yUnit
                },
            });
        }
        // Last Bucket
        if (i === (range.length - 1)) {
            let filteredArray = cloneFilteredList.filter(substation => {
                let keyValue = substation[xkey];
                return (keyValue <= max);
            });
            let y = filteredArray.length || 0;
            list.push({
                x: isLog ? calculateLogValue(max) : max,
                y: y,
                color: chartColor,
                tooltip: {
                    count: (y + ' substations'),
                    rangeValue: xAxisText +
                        formatNumberForLocale(range[i - 1]) + yUnit +
                        ' to ' + formatNumberForLocale(max) + yUnit
                },
            });
        }
    }));
    return list;
}

export function prepareHistogramExponentialChartData({ filteredList, xkey, chartColor, yUnit = ' °C', xAxisText = '' }) {
    let max = (filteredList.length > 0) ? getMax(filteredList, xkey) : 0;
    let min = 0;
    let binSize = (max - min) / 50; // 50 bins for histogram
    let list = [];
    let isLog = false;

    // We calculate the range for the histogram based on the max, min and binSize
    // leading to a range of 51 values and 50 bins in between those values
    let range = (filteredList.length > 0) ? getRangeWithDynamicBins(max, min, binSize) : [];

    // Sort data into bins, x-values are the bin centers, y-values are the counts
    list = calculateRangeData(filteredList, max, min, range, xkey, chartColor, xAxisText, yUnit, false);

    let retData = { list: list, log: isLog };

    // If the data is really concentrated to the left, we need to use logarithmic scale
    if (list.slice(0, 5).reduce((prev, cur) => prev + cur.y, 0) > filteredList.length * 0.9) {
        // First we determine the log range
        let logRange = calculateLogRangeOld(0, 51, max);

        // Then we calculate the log data, using the initial list
        retData = {
            list: calculateRangeData(
                filteredList,
                max,
                min,
                logRange,
                xkey,
                chartColor,
                xAxisText,
                yUnit,
                true
            ),
            log: true,
        };
    }
    return retData;
}

export function getColMinMax(rows, colIdx) {
    if (rows.length >= 1) {
        let max = rows[0][colIdx];
        let min = rows[0][colIdx];
        for (let row of rows) {
            let val = row[colIdx];
            max = max < val ? val : max;
            min = min > val ? val : min;
        }
        return [min, max]
    }
    else {
        return [null, null]
    }
}

function calculateHistRangeData({
    rows,
    max,
    min,
    range,
    xkey,
    xAxisText,
    yUnit,
    isLog }) {
    let list = [];
    let cloneFilteredList = rows.slice()

    range.forEach(((bar, i) => {
        if (i === 0) {
            let filteredArray = cloneFilteredList.filter(substation => {
                let keyValue = substation[xkey];
                return (keyValue <= min);
            });
            let y = filteredArray.length || 0;
            cloneFilteredList = cloneFilteredList.filter(x => !filteredArray.includes(x));
            list.push({
                x: isLog ? calculateLogValue(bar) : bar,
                y: y,
                tooltip: {
                    count: (y + ' substations'),
                    rangeValue: xAxisText +
                        '<= ' + min + ' ' + yUnit
                },
            });
        }
        if (i !== (range.length - 1)) {
            let filteredArray = cloneFilteredList.filter(substation => {
                let keyValue = substation[xkey];
                return (bar < keyValue && keyValue <= (range[i + 1]));
            });
            let y = filteredArray.length || 0;
            cloneFilteredList = cloneFilteredList.filter(x => !filteredArray.includes(x));
            list.push({
                x: isLog ? calculateLogValue((bar + range[i + 1]) / 2) : (bar + range[i + 1]) / 2,
                y: y,
                tooltip: {
                    count: (y + ' substations'),
                    rangeValue: xAxisText +
                        formatNumberForLocale(bar) + yUnit +
                        ' to ' + formatNumberForLocale(range[i + 1]) + yUnit
                },
            });
        }
        if (i === (range.length - 1)) {
            let filteredArray = cloneFilteredList.filter(substation => {
                let keyValue = substation[xkey];
                return (keyValue <= max);
            });
            let y = filteredArray.length || 0;
            cloneFilteredList = cloneFilteredList.filter(x => !filteredArray.includes(x));
            list.push({
                x: isLog ? calculateLogValue(max) : max,
                y: y,
                tooltip: {
                    count: (y + ' substations'),
                    rangeValue: xAxisText +
                        formatNumberForLocale(range[i - 1]) + yUnit +
                        ' to ' + formatNumberForLocale(max) + yUnit
                },
            });
        }
    }));
    return list;
}

export function prepareHistogramData({
    rows,
    xcolIdx,
    scale = 'linear',
    bins = 50,
    xAxisText = '',
    yUnit = 'C'
}) {
    /*
    rows : array of array , rows of df
    xcolIdx: index for getting value of x
    scale: log/linear
    bins: no of bins , default 50
    yUnit: y unit for tooltips
    xAxisText: x axis text
    col_max: max value of col if we already have
    */
    if (rows.length > 0) {
        let [min, max] = getColMinMax(rows, xcolIdx)
        let binSize = (max - min) / bins;
        const isLog = scale === 'logarithmic';
        let range;
        if (isLog) {
            range = calculateLogRange(0, bins, min, max);
        } else {
            range = (rows.length > 0) ? getRangeWithDynamicBins(max, min, binSize) : [];
        }

        let data = calculateHistRangeData({
            rows,
            min,
            max,
            range,
            xkey: xcolIdx,
            xAxisText,
            yUnit,
            isLog: false
        });
        return { data, log: isLog, chartMin: 0, chartMax: max };
    } else {
        return {
            data: [], log: false, chartMin: 0, chartMax: 0
        }
    }

}
