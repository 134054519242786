import { action, computed, flow, observable, makeObservable } from "mobx";

import { sleep } from "../../core/utils";

const clrs = [
  ["graph", "blue", "#4A88BB"],
  ["graph", "pink", "#BB4AAA"],
  ["graph", "yellow", "#4AB7BB"],
  ["graph", "green", "#BBAE4A"],
  ["graph", "purple", "#6D4ABB"],
];

class ReportStore {
  sim_type = "simulated";
  run_start_year = "";
  run_end_year = "";
  training_year = "";
  job_status = "";
  job_error = false;
  job_id = null;

  // Config table
  configurations = [];
  confSrtId = "name";
  confSortDirection = "desc";
  selected_configs = new Set();

  // results
  result_tab = "overview";
  results_meta = null;
  results_data = {};
  currentYear = 2018;

  // winlose
  compare_from = null;
  compare_to = null;

  get configurationNameMap() {
    return new Map(this.configurations.map((p) => [p.id, p.name]));
  }

  get graphColors() {
    const map = new Map();
    if (this.results_meta) {
      for (let i = 0; i < this.results_meta.configs.length; i++) {
        map.set(this.results_meta.configs[i].id, clrs[i]);
      }
    }
    return map;
  }

  get availableYears() {
    if (this.results_meta) {
      return [
        ...this.results_meta.years.map((i) => [i, i]),
        ["Average", 0],
        ["Min revenue year", this.results_meta.min_revenue_year],
        ["Max revenue year", this.results_meta.max_revenue_year],
      ];
    }
    return [];
  }

  changeSortDirection(colId, direction) {
    this.confSrtId = colId;
    this.confSortDirection = direction;
  }

  changeSimType(e) {
    this.sim_type = e.target.value;
    this.training_year = "";
    this.run_end_year = "";
    this.run_start_year = "";
  }

  changeTrainingYear(e) {
    this.training_year = parseInt(e.target.value);
  }

  changeRunStart(e) {
    this.run_start_year = parseInt(e.target.value);
    if (this.run_start_year > this.run_end_year) {
      this.run_end_year = this.run_start_year;
    }
  }

  changeRunEnd(e) {
    this.run_end_year = parseInt(e.target.value);
  }

  selectConfig(configId, selected) {
    if (selected) {
      this.selected_configs.add(configId);
    } else {
      this.selected_configs.delete(configId);
    }
  }

  changeResultTab(tab) {
    this.result_tab = tab;
  }

  constructor(parent) {
    makeObservable(this, {
      sim_type: observable,
      run_start_year: observable,
      run_end_year: observable,
      training_year: observable,
      job_status: observable,
      job_error: observable,
      job_id: observable,
      configurations: observable,
      confSrtId: observable,
      confSortDirection: observable,
      selected_configs: observable,
      result_tab: observable,
      results_meta: observable,
      results_data: observable,
      currentYear: observable,
      compare_from: observable,
      compare_to: observable,
      configurationNameMap: computed,
      graphColors: computed,
      availableYears: computed,
      changeSortDirection: action.bound,
      changeSimType: action.bound,
      changeTrainingYear: action.bound,
      changeRunStart: action.bound,
      changeRunEnd: action.bound,
      selectConfig: action.bound,
      changeResultTab: action.bound,
      addResults: action.bound,
      updateCurrentYear: action.bound,
      clearResults: action.bound,
      reset: action.bound,
      updateCompareFrom: action.bound,
      updateCompareTo: action.bound,
    });

    this.parent = parent;
  }

  getConfigurations = flow(function* () {
    try {
      const configurations = yield this.parent.utfapi.getConfigurations({
        network__exact: this.parent.networks.current_network.uid,
      });
      this.configurations = configurations;
      const filteredSelectedConfigs = new Set();
      configurations
        .filter((config) => this.selected_configs.has(config.id))
        .forEach((config) => {
          filteredSelectedConfigs.add(config.id);
        });
      this.selected_configs = filteredSelectedConfigs;
    } catch (err) {
      console.log("error", err);
      this.parent.app.showMsg("unable to get configurations");
    }
  });

  runSimulation = flow(function* () {
    this.job_status = "submitting job";
    this.job_error = false;
    this.results = false;
    this.results_meta = null;
    let jobDetail;
    try {
      jobDetail = yield this.parent.utfapi.postJob("pricing", "pricing_calculate", {
        simulation: this.sim_type,
        training_year: this.training_year,
        run_start_year: this.run_start_year,
        run_end_year: this.run_end_year,
        configs: Array.from(this.selected_configs),
      });
    } catch (err) {
      console.log("error", err.error);
      this.job_status = err.error;
      this.job_error = true;
      return;
    }
    this.job_id = jobDetail.job_id;
    this.job_status = "job submitted";
    let jobStatus = "init";
    let status = {};
    do {
      yield sleep(2000);
      status = yield this.parent.utfapi.getJobStatus("pricing", this.job_id);
      jobStatus = status.status;
      console.log("job status", jobStatus);
      this.job_status = `${jobStatus}`;
    } while (jobStatus !== "done" && jobStatus !== "error");
    if (jobStatus === "done" || jobStatus === "error") {
      if (status.error && status.error !== "") {
        this.job_status = status.error;
        this.job_error = true;
      } else {
        const results = yield this.parent.utfapi.getJobResult("pricing", this.job_id, {});

        if (results.errors.length > 0) {
          [this.job_status] = results.errors;
          this.job_error = true;
        } else {
          this.results_meta = results;
          this.currentYear =
            results.years && results.years.length > 0
              ? Math.max(...results.years)
              : this.run_end_year;
          this.job_status = "results available";
        }
      }
    } else {
      this.job_status = status ? `${status.status}` : "...";
    }
  });

  fetchSimulationResultView = flow(function* (query) {
    return yield this.parent.utfapi.getJobResult("pricing", this.job_id, query);
  });

  addResults(result) {
    this.results_data = result;
    this.results = true;
  }

  updateCurrentYear(year) {
    this.currentYear = year;
  }

  clearResults() {
    this.results = false;
    this.results_data = {};
    this.compare_from = null;
    this.compare_to = null;
  }

  reset() {
    this.selected_configs.clear();
    this.clearResults();
  }

  updateCompareFrom(configId) {
    this.compare_from = configId;
  }

  updateCompareTo(configId) {
    this.compare_to = configId;
  }
}

export default ReportStore;
