import { createTheme } from "@mui/material";
import { adaptV4Theme } from "@mui/material/styles";

const appBarHeight = 64;

const themes = {
  new: {
    // Calculate shadows based on level 1 to 24
    shadows: [
      "none",
      ...Array(24)
        .fill()
        .map(
          (_, level) => `0 ${1 + level}px ${3 + level}px 0 rgba(0, 0, 0, ${0.16 + 0.01 * level})`
        ),
    ],
    palette: {
      tonalOffset: 0.09,
      primary: {
        dark: "#00507a",
        main: "#1a5e8a",
      },
      secondary: {
        main: "#db742b",
      },
      error: {
        main: "#bb4a4a",
      },
      success: {
        main: "#53B84A",
      },
      warning: {
        main: "#FFC107",
      },
      info: {
        main: "#4AB7BB",
      },
      background: {
        paper: "#fff",
        default: "#f5f7f8",
        selected: "#ebebeb",
        hover: "#f5f5f5",
      },
      grey: {
        blue72: "#42494E",
        blue50: "#747B80",
        blue28: "#A7AEB3",
        blue12: "#e3e7ea",
        blue4: "#ECEFF0",
        blue2: "#F5F7F8",
        white: "#FDFDFD",
      },
      graph: {
        blue: "#4A88BB",
        lightblue: "#a4c3dd",
        lightPink: "#dda4d4",
        pink: "#BB4AAA",
        darkPink: "#bc5090",
        lightTeal: "#a4dbdd",
        teal: "#4AB7BB",
        yellow: "#BBAE4A",
        darkYellow: "#d9bb3c",
        purple: "#6D4ABB",
        lightGreen: "#a8dda4",
        green: "#53bb4a",
        red: "#BB4A4A",
        lightOrange: "#ecb893",
        orange: "#db742b",
      },
      text: {
        primary: "#42494E",
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: "Roboto",
      h1: {
        fontFamily: "Roboto",
        fontSize: "5.714rem",
        lineHeight: 1.2,
        fontWeight: 500,
      },
      h2: {
        fontFamily: "Roboto",
        fontSize: "2.857rem",
        lineHeight: 1.2,
        fontWeight: 500,
      },
      h3: {
        fontFamily: "Roboto",
        fontSize: "1.286rem",
        lineHeight: 1.33,
        fontWeight: 500,
      },
      h4: {
        fontFamily: "Montserrat",
        fontWeight: "bold",
        lineHeight: 1.71,
        fontSize: "1rem",
      },
      h5: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        lineHeight: 1.71,
        fontSize: "1rem",
      },
      h6: {
        fontFamily: "Montserrat",
        fontWeight: 500,
        lineHeight: 1.14,
        fontSize: "1rem",
      },
      body1: {
        fontFamily: "Roboto",
        fontSize: "1rem",
        lineHeight: 1.14,
        fontWeight: 300,
      },
      body2: {
        fontFamily: "Montserrat",
        fontSize: "1rem",
        lineHeight: 1.14,
        fontWeight: 500,
      },
      button: {
        fontFamily: "Roboto Condensed",
        fontSize: ".929rem",
        fontWeight: "bold",
        fontStretch: "condensed",
        lineHeight: 1.23,
      },
      title: {
        fontFamily: "Montserrat",
        fontSize: "1.714rem",
        fontWeight: "bold",
        lineHeight: 1.33,
      },
      subtitle1: {
        fontFamily: "Montserrat",
        fontSize: "1.714rem",
        fontWeight: 600,
        lineHeight: 1.33,
      },
      subtitle2: {
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "1.143rem",
        lineHeight: 1.5,
      },
      caption: {
        fontFamily: "Montserrat",
        fontSize: "0.857rem",
        fontWeight: 500,
        lineHeight: 1.33,
      },
    },
    shape: {
      borderRadius: 2,
    },
    components: {
      MuiPaper: {
        root: {
          color: "#42494E",
        },
      },
      MuiInputBase: {
        root: {
          color: "#42494E",
        },
      },
      MuiPickersDay: {
        day: {
          color: "rgba(0, 0, 0, 0.54)",
        },
      },
      MuiPickersClockNumber: {
        clockNumber: {
          color: "#42494E",
        },
      },
      MuiTableCell: {
        root: {
          body: {
            color: "#42494E",
          },
        },
      },
      MuiTablePagination: {
        root: {
          color: "#42494E",
        },
      },
      MuiDialogTitle: {
        root: {
          textTransform: "uppercase",
        },
      },
      MuiDataGrid: {},
      MuiTooltip: {},
      MuiCssBaseline: {},
      MuiTabs: {},
      MuiRadio: {},
      MuiMenuItem: {},
    },
    mixins: {
      toolbar: {
        minHeight: appBarHeight - 10 + 2,
        "@media (min-width:0px) and (orientation: landscape)": { minHeight: appBarHeight - 20 + 4 },
        "@media (min-width:600px)": { minHeight: appBarHeight },
      },
      // its not possible to extend mui "typography" theme object hence using mixin
      small: {
        fontFamily: "Montserrat",
        fontSize: "0.71rem",
        fontWeight: 500,
        lineHeight: 1,
      },
    },
  },
};

const defaultTheme = createTheme(adaptV4Theme(themes.new));

// Override "disabled" colors with system palette
themes.new.palette.action = {};
themes.new.palette.action.disabled = defaultTheme.palette.primary.contrastText;
themes.new.palette.action.disabledBackground = defaultTheme.palette.grey.blue28;

// Global overrides for the Tooltip components
themes.new.components.MuiTooltip.styleOverrides = {
  tooltip: {
    maxWidth: 190,
    padding: defaultTheme.spacing(1, 2),
    fontFamily: defaultTheme.typography.body2.fontFamily,
    fontSize: 12,
    lineHeight: 1.3,
    fontWeight: defaultTheme.typography.fontWeightMedium,
    color: defaultTheme.palette.grey.white,
    backgroundColor: defaultTheme.palette.grey.blue28,
  },
  /* Styles applied to the tooltip (label wrapper) element if `placement` contains "right". */
  tooltipPlacementRight: {
    transformOrigin: "left center",
    margin: "0 !important",
  },
};

themes.new.components.MuiCssBaseline.styleOverrides = {
  body: {
    // Global overrides for the Scrollbar
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      width: "0.6em",
      height: "0.6em",
      borderRadius: 5,
    },
    "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
      borderRadius: 5,
      backgroundColor: defaultTheme.palette.grey.white,
      boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.2);",
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 5,
      backgroundColor: defaultTheme.palette.primary.main,
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: defaultTheme.palette.primary.light,
    },
    // Global overrides for HighCharts options button
    "& .highcharts-menu-item": {
      ...defaultTheme.typography.h6,
    },
    "& .highcharts-data-table": {
      background: defaultTheme.palette.grey.white,
      "& > table": {
        color: defaultTheme.palette.grey.blue72,
        "& > caption": {
          ...defaultTheme.typography.body2,
        },
        "& > thead": {
          ...defaultTheme.typography.h5,
        },
        "& > tbody": {
          "& > tr": {
            ...defaultTheme.typography.h6,
          },
        },
      },
    },
  },
};

themes.new.components.MuiTabs.styleOverrides = {
  root: {
    "& .MuiTabs-indicator": {
      backgroundColor: defaultTheme.palette.secondary.main,
    },
  },
};

themes.new.components.MuiRadio.styleOverrides = {
  root: {
    color: defaultTheme.palette.primary.main,
  },
};

themes.new.components.MuiMenuItem.styleOverrides = {
  root: {
    "&.Mui-selected": {
      background: defaultTheme.palette.background.selected,
      "&:hover": {
        background: defaultTheme.palette.background.selected,
      },
    },
    "&:hover": {
      background: defaultTheme.palette.background.hover,
    },
  },
};

themes.new.components.MuiDataGrid.styleOverrides = {
  root: {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      textTransform: "capitalize",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      ...defaultTheme.typography.h5,
    },
    "& .MuiDataGrid-cell": {
      ...defaultTheme.typography.h6,
    },
  },
};
export default themes;
