import { useState } from "react";
import { observer } from "mobx-react";
import { Skeleton } from "@mui/material";
import { useTheme } from "@mui/styles";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

import { formatNumberForLocale, numbersOnly } from "../../../core/utils";
import {
  ChartToolbox,
  HighchartsBase,
  GraphContainer,
} from "../../../shared_components/ui/analytics/charts";
import { addAnnotations } from "../../../shared_components/ui/analytics/charts/utils";
import { withErrorBoundary } from "../../ui/ErrorBoundary";

import { columns, GRAPH_GRID, DATETIME_FORMAT_YYYY_MM_DD_HH_MM } from "./Constants";
import useConsumption from "./state";
import { isTemperature, legendItemClickHandler } from "./utils";

const HEIGHT = 460;

function ScatterMetrics() {
  const theme = useTheme();
  const { t } = useTranslation(["extendView"]);
  const { isDataAvailable, flowLimiter, areReadingsAveragedForDaily, chartWidth, seriesInRanges } =
    useConsumption();
  const [signatureDiagramToolbox, setSignatureDiagramToolbox] = useState([]);
  const [visibleSeries, setVisibleSeries] = useState({
    heat: true,
    vol: false,
    st: false,
    rt: false,
    dt: false,
  });

  // How many of RT, ST or DT series are visible
  const visibleTemperatureSeries = Object.keys(visibleSeries).reduce((prev, current) => {
    if (!isTemperature(current)) return prev;
    if (visibleSeries[current]) return prev + 1;
    return prev;
  }, 0);

  const chartId = "signature";
  const series = [];
  const yAxis = [];

  if (isDataAvailable) {
    let yAxisSeries;

    if (visibleTemperatureSeries) {
      yAxis.push({
        visible: true,
        lineWidth: 1,
        opposite: false,
        title: {
          useHTML: true,
          text: `<span class="${chartId}-title-temp">${t("text_temperature")} °C</span>`,
        },
        labels: {
          align: "right",
          x: -15,
        },
      });
    }

    columns.forEach((col) => {
      const { key } = col;
      const color = theme.palette[col.color[0]][col.color[1]];
      const visible = visibleSeries[key];
      const name = col.suffix === "°C" ? `${t(col.name)}.` : t(col.name);
      let yAxisIndex = yAxis.length;

      if (isTemperature(key)) yAxisIndex = 0;

      series.push({
        id: key,
        type: "scatter",
        name,
        color,
        visible,
        data: seriesInRanges.map((row) => ({ x: row.outdoor, y: row[key], z: row.ts })),
        timeStamp: seriesInRanges.map((row) => row.ts),
        marker: {
          fillColor: color,
          radius: 2,
          symbol: "circle",
        },
        tooltip: {
          valueSuffix: col.suffix,
        },
        allowPointSelect: true,
        showInLegend: col.showInLegend,
        yAxis: yAxisIndex,
        events: {
          legendItemClick: legendItemClickHandler(setVisibleSeries),
        },
      });

      yAxisSeries = {
        visible,
        lineWidth: 1,
        opposite: false,
        title: {
          useHTML: true,
          text: `<span class="${chartId}-title-${key}">${name} ${col.suffix}</span>`,
        },
        labels: {
          align: "right",
          x: -15,
          color,
        },
        chartColor: theme.palette[col.lineColor[0]][col.lineColor[1]],
      };
      if (key === "vol" && flowLimiter) {
        yAxisSeries.plotLines = [
          {
            color: "red",
            value: flowLimiter,
            width: 1,
          },
        ];
      }

      if (!isTemperature(key)) yAxis.push(yAxisSeries);
    });
  }

  let xMax = Math.max(...(series[0]?.data.map((d) => d.x) || []).filter(numbersOnly)); // outdoor temp.
  xMax += (xMax / 100) * 5; // 5% gap as right margin

  return (
    <GraphContainer
      title={t("text_signature_diagram")}
      hasData
      subTitles={
        <ChartToolbox
          values={signatureDiagramToolbox}
          setValues={setSignatureDiagramToolbox}
          disabled={!isDataAvailable}
          data-testid={`${chartId}-toolbox`}
        />
      }
      data-testid={`chart-${chartId}-container`}
    >
      {!isDataAvailable ? (
        <Skeleton variant="rectangular" height={HEIGHT} />
      ) : (
        <HighchartsBase
          xAxis={{
            title: {
              text: "Outdoor Temp °C",
            },
            gridLineWidth: signatureDiagramToolbox.includes(GRAPH_GRID) ? 1 : 0,
            max: xMax,
          }}
          yAxis={yAxis}
          series={series}
          chart={{
            id: chartId,
            height: HEIGHT,
            marginTop: 60,
            width: chartWidth,
            zoomType: "xy",
          }}
          annotations={addAnnotations({
            series,
            options: signatureDiagramToolbox,
          })}
          legend={{
            enabled: true,
            layout: "horizontal",
            useHTML: true,
            align: "left",
            verticalAlign: "top",
            labelFormatter() {
              const { color, name, userOptions } = this;
              return `<div data-testid="${chartId}__legend__${userOptions.id}" style="border-bottom: 3px solid ${color}">${name}</div>`;
            },
            itemStyle: {
              ...theme.typography.body2,
            },
          }}
          tooltip={{
            formatter() {
              const { series: chartSeries, point, y, x } = this;
              const { z } = point;

              let pointTimestamp = DateTime.fromMillis(z);

              if (areReadingsAveragedForDaily) {
                pointTimestamp = pointTimestamp.set({ hour: 0, minute: 0 });
              }

              const formattedDate = pointTimestamp.toFormat(DATETIME_FORMAT_YYYY_MM_DD_HH_MM);

              return `<b> ${chartSeries.name} ${formatNumberForLocale(y)}${
                chartSeries.tooltipOptions.valueSuffix
              } / ${formatNumberForLocale(x)}°C <br/> ${formattedDate}</b>`;
            },
          }}
        />
      )}
    </GraphContainer>
  );
}

export default withErrorBoundary(observer(ScatterMetrics));
