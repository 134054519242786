import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Flag } from "@mui/icons-material";

import CalculatorIcon from "../../../../../icons/Calculator";
import BaseCard from "../BaseCard/BaseCard";
import QuestionIcon from "../../../../../icons/Question";

const iconClasses = makeStyles({
  icon: {
    "& .MuiSvgIcon-root": {
      fontSize: "3.5rem",
      marginLeft: "0.5rem",
      marginBottom: "0.5rem",
    },
  },
});

/**
- This component is built on top of the BaseCard.
- The icon is passed as a string, and mapped to the icon component.
- The icon is displayed in the right side of the value.
* */
function IconCard(props) {
  const { title, icon, value, loading, error, color = "secondary" } = props;
  const classes = iconClasses();

  const iconComponent = () => {
    switch (icon) {
      case "calculator":
        return <CalculatorIcon />;
      case "flag":
        return <Flag />;
      default:
        return <QuestionIcon />;
    }
  };

  return (
    <BaseCard loading={loading} error={error} title={title}>
      <Typography variant="h1" color={color}>
        {value}
        <span className={classes.icon}>{iconComponent()}</span>
      </Typography>
    </BaseCard>
  );
}

IconCard.defaultProps = {
  title: "",
  value: "",
  icon: "",
  color: "secondary",
  loading: false,
  error: false,
};

IconCard.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.oneOf(["calculator", "flag"]),
  color: PropTypes.oneOf(["primary", "secondary", "error"]),
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default IconCard;
