import React from "react";
import { inject, observer } from "mobx-react";
import { observable, action, makeObservable } from "mobx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { withTranslation } from "react-i18next";

import themes from "../../themes";

const CHIP_STYLES = {
  textTransform: "upperCase",
  fontWeight: "500",
};

class EditFilters extends React.Component {
  selected = [];

  constructor(props) {
    super(props);

    makeObservable(this, {
      selected: observable,
      updateSelected: action.bound,
      toggleSelected: action.bound,
      applyChanges: action.bound,
    });
    this.updateSelected();
  }

  updateSelected() {
    const {
      rootStore: { filters },
    } = this.props;
    this.selected = filters.activeFilters.slice();
  }

  toggleSelected(spec) {
    if (this.selected.indexOf(spec) >= 0) {
      this.selected.splice(this.selected.indexOf(spec), 1);
    } else {
      this.selected.push(spec);
    }
  }

  applyChanges() {
    const {
      rootStore: { filters, ui },
    } = this.props;
    filters.filters.forEach((spec) => {
      if (this.selected.indexOf(spec) >= 0) {
        spec.set("is_active", true);
      } else {
        spec.set("is_active", false);
      }
    });
    ui.closeEditFilters();
  }

  render() {
    const {
      t,
      rootStore: { filters },
    } = this.props;
    return (
      <Box display="flex" flexDirection="column" px={4} py={2}>
        <Box display="flex" justifyContent="center">
          <Typography variant="subtitle1">{t("text_edit_filter", { ns: "filters" })}</Typography>
        </Box>
        <Divider variant="middle" />
        <Box display="flex" flexDirection="row" py={1}>
          <Box width="80%" display="flex" justifyContent="start" flexDirection="column" p={1}>
            <Typography variant="subtitle2">{t("text_selected")}</Typography>
            <Box display="flex" flexDirection="row" flexWrap="wrap" pl={1}>
              {this.selected
                .slice()
                .sort((a, b) => a.get("label").localeCompare(b.get("label")))
                .map((f) => (
                  <Box pl={1} pb={1} key={f.get("label")}>
                    <Chip
                      sx={CHIP_STYLES}
                      label={t(f.get("label"), { ns: "filters" })}
                      onClick={this.toggleSelected.bind(this, f)}
                      onDelete={this.toggleSelected.bind(this, f)}
                      color={this.selected.indexOf(f) >= 0 ? "secondary" : "default"}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box display="flex" justifyContent="start" flexDirection="column" p={1}>
            {filters.filterGroups.map((g) => (
              <React.Fragment key={g}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle2">{g}</Typography>
                  <Box display="flex" flexDirection="row" flexWrap="wrap" pl={1}>
                    {filters.allFilters
                      .filter((f) => f.get("group") === g)
                      .sort((a, b) => a.get("label").localeCompare(b.get("label")))
                      .map((f) => (
                        <Box pl={1} pb={1}>
                          <Chip
                            sx={{
                              ...CHIP_STYLES,
                              color:
                                this.selected.indexOf(f) >= 0
                                  ? themes.new.palette.grey.white
                                  : "black",
                              backgroundColor:
                                this.selected.indexOf(f) >= 0
                                  ? themes.new.palette.secondary.main
                                  : "#e0e0e0",
                            }}
                            label={t(f.get("label"), { ns: "filters" })}
                            onClick={this.toggleSelected.bind(this, f)}
                            color={this.selected.indexOf(f) >= 0 ? "secondary" : "default"} // we already have the color in the sx props but without this "color" prop it will mess up the hovering color
                          />
                        </Box>
                      ))}
                  </Box>
                </Box>
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Button variant="contained" color="primary" onClick={this.applyChanges}>
          {t("action_apply_changes", { ns: "_action" })}
        </Button>
      </Box>
    );
  }
}

export default withTranslation(["filters", "_action"])(inject("rootStore")(observer(EditFilters)));
