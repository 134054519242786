import { inject } from "mobx-react";
import withStyles from '@mui/styles/withStyles';
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CodeIcon from "@mui/icons-material/Code";

import ListIcon from "../../icons/List";
import SalesIcon from "../../icons/Sales";
import ProductionIcon from "../../icons/Production";
import DistributionIcon from "../../icons/Distribution";
import HomeIcon from "../../icons/Home";
import MeteringIcon from "../../icons/Meter";
import MapIcon from "../../icons/Map";
import SideBarNestedItem from "../ui/SideBarNestedItem";
// eslint-disable-next-line camelcase
import { is_feature_flag_on } from "../../conf/conf";
import SupportIcon from "../../icons/Support";
import FEATURE from "../../conf/feature_access";

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.grey.blue2,
  },
  item: {
    backgroundColor: theme.palette.grey.blue4,
    marginTop: 0,
  },
});

const optionals = is_feature_flag_on("TEST_ADMIN") || is_feature_flag_on("BLOCK_ADMIN");
const optionalRoutes = {
  access: [],
  text: "Optional Test Pages",
  Icon: CodeIcon,
  children: [
    {
      route: "testing",
      access: [],
      text: "Optional Test Pages",
    },
  ],
};
if (is_feature_flag_on("TEST_ADMIN")) {
  optionalRoutes.children.push({
    route: "test_admin",
    access: [],
    text: "Test Admin",
  });
}
if (is_feature_flag_on("BLOCK_ADMIN")) {
  optionalRoutes.children.push({
    route: "block_admin",
    access: [],
    text: "Block Admin",
  });
}

const productionRoutes = {
  text: "Production",
  access: [FEATURE.production],
  Icon: ProductionIcon,
  children: [
    {
      route: "production",
      text: "Production Dashboard",
      access: [FEATURE.production],
    },
    {
      route: "forecast",
      access: [FEATURE.forecast],
      text: "Forecast",
    },
  ],
};

if (is_feature_flag_on("OPTIMIZATION")) {
  productionRoutes.children.push({
    route: "optimization",
    access: [FEATURE.optimization],
    text: "Optimization",
  });
}

if (is_feature_flag_on("SCENARIO_ANALYSIS")) {
  productionRoutes.children.push({
    route: "scenario_analysis",
    text: "Scenario Analysis",
  });
}

const meteringRoutes = {
  text: "Metering",
  access: [FEATURE.metering],
  Icon: MeteringIcon,
  children: [
    {
      route: "metering",
      text: "Metering Dashboard",
      access: [FEATURE.metering],
    },
  ],
};

if (is_feature_flag_on("FAULT_DETECTION")) {
  meteringRoutes.children.push({
    route: "fault_detection",
    text: "Fault Detection",
    access: [FEATURE.fault_detection],
  });
}

function NavDrawer(props) {
  const { session, classes } = props;
  return (
    <>
      <List disablePadding className={classes.root} data-testid="sidebar">
        <SideBarNestedItem
          key="dash"
          routes={{
            route: "dash",
            text: "Home",
            access: [FEATURE.home],
            Icon: HomeIcon,
            children: [],
          }}
        />
        <SideBarNestedItem key="metering" routes={meteringRoutes} />
        <SideBarNestedItem
          key="sales"
          routes={{
            text: "Sales",
            access: [FEATURE.sales],
            Icon: SalesIcon,
            children: [
              {
                route: "sales",
                text: "Sales Dashboard",
                access: [FEATURE.sales],
              },
              {
                route: "pricing",
                access: [FEATURE.pricing],
                text: "Pricing",
              },
            ],
          }}
        />
        <SideBarNestedItem
          key="distribution"
          routes={{
            text: "Distribution",
            access: [FEATURE.dist],
            Icon: DistributionIcon,
            children: [
              {
                route: "distribution",
                text: "Distribution Dashboard",
                access: [FEATURE.dist],
              },
              {
                route: "rt_analysis",
                access: [FEATURE.dist_rta, FEATURE.dist_rtsp],
                text: "Return Temp Analysis",
              },
              {
                route: "design_load",
                access: [FEATURE.dist_dload],
                text: "Design Load",
              },
            ],
          }}
        />
        <SideBarNestedItem key="production" routes={productionRoutes} />
        <SideBarNestedItem
          key="substations"
          routes={{
            route: "substations",
            text: "Data Library",
            access: [FEATURE.datalibrary],
            Icon: ListIcon,
            children: [],
          }}
        />
        <SideBarNestedItem
          key="mapview"
          routes={{
            route: "mapview",
            text: "Mapview",
            access: [FEATURE.mapview],
            Icon: MapIcon,
            children: [],
          }}
        />
        {optionals && <SideBarNestedItem key="optionals" routes={optionalRoutes} />}
      </List>
      <Box display="flex" flexGrow={1} flexShrink={1} />
      <SideBarNestedItem
        key="support"
        routes={{
          route: "",
          text: "support",
          Icon: SupportIcon,
          access: [],
          children: [],
          cb: () => window.open("mailto:kundtjanst@utilifeed.com?subject=&body="),
        }}
      />
      <SideBarNestedItem
        key="profile"
        routes={{
          text: "Profile & Settings",
          access: [FEATURE.profile],
          Icon: PersonOutlineIcon,
          children: [
            {
              route: "profile",
              text: "Profile",
              access: [FEATURE.profile],
            },
            {
              route: "settings",
              access: [FEATURE.profile],
              text: "Settings",
            },
            {
              route: "logout",
              access: [],
              text: "Logout",
              cb: () => {
                session.logout();
              },
            },
          ],
        }}
      />
    </>
  );
}

export default inject("session")(withStyles(styles)(NavDrawer));
