import { observable, flow, computed, action, reaction, makeObservable } from "mobx";
import { Settings, DateTime } from "luxon";
import Highcharts from "highcharts/highstock";

import { isValue } from "../core/utils";
import { CODES } from "../conf/errors";
import {
  NETWORK_BLOCK_TYPES,
  SUBSTATION_BLOCK_TYPES,
  getValueFromBlock,
  updateStoreValueFromBlock,
} from "../conf/blocks";
import { getLatestProcessedMonth, getLatestProcessedYear, timezoneToMinutes } from "../core/logics";
import { is_feature_flag_on } from "../conf/conf";
import { DEFAULT_TARGET_ENERGY, DEFAULT_TARGET_TEMP } from "../pages/RTAnalysis/constants";

import { autoSave } from "./cache";

const DEFAULT_NETWORK_TIMEZONE = "UTC+01:00";
const DEFAULT_NETWORK_TIMEZONE_NAME = "Central European Time";

export default class Networks {
  current_network = null;
  network_timezone = DEFAULT_NETWORK_TIMEZONE;
  tz_name = DEFAULT_NETWORK_TIMEZONE_NAME;
  coordinates = null;
  current_substations = new Map();
  current_substations_v3_map = new Map();
  current_clusters = new Set();
  _network_feature_set = new Set();
  _test_feature_set = new Set();
  ready = false;
  networks = [];

  sub_ignore_filters = false;
  filtered_view = null;
  filtered_cluster_view = null;
  SUBSTATION_BLOCK = null;
  CLUSTER_BLOCK = null;

  substations_count = null; // Block count
  clusters_count = null;

  active_substations = new Map(); //filtered count
  active_clusters = new Map();

  network_resources_fetched = false;
  hidden_date = DateTime.local();
  lpYear = null;
  lpMonth = null;
  networkActiveYear = null;
  networkActiveMonth = null;
  networkActiveEPMonth = null;
  networkActiveEPMonthClusters = null;
  networkActiveWeek = null;
  preferences = {};

  get CurrentHour() {
    let ctime = null;
    if (is_feature_flag_on("TEST_ADMIN") || this.test_run) {
      ctime = this.hidden_date.setZone(this.network_timezone).startOf("hour");
    } else {
      ctime = DateTime.local().setZone(this.network_timezone).startOf("hour");
    }
    return ctime;
  }

  get LastProcessedMonth() {
    return getLatestProcessedMonth(this.CurrentHour, this.networkActiveMonth);
  }

  get LastProcessedYear() {
    return getLatestProcessedYear(this.CurrentHour, this.networkActiveYear);
  }

  get LastEPMonth() {
    return getLatestProcessedMonth(this.CurrentHour, this.networkActiveEPMonth);
  }

  get LastProcessedWeek() {
    return this.networkActiveWeek;
  }

  updateNetworkWeek(ts) {
    this.networkActiveWeek = ts;
  }

  dtFromYM(year, month, boundary = "start") {
    let rdate;
    if (boundary === "start") {
      if (month) {
        rdate = DateTime.fromObject({ year, month }).startOf("month");
      } else {
        rdate = DateTime.fromObject({ year, month: 6 }).startOf("year");
      }
    } else {
      if (month) {
        rdate = DateTime.fromObject({ year, month }).endOf("month").plus({ seconds: 1 });
      } else {
        rdate = DateTime.fromObject({ year, month: 6 }).endOf("year").plus({ seconds: 1 });
      }
    }
    return rdate;
  }

  setTestDate(dt, timezone = DEFAULT_NETWORK_TIMEZONE) {
    this.test_run = true;
    this.network_timezone = timezone;
    this.hidden_date = dt;
  }

  get timezone_minutes() {
    return timezoneToMinutes(this.network_timezone);
  }

  constructor(root) {
    makeObservable(this, {
      current_network: observable,
      network_timezone: observable,
      coordinates: observable,
      current_substations: observable,
      current_substations_v3_map: observable,
      current_clusters: observable,
      ready: observable,
      networks: observable,
      sub_ignore_filters: observable,
      filtered_view: observable,
      filtered_cluster_view: observable,
      SUBSTATION_BLOCK: observable,
      CLUSTER_BLOCK: observable,
      substations_count: observable,
      clusters_count: observable,
      active_substations: observable,
      active_clusters: observable,
      network_resources_fetched: observable,
      hidden_date: observable,
      lpYear: observable,
      lpMonth: observable,
      networkActiveYear: observable,
      networkActiveMonth: observable,
      networkActiveEPMonth: observable,
      networkActiveWeek: observable,
      preferences: observable,
      updateNetworkWeek: action.bound,
      setTestDate: action.bound,
      allSubstationCluster: computed,
      loadFromCache: action.bound,
      updateCurrentSubstation: action.bound,
      network_names: computed,
      filtered_substation_count: computed,
      filterBlock: action.bound,
      filterClusterBlock: action.bound,
      updateNetworksFromNames: action.bound,
      refreshTime: action.bound,
      setSubIgnoreFilters: action.bound,
      _network_feature_set: observable,
      _test_feature_set: observable,
      updateNetworkFeatureSet: action.bound,
      features: computed,
    });

    this.parent = root;
    this.has_run = false; // if not run and no cache , run
    this.test_run = false;
    this.SUBSTATION_BLOCK = {};
    this.CLUSTER_BLOCK = null;
    this.loadFromCache();
    this.shouldStore = this.shouldStore.bind(this);
    this.saveToCache = this.saveToCache.bind(this);

    autoSave(this, this.shouldStore, this.saveToCache);
  }

  shouldStore() {
    return [this.current_network, this.hidden_date, this.network_timezone];
  }

  get features() {
    if (is_feature_flag_on("TEST_ADMIN") || this.test_run) {
      // this check is required security wise, use should not able able to override using localstorage.
      if (this._test_feature_set) {
        return this._test_feature_set;
      } else {
        return this._network_feature_set;
      }
    }
    return this._network_feature_set;
  }

  get allSubstationCluster() {
    const targetName = `all$${this.current_network.name}`;
    for (let [cClusterUid, cClusterName] of this.active_clusters) {
      if (cClusterName === targetName) {
        return cClusterUid;
      }
    }
    return null;
  }

  loadFromCache() {
    let pd = null;
    let d = localStorage.getItem("networks");
    if (d) {
      pd = JSON.parse(d);
      if (pd.hasOwnProperty("networks")) {
        this.networks = pd.networks;
      }
      if (pd.hasOwnProperty("current_network")) {
        this.current_network = pd.current_network;
      }
      if (pd.hasOwnProperty("tz")) {
        this.network_timezone = pd.tz;
      }
      if (pd.hasOwnProperty("ctime")) {
        if (pd.ctime) {
          this.hidden_date = DateTime.fromISO(pd.ctime).setZone(this.network_timezone);
        }
      }
    }
    let fa = localStorage.getItem("feature_access");
    if (fa) {
      pd = JSON.parse(fa);
      this._test_feature_set = new Set(pd);
    }
  }

  updateCurrentSubstation(substation) {
    // DEPRECATE it
    this.parent.sub.updateCurrentSubstation(substation);
  }

  updateNetworkFeatureSet(set) {
    this._network_feature_set = set;
  }

  saveToCache() {
    const tocache = JSON.stringify({
      current_network: this.current_network,
      networks: this.networks,
      ctime: this.hidden_date.toISO(),
      tz: this.network_timezone,
    });
    localStorage.setItem("networks", tocache);
  }

  clearCache() {
    localStorage.removeItem("networks");
  }

  get network_names() {
    return this.networks;
  }

  get filtered_substation_count() {
    if (this.sub_ignore_filters) {
      return this.current_substations.size;
    } else {
      if (this.ready) {
        return this.active_substations.size;
      } else {
        return this.current_substations.size;
      }
    }
  }

  /* generate filtered_view */
  filterBlock() {
    if (!this.ready) {
      return;
    }
    const required_blocks = this.getGlobalFilterRequiredBlocks();
    const lastProcessedMonth = this.lpYear;
    const filters = this.parent.filters;
    const network_id = this.current_network.uid;
    const block_data = this.parent.newapi.getInfoBlocksFromCache({
      resource_type: "network_substations",
      resource_id: this.current_network.uid,
      filter_by: "",
      block_names: required_blocks,
    });
    const newActiveSubstations = new Map(this.current_substations);

    for (let [filterParam, filterData] of filters.filters.entries()) {
      if (this.sub_ignore_filters || !filterData.get("is_active")) {
        continue;
      }
      if (filterData.get("type") !== "favs" && Object.keys(block_data).length <= 0) {
        continue;
      }

      let filterState = filterData.get("state");
      let filterYear = lastProcessedMonth.year;
      let filterMonth = null;
      let filterBlockName = "";
      if (filterData.get("type") === "categorical" || filterData.get("type") === "histogram") {
        filterYear = filterState.year || lastProcessedMonth.year;
        filterMonth = filterState.month || null;
        filterBlockName = SUBSTATION_BLOCK_TYPES[filterData.get("block")].to_block_name({
          year: filterYear,
          month: filterMonth,
        });
      }
      if (filterData.get("type") === "favs") {
        const favs = this.parent.preferences.fav_subs.get(network_id);
        if (filterState.all === "fav") {
          for (let sub of newActiveSubstations.keys()) {
            if ((favs && !favs.has(sub)) || !favs) {
              newActiveSubstations.delete(sub);
            }
          }
        }
        if (filterState.all === "non-fav") {
          for (let sub of newActiveSubstations.keys()) {
            if (favs && favs.has(sub)) {
              newActiveSubstations.delete(sub);
            }
          }
        }
      }

      // All data is to be returned, so no need to filter
      // TODO: Filters should apply to all data, not just the selected data, but the min max isnt set when loaded, and i dont have time to find it
      if (filterState.all) {
        continue;
      }
      if (filterData.get("type") === "categorical" && filterState.selected) {
        let selectedOptions = new Set(filterState.selected);
        for (let sub of newActiveSubstations.keys()) {
          let value = getValueFromBlock(block_data, filterBlockName, sub, filterParam);
          if (!selectedOptions.has(value)) {
            newActiveSubstations.delete(sub);
          }
        }
      }

      if (filterData.get("type") === "histogram") {
        for (let sub of newActiveSubstations.keys()) {
          let Val = getValueFromBlock(block_data, filterBlockName, sub, filterParam);
          if (isValue(Val)) {
            if (
              (filterState.min && filterState.min >= Val) ||
              (filterState.max && filterState.max < Val)
            ) {
              newActiveSubstations.delete(sub);
            }
          } else if (filterState.exclude) {
            newActiveSubstations.delete(sub);
          }
        }
      }
    }
    this.active_substations = newActiveSubstations;
  }

  /* generate filtered_view */
  filterClusterBlock() {
    // TODO: needs updating for new blockstorage.
    const filters = this.parent.filters;
    this.active_clusters.clear();

    if (this.CLUSTER_BLOCK && this.CLUSTER_BLOCK.df) {
      let source_rows = this.CLUSTER_BLOCK.df.rows;
      this.active_clusters = new Map(this.CLUSTER_BLOCK.df.idx);
      for (let i = 0; i < source_rows.length; i++) {
        for (let flt_data of filters.filters.values()) {
          let state = flt_data.get("state");
          if (!flt_data.get("is_active")) {
            continue;
          }
          let colIndex = this.CLUSTER_BLOCK.df.columns.get(
            flt_data.get("param") + "$" + flt_data.get("block")
          );
          if (!colIndex) {
            continue;
          }
          if (flt_data.get("type") === "categorical" && !state.all) {
            if (state.selected && !state.selected.has(source_rows[i][colIndex])) {
              this.active_clusters.delete(source_rows[i][0]);
            }
          }

          if (flt_data.get("type") === "histogram") {
            let rowVal = source_rows[i][colIndex];
            if (
              (!state.all && state.min && state.min >= rowVal) ||
              (state.max && state.max <= rowVal)
            ) {
              this.active_clusters.delete(source_rows[i][0]);
            }
            if (!state.exclude && !isValue(rowVal)) {
              this.active_clusters.delete(source_rows[i][0]);
            }
          }
        }
      }
      if (!this.sub_ignore_filters) {
        let nrows = [];
        this.active_clusters.forEach((rowId, subId) => {
          nrows.push(this.CLUSTER_BLOCK.df.rows[rowId]);
        });
        this.filtered_cluster_view = {
          rows: nrows,
          columns: this.CLUSTER_BLOCK.df.columns,
        };
      } else {
        this.filtered_cluster_view = {
          rows: source_rows,
          columns: this.CLUSTER_BLOCK.df.columns,
        };
      }
    } else {
      this.filtered_cluster_view = {};
    }
  }

  /* keep substations in sync with filters */
  async keepFilteredUpdated() {
    this.__keep_filtered_updated = reaction(
      () => {
        return {
          block: Object.keys(this.SUBSTATION_BLOCK),
          ign: this.sub_ignore_filters,
          net: this.current_network,
          filters: Array.from(this.parent.filters.filters.entries()).map(([a, b]) => {
            let s = b.get("state");
            if (!b.get("is_active")) {
              return null;
            }
            return {
              name: a,
              is_active: b.get("is_active"),
              all: s.all,
              sel: s.selected.size,
              min: s.min,
              max: s.max,
              year: s.year,
              month: s.month,
              exclude: s.exclude,
            };
          }),
        };
      },
      (data) => {
        this.filterBlock();
      },
      { fireImmediately: true }
    );
  }

  updateNetworksFromNames(networknames) {
    this.networks = networknames;
    if (
      this.networks &&
      this.current_network &&
      this.networks.filter((cn) => cn.uid === this.current_network.uid).length === 0
    ) {
      this.current_network = null;
    }
  }

  selectDefaultCurrentNetwork = flow(function* (new_current_network = null) {
    if (this.networks && this.networks.length > 0) {
      // we have a previous current_network which is fine for now.
      if (
        this.current_network &&
        this.networks.filter((cn) => cn.uid === this.current_network.uid).length > 0
      ) {
        if (!this.has_run) {
          yield this.selectNetwork(this.current_network);
        }
        // we are good here.
      } else {
        // our old current network is not valid now.
        // use new_network provided then select first one as random
        if (new_current_network && this.networks.includes(new_current_network)) {
          yield this.selectNetwork(new_current_network);
        } else {
          if (this.networks.length > 0) {
            yield this.selectNetwork(this.networks[0]);
          }
        }
      }
    }
  });

  refresh = flow(function* () {
    try {
      let [networknames, rc] = yield this.parent.utfapi.getNetworksForUserV4();
      if (rc === 404 || rc === 401 || networknames.length === 0) {
        if (rc === 401) {
          yield this.parent.utfapi.registerUserInMDSL();
        }
        const userProfile = yield this.parent.session.getUserProfile();
        if (userProfile) {
          this.parent.app.gotError(CODES.no_permission, {
            msg: `you do not have permission to any network, please contact your administrator, with your user_id : ${userProfile.sub}`,
          });
        }
        return;
      }
      this.updateNetworksFromNames(networknames);
      yield this.selectDefaultCurrentNetwork();
    } catch (err) {
      console.log(err);
    }
  });

  check = flow(function* () {
    if (!this.has_run) {
      yield this.refresh();
      this.has_run = true;
    }
  });

  updateUserPreferences = flow(function* () {
    try {
      let serve_pref = yield this.parent.utfapi.getPreferences();
      if (serve_pref) {
        this.parent.preferences.fromJson(serve_pref);
      }
    } catch (err) {
      console.log("unable to get user preferences");
      this.parent.preferences.disableSync();
    }
  });

  haveAccess = (access_list) => {
    if (is_feature_flag_on("FEATURE_ACCESS_RESTRICTION")) {
      if (access_list === undefined || access_list === null || access_list.length === 0) {
        return true;
      }
      for (let access of access_list) {
        if (this.features.has(access)) {
          return true;
        }
      }
      return false;
    } else {
      return true;
    }
  };

  updateNetworkResources = flow(function* (network) {
    let current_network = network || this.current_network;
    try {
      if (this.networks.filter((cn) => cn.uid === current_network.uid).length > 0) {
        let resources = yield this.parent.utfapi.getNetworkResourcesV4(current_network.uid);
        this.current_substations = new Map(resources.substations.map((k) => [k.uid, k.name]));
        this._network_feature_set = new Set((resources.frontend_features || []).map((k) => k.name));
        this.current_substations_v3_map = new Map(
          resources.substations.map((k) => [k.name, k.uid])
        );
        if (resources.substations) {
          this.active_substations = new Map(resources.substations.map((s) => [s.uid, s.name]));
        }
        if (resources.clusters !== null) {
          this.current_clusters = new Map(resources.clusters.map((c) => [c.uid, c.name]));
        } else {
          this.current_clusters = new Map();
        }
        if (resources.clusters) {
          this.active_clusters = new Map(resources.clusters.map((c) => [c.uid, c.name]));
        }
      }
    } catch (err) {
      console.log("updateNetworkResources", err);
    }
  });

  updateNetworkTimezone = flow(
    function* () {
      let network_uid = this.current_network.uid;
      try {
        let data = yield this.parent.newapi.getInfoBlocksV4({
          resource_type: "network",
          resource_id: network_uid,
          block_names: [
            NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
            NETWORK_BLOCK_TYPES.lava_calc.to_block_name(),
            NETWORK_BLOCK_TYPES.location.to_block_name(),
          ],
        });
        updateStoreValueFromBlock(
          this,
          "tz_name",
          data,
          NETWORK_BLOCK_TYPES.location.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.location.col.tz_name,
          DEFAULT_NETWORK_TIMEZONE
        );
        updateStoreValueFromBlock(
          this,
          "network_timezone",
          data,
          NETWORK_BLOCK_TYPES.location.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.location.col.timezone,
          DEFAULT_NETWORK_TIMEZONE
        );
        updateStoreValueFromBlock(
          this,
          "coordinates",
          data,
          NETWORK_BLOCK_TYPES.location.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.location.col.coordinates,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveYear",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.core_year,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveMonth",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.core_month,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveWeek",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.week_nr,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveEPMonth",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.ep_month,
          null
        );
        updateStoreValueFromBlock(
          this,
          "networkActiveEPMonthClusters",
          data,
          NETWORK_BLOCK_TYPES.ufint_latest.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.ufint_latest.col.ep_month_clusters,
          null
        );
        updateStoreValueFromBlock(
          this.parent.rta,
          "target_temp",
          data,
          NETWORK_BLOCK_TYPES.lava_calc.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.lava_calc.col.returntemp_target,
          DEFAULT_TARGET_TEMP
        );
        updateStoreValueFromBlock(
          this.parent.rta,
          "target_energy",
          data,
          NETWORK_BLOCK_TYPES.lava_calc.to_block_name(),
          network_uid,
          NETWORK_BLOCK_TYPES.lava_calc.col.efficiency_value,
          DEFAULT_TARGET_ENERGY
        );
      } catch (err) {
        console.log(err);
      }

      // Set timezones for 3rd party libraries
      Highcharts.setOptions({
        time: {
          timezoneOffset: this.timezone_minutes,
        },
      });

      // for Luxon instances
      Settings.defaultZone = this.network_timezone;

      // Notify user if browser timezone is different than the networks'
      const tzNotificationStorageKey = "timezoneNotificationShown";
      try {
        const browserTimeZoneOffset = Math.abs(new Date().getTimezoneOffset());
        const systemNow = DateTime.now();
        const notificationShown = sessionStorage.getItem(tzNotificationStorageKey);
        if (systemNow.o !== browserTimeZoneOffset && notificationShown !== "true") {
          this.parent.notifStore.info(
            // eslint-disable-next-line no-underscore-dangle
            `Your timezone is different than the networks timezone. All data will be displayed in "${systemNow._zone.name}" timezone.`
          );
          sessionStorage.setItem(tzNotificationStorageKey, true);
        }
      } catch (e) {
        console.log(e);
      }

      this.refreshTime();
    }.bind(this)
  );

  refreshTime() {
    const k = this.CurrentHour;
    this.currentHour = k;
    this.lpMonth = getLatestProcessedMonth(k, this.networkActiveMonth);
    this.lpYear = getLatestProcessedYear(k, this.networkActiveYear);
  }

  selectNetwork = flow(function* (network) {
    if (this.networks.filter((cn) => cn.uid === network.uid).length > 0) {
      this.ready = false;
      this.filter_set = new Set();
      try {
        this.parent.newapi.trimCache();
        this.current_network = network;
        yield this.updateNetworkTimezone();
        this.updateCurrentSubstation(null);
        yield this.updateUserPreferences();
        yield this.updateNetworkResources();
        yield this.fetchGlobalFiltersBlock();
        this.ready = true;
      } catch (err) {
        console.log(err);
      }
    }
  });

  setSubIgnoreFilters(event) {
    this.sub_ignore_filters = event.target.checked;
  }

  getGlobalFilterRequiredBlocks() {
    const lastProcessedMonth = this.lpYear;
    const filters = this.parent.filters;
    const required_blocks = new Set();
    for (let [, filterData] of filters.filters.entries()) {
      let filterState = filterData.get("state");
      if (!filterData.get("is_active") || filterState.all) {
        continue;
      }
      let filterYear = lastProcessedMonth.year;
      let filterMonth = null;
      let filterBlockName = "";
      if (filterData.get("type") !== "favs") {
        if (filterState.year) {
          filterYear = filterState.year;
        }
        if (filterState.month) {
          filterMonth = filterState.month;
        }
        filterBlockName = SUBSTATION_BLOCK_TYPES[filterData.get("block")].to_block_name({
          year: filterYear,
          month: filterMonth,
        });
        required_blocks.add(filterBlockName);
      }
    }
    return Array.from(required_blocks);
  }

  fetchGlobalFiltersBlock = flow(function* () {
    const required_blocks = this.getGlobalFilterRequiredBlocks();
    yield this.parent.newapi.getInfoBlocksV4({
      resource_type: "network_substations",
      resource_id: this.current_network.uid,
      block_names: required_blocks,
    });
  });
}
