import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { observer } from "mobx-react";

import FUnitValue from "../analytics/FUnitValue";
import FEATURE from "../../conf/feature_access";
import useStores from "../../hooks/useStores";

import { CLUSTER, SUBSTATION } from "./constant";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    borderBottom: `2px solid ${theme.palette.grey.blue12}`,
    backgroundColor: theme.palette.grey.blue2,
    height: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey.blue4,
    },
  },
  odd: {
    backgroundColor: theme.palette.grey.white,
  },
  current: {
    backgroundColor: theme.palette.grey.blue,
  },
  clickable: {
    cursor: "pointer",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    colors: theme.palette.grey[800],
    justifyContent: "space-between",
    paddingTop: theme.spacing(0.5),
  },
  titleBlock: {
    display: "block",
    width: 150,
    fontFamily: "Roboto",
    color: theme.palette.grey.blue72,
  },
  topReading: {
    ...theme.typography.h3,
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    color: theme.palette.secondary.main,
    justifyContent: "flex-end",
  },
  streetName: {
    ...theme.typography.h6,
    color: theme.palette.grey.blue50,
    paddingTop: theme.spacing(1.5),
    fontSize: 12,
  },
  subName: {
    ...theme.typography.h3,
    fontSize: 14,
    lineHeight: 1,
    color: theme.palette.grey.blue50,
  },
  clusterWrapper: {
    paddingTop: theme.spacing(2),
  },
}));

function SubRow({ subUid, reader, index, isCluster }) {
  const { ui, sub, networks } = useStores();
  const classes = useStyles();
  const rowType = isCluster ? CLUSTER : SUBSTATION;
  const substation = networks.current_substations.get(subUid);
  const cluster = isCluster && reader.filter((r) => r.uid === subUid);
  // temp solution until the API is done
  // TODO: The reader shouldnt be used on each row, its better to read either on the page or store level, and pass the data to the table
  const det = isCluster ? cluster[0] : reader(subUid);

  const onClick = () => {
    if (det.uid) {
      if (
        networks.haveAccess([FEATURE.cluster_overview, FEATURE.cluster_consumption]) &&
        isCluster
      ) {
        ui.openSubCluster();
        sub.updateCurrentCluster({ id: det.uid, name: det.name.split("$")[0] });
      }
    } else if (networks.haveAccess([FEATURE.substation_overview, FEATURE.substation_consumption])) {
      sub.updateCurrentSubstation(subUid);
      ui.openSubsummary();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={clsx(classes.root, {
        [classes.current]: sub.current_substation === substation,
        [classes.odd]: index % 2 !== 0,
        [classes.clickable]: det?.uid,
      })}
      onClick={onClick}
      data-testid={`${rowType}_sidebar-list-item`}
    >
      <div
        className={clsx(classes.top, {
          [classes.clusterWrapper]: isCluster,
        })}
      >
        {isCluster ? (
          <div className={classes.subName}>{det?.name.split("$")[0] || "..."}</div>
        ) : (
          <Tooltip
            title={det?.customer}
            TransitionProps={{ "data-maskdata": "true" }}
            componentsProps={{
              tooltip: {
                sx: {
                  cursor: "pointer",
                },
              },
            }}
          >
            <div className={classes.titleBlock} data-maskdata>
              <Typography noWrap variant="h6">
                {det?.customer || "..."}
              </Typography>
            </div>
          </Tooltip>
        )}
        {!isCluster && (
          <div className={classes.topReading} data-maskdata>
            <FUnitValue num={det.heat} unit="kWh" />
          </div>
        )}
      </div>
      {!isCluster && (
        <div data-maskdata>
          <div className={classes.subName}>{det?.street}</div>
          <div className={classes.streetName}>{substation}</div>
        </div>
      )}
    </div>
  );
}

export default observer(SubRow);
