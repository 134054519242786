import makeStyles from "@mui/styles/makeStyles";
import Tab from "@mui/material/Tab";

import LockIcon from "../../icons/Lock";

const useStyles = makeStyles((theme) => ({
  root: {
    "&&": {
      boxShadow: "0 1px 3px 0 #00000029",
      height: theme.spacing(7),
      opacity: 1,
      minWidth: 0,
      justifyContent: "center",
      color: theme.palette.primary.main,
    },
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    "&$selected": {
      "&&": {
        color: theme.palette.secondary.main,
      },
    },
    "&:focus": {
      color: theme.palette.secondary.main,
    },
    "&:disabled": {
      color: theme.palette.grey.blue28,
    },
    ...theme.typography.button,
  },
  labelIcon: {
    minHeight: theme.spacing(7),
  },
  IconWrap: {
    "&&": {
      marginBottom: "0px !important",
      paddingLeft: 16,
      paddingTop: 6,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  lockIcon: {},
  wrapper: {
    flexDirection: "row-reverse",
  },
  selected: {
    "&&": {
      color: theme.palette.secondary.main,
      boxShadow: "none",
      backgroundColor: theme.palette.grey.blue4,
    },
  },
}));

function UtfTab({ children, isLocked = false, ...props }) {
  const classes = useStyles();
  const baseProps = {};
  if (isLocked) {
    baseProps.icon = (
      <div className={classes.IconWrap} data-testid="tab-lock-icon">
        <LockIcon className={classes.lockIcon} />
      </div>
    );
  }
  return (
    <Tab
      classes={{
        root: classes.root,
        selected: classes.selected,
        wrapper: classes.wrapper,
        labelIcon: classes.labelIcon,
      }}
      data-testid={`tab-${props.value}`}
      {...baseProps}
      {...props}
    >
      {children}
    </Tab>
  );
}

export default UtfTab;
