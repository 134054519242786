import { Fragment } from "react";
import { Box, Typography } from "@mui/material";

function GraphTop(props) {
  const { unit, legend, legendmarkerwidth = "wide", unittextvariant = "subtitle2", dense } = props;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mx={1}
      mt={dense ? 1 : 3}
    >
      <Typography variant={unittextvariant} color="secondary" sx={{ textTransform: "lowercase" }}>
        {unit}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        {legend.map((l) => (
          <Fragment key={l.name}>
            <Box
              width={legendmarkerwidth === "wide" ? 16 : 2}
              height={16}
              bgcolor={l.color}
              mx={1}
            />
            <Typography variant="caption">{l.name}</Typography>
          </Fragment>
        ))}
        <Box mr={1} />
      </Box>
      <div />
    </Box>
  );
}

export default GraphTop;
