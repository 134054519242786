import { flow, makeAutoObservable } from "mobx";

import urls from "../../../conf/urls";

export default class FaultDetectionApi {
  faultDetectionKey = "fault-detection-visibility";

  constructor(parent) {
    this.parent = parent;
    makeAutoObservable(this);
  }

  // This gets the faults for a specific network between two dates
  getFaultsForNetwork = flow(function* (networkId, fromDate, toDate) {
    const headers = yield this.parent.session.authHeaders();
    const params = {
      "datetime[ge]": fromDate,
      "datetime[lt]": toDate,
    };
    const response = yield fetch(
      `${urls.mdslv4}/networks/${networkId}/substations/faults?${new URLSearchParams(params)}`,
      {
        method: "GET",
        headers,
      }
    );
    if (response.ok) {
      return yield response.json();
    }
    throw new Error("Failed to get faults");
  });

  getSubstationFaultStatus = flow(function* (substationId) {
    // For now we have to get the full substation data, and then filter it
    // https://utilifeed.atlassian.net/wiki/spaces/DV/pages/3012395089/Fault+detection+Finalised+API#GET-status-for-substation
    // TODO: [PLAT-2793] Add an endpoint to get a single info-property on a substation
    const headers = yield this.parent.session.authHeaders();
    const response = yield fetch(`${urls.mdslv4}/substations/${substationId}`, {
      method: "GET",
      headers,
    });
    if (response.ok) {
      const substation = yield response.json();
      const faultStatus = substation.data.info_properties.find(
        (prop) => prop.key === this.faultDetectionKey
      );
      return faultStatus ? faultStatus.value : null;
    }
    throw new Error("Failed to get substation fault status");
  });

  setSubstationFaultStatus = flow(function* (networkId, substationId, status) {
    // PUT /networks/{network_uid}/substations/info-properties
    // https://utilifeed.atlassian.net/wiki/spaces/DV/pages/3012395089/Fault+detection+Finalised+API#GET-status-for-substation
    const headers = yield this.parent.session.authHeaders();
    const response = yield fetch(
      `${urls.mdslv4}/networks/${networkId}/substations/info-properties`,
      {
        method: "PUT",
        headers: {
          ...headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify([
          {
            property_key: this.faultDetectionKey,
            substation_uid: substationId,
            value: status.toLowerCase(),
          },
        ]),
      }
    );
    if (response.ok) {
      return yield response.json();
    }
    throw new Error("Failed to set substation fault status");
  });
}
