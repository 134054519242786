import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
    root: {
        width: theme.spacing(1),
        display: "flex",
        flexDirection: "row",
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: theme.spacing(1),
    }
})

function VerticalSeparator(props) {
    const { classes } = props;

    return <div className={classes.root} />
}

export default withStyles(styles)(VerticalSeparator)
