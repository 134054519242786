import React from "react";
import { observer, Observer } from "mobx-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import makeStyles from "@mui/styles/makeStyles";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { useTranslation } from "react-i18next";

import UtfTab from "../../ui/UtfTab";
import UtfTabs from "../../ui/UtfTabs";
import StarIcon from "../../../icons/Star";
import SearchIcon from "../../../icons/Search";
import useStores from "../../../hooks/useStores";
import StarFullIcon from "../../../icons/Starfull";
import { BAR_HEIGHT, MAX_MENU_WIDTH } from "../../../conf/ui_constants";
import { blkReader } from "../../../conf/blocks";
import { NETWORK_SUBSTATION } from "../constant";

import useSubExplore from "./useSubExplore";
import renderRow from "./renderRow";

const FAVORITE = "favorite";
const LIST = "list";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    height: "100%",
  },
  searchBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.grey.blue2,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey.white,
    height: "48px",
    margin: theme.spacing(0, 2),
    marginBottom: "12px",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
  inputRoot: {
    color: "inherit",
    height: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    fontSize: 13,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
  tabsOverride: {
    minWidth: 0,
  },
  substationCount: {
    ...theme.typography.h6,
  },
}));

function SubStationExploreComponent() {
  const classes = useStyles();
  const { networks } = useStores();
  const { t } = useTranslation(["_action", "_messages"]);
  const explore = useSubExplore({ type: NETWORK_SUBSTATION });
  const reader = blkReader(explore.substations, explore.colSpecs);

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="100%"
      height="100%"
      data-testid="substation-explore"
    >
      <UtfTabs
        value={explore.state.currentTab}
        variant="fullWidth"
        onChange={explore.handleTabChange}
        classes={{ root: classes.tabsOverride }}
      >
        <UtfTab
          component="div"
          value={LIST}
          label="ALL"
          style={{ paddingLeft: 30, paddingRight: 26 }}
          data-testid="substations-all-tab"
        />
        <UtfTab
          component="div"
          value={FAVORITE}
          icon={explore.state.currentTab === FAVORITE ? <StarFullIcon /> : <StarIcon />}
          data-testid="substations-favorite-tab"
        />
      </UtfTabs>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        height={BAR_HEIGHT}
        bgcolor="background.default"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          ml="auto"
          mr="auto"
        >
          <Switch
            checked={networks.sub_ignore_filters}
            onChange={networks.setSubIgnoreFilters}
            value="ignore_filters"
            data-testid="substations-ignore-filter-switch"
          />
          <Typography variant="h5">{t("action_ignore_filter", { ns: "_action" })}</Typography>
        </Box>

        <Box mr={1}>
          <Tooltip title={t("message_clear_favourites_substation", { ns: "_messages" })}>
            <HelpOutlineIcon color="primary" />
          </Tooltip>
        </Box>
      </Box>
      <div className={classes.searchContainer}>
        <div className={classes.searchBox}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={t("search_type_to_find_a_substation", { ns: "_action" })}
              value={explore.inputText}
              onChange={explore.onInputChange}
              fullWidth
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search", "data-maskdata": "true" }}
              data-testid="substations-search-input"
            />
          </div>
          <Box mb={1} mx={2} display="flex" justifyContent="space-between" alignItems="center">
            <div className={classes.substationCount}>
              {t("text_showing")}{" "}
              {explore.filteredLength > 0 && explore.totalCount >= 0
                ? `${explore.totalCount}/${explore.filteredLength}`
                : "NA"}{" "}
              {t("text_substations")}
            </div>
            {explore.state.currentTab === FAVORITE && (
              <Button color="primary" onClick={explore.handleClearList}>
                {t("action_clear_list", { ns: "_action" })}
              </Button>
            )}
          </Box>
        </div>

        <Box height={16} bgcolor="background.paper" />

        {explore.state.force && explore.state.currentTab === FAVORITE && (
          <Box px={2}>
            <Typography variant="body2" color="error" style={{ marginBottom: 8 }}>
              {t("message_confirm_to_remove_all_favourites", { ns: "_messages" })}
            </Typography>
            <Button
              onClick={explore.handleClearListInner}
              color="primary"
              variant="contained"
              style={{ marginBottom: 8 }}
            >
              {t("action_clear", { ns: "_action" })}
            </Button>
          </Box>
        )}
        <Box
          display="flex"
          flexGrow={1}
          bgcolor="background.paper"
          data-testid="substation-list-container"
        >
          <AutoSizer>
            {({ height, width }) => (
              <Observer>
                {() => (
                  <FixedSizeList
                    height={height - 32}
                    width={width}
                    itemSize={MAX_MENU_WIDTH}
                    itemData={{ list: explore.filteredList, reader }}
                    itemCount={explore.totalCount}
                    rowHeight={180}
                  >
                    {renderRow}
                  </FixedSizeList>
                )}
              </Observer>
            )}
          </AutoSizer>
        </Box>
      </div>
    </Box>
  );
}

export default observer(SubStationExploreComponent);
